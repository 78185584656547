import React from 'react'

export const interestDetailsColumns = [
  {
    title: <span className='font-bold'>Instalment</span>,
    dataIndex: 'first_name',
    key: 'first_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.first_name?.localeCompare(b.name)
  },
  {
    title: <span className='font-bold'>Amount Due</span>,
    dataIndex: 'last_name',
    key: 'last_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.last_name?.localeCompare(b.last_name)
  },
  {
    title: <span className='font-bold'>Type</span>,
    dataIndex: 'business_name',
    key: 'business_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.business_name?.localeCompare(b.business_name)
  },
  {
    title: <span className='font-bold'>Due Date</span>,
    dataIndex: 'email',
    key: 'email',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.email?.localeCompare(b.email)
  },
  {
    title: <span className='font-bold'>Status</span>,
    dataIndex: 'phone_number',
    key: 'phone_number'
  }
]

export const disbursementDetailsColumns = [
  {
    title: <span className='font-bold'>Customer</span>,
    dataIndex: 'first_name',
    key: 'first_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.first_name?.localeCompare(b.name)
  },
  {
    title: <span className='font-bold'>Amt Disbursed</span>,
    dataIndex: 'last_name',
    key: 'last_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.last_name?.localeCompare(b.last_name)
  },
  {
    title: <span className='font-bold'>Bank Account</span>,
    dataIndex: 'business_name',
    key: 'business_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.business_name?.localeCompare(b.business_name)
  },
  {
    title: <span className='font-bold'>Start Date</span>,
    dataIndex: 'email',
    key: 'email',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.email?.localeCompare(b.email)
  },
  {
    title: <span className='font-bold'>End Date</span>,
    dataIndex: 'phone_number',
    key: 'phone_number'
  },
  {
    title: <span className='font-bold'>Status</span>,
    dataIndex: 'phone_number',
    key: 'phone_number'
  }
]
