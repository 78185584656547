import React, { useRef } from 'react'
import GetStarted from '../../sharedComponents/HomePage/GetStarted'
import GetIntouch from '../../sharedComponents/footer/GetIntouch'
import Benefits from './components/Benefits'
import { CareerHeader } from './components/CareerHeader'
import CoreValues from './components/CoreValues'
import HowWeCreate from './components/HowWeCreate'
import OpenRoles from './components/OpenRoles'
import VittasPeople from './components/VittasPeople'
import WorkThatMatters from './components/WorkThatMatters'

const CareerPage = () => {
  const openRolesRef = useRef(null)

  const scrollToSection = () => {
    openRolesRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <main className=''>
      <div className='py-10 bg-neutral3'>
        <CareerHeader scrollToSection={scrollToSection} />
      </div>
      <div className='md:px-4 lg:px-20'>
        <WorkThatMatters />
        <HowWeCreate />
        <CoreValues />
        <Benefits />
        <OpenRoles ref={openRolesRef} />
        <VittasPeople />
      </div>
      <GetIntouch />
      <GetStarted />
    </main>
  )
}

export default CareerPage
