import React, { useState } from 'react'
import BankStatementCard from './BankStatementCard'
import { BankStatementCardData } from '../constants'
import BankStatementModal from '../modals/BankStatementModal'
import TotalFundModal from '../modals/TotalFundModal'

const BankStatement = () => {
  const [isBankStatementModalOpen, setIsBankStatementModalOpen] = useState(false)
  const [isTotalFundModalOpen, setIsTotalFundModalOpen] = useState(false)

  return (
    <div className='flex w-full flex-col  p-4'>
      {/* Bank Statement Buttons */}
      <div className='place-items-center justify-end mr-8 flex mb-4'>
        <button
          onClick={() => setIsBankStatementModalOpen(true)}
          className='text-vittasBlue focus:outline-none'
        >
          Add Additional Statement
        </button>
        <button
          onClick={() => setIsTotalFundModalOpen(true)}
          className='border bg-white border-vittasBlue text-vittasBlue px-4 py-2 ml-4 rounded focus:outline-none'
        >
          Total Fund Available
        </button>
      </div>
      <div className='w-full mt-4 grid grid-cols-3 gap-4'>
        {BankStatementCardData.map((card, index) => (
          <BankStatementCard
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
            annualReturn={card.annualReturn}
          />
        ))}
      </div>
      {/* Bank Statement Modal */}
      <BankStatementModal
        showModal={isBankStatementModalOpen}
        setShowModal={setIsBankStatementModalOpen}
      />

      {/* Total Fund Modal */}
      <TotalFundModal showModal={isTotalFundModalOpen} setShowModal={setIsTotalFundModalOpen} />
    </div>
  )
}

export default BankStatement
