import React from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'
import { DeclinedTableColumns } from '../declined-constants'

const DeclinedTable = ({ tableData }) => {
  return (
    <div className='z-40'>
      {tableData && tableData.data && tableData.data.length > 0 ? (
        <Table
          columns={DeclinedTableColumns}
          dataSource={tableData.data}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            current: 1
          }}
        />
      ) : (
        <p>No data available.</p>
      )}
    </div>
  )
}

DeclinedTable.propTypes = {
  tableData: PropTypes.shape({
    data: PropTypes.array.isRequired
  }).isRequired
}

// Default props to prevent errors
DeclinedTable.defaultProps = {
  tableData: { data: [] }
}

export default DeclinedTable
