import React, { useState, useEffect } from 'react'
import { Modal, notification } from 'antd'
import { resetTempPwd } from '../../utils/inputValidator'
import { CHANGE_PWD_BTN, EMAIL_LABEL, CREATE_NEW_PWD, NEW_PWD_LABEL } from './constant'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const ResetTempPwdModal = ({ headerTitle }) => {
  const loading = useSelector(({ loading }) => loading.effects.auth.resetTempPwd)
  const tempChangePwdModal = useSelector(({ auth }) => auth.tempChangePwdModal)

  const [visible, setModal] = useState(false)
  const [showPwd, setShowPwd] = useState(false)

  const inputObj = {
    email: '',
    new_password: ''
  }
  const [input, setInput] = useState(inputObj)

  const handleCancel = () => {
    setModal(false)
    dispatch.auth.tempChangePwdModal({ visible: false, email: null })
  }

  const onChangeText = ({ target }) => {
    setInput({ ...input, [target.name]: target.value })
  }

  const submitFormHandle = () => {
    const validator = resetTempPwd(input)
    if (validator.isValid) {
      dispatch.auth.resetTempPwd(input)
      setInput(inputObj)
    } else {
      notification.error({
        message: 'An error occured',
        description: validator.error
      })
    }
  }

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.nativeEvent.keyCode === 13) {
      submitFormHandle()
    }
  }
  useEffect(() => {
    setModal(tempChangePwdModal?.visible)
  }, [tempChangePwdModal])

  return (
    <>
      <Modal open={visible} onCancel={handleCancel} title={headerTitle} footer={[]} zIndex='1000'>
        <div className='flex flex-wrap mt-2'>
          <div className='w-full text-white'>
            <h1 className='text-black text-2xl mt-2 font-bold text-center'>{CREATE_NEW_PWD}</h1>
            <div className='mt-5'>
              <span className='text-sm text-black text-left'>{EMAIL_LABEL}</span>
              <input
                className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                type='email'
                value={input.email}
                onChange={onChangeText}
                name='email'
              />
            </div>
            <div className='flex items-center mt-6'>
              <div className='w-full'>
                <span className='text-sm text-black text-left'>{NEW_PWD_LABEL}</span>
                <div className='relative pt-2'>
                  <input
                    className='text-base block w-full text-black bg-gray-300 border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:shadow-outline focus:bg-white'
                    type={showPwd ? 'text' : 'password'}
                    value={input.new_password}
                    onChange={onChangeText}
                    name='new_password'
                    onKeyPress={handleKeypress}
                  />

                  <button
                    onClick={() => setShowPwd(!showPwd)}
                    type='submit'
                    className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                  >
                    {showPwd ? <i className='fas fa-eye-slash' /> : <i className='fas fa-eye' />}
                  </button>
                </div>
              </div>
            </div>

            <div className='mt-5'>
              <button
                onClick={submitFormHandle}
                className='py-3 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
              >
                {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                {CHANGE_PWD_BTN}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

ResetTempPwdModal.propTypes = {
  headerTitle: PropTypes.string.isRequired
}
export default ResetTempPwdModal
