const FIRST_NAME = 'Firist Name'
const LAST_NAME = 'Last Name'
const EMAIL = 'Email'
const CANCEL = 'Cancel'
const PHONE_NUMBER = 'Phone Number'
const PASSWORD = 'Password'
const CREATE_ADMIN = 'Create Administrator'
const ROLE = 'Role'
const MAKE_ADMIN = 'Make Admin'
const MAKE_USER_ADMIN = 'Create admin from existing users and asign role'
const USER = 'User'
const VIEW_TRANSACTIONS = 'View Transactions'
const INTEREST_AMOUNT = 'Interest Amount'
const STARTING_DATE = 'Starting Date'
const COMPANY_NAME = 'Company Name'
const RC_NUMBER = 'RC number'
const LICENSE_EXPIRY = 'License expiry'
const NUMBER_OF_LOANS = 'Number of loans'
const DATE_CREATED = 'Date created'
const REGISTERED_BY = 'Registered By'
const NO_OF_LOAN_DEFAULTS = 'No of Loan Defaults'
const REGISTER_COMPANY_BTN = 'Register Company'
const BUSINESS_NAME = 'Business Name'
const REGISTER_COMPANY = 'Register a new company'
const UPDATE_ADMIN = 'Update Admin'
const ADMIN = 'Select Admin'
export {
  UPDATE_ADMIN,
  ADMIN,
  REGISTER_COMPANY,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  REGISTER_COMPANY_BTN,
  BUSINESS_NAME,
  CANCEL,
  PHONE_NUMBER,
  PASSWORD,
  CREATE_ADMIN,
  ROLE,
  MAKE_ADMIN,
  USER,
  MAKE_USER_ADMIN,
  STARTING_DATE,
  VIEW_TRANSACTIONS,
  INTEREST_AMOUNT,
  COMPANY_NAME,
  RC_NUMBER,
  LICENSE_EXPIRY,
  NUMBER_OF_LOANS,
  DATE_CREATED,
  REGISTERED_BY,
  NO_OF_LOAN_DEFAULTS
}
