import React from 'react'
import { MARK_AS_READ, NOTIFICATION_DATA, Read } from './admin-navbar/constants'
import DashboardLayout from './DashboardLayout'

export const Notification = () => {
  return (
    <DashboardLayout>
      <div className='px-2 py-3 flex flex-col'>
        <h3 className='text-gray-600 text-lg'>You have got 5 Notifications</h3>
        <div className='flex justify-between px-8 items-center'>
          <h1 className='text-xl'>Today</h1>
          <button className='app-btn px-4'>{MARK_AS_READ}</button>
        </div>
        {NOTIFICATION_DATA.map(({ id, title, content }) => {
          return (
            <div
              key={id}
              className='mt-6 py-6 px-6 w-full bg-white self-center flex justify-between'
            >
              <div>
                <div className='text-lg'>{title}</div>
                <div className='text-gray-500'>{content}</div>
              </div>
              <button className='border border-gray-300 text-gray-400 font-bold px-6 h-9 hover:bg-blue-100 hover:text-gray-600'>
                {Read}
              </button>
            </div>
          )
        })}
        <h1 className='text-xl mt-4 ml-3'>Yesterday</h1>
        <div className='flex justify-between px-8 items-center'>
          <div className='py-6 px-6 w-full bg-white self-center flex justify-between'>
            <div>
              <div className='text-lg'>Application Declined</div>
              <div className='text-gray-500'>
                Lorem ipsum dolor sit amet consectetur adipiscing Lorem ipsum dolor sit amet
                consectetur...
              </div>
            </div>
            <button className='border border-gray-300 text-gray-400 font-bold px-8 h-9 hover:bg-blue-100 hover:text-gray-600'>
              {Read}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}
