import React from 'react'
import AddDirectorForm from '../../pages/directors/AddDirectorForm'
import PropTypes from 'prop-types'

const AddDirector = ({ openModal, setOpenModal, user }) => {
  return (
    <div
      className={`${
        openModal ? 'block' : 'hidden'
      } absolute w-full flex h-full py-10 justify-center items-center top-0 left-0 z-10 bg-gray-800 bg-opacity-40`}
    >
      <div className='bg-white h-full my-8 flex mx-2 justify-center items-center w-full sm:w-3/4 rounded-xl'>
        <div className='relative p-3 h-full'>
          <button
            onClick={() => setOpenModal(false)}
            className='absolute scale-150 -top-4 grid place-content-center outline-none focus:outline-none cursor-pointer right-1 md-right-2 bg-white w-8 h-8 rounded-full'
          >
            <i className='fa fa-times' />
          </button>
          <div className='h-full overflow-auto'>
            <AddDirectorForm user={user} />
          </div>
        </div>
      </div>
    </div>
  )
}

AddDirector.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  user: PropTypes.object
}

export default AddDirector
