import React from 'react'
import { ReadMore, blogs } from '../constants'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export const BlogItem = ({ item }) => {
  return (
    <div className='mb-10 max-w-screen-xl lg:w-11/12 grid place-content-center hover:shadow-xl  border-blue-300 p-4 border hover:border-blue-400 rounded-lg'>
      <img className='rounded-xl w-full mb-5' src={item.image} alt={item.title} />
      <h2 className='text-lg md:text-xl font-black w-full'>{item.title}</h2>
      <p className='text-header-txt text-sm md:text-lg w-full font-normal'>{item.Brief}</p>
      <Link
        to={`/blog/${item.title}`}
        className='py-3 text-xl font-bold hover:text-gray-300 text-white bg-primary text-center'
      >
        {' '}
        <button>{ReadMore}</button>
      </Link>
    </div>
  )
}

BlogItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    paragraphs: PropTypes.array.isRequired,
    Brief: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }).isRequired
}

const SectionOne = () => {
  return (
    <div className='flex py-3 w-screen mt-[6rem] p-3'>
      <div className='flex flex-col overflow-hidden mt-4 mb-4 max-w-full lg:px-8  w-full'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-6'>
          {blogs.map((item, index) => (
            <BlogItem key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SectionOne
