import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Modal } from 'antd'
import { InputField } from '../../../../sharedComponents/input-field'
import PropTypes from 'prop-types'

const BankStatementModal = ({ showModal, setShowModal }) => {
  const initialValues = {
    bank_name: '',
    account_number: '',
    opening_balance: '',
    closing_balance: '',
    total_credit: '',
    total_debit: '',
    total_turnover: '',
    average_turnover: ''
  }

  const handleSubmit = values => {
    console.log(values)
    // Handle form submission
  }

  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      destroyOnClose
      centered
      width={800}
    >
      <div className='header_modal bg-primary w-full flex justify-between px-3 py-5'>
        <h2 className='text-white font-bold tracking-tighter text-2xl'>Add Bank Statement</h2>
      </div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div className='w-full my-8 px-4'>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                {/* Bank Name */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Bank Name</label>
                  <Field
                    name='bank_name'
                    placeholder='Enter Bank Name'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage name='bank_name' component='small' className='text-red-500' />
                </div>

                {/* Account Number */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Account Number</label>
                  <Field
                    name='account_number'
                    placeholder='Enter Account Number'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage name='account_number' component='small' className='text-red-500' />
                </div>

                {/* Opening Balance */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Opening Balance</label>
                  <Field
                    name='opening_balance'
                    placeholder='Enter Opening Balance'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage name='opening_balance' component='small' className='text-red-500' />
                </div>

                {/* Closing Balance */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Closing Balance</label>
                  <Field
                    name='closing_balance'
                    placeholder='Enter Closing Balance'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage name='closing_balance' component='small' className='text-red-500' />
                </div>

                {/* Total Credit */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Total Credit</label>
                  <Field
                    name='total_credit'
                    placeholder='Enter Total Credit'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage name='total_credit' component='small' className='text-red-500' />
                </div>

                {/* Total Debit */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Total Debit</label>
                  <Field
                    name='total_debit'
                    placeholder='Enter Total Debit'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage name='total_debit' component='small' className='text-red-500' />
                </div>

                {/* Total Turnover */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Total Turnover</label>
                  <Field
                    name='total_turnover'
                    placeholder='Enter Total Turnover'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage name='total_turnover' component='small' className='text-red-500' />
                </div>

                {/* Average Turnover */}
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>Average Turnover</label>
                  <Field
                    name='average_turnover'
                    placeholder='Enter Average Turnover'
                    as={InputField}
                    className='form-control'
                  />
                  <ErrorMessage
                    name='average_turnover'
                    component='small'
                    className='text-red-500'
                  />
                </div>
              </div>

              {/* Add Statement Button */}
              <div className='flex justify-center mt-8'>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
                >
                  Add Statement
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

BankStatementModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
}

export default BankStatementModal
