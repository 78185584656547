/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import {
  EMAIL,
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  NO_OF_KIDS,
  PHONE_NO
} from '../../sharedComponents/entries/constant'
import { ACTIONS } from '../loans/constant'
import { HIGHEST_LEVEL_EDUCATION, TITLE, EMAILCONFIRMATION } from './constants'
import DirectorDetails from './DirectorDetails'
import { useHistory } from 'react-router-dom'
import { dispatch } from '../../redux/store'

export const getColums = setDetails => [
  {
    title: <span className='font-bold'>{FIRST_NAME}</span>,
    dataIndex: 'first_name',
    key: 'first_name',
    render: first_name => <span>{first_name}</span>
  },
  {
    title: <span className='font-bold'>{LAST_NAME}</span>,
    dataIndex: 'last_name',
    key: 'last_name',
    render: last_name => <span>{last_name}</span>
  },
  {
    title: <span className='font-bold'>{EMAIL}</span>,
    dataIndex: 'email',
    key: 'email',
    render: email => <span>{email}</span>
  },
  {
    title: <span className='font-bold'>{TITLE}</span>,
    dataIndex: 'title',
    key: 'title',
    render: title => <span>{title}</span>
  },
  {
    title: <span className='font-bold'>{HIGHEST_LEVEL_EDUCATION}</span>,
    dataIndex: 'education_level',
    key: 'education_level',
    render: educational_level => <span>{educational_level}</span>
  },
  {
    title: <span className='font-bold'>{PHONE_NO}</span>,
    dataIndex: 'phone',
    key: 'phone',
    render: phone => <span>{phone}</span>
  },
  {
    title: <span className='font-bold'>{NO_OF_KIDS}</span>,
    dataIndex: 'num_kids',
    key: 'num_kids',
    render: num_kids => <span>{num_kids}</span>
  },
  {
    title: <span className='font-bold'>{GENDER}</span>,
    dataIndex: 'gender',
    key: 'gender',
    render: gender => <span>{gender}</span>
  },

  {
    title: <span className='font-bold capitalize'>{EMAILCONFIRMATION}</span>,
    key: 'emailConfirmation',
    render: records => (
      <button
        disabled={records?.email_verified}
        onClick={() => dispatch.directors.resendConfirmationEmail({ email: records.email })}
        className='px-6 py-2 border text-primary hover:text-white rounded-lg hover:bg-primary text-sm'
      >
        {records.email_verified ? 'Verified' : 'Resend confirmation'}
      </button>
    )
  },

  {
    title: <span className='font-bold capitalize'>{ACTIONS}</span>,
    key: 'action',
    render: records => (
      <button
        onClick={() => setDetails(records)}
        className='px-6 py-2 border text-primary hover:text-white rounded-lg hover:bg-primary text-sm'
      >
        Edit
      </button>
    )
  }
]

const ListDirectors = ({ user }) => {
  const [selectedData, setCurrentDataSelected] = useState({})
  const [modalVisibility, setModalVisible] = useState(false)
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })
  const history = useHistory()
  const { data, isAdmin, currentUserData, isloading } = useSelector(
    ({ auth: { authUser }, users, loading }) => ({
      data: authUser.userInfo?.data?.directors,
      isAdmin: authUser.userInfo?.is_admin,
      currentUserData: authUser.userInfo?.is_admin
        ? users?.exitingUsers.find(record => user?.id === record.id)
        : [],
      isloading: loading.effects.users.getAllusers
    })
  )

  const setDetails = record => {
    setCurrentDataSelected(record)
    setModalVisible(true)
  }

  useEffect(() => {
    if (isAdmin && !user) {
      history.goBack()
    }
  }, [user])

  return (
    <div className='mb-10'>
      <div className='bg-pastel mb-5 px-3 md:px-5 rounded-lg py-5 text-lg font-semibold text-gray-700 w-full'>
        List of Directors
      </div>
      <div className='overflow-auto'>
        {Object.keys(selectedData).length > 0 && (
          <DirectorDetails
            visible={modalVisibility}
            data={selectedData}
            handleClose={() => setModalVisible(false)}
          />
        )}
        <Table
          loading={isloading}
          columns={getColums(setDetails)}
          onChange={value => setPagination(value)}
          pagination={pagination}
          dataSource={
            isAdmin && !isloading
              ? currentUserData?.directors?.map(item => ({ ...item, key: item.id })) || []
              : data
          }
        />
      </div>
    </div>
  )
}

ListDirectors.propTypes = {
  user: PropTypes.object
}
export default ListDirectors
