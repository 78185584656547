import React from 'react'
import PropTypes from 'prop-types'
import { EXPORT, SEARCH_BY } from '../constant'
import '../Applications.css'
import { exportFile } from '../../../utils/export'
import { Dropdown, Menu, DatePicker, Popover } from 'antd'

const { RangePicker } = DatePicker

const ApplicationSearch = ({ onChange, value, data, handleDate }) => {
  const [filter, setFilter] = React.useState('Amount')

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-1 mx-3 my-4 text-lg'>
      {filter === 'Date' ? (
        <div className='relative rounded-lg mr-6 ml-3 col-span-2' style={{ marginTop: '28px' }}>
          <RangePicker onChange={handleDate} className='w-full h-12' />
        </div>
      ) : (
        <div className='relative mt-6 border rounded-lg mr-6  ml-3 col-span-2'>
          <div className='absolute top-4 left-3 pl-2'>
            <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />{' '}
          </div>
          <input
            type='text'
            onChange={onChange}
            value={value}
            className='h-14 w-full pl-14 pr-20 rounded-lg z-0 focus:shadow focus:outline-none'
            placeholder={SEARCH_BY + ' ' + filter}
          />
        </div>
      )}
      <div className='d-flex mt-6'>
        <div className='group inline-block mr-5 z-50'>
          <div>
            <Popover
              content={
                <div>
                  <p onClick={() => setFilter('Purpose')} className='cursor-pointer'>
                    Purpose
                  </p>
                  <p onClick={() => setFilter('Amount')} className='cursor-pointer'>
                    Amount
                  </p>
                  <p onMouseEnter={() => setFilter('Date')} className='cursor-pointer'>
                    Date
                  </p>
                </div>
              }
              title='Filter by:'
              trigger='hover'
            >
              <div className='flex -mr-px py-3 border px-3 mb-1 cursor-pointer'>
                <span className='flex items-center leading-normal bg-grey-lighter rounded rounded-r-none pr-3 whitespace-no-wrap text-grey-dark text-sm'>
                  <i className='fas fa-filter mr-2' />
                </span>
                <span>Filter</span>
              </div>
            </Popover>
          </div>
        </div>
        <Dropdown menu={menu(data)} placement='bottomRight' arrow>
          <button className='btn-primary-200'>
            {EXPORT} <i className='fas fa-upload ml-2' />
          </button>
        </Dropdown>
      </div>
    </div>
  )
}

export const menu = data => (
  <Menu>
    <Menu.Item>
      <button
        onClick={() => exportFile(data, 'Pdf')}
        disabled={data.data && data?.data?.length < 1}
        title={data?.data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        PDF
      </button>
    </Menu.Item>
    <Menu.Item>
      <button
        onClick={() => exportFile(data, '.xls')}
        disabled={data.data && data?.data?.length < 1}
        title={data?.data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        Excel
      </button>
    </Menu.Item>
  </Menu>
)

ApplicationSearch.propTypes = {
  onChange: PropTypes.func,
  handleDate: PropTypes.func,
  value: PropTypes.string,
  data: PropTypes.shape({
    data: PropTypes.any,
    column: PropTypes.array
  })
}

export default ApplicationSearch
