import React, { useState } from 'react'
import { Modal } from 'antd'
import UpdateRole from './UpdateRole'
import { PropTypes } from 'prop-types'

const UpdateRoleModal = ({ role }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  return (
    <div className=''>
      <a className='text-gray-700' onClick={() => setIsModalVisible(true)}>
        <i className='fas fa-pencil-alt mr-2' aria-hidden='true' />
        Edit Role
      </a>
      <Modal
        title=''
        onCancel={() => setIsModalVisible(false)}
        width='40%'
        open={isModalVisible}
        footer={false}
      >
        <UpdateRole role={role} />
      </Modal>
    </div>
  )
}
UpdateRoleModal.propTypes = {
  role: PropTypes.object
}
export default UpdateRoleModal
