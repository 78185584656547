import { CsvBuilder } from 'filefy'
import JsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

/* Export file */
/**
 * A function that exports data in pdf, csv or excel
 * @param {{ data: object, column: Array }} exportData the data to be exported
 * @param {string | '.csv' | 'Pdf'} fileType the file type we should export to.
 * @param {boolean} custom used to inform the function if the data pattern is custom from the normal one it understands
 * @returns {null | undefined}
 */
export function exportFile(exportData, fileType = '.csv', custom = false) {
  if (!exportData) {
    return null
  }

  const column = exportData.column?.map(({ title }) => title.props.children)
  const actionIndex = column.indexOf('Action')
  if (actionIndex !== -1) {
    column.splice(actionIndex, 1)
  }

  let csvBuilder
  const date = new Date()
  const fileName = date.toISOString() + 'file'
  if (fileType !== undefined) {
    if (fileType !== 'Pdf') {
      csvBuilder = new CsvBuilder(fileName + fileType)
      let index = 1
      let col = ['#', ...column]

      if (custom) {
        col = ['#', ...exportData.column?.map(({ key }) => key?.toUpperCase())]
        csvBuilder.setColumns(col)
        exportData.data.forEach(record => {
          const oneRow = []
          for (const key in record) {
            const currentColumn = col.indexOf(key?.toUpperCase())
            if (currentColumn > -1) {
              oneRow[currentColumn - 1] = record[key]
            }
          }
          csvBuilder.addRow([index++, ...oneRow])
        })
      } else {
        csvBuilder.setColumns(col)
        exportData.data.forEach(record => {
          csvBuilder.addRow([
            index++,
            record.loan_id,
            record.loan_application_initiated,
            record.loan_purpose,
            record.loan_amount,
            record.loan_app_status
          ])
        })
      }

      csvBuilder.exportFile()
    } else if (fileType === 'Pdf') {
      const doc = new JsPDF('landscape', 'pt', 'a4', true)
      let col = ['#', ...column]
      let index = 1
      let rows = []
      if (custom) {
        col = ['#', ...exportData.column?.map(({ key }) => key?.toUpperCase())]
        rows = exportData.data.map(record => {
          const oneRow = []
          for (const key in record) {
            const currentColumn = col.indexOf(key?.toUpperCase())
            if (currentColumn > -1) {
              oneRow[currentColumn - 1] = record[key]
            }
          }
          return [index++, ...oneRow]
        })
      } else {
        rows = exportData.data.map(record => {
          return [
            index++,
            record.loan_id,
            record.loan_application_initiated,
            record.loan_purpose,
            record.loan_amount,
            record.loan_app_status
          ]
        })
      }
      autoTable(doc, {
        head: [col],
        body: rows,
        theme: 'grid',
        styles: {
          minCellWidth: 40
        },
        columnStyles: {
          1: { minCellWidth: 50 },
          2: { minCellWidth: 85 },
          3: { minCellWidth: 55 },
          4: { minCellWidth: 85 },
          6: { minCellWidth: 85 }
        },
        headStyles: {
          halign: 'center',
          valign: 'middle'
        },
        rowPageBreak: 'avoid'
      })
      doc.save(fileName + '.pdf')
    }
  }
}

/* Export file */
export function exportFile2(exportData, fileType = '.csv') {
  const column = ['Loan ID', 'Loan Collected', 'Amount PaidBack', 'Loan Duration', 'Starting Date']
  const actionIndex = column.indexOf('Action')
  if (actionIndex !== -1) {
    column.splice(actionIndex, 1)
  }

  let csvBuilder
  const date = new Date()
  const fileName = date.toISOString() + '-loanifo'
  if (fileType !== undefined) {
    if (fileType !== 'Pdf') {
      csvBuilder = new CsvBuilder(fileName + fileType)
      let index = 1
      csvBuilder.setColumns(['#', ...column])
      exportData.data.forEach(record => {
        csvBuilder.addRow([
          index++,
          record.loan_app_id,
          record.admin_note,
          record.approval_date,
          record.disbursed_date,
          record.next_interest_date
        ])
      })
      csvBuilder.exportFile()
    } else if (fileType === 'Pdf') {
      const doc = new JsPDF('landscape', 'pt', 'a4', true)
      const col = ['#', ...column]
      let index = 1
      const rows = exportData.data.map(record => {
        return [
          index++,
          record.loan_app_id,
          record.admin_note,
          record.approval_date,
          record.disbursed_date,
          record.next_interest_date
        ]
      })
      autoTable(doc, {
        head: [col],
        body: rows,
        theme: 'grid',
        styles: {
          minCellWidth: 40
        },
        columnStyles: {
          1: { minCellWidth: 50 },
          2: { minCellWidth: 85 },
          3: { minCellWidth: 55 },
          4: { minCellWidth: 85 },
          6: { minCellWidth: 85 }
        },
        headStyles: {
          halign: 'center',
          valign: 'middle'
        },
        rowPageBreak: 'avoid'
      })
      doc.save(fileName + '.pdf')
    }
  }
}
