import React, { useState } from 'react'
import { notification } from 'antd'
import {
  S_6_CONTACT_INPUT_EMAIL,
  S_6_CONTACT_INPUT_MSG,
  S_6_CONTACT_INPUT_NAME,
  S_6_CONTACT_LABEL_NAME,
  S_6_CONTACT_LABEL_EMAIL,
  S_6_CONTACT_LABEL_MSG,
  S_6_CONTACT_BTN
} from './constants'
import { ContactUs } from '../../services'

const Contact = () => {
  const [contact, setContact] = useState({ name: '', email: '', message: '' })
  const [loading, setLoading] = useState(false)

  const onChangeText = e => {
    setContact({ ...contact, [e.target.name]: e.target.value })
  }

  const submitFormHandle = async e => {
    e.preventDefault()
    if (!contact.name || !contact.email || !contact.message) {
      notification.error({
        message: 'Error Occurred',
        description: 'Please fill all field!'
      })
      return false
    }
    if (contact.message.length > 600) {
      notification.error({
        message: 'Error Occurred',
        description: 'Message should not be more than 600 characters!'
      })
      return false
    }
    try {
      setLoading(true)
      const { data } = await ContactUs.contactMsg(contact)
      if (data.success) {
        notification.success({
          message: 'Message sent successfully!',
          description: data.message
        })
        setContact({
          name: '',
          email: '',
          message: ''
        })
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Something wrong, please try again!...'
      })
      setLoading(false)
    }
  }

  return (
    <div className='max-w-screen-xl mt-2 px-8 grid gap-2 grid-cols-1 py-4 mx-auto bg-white text-gray-900 rounded-lg shadow-lg'>
      <div className=''>
        <div>
          <span className='text-sm text-gray-900'>{S_6_CONTACT_LABEL_NAME}</span>
          <input
            className='w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
            type='text'
            value={contact.name}
            onChange={onChangeText}
            name='name'
            placeholder={S_6_CONTACT_INPUT_NAME}
          />
        </div>
        <div className='mt-8'>
          <span className='text-base text-gray-900'>{S_6_CONTACT_LABEL_EMAIL}</span>
          <input
            className='w-full bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
            type='text'
            value={contact.email}
            onChange={onChangeText}
            name='email'
            placeholder={S_6_CONTACT_INPUT_EMAIL}
          />
        </div>
        <div className='mt-8'>
          <span className=' text-base text-gray-900'>{S_6_CONTACT_LABEL_MSG}</span>
          <textarea
            className='resize-none w-full h-32 bg-gray-200 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
            onChange={onChangeText}
            value={contact.message}
            name='message'
            placeholder={S_6_CONTACT_INPUT_MSG}
          />
        </div>
        <div className='mt-8'>
          <button
            onClick={submitFormHandle}
            className='h-10 text-base bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
          >
            {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
            {S_6_CONTACT_BTN}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Contact
