import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { select } from '../../../redux/store'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import CustomerLoans from './CustomerLoans'
const AdminContacts = () => {
  const history = useHistory()
  const exitingUsers = useSelector(select.users.getUsersNotAdmin)

  return (
    <DashboardLayout>
      <main className='w-full h-full flex-grow p-4 bg-white'>
        <div className='ml-1 mt-4 flex justify-between'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            All Customers
          </h3>
          <h1 className='font-bold text-2xl'>Total customers [{exitingUsers.length}]</h1>
        </div>
        <div className='mb-6 px-4 overflow-x-auto'>
          <CustomerLoans exitingUsers={exitingUsers || []} />
        </div>
      </main>
    </DashboardLayout>
  )
}

export default AdminContacts
