const TITLE = 'Vittas'
const HOME = 'Home'
const ABOUT = 'About'
const HOW_IT_WORKS = 'How it works'
const FAQ = 'FAQ'
const CONTACT_US = 'Contact us'
const LOGIN = 'Login'
const BLOG = 'Blog'
const SIGN_UP = 'Sign Up'
const DASHBOARD = 'Dashboard'

export { TITLE, HOME, ABOUT, HOW_IT_WORKS, FAQ, BLOG, CONTACT_US, LOGIN, SIGN_UP, DASHBOARD }
