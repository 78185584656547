import { Dropdown } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dispatch } from '../../redux/store'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { exportFile2 } from '../../utils/export'
import { ALL_TRANSACTIONS, EXPORT } from './constant'
import LoanTable, { getLoanColumns } from './LoanTable'

const Loans = () => {
  const history = useHistory()
  const { disbursedLoan, loading, isServerError } = useSelector(({ loading, loans }) => ({
    loading: loading.effects.loans.getDisbursedLoan,
    disbursedLoan: loans.disbursedLoan,
    isServerError: loans.isServerError
  }))

  useEffect(() => {
    if ((!disbursedLoan?.length || isServerError) && !loading) {
      dispatch.loans.getCustomerDisbursedLoan()
    }
  }, [])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-between mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {ALL_TRANSACTIONS}
          </h3>
          <Dropdown menu={{ items: menu(disbursedLoan) }} placement='bottomRight' arrow>
            <button className='btn-primary-200'>
              {EXPORT} <i className='fas fa-upload ml-2' />
            </button>
          </Dropdown>
        </div>
        <div className='mt-8 mb-16 px-4 overflow-x-auto'>
          <LoanTable tableData={disbursedLoan} loading={loading} />
        </div>
      </main>
    </DashboardLayout>
  )
}

export const menu = data => [
  {
    label: (
      <button
        onClick={() => exportFile2({ data, column: getLoanColumns() }, 'Pdf')}
        disabled={data?.length < 1}
        title={data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        PDF
      </button>
    ),
    key: 1
  },
  {
    label: (
      <button
        onClick={() => exportFile2({ data, column: getLoanColumns() }, '.xls')}
        disabled={data.data && data?.data?.length < 1}
        title={data?.data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        Excel
      </button>
    ),
    key: 2
  }
]

export default Loans
