/* eslint-disable no-sequences */
import React, { useState, useEffect } from 'react'
import { InputField } from '../../sharedComponents/input-field'
import { stepOneValidator } from '../../utils/inputValidator'
import PropTypes from 'prop-types'
import { notification } from 'antd'
import {
  BUSINESS_EMAIL,
  BUSINESS_INDUSTRY,
  BUSINESS_LOCATION,
  BUSINESS_NAME,
  BUSINESS_PHONE_NO,
  FOUNDED_DATE,
  INCORPORATION_DATE,
  NO_OF_EMPLOYEE,
  RC_NUMBER,
  SAVE_AND_NEXT,
  YEAR_AT_BIZ,
  BIZ_DESCRIPTION,
  EQUIPMENT_LOAN,
  MEDICATION_LOAN
} from './constant'
import { useSelector } from 'react-redux'

const StepOne = props => {
  const [error, setError] = useState({})

  const { data, isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin,
    data: authUser.userInfo?.data
  }))

  const [biz, setBiz] = useState({
    bizName: isAdmin ? props.user?.business_name : data.business_name || '',
    bizEmail: isAdmin ? props.user?.email : data.email,
    bizPhone: isAdmin ? props.user?.phone_number : data.phone_number,
    noOfEmployee: '',
    incorporationDate: '',
    bizFound: '',
    bizDescription: '',
    bizIndustry: '',
    bizRcNo: '',
    bizYear: '',
    bizLocation: ''
  })
  useEffect(() => {
    if (window.localStorage.getItem('apply-for-loan')) {
      setBiz(JSON.parse(window.localStorage.getItem('apply-for-loan')).businessInformation)
    }
  }, [])
  const onChangeText = ({ target }) => {
    setError({ ...error, [target.name]: false })
    setBiz({ ...biz, [target.name]: target.value })
  }
  const validator = stepOneValidator(biz)

  const handleSubmit = async () => {
    if (validator.valid) {
      if (window.localStorage.getItem('apply-for-loan')) {
        const bizInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))
        const applyForLoan = { ...bizInfo, businessInformation: biz }
        window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
      } else {
        window.localStorage.setItem('apply-for-loan', JSON.stringify({ businessInformation: biz }))
      }
      props.nextKey(2)
    } else {
      setError({ ...validator.error })
      notification.error({
        message: 'An error occured',
        description:
          validator.error.bizEmail ||
          validator.error.bizName ||
          validator.error.bizLocation ||
          validator.error.bizPhone ||
          validator.error.noOfEmployee ||
          validator.error.bizIndustry ||
          validator.error.bizYear ||
          validator.error.incorporationDate ||
          validator.error.bizFound ||
          validator.error.bizDescription ||
          validator.error.bizRcNo
      })
    }
  }

  return (
    <div className='w-full mx-auto md:px-6 rounded-xl bg-theme1-400'>
      <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
        <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.bizName}
            type='text'
            name='bizName'
            label={BUSINESS_NAME}
            placeholder={BUSINESS_NAME.slice(0, -1)}
            className='form-control'
          />
          {error.bizName ? <small className='text-red-500'>{error.bizName}</small> : ''}
        </div>
        <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.bizEmail}
            type='email'
            name='bizEmail'
            label={BUSINESS_EMAIL}
            placeholder={BUSINESS_EMAIL.slice(0, -1)}
            className='form-control'
          />
          {error.bizEmail ? <small className='text-red-500'>{error.bizEmail}</small> : ''}
        </div>
        <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.bizPhone}
            type='text'
            name='bizPhone'
            maxLength='15'
            label={BUSINESS_PHONE_NO}
            placeholder={BUSINESS_PHONE_NO.slice(0, -1)}
            className='form-control'
          />
          {error.bizPhone ? <small className='text-red-500'>{error.bizPhone}</small> : ''}
        </div>
        <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.noOfEmployee}
            type='number'
            name='noOfEmployee'
            maxLength='7'
            label={NO_OF_EMPLOYEE}
            placeholder={NO_OF_EMPLOYEE.slice(0, -1)}
            className='form-control'
          />
          {error.noOfEmployee ? <small className='text-red-500'>{error.noOfEmployee}</small> : ''}
        </div>
        <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.incorporationDate}
            type='date'
            label={INCORPORATION_DATE}
            placeholder={INCORPORATION_DATE.slice(0, -1)}
            name='incorporationDate'
            max={new Date().toISOString().slice(0, 10)}
            className='form-control'
          />
          {error.incorporationDate ? (
            <small className='text-red-500'>{error.incorporationDate}</small>
          ) : (
            ''
          )}
        </div>
        <div className='w-full md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.bizFound}
            type='date'
            label={FOUNDED_DATE}
            name='bizFound'
            max={new Date().toISOString().slice(0, 10)}
            className='form-control'
          />
          {error.bizFound ? <small className='text-red-500'>{error.bizFound}</small> : ''}
        </div>
        <div className='w-full md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.bizDescription}
            type='text'
            name='bizDescription'
            label={BIZ_DESCRIPTION}
            className='form-control'
          />
          {error.bizDescription ? (
            <small className='text-red-500'>{error.bizDescription}</small>
          ) : (
            ''
          )}
        </div>
        <div className='w-full md:w-1/3 md:px-4 mt-8'>
          <label className='ml-2 text-gray-500 leading-8'>{BUSINESS_INDUSTRY}</label>
          <select
            onChange={onChangeText}
            value={biz.bizIndustry}
            type='text'
            name='bizIndustry'
            className='form-control'
          >
            {[`-${BUSINESS_INDUSTRY.slice(0, -1)}-`, EQUIPMENT_LOAN, MEDICATION_LOAN].map(
              (state, id) => {
                return (
                  <option
                    key={id}
                    value={state === `-${BUSINESS_INDUSTRY.slice(0, -1)}-` ? '' : state}
                  >
                    {state}
                  </option>
                )
              }
            )}
          </select>
          {error.bizIndustry ? <small className='text-red-500'>{error.bizIndustry}</small> : ''}
        </div>
        <div className='w-full md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.bizRcNo}
            type='text'
            maxLength='15'
            name='bizRcNo'
            label={RC_NUMBER}
            className='form-control'
          />
          {error.bizRcNo ? <small className='text-red-500'>{error.bizRcNo}</small> : ''}
        </div>

        <div className='w-full md:w-2/3 px-1 md:px-4 mt-8'>
          <label className='text-gray-500 leading-8'>{BUSINESS_LOCATION}</label>
          <textarea
            onChange={onChangeText}
            value={biz.bizLocation}
            type='text'
            name='bizLocation'
            rows='6'
            className='resize-none border rounded-md w-full appearance-none bg-transparent border-b-2 mr-3 py-2 px-2 leading-tight  mt-1 focus:outline-none  border-gray-400'
          />
          {error.bizLocation ? <small className='text-red-500'>{error.bizLocation}</small> : ''}
        </div>
        <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={onChangeText}
            value={biz.bizYear}
            type='text'
            name='bizYear'
            maxLength='10'
            label={YEAR_AT_BIZ}
            className='form-control'
          />
          {error.bizYear ? <small className='text-red-500'>{error.bizYear}</small> : ''}
        </div>
      </div>

      <div className='w-full flex mt-6 mb-8 justify-start pr-10 md:ml-5'>
        <button
          className='btn-primary'
          onClick={() => {
            const err = Object.keys({ ...validator.error })
            if (err?.length > 0) {
              const input = document.querySelector(`input[name=${err[0]}]`)

              input.scrollIntoView({
                behavior: 'smooth'
              })
            }
            handleSubmit()
          }}
        >
          {SAVE_AND_NEXT}
        </button>
      </div>
    </div>
  )
}

StepOne.propTypes = {
  nextKey: PropTypes.func,
  user: PropTypes.shape({
    phone_number: PropTypes.string,
    email: PropTypes.string,
    business_name: PropTypes.string
  })
}

export default StepOne
