import ApiHandler from './ApiHandler'

export default {
  customerApplyForLoan: data => ApiHandler.post('customer/loan-application', data),
  adminApplyForLoan: data => ApiHandler.post('admin/loan-application', data),
  getUserLoansByUserId: id => ApiHandler.get(`admin/user-loans/${id}`),
  getCustomerLoans: () => ApiHandler.get('customer/disbursed-apps'),
  getRepaymentHistory: id => ApiHandler.get(`customer/view-payment-history/${id}`),
  disbursedLoan: data => ApiHandler.post('admin/open_loan', data),
  getCustomerLinechartA: data => ApiHandler.post('customer/calender-a', data),
  getCustomerBarchartB: data => ApiHandler.post('customer/calender-b', data),
  getLoanPaymentHistory: id => ApiHandler.get(`admin/view-loan-payment-history/${id}`),
  getCloseLoan: id => ApiHandler.get(`admin/close_loan/${id}`),
  setDefaultLoan: id => ApiHandler.get(`admin/default-loan/${id}`),
  getLoanAplicationBySteps: (data, step) => ApiHandler.post(`admin/loan-step/${step}`, data),
  applyForExistingCustomer: data => ApiHandler.post('user/express-loan-application', data),
  getApprovedLoanUsers: () => ApiHandler.get('admin/approved-loan-users'),
  viewLoanInterestTracker: data => ApiHandler.post('admin/loan-interest-tracker', data)
}
