/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { BACK_TO_DASHBOARD } from '../application-success/constants'
import ListOfDistributors from './ListDistributors'
import AddDistributor from './AddDistributors'
import { Button, Modal } from 'antd'

const Distributors = () => {
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-between mt-4'>
          <h3
            onClick={() => history.push('/admin-dashboard')}
            className='font-bold text-lg cursor-pointer'
          >
            <i className='fas fa-arrow-left mr-3' />
            {BACK_TO_DASHBOARD}
          </h3>
          <button
            className='font-bold text-lg cursor-pointer btn-primary mb-10'
            onClick={() => setIsModalVisible(true)}
          >
            Add Distributor
          </button>
          <Modal
            title='Add New Distributor'
            onCancel={() => setIsModalVisible(false)}
            width='70%'
            open={isModalVisible}
            footer={false}
          >
            <AddDistributor />
          </Modal>
        </div>
        <div className='mt-8 mb-16 overflow-x-auto'>
          <ListOfDistributors />
        </div>
      </main>
    </DashboardLayout>
  )
}

export default Distributors
