export const allLoanStatus = {
  pending: 'pendingLoans',
  approved: 'approvedLoans',
  declined: 'declinedLoans',
  disbursed: 'disbursedLoans'
}

/**
 * @note snake case is used to accomodate backend
 */
export const responseMapStatus = {
  pending: 'pending_apps',
  approved: 'approved_apps',
  declined: 'declined_apps',
  disbursed: 'disbursed_apps'
}
