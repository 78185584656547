import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Icon } from '@iconify/react'
import LockIcon from '../../../../../images/Lock.svg'
import SuccessModal from './SuccessModal'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}
const inputStyle = 'w-12 bg-indigo-700 bg-opacity-10 flex text-center border-2 py-2 rounded-xl'

const OtpModal = ({ visible, onCancel, title, description, onContinue }) => {
  const [pinValues, setPinValues] = useState(['', '', '', '', '', ''])
  const [success, setSuccess] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const inputRefs = Array.from({ length: 6 }, () => useRef())

  const handlePinChange = (index, value) => {
    setPinValues(prevValues => {
      const newValues = [...prevValues]
      newValues[index] = value

      if (value !== '' && index < 5) {
        inputRefs[index + 1].current.focus()
      }

      return newValues
    })
  }
  const handleContinue = () => {
    const isPinCorrect = pinValues.join('') === '123456'

    setSuccess(isPinCorrect)

    if (isPinCorrect) {
      setPinValues(['', '', '', '', '', ''])
      setShowSuccessModal(true) // Show the SuccessModal
    }
  }

  const handleSuccessCancel = () => {
    setShowSuccessModal(false)
  }
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      centered
      className='info-modal'
      closeIcon={<Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />}
      bodyStyle={bodyStyle}
      width={500}
    >
      <div className='modal-header bg-primary px-6 py-2 flex justify-between'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>{title}</h2>
      </div>
      <div className='py-8'>
        <p className='text-black text-base text-center font-bold'>{description}</p>
        <div className='w-12 self-center justify-center flex mx-auto'>
          <img src={LockIcon} alt='icon' className='' />
        </div>
        <div className='Pin flex justify-center gap-4 text-center mt-4 py-4'>
          {pinValues.map((value, index) => (
            <input
              key={index}
              type='number'
              className={inputStyle}
              placeholder='-'
              value={value}
              onChange={e => handlePinChange(index, e.target.value)}
              ref={inputRefs[index]}
              style={{ fontSize: '18px', color: 'black' }}
            />
          ))}
        </div>
        <div>
          {success ? (
            <p className='text-green-700 text-sm font-normal text-center leading-normal'>
              Successful
            </p>
          ) : (
            <p className='text-red-600 text-sm font-normal text-center leading-normal'>
              Your account will be locked after unsuccessful 3 trial
            </p>
          )}
        </div>
        <button
          className='bg-primary flex mx-auto text-base  mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
      {/* SuccessModal */}
      <SuccessModal visible={showSuccessModal} onCancel={handleSuccessCancel} loading={false} />
    </Modal>
  )
}

OtpModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired
}

export default OtpModal
