import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { notification } from 'antd'
import { dispatch } from '../../../redux/store'

import { NEW_PIN, OLD_PIN, CONFIRM_NEW_PIN, CREATE_PIN, CHANGE_PIN } from '../constants'

const TransactionPin = () => {
  const [showPinOne, setShowPinOne] = useState(false)
  const [showPinTwo, setShowPinTwo] = useState(false)
  const [showPinThree, setShowPinThree] = useState(false)
  const [user, setUser] = useState({ old_pin: '', new_pin: '', confirm_pin: '' })

  // Selectors
  const { userInfo, token, permissions } = useSelector(({ auth: authUser }) => authUser.authUser)
  const userHasPin = !!userInfo?.data?.user_pin_id

  const loading = useSelector(
    ({ loading }) =>
      loading.effects.users.createUserTransactionPin ||
      loading.effects.users.updateUserTransactionPin
  )

  const onChangeText = ({ target }) => {
    setUser({ ...user, [target.name]: target.value })
  }

  const clearInputs = () => {
    setUser({ old_pin: '', new_pin: '', confirm_pin: '' })
  }

  const submitFormHandle = async e => {
    e.preventDefault()

    if (!userHasPin && user.new_pin.length !== 6) {
      return notification.error({
        message: 'An error occured',
        description: 'Pin length must be 6 digits!'
      })
    }

    if (!userHasPin && user.new_pin !== user.confirm_pin) {
      return notification.error({
        message: 'An error occured',
        description: 'New Pin does not match comfirm Pin!'
      })
    }

    if (userHasPin && user.old_pin.length !== 6) {
      return notification.error({
        message: 'An error occured',
        description: 'Old Pin length must be 6 digits!'
      })
    }
    if (userHasPin && user.new_pin.length !== 6) {
      return notification.error({
        message: 'An error occured',
        description: 'New Pin length must be 6 digits!'
      })
    }

    if (userHasPin) {
      // Update Action
      const response = await dispatch.users.updateUserTransactionPin({
        old_pin: user.old_pin,
        new_pin: user.new_pin
      })

      if (response) {
        clearInputs()
        await dispatch.auth.fetchUserInfo({ token, permissions })
      }
    } else {
      // Create Action
      const response = await dispatch.users.createUserTransactionPin({
        pin: user.new_pin
      })

      if (response) {
        clearInputs()
        await dispatch.auth.fetchUserInfo({ token, permissions })
      }
    }
  }

  return (
    <div className='w-full'>
      <div className='flex flex-wrap pb-36 justify-center mt-8'>
        <div className='w-full md:w-3/5 md:mx-6'>
          <div>
            <div className='profile-form'>
              <div className=''>
                <div className='mt-1'>
                  <div className='flex flex-wrap items-center'>
                    {userHasPin && (
                      <div className='w-full mb-6'>
                        <label className='font-semibold'>{OLD_PIN} </label>
                        <div className='relative mt-2'>
                          <input
                            className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                            type={showPinOne ? 'text' : 'password'}
                            value={user.old_pin}
                            onChange={onChangeText}
                            name='old_pin'
                          />

                          <button
                            onClick={() => setShowPinOne(!showPinOne)}
                            className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                          >
                            {showPinOne ? (
                              <i className='fas fa-eye-slash' />
                            ) : (
                              <i className='fas fa-eye' />
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                    <div className='w-full mb-6'>
                      <label className='font-semibold'>{NEW_PIN} </label>
                      <div className='relative mt-2'>
                        <input
                          className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                          type={showPinThree ? 'text' : 'password'}
                          value={user.new_pin}
                          onChange={onChangeText}
                          name='new_pin'
                        />

                        <button
                          onClick={() => setShowPinThree(!showPinThree)}
                          className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                        >
                          {showPinThree ? (
                            <i className='fas fa-eye-slash' />
                          ) : (
                            <i className='fas fa-eye' />
                          )}
                        </button>
                      </div>
                    </div>
                    {!userHasPin && (
                      <div className='w-full mb-6'>
                        <label className='font-semibold'>{CONFIRM_NEW_PIN}</label>
                        <div className='relative mt-2'>
                          <input
                            className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                            type={showPinTwo ? 'text' : 'password'}
                            value={user.confirm_pin}
                            onChange={onChangeText}
                            name='confirm_pin'
                          />

                          <button
                            onClick={() => setShowPinTwo(!showPinTwo)}
                            className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                          >
                            {showPinTwo ? (
                              <i className='fas fa-eye-slash' />
                            ) : (
                              <i className='fas fa-eye' />
                            )}
                          </button>
                        </div>
                      </div>
                    )}

                    <div className='w-full flex mt-6 mb-8 justify-start'>
                      <button className='btn-primary-200' onClick={submitFormHandle} type='submit'>
                        {loading ? (
                          <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                        ) : (
                          ''
                        )}{' '}
                        {userHasPin ? CHANGE_PIN : CREATE_PIN}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionPin
