/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import AppTable from '../../../sharedComponents/table/AppTable'
import { closedLoansCollumn, LOAN_APPLICATION } from './constants'
import { dispatch } from '../../../redux/store'

const ClosedLoans = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const { permissions } = useSelector(({ auth }) => auth.authUser)
  const { closedLoansData, adminClosedLoansLoading } = useSelector(({ applications, loading }) => ({
    adminClosedLoansLoading: loading.effects.applications.closedLoans,
    closedLoansData: applications.closedLoans,
    isServerError: applications.closedLoans
  }))
  const searchInput = useRef(null)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  useEffect(() => {
    dispatch.applications.adminClosedLoans()
  }, [])
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {LOAN_APPLICATION}
          </h3>
        </div>
        <div className='mb-6 px-4 overflow-x-auto'>
          <AppTable
            columns={closedLoansCollumn(permissions, {
              searchInput,
              handleSearch,
              handleReset,
              setSearchText,
              searchedColumn,
              searchText,
              setSearchedColumn
            })}
            tableData={closedLoansData?.map((item, idx) => ({ ...item, key: idx }))}
            loading={adminClosedLoansLoading}
          />
        </div>
      </main>
    </DashboardLayout>
  )
}

export default ClosedLoans
