import React, { useRef } from 'react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useReactToPrint } from 'react-to-print'

import Logo from '../../../../images/vittasLogo.svg'
import SuccessIcon from '../../../../images/successGreen.svg'
import FailedIcon from '../../../../images/failed.svg'
import { DashboardLayout } from '../../../../sharedComponents/dashboardLayout'

const ReceiptPage = () => {
  const printableAreaRef = useRef()
  const history = useHistory()

  const parsedQuery = queryString.parse(window.location.search)
  const transactionAmount = parsedQuery?.amount
  const transactionType = parsedQuery?.transactionType
  const transactionDate = parsedQuery?.time
  const beneficiary = parsedQuery?.walletName
  const sender = parsedQuery?.sender
  const transactionNote = 'N/A'
  const transactionReference = parsedQuery?.transactionId
  const accountNumber = parsedQuery?.toAccountNo
  const bankName = parsedQuery?.toBank

  const getStatusTextColor = () => {
    switch (parsedQuery?.transactionResponse) {
      case '00':
        return 'text-green-800'
      default:
        return 'text-red-500'
    }
  }
  const getStatusIcon = () => {
    switch (parsedQuery?.transactionResponse) {
      case '00':
        return <img src={SuccessIcon} />
      default:
        return <img src={FailedIcon} />
    }
  }

  const handleDownloadClick = useReactToPrint({
    content: () => printableAreaRef.current
  })

  return (
    <DashboardLayout className='print-hidden'>
      <main className='bg-gray-200 p-6 py-9 '>
        <div className='ml-1 mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </h3>
        </div>

        <div className='w-1/2 mx-auto'>
          <div ref={printableAreaRef} className='px-10'>
            <header className='flex justify-between '>
              <div className='flex items-center gap-2'>
                <span>
                  <img src={Logo} alt='logo' className='w-full' />
                </span>
                <span className='text-indigo-700 text-xs font-medium'>Vittas International </span>
              </div>
              <div className='text-black text-xs font-medium '>Transaction receipt</div>
            </header>
            <div className=' bg-white py-4 my-4 rounded-xl w-full flex-col justify-center items-center  inline-flex'>
              <p className='text-center text-black text-base font-semibold'>{transactionAmount}</p>
              <div className='status-description self-stretch justify-center items-center gap-2 inline-flex'>
                <span>{getStatusIcon()}</span>
                <span className={`text-center ${getStatusTextColor()} text-xs font-normal`}>
                  {parsedQuery?.transactionResponse === '00' ? (
                    <span>Successful</span>
                  ) : (
                    <span>Failed</span>
                  )}
                </span>
              </div>
            </div>
            <p className='text-paragrapgh text-xs font-normal ml-6'>Details</p>
            <div className='bg-white rounded-xl flex-col space-y-4 flex w-full py-6 px-6'>
              <div className='flex w-full justify-between'>
                <span className='text-paragrapgh text-xs font-light'>Transaction type</span>
                <span className='text-black text-xs font-normal '>{transactionType} </span>
              </div>
              <div className='flex w-full justify-between'>
                <span className='text-paragrapgh text-xs font-light'>Transaction Date</span>
                <span className='text-black text-xs font-normal '>{transactionDate} </span>
              </div>
              <div className='flex w-full justify-between'>
                <span className='text-paragrapgh text-xs font-light'>Beneficiary </span>
                <span className='flex flex-col gap-2'>
                  <span className='text-black text-xs font-normal '>{beneficiary} </span>
                  <span className='text-black text-xs font-normal text-right'>
                    {accountNumber}{' '}
                  </span>
                  <span className='text-black text-xs font-normal text-right'>{bankName} </span>
                </span>
              </div>
              <div className='flex w-full justify-between'>
                <span className='text-paragrapgh text-xs font-light'>Sender</span>
                <span className='text-black text-xs font-normal '>{sender}</span>
              </div>

              <div className='flex w-full justify-between'>
                <span className='text-paragrapgh text-xs font-light'>Remark/Transaction note</span>
                <span className='text-black text-xs font-normal '>{transactionNote}</span>
              </div>
              <div className='flex w-full justify-between'>
                <span className='text-paragrapgh text-xs font-light'>Transaction Reference</span>
                <span className='text-black text-xs font-normal '>{transactionReference} </span>
              </div>
              <div className='flex w-full justify-between'>
                <span className='text-paragrapgh text-xs font-light'>Transaction Status</span>
                <span className='text-black text-xs font-normal '>
                  {parsedQuery?.transactionResponse === '00' ? (
                    <span>Successful</span>
                  ) : (
                    <span>Failed</span>
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className='flex gap-4 mt-8 justify-center items-center'>
            <button
              onClick={handleDownloadClick}
              className='rounded border print-hidden text-base text-indigo-700 border-blue-300 justify-center py-2 px-4 items-center gap-2.5 inline-flex'
            >
              Download
            </button>
            <button className='rounded border print-hidden text-base text-indigo-700 border-blue-300 justify-center py-2 px-4 items-center gap-2.5 inline-flex'>
              Continue
            </button>
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default ReceiptPage
