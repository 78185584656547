/* eslint-disable indent */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

/**
 *
 * @property {string} nextDate the next interest date
 * @property {number} numOfDays - default to 7days the next number of days remaining to show this component
 * @returns {JSX}
 */
const DueSoon = ({ nextDate = '', numOfDays = 7 }) => {
  const dateFrom = moment().add(numOfDays, 'd').format('YYYY-MM-DD')
  const addDueDayUnix = moment(dateFrom).unix()
  const nextDueDateUnix = moment(new Date(nextDate)).unix()

  return nextDate ? (
    <div>
      {nextDate?.substring(0, 10)}
      {nextDueDateUnix < addDueDayUnix && (
        <span className='inline-flex items-center justify-center animate-pulse px-2 py-1 text-xs font-bold leading-none text-indigo-100 bg-indigo-700 rounded'>
          {nextDueDateUnix - moment().unix() > 0
            ? `Due Soon ${moment(new Date(nextDate)).fromNow()}`
            : nextDueDateUnix - moment().unix() < 0
            ? `Overdue ${moment(new Date(nextDate)).fromNow()}`
            : ''}
        </span>
      )}
    </div>
  ) : (
    <span className='text-center font-bold'> - </span>
  )
}

DueSoon.propTypes = {
  nextDate: PropTypes.string,
  numOfDays: PropTypes.number
}

export default DueSoon
