const S_6_CONTACT_US = 'Contact Us'
const S_6_INFORMATIN = 'Information'
const S_6_CONTACT_EMAIL = 'contact@vittasinternational.com'
const S_6_CONTACT_PHONE = '+234 889 8897 908'
const S_6_CONTACT_LOCATION = 'Lagos, Nigeria'
const S_6_CONTACT_TIME = '8am - 5pm'
const S_6_CONTACT_LABEL_NAME = 'Name'
const S_6_CONTACT_INPUT_NAME = 'Full Name'
const S_6_CONTACT_LABEL_EMAIL = 'Email'
const S_6_CONTACT_INPUT_EMAIL = 'Email Address'
const S_6_CONTACT_LABEL_MSG = 'Message'
const S_6_CONTACT_INPUT_MSG = 'Write your message'
const S_6_CONTACT_BTN = 'Send Message'

export {
  S_6_CONTACT_US,
  S_6_INFORMATIN,
  S_6_CONTACT_EMAIL,
  S_6_CONTACT_PHONE,
  S_6_CONTACT_LOCATION,
  S_6_CONTACT_TIME,
  S_6_CONTACT_LABEL_NAME,
  S_6_CONTACT_INPUT_NAME,
  S_6_CONTACT_LABEL_EMAIL,
  S_6_CONTACT_INPUT_EMAIL,
  S_6_CONTACT_LABEL_MSG,
  S_6_CONTACT_INPUT_MSG,
  S_6_CONTACT_BTN
}
