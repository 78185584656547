/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import ViewBankStatement from '../admin-pages/customers/ViewBankStatement'
import { notification, Popconfirm } from 'antd'
import { useLocation } from 'react-router-dom'

const DocDetails = ({ visible, handleClose, data }) => {
  const { token, permissions } = useSelector(({ auth: authUser }) => authUser.authUser)
  const { state } = useLocation()
  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))
  const cancel = () => {
    notification.warn({
      message: 'Document not deleted',
      placement: 'bottomRight'
    })
  }
  const confirmDelete = (customerId, documentId) => {
    if (!isAdmin) {
      token.length &&
        dispatch.applications.customerDeleteDocument({ token, permissions, documentId })
      handleClose()
    } else {
      dispatch.applications.adminDeleteDocument({ customerId, documentId })
      handleClose()
    }
  }

  if (data?.isMono) {
    return (
      <>
        <div>
          <ViewBankStatement
            data={data?.file}
            visible={visible}
            total={data?.file?.length}
            setCurrentBankStatement={() => handleClose()}
            componentAddon={
              <Popconfirm
                title='Are you sure to delete this document?'
                onConfirm={() => confirmDelete(state?.user?.id, data.id)}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <button className='btn-primary-200 w-2/5 transition-all duration-300 active:bg-red-700 bg-red-800'>
                  Delete
                </button>
              </Popconfirm>
            }
          />
        </div>
      </>
    )
  }

  return visible && !data?.isMono ? (
    <div className='fixed z-50 bg-black bg-opacity-50 inset-0 flex flex-col justify-center'>
      <div
        style={{ maxWidth: '1550px' }}
        className={`${
          visible && 'scale-90'
        } duration-200 overflow-y-auto delay-100 bg-white w-full transform mx-auto rounded-2xl p-10`}
      >
        <div className='flex flex-wrap'>
          <div
            style={{ background: '#FAFAFA' }}
            className='bg-gray-100 w-full flex flex-wrap px-5 py-2'
          >
            <div className='w-full'>
              <button
                onClick={() => handleClose()}
                className='fixed top-4 grid place-content-center outline-none focus:outline-none cursor-pointer right-2 bg-white w-8 h-8 rounded-full'
              >
                <i className='fa fa-times text-3xl' />
              </button>
              <h4 className='text-dark1 text-lg md:text-xl font-semibold text-center font-manrope'>
                {data.title}
              </h4>
            </div>
            {!data.title.includes('pdf') ? (
              <div className='w-full flex justify-center'>
                <img src={data.file_url} alt='Image here' />
              </div>
            ) : (
              <iframe
                src={data.file_url}
                width='800px'
                height='500px'
                style={{ margin: '0 auto' }}
              />
            )}

            <div className='mt-5 w-full flex justify-evenly'>
              <a
                href={data.file_url}
                target='_blank'
                rel='noopener noreferrer'
                download={data.title}
                className='btn-primary-200 no-underline outline-none text-center w-2/5 transition-all duration-300 active:bg-blue-700 bg-blue-800'
              >
                Download / Preview
              </a>
              <Popconfirm
                title='Are you sure to delete this document?'
                onConfirm={() => confirmDelete(state?.user?.id, data.id)}
                onCancel={cancel}
                okText='Yes'
                cancelText='No'
              >
                <button className='btn-primary-200 w-2/5 transition-all duration-300 active:bg-red-700 bg-red-800'>
                  Delete
                </button>
              </Popconfirm>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

DocDetails.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    file_url: PropTypes.string,
    id: PropTypes.string,
    file: PropTypes.any,
    isMono: PropTypes.bool
  })
}

export default DocDetails
