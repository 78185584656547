import React from 'react'

import Header from './components/Header'
import FaqComponent from './components/FaqComponent'

const Faq = () => {
  return (
    <div className='w-full pb-16 pt-12'>
      <Header />
      <FaqComponent />
    </div>
  )
}

export default Faq
