import { Modal, Space, Table } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { downloadCSV } from '../../../sharedComponents/export/bankStatement'
import { bankStatementColumn } from './constants'

const ViewBankStatement = ({ total, data, setCurrentBankStatement, visible, componentAddon }) => {
  return (
    <Modal
      width='70%'
      closable
      title={
        <Space>
          <h2 className='pt-2'>
            Total transactions
            <span className='font-semibold'>&quot;{total}&quot;</span>
          </h2>{' '}
          <button
            className='bg-blue-500 p-2 text-white'
            onClick={() => downloadCSV(bankStatementColumn, data)}
          >
            Download as CSV
          </button>
        </Space>
      }
      className='w-full'
      okText='Download as CSV'
      okButtonProps={{
        onClick: () => downloadCSV(bankStatementColumn, data)
      }}
      onCancel={() => setCurrentBankStatement({ visible: false, data: [] })}
      open={visible}
    >
      <Table scroll={{ x: 1000 }} columns={bankStatementColumn} dataSource={data} />
      {componentAddon}
    </Modal>
  )
}

export default ViewBankStatement

ViewBankStatement.propTypes = {
  visible: PropTypes.bool,
  setCurrentBankStatement: PropTypes.func,
  data: PropTypes.any,
  total: PropTypes.number,
  componentAddon: PropTypes.any
}
