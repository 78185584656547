import { notification } from 'antd'
import { SalesRep } from '../../services'
import { reducerActions } from '../reducer'

const initialState = {
  exitingSalesRep: [],
  isServerError: false
}

export const salesRep = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async getAllSales() {
      dispatch.salesRep.setError(false)
      try {
        const { data } = await SalesRep.getSalesPerson()
        dispatch.salesRep.setState({
          exitingSalesRep: data.data
        })
      } catch ({ response, message }) {
        dispatch.salesRep.setError(response)
        notification.error({
          message: 'An error occured',
          description: message
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.salesRep.setState(initialState)])
    }
  })
}
