import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'
import { NAME, MOU, ADDRESS, DRAG_FILES, OR, EMAIL } from './constants'
import { InputField } from '../../sharedComponents/input-field'
import { message, Upload } from 'antd'
import { SUPPORTED_IMAGE_TYPE } from '../../utils/data'
import { UPLOAD_BASE_URL } from '../../utils/constants'
import { BROWSE_FILE } from '../../sharedComponents/entries/constant'
import { updateDistributorValidator } from '../../utils/inputValidator'

const UpdateDistributors = ({ visible, handleClose, data }) => {
  const [error, setError] = useState({})
  const [edit, setEdit] = useState(false)
  const distributorLoading = useSelector(
    ({ loading }) => loading.effects.distributors.adminUpdateDistributor
  )
  const [input, setInput] = useState({
    name: '',
    address: '',
    mou: '',
    email: ''
  })

  const uploadProps = {
    action: `${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        return message.error(`${file.name} is not supported`)
      }
      if (file.size / 1000000 > 35) {
        return message.error('This file size is more than the required 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const handleChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        const ImgUrl = info.file.response.data.url
        if (type === 'mou') {
          setInput({ ...input, mou: ImgUrl })
        }
      }
    }
  }
  const onChangeText = ({ target }) => {
    setInput({ ...input, [target.name]: target.value })
    setError({ ...error, [target.name]: false })
  }

  useEffect(() => {
    const dataImport = data.data
    if (dataImport) {
      setInput({
        name: data.data.name,
        address: data.data.address,
        mou: data.data.mou,
        email: data.data.email
      })
    }
  }, [data.data])

  const validator = updateDistributorValidator(input)

  const handleSubmit = async () => {
    let details = {}
    details = {
      id: data.data.key,
      name: input.name,
      address: input.address,
      mou: input.mou,
      email: input.email
    }

    if (validator.valid) {
      dispatch.distributors.adminUpdateDistributor({ details })
    }
  }
  const handleDelete = key => {
    dispatch.distributors.adminDeleteDistributor({ key })
    handleClose()
  }
  const clearStateOnCloseModal = () => {
    handleClose()
    setInput({
      name: '',
      address: '',
      mou: '',
      email: ''
    })
  }
  return visible ? (
    <div className='fixed z-50 bg-black bg-opacity-50 inset-0 flex flex-col justify-center'>
      <div
        style={{ maxWidth: '1550px' }}
        className={`${
          visible && 'scale-90'
        } duration-200 overflow-y-auto delay-100 bg-white w-full transform mx-auto rounded-2xl p-10`}
      >
        <div className='flex flex-wrap'>
          <div
            style={{ background: '#FAFAFA' }}
            className='bg-gray-100 w-full flex flex-wrap px-5 py-2'
          >
            <div className='w-full'>
              <button
                onClick={() => clearStateOnCloseModal()}
                className='fixed top-4 grid place-content-center outline-none focus:outline-none cursor-pointer right-2 bg-white w-8 h-8 rounded-full'
              >
                <i className='fa fa-times text-3xl' />
              </button>
              <h4 className='text-dark1 text-lg md:text-xl font-semibold text-center font-manrope'>
                Modify Details for {data.data.name}
              </h4>
            </div>
            <div className='mt-5 w-full flex justify-evenly'>
              <button
                onClick={() => setEdit(!edit)}
                className='btn-primary-200 w-2/5 transition-all duration-300 active:bg-blue-700'
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(data.data.key)}
                className='btn-primary-200 w-2/5 transition-all duration-300 active:bg-red-700 bg-red-800'
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className={`${edit ? 'block' : 'hidden'}`}>
          <div className='mx-auto md:px-6 rounded-xl bg-theme1-400'>
            <div className='flex flex-wrap mt-20 lg:mx-2 md:mx-auto justify-center'>
              <div className='w-full md:w-2/5 px-2 mb-6'>
                <label className='text-sm text-gray-900  font-semibold'>{NAME + ' *'}</label>
                <InputField
                  className='form-control'
                  name='name'
                  type='text'
                  onChange={onChangeText}
                  value={input.name}
                  maxLength='35'
                  placeholder={NAME}
                />
                {error.name ? <small className='text-red-500'>{error.name}</small> : ''}
              </div>
              <div className='w-full md:w-2/5 px-2 mb-6'>
                <label className='text-sm text-gray-900  font-semibold'>{EMAIL + ' *'}</label>
                <InputField
                  onChange={onChangeText}
                  value={input.email}
                  type='email'
                  name='email'
                  className='form-control'
                />
                {error.email ? <small className='text-red-500'>{error.email}</small> : ''}
              </div>
              <div className='w-full md:w-2/5 px-2 mb-6'>
                <label className='text-sm text-gray-900  font-semibold'>{ADDRESS + ' *'}</label>
                <textarea
                  name='address'
                  type='text'
                  onChange={onChangeText}
                  value={input.address}
                  rows='10'
                  className='form-control resize-none border rounded-md w-full appearance-none bg-transparent border-b-2 mr-3 py-1 px-1 leading-tight  mt-1 focus:outline-none  border-gray-400'
                />
                {error.address ? <small className='text-red-500'>{error.address}</small> : ''}
              </div>
              <div className='w-full md:w-2/5 lg:w-2/5 px-4'>
                <label className='text-sm text-gray-900  font-semibold'>{MOU + '*'}</label>
                <div className='flex w-full px-3 mt-3 border'>
                  <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                    <div
                      className='text-center px-2'
                      style={{
                        wordWrap: 'break-word',
                        maxWidth: '190px',
                        minWidth: '180px'
                      }}
                    >
                      <Upload.Dragger {...uploadProps} onChange={info => handleChange(info, 'mou')}>
                        <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                        <p className='text-center my-6 font-normal'>{OR} </p>
                        <p className='text-center font-normal text-base text-primary underline'>
                          {BROWSE_FILE}
                        </p>
                      </Upload.Dragger>
                      {input.mou && (
                        <a
                          className='hover:bg-blue-600 px-4 py-2 rounded-md hover:text-white'
                          target='_blank'
                          href={input.mou}
                          rel='noopener noreferrer'
                        >
                          View existing MOU
                        </a>
                      )}
                    </div>
                    {error.mou ? <small className='text-red-500'>{error.mou}</small> : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex mt-6 mb-8 justify-around md:ml-4'>
              <button className='btn-primary w-full md:w-2/5 px-2 ' onClick={handleSubmit}>
                {distributorLoading && <i className='fa fa-spinner animate-spin mr-3' />}
                Update Distributor
              </button>
              <button
                onClick={() => setEdit(!edit)}
                className=' w-full md:w-2/5 px-2 btn-primary ml-3'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

UpdateDistributors.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  setModalVisible: PropTypes.func,
  data: PropTypes.shape({
    data: PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      address: PropTypes.string,
      mou: PropTypes.string,
      email: PropTypes.string
    })
  })
}

export default UpdateDistributors
