import React from 'react'
import Header from './components/Header'
import Section from './components/SectionOne'
import Newsletter from './components/Newsletter'

const Blog = () => {
  return (
    <div className='w-screen'>
      <Header />
      <Section />
      <Newsletter />
    </div>
  )
}

export default Blog
