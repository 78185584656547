/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'
import {
  BIRTHDATE,
  EMAIL,
  LAST_NAME,
  FIRST_NAME,
  PHONE_NO,
  TITLE_AND_ROLE,
  BANK_VERIFICATION_NO,
  YEARS_OF_EXPERIENCE,
  GENDER,
  MARITAL_STATUS,
  HIGHEST_EDUCATION,
  GOVERNMENT_ID,
  BROWSE_FILE,
  PASSPORT_PICS,
  HOME_ADDRESS
} from '../../sharedComponents/entries/constant'
import { InputField } from '../../sharedComponents/input-field'
import { message, Modal, Upload } from 'antd'
import { SUPPORTED_IMAGE_TYPE } from '../../utils/data'
import { UPLOAD_BASE_URL } from '../../utils/constants'
import { NO_OF_KIDS } from '../applications/constant'
import { directorDetailsValidator } from '../../utils/inputValidator'

const DirectorDetails = ({ visible, handleClose, data }) => {
  const [error, setError] = useState({})
  const [edit, setEdit] = useState(false)
  const directorLoading = useSelector(
    ({ loading }) => loading.effects.directors.customerUpdateDirector
  )
  const { token, permissions, userInfo } = useSelector(({ auth: authUser }) => authUser.authUser)
  const [previewDocs, setPreviewDocs] = useState({
    src: '',
    previewVisible: false
  })

  const [input, setInput] = useState({
    first_name: '',
    last_name: '',
    title: '',
    email: '',
    phone: '',
    birth_date: '',
    bank_veri_no: '',
    address: '',
    gender: '',
    years_of_experience: '',
    marital_status: '',
    education_level: '',
    photo: '',
    num_kids: '',
    government_id: ''
  })

  const validator = directorDetailsValidator(input)

  const uploadProps = {
    action: `${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        return message.error(`${file.name} is not supported`)
      }
      if (file.size / 1000000 > 35) {
        return message.error('This file size is more than the required 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const handleChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        const ImgUrl = info.file.response.data.url
        if (type === 'photo') {
          setInput({ ...input, photo: ImgUrl })
        } else if (type === 'government_id') {
          setInput({ ...input, government_id: ImgUrl })
        }
      }
    }
  }
  const onChangeText = ({ target }) => {
    setInput({ ...input, [target.name]: target.value })
    setError({ ...error, [target.name]: false })
  }
  const handleSubmit = async () => {
    let details = {}
    details = {
      director_id: data.id,
      first_name: input.first_name,
      last_name: input.last_name,
      title: input.title,
      email: input.email,
      phone: input.phone,
      birth_date: input.birth_date,
      bank_veri_no: input.bank_veri_no,
      address: input.address,
      gender: input.gender,
      years_of_experience: input.years_of_experience,
      marital_status: input.marital_status,
      education_level: input.education_level,
      photo: input.photo,
      num_kids: input.num_kids,
      government_id: input.government_id
    }
    if (validator.valid) {
      const url = userInfo.is_admin ? 'admin/update-director' : 'customer/update-director'
      await dispatch.directors.customerUpdateDirector({
        token,
        permissions,
        details,
        endpoint: url
      })
      handleClose()
    }
  }

  const handleDelete = async id => {
    await dispatch.directors.customerDeleteDirector({
      token,
      permissions,
      id,
      isAdmin: userInfo.is_admin
    })
    if (window.localStorage.getItem('apply-for-loan')) {
      const buzInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))
      const directorsInApplication = JSON.parse(window.localStorage.getItem('apply-for-loan'))
        .directors
        ? JSON.parse(window.localStorage.getItem('apply-for-loan')).directors
        : []

      const cleanedDirectorsList = directorsInApplication.filter(dir => dir !== id)

      const applyForLoan = {
        ...buzInfo,
        directors: cleanedDirectorsList
      }
      window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
    }
    handleClose()
  }
  const clearStateOnCloseModal = () => {
    handleClose()
    setInput({
      first_name: '',
      last_name: '',
      title: '',
      email: '',
      phone: '',
      birth_date: '',
      bank_veri_no: '',
      address: '',
      gender: '',
      years_of_experience: '',
      marital_status: '',
      education_level: '',
      photo: '',
      num_kids: '',
      government_id: ''
    })
  }

  const handlePreviewCancel = () => setPreviewDocs({ src: '', previewVisible: false })

  useEffect(() => {
    const dataImport = data.first_name
    if (dataImport) {
      setInput({
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
        email: data.email,
        phone: data.phone,
        birth_date: data.birth_date,
        bank_veri_no: data.bank_veri_no,
        address: data.address,
        gender: data.gender,
        years_of_experience: data.years_of_experience,
        marital_status: data.marital_status,
        education_level: data.education_level,
        photo: data.photo,
        num_kids: data.num_kids,
        government_id: data.government_id
      })
    }
  }, [data])

  return (
    <>
      <Modal
        footer={null}
        title='Preview'
        width='1000px'
        onCancel={handlePreviewCancel}
        open={previewDocs.previewVisible}
      >
        {previewDocs.src}
      </Modal>
      {visible ? (
        <div className='fixed z-50 bg-black bg-opacity-50 inset-0 flex flex-col justify-center'>
          <div
            style={{ maxWidth: '1550px' }}
            className={`${
              visible && 'scale-90'
            } duration-200 overflow-y-auto delay-100 bg-white w-full transform mx-auto rounded-2xl p-10`}
          >
            <div className='flex flex-wrap'>
              <div
                style={{ background: '#FAFAFA' }}
                className='bg-gray-100 w-full flex flex-wrap px-5 py-2'
              >
                <div className='w-full'>
                  <button
                    onClick={() => clearStateOnCloseModal()}
                    className='fixed top-4 grid place-content-center outline-none focus:outline-none cursor-pointer right-2 bg-white w-8 h-8 rounded-full'
                  >
                    <i className='fa fa-times text-3xl' />
                  </button>
                  <h4 className='text-dark1 text-lg md:text-xl font-semibold text-center font-manrope'>
                    Modify Details for {data.first_name} {data.last_name}
                  </h4>
                </div>
                <div className='mt-5 w-full flex justify-evenly'>
                  <button
                    onClick={() => setEdit(!edit)}
                    className='btn-primary-200 w-2/5 transition-all duration-300 active:bg-blue-700'
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(data.id)}
                    className='btn-primary-200 w-2/5 transition-all duration-300 active:bg-red-700 bg-red-800'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className={`${edit ? 'block' : 'hidden'}`}>
              <div className='mx-auto md:px-6 rounded-xl bg-theme1-400'>
                <div className='flex flex-wrap lg:mx-2 md:mx-auto'>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
                    <InputField
                      onChange={onChangeText}
                      value={input.first_name}
                      type='text'
                      name='first_name'
                      maxLength='35'
                      label={FIRST_NAME}
                      placeholder={FIRST_NAME}
                      className='form-control'
                    />
                    {error.first_name ? (
                      <small className='text-red-500'>{error.first_name}</small>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-4'>
                    <InputField
                      onChange={onChangeText}
                      value={input.last_name}
                      type='text'
                      name='last_name'
                      maxLength='35'
                      label={LAST_NAME}
                      placeholder={LAST_NAME}
                      className='form-control'
                    />
                    {error.last_name ? (
                      <small className='text-red-500'>{error.last_name}</small>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
                    <label className='text-gray-500 leading-8'>{TITLE_AND_ROLE}</label>

                    <select
                      onChange={onChangeText}
                      value={input.title}
                      type='text'
                      name='title'
                      maxLength='35'
                      placeholder={TITLE_AND_ROLE}
                      className='form-control'
                    >
                      {[
                        'Choose Title/role',
                        'Mr',
                        'Mrs',
                        'Chief',
                        'Engr',
                        'Dr',
                        'Prof',
                        'Master',
                        'Miss'
                      ].map((state, id) => {
                        return (
                          <option key={id} value={state === 'Title/role' ? '' : state}>
                            {state}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
                    <InputField
                      onChange={onChangeText}
                      value={input.email}
                      type='text'
                      name='email'
                      maxLength='45'
                      label={EMAIL}
                      placeholder={EMAIL}
                      className='form-control'
                    />
                    {error.email ? <small className='text-red-500'>{error.email}</small> : ''}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3  px-1 md:px-4 mt-4'>
                    <InputField
                      onChange={onChangeText}
                      value={input.phone}
                      type='text'
                      name='phone'
                      maxLength='15'
                      label={PHONE_NO}
                      placeholder={PHONE_NO}
                      className='form-control'
                    />
                    {error.phone ? <small className='text-red-500'>{error.phone}</small> : ''}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
                    <InputField
                      onChange={onChangeText}
                      value={input.birth_date}
                      type='date'
                      label={BIRTHDATE}
                      name='birth_date'
                      max={new Date().toISOString().slice(0, 10)}
                      placeholder={BIRTHDATE}
                      className='form-control'
                    />
                    {error.birth_date ? (
                      <small className='text-red-500'>{error.birth_date}</small>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
                    <InputField
                      onChange={onChangeText}
                      value={input.bank_veri_no}
                      type='text'
                      name='bank_veri_no'
                      maxLength='11'
                      label={BANK_VERIFICATION_NO}
                      placeholder={BANK_VERIFICATION_NO}
                      className='form-control'
                    />
                    {error.bank_veri_no ? (
                      <small className='text-red-500'>{error.bank_veri_no}</small>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-4'>
                    <InputField
                      onChange={e => {
                        if (isNaN(Number(e.target.value))) return false
                        onChangeText(e)
                      }}
                      value={input.years_of_experience}
                      type='text'
                      name='years_of_experience'
                      maxLength='2'
                      label={YEARS_OF_EXPERIENCE}
                      placeholder={YEARS_OF_EXPERIENCE}
                      className='form-control'
                    />
                    {error.yearOfExperience ? (
                      <small className='text-red-500'>{error.years_of_experience}</small>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
                    <label className='text-gray-500 leading-8'>{GENDER}</label>
                    <select
                      onChange={onChangeText}
                      value={input.gender}
                      type='text'
                      name='gender'
                      className='form-control'
                    >
                      {['Select gender', 'Male', 'Female'].map((state, id) => {
                        return (
                          <option key={id} value={state === 'Select gender' ? '' : state}>
                            {state}
                          </option>
                        )
                      })}
                    </select>
                    {error.gender ? <small className='text-red-500'>{error.gender}</small> : ''}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
                    <label className='text-gray-500 leading-8'>{MARITAL_STATUS}</label>

                    <select
                      onChange={onChangeText}
                      value={input.marital_status}
                      type='text'
                      name='maritalStatus'
                      className='form-control'
                    >
                      {['Marital Status', 'Single', 'Married', 'Others'].map((state, id) => {
                        return (
                          <option key={id} value={state === 'Marital Status' ? '' : state}>
                            {state}
                          </option>
                        )
                      })}
                    </select>
                    {error.maritalStatus ? (
                      <small className='text-red-500'>{error.maritalStatus}</small>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-8'>
                    <InputField
                      onChange={e => {
                        if (isNaN(Number(e.target.value))) return false
                        onChangeText(e)
                      }}
                      value={input.num_kids}
                      type='text'
                      name='num_kids'
                      maxLength='2'
                      label={NO_OF_KIDS}
                      placeholder={NO_OF_KIDS}
                      className='form-control'
                    />
                    {error.kids ? <small className='text-red-500'>{error.num_kids}</small> : ''}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
                    <label className='ml-2 text-gray-500 leading-8'>{HIGHEST_EDUCATION}</label>
                    <select
                      onChange={onChangeText}
                      value={input.education_level}
                      type='text'
                      name='education_level'
                      className='form-control'
                    >
                      {['Select one', 'None', 'Higer Education', 'Bachelor', 'Masters', 'Ph.D'].map(
                        (state, id) => {
                          return (
                            <option key={id} value={state === 'Select one' ? '' : state}>
                              {state}
                            </option>
                          )
                        }
                      )}
                    </select>
                    {error.education_level ? (
                      <small className='text-red-500'>{error.education_level}</small>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='w-full md:w-1/3 mt-4'>
                    <label className='text-gray-500 leading-8'>{HOME_ADDRESS}</label>
                    <textarea
                      onChange={onChangeText}
                      value={input.address ? input.address : data.address}
                      name='address'
                      rows='10'
                      placeholder={HOME_ADDRESS}
                      className='resize-none border rounded-md w-full appearance-none bg-transparent border-b-2 mr-3 py-2 px-2 leading-tight  mt-1 focus:outline-none  border-gray-400'
                    />
                    {error.address ? <small className='text-red-500'>{error.address}</small> : ''}
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-4'>
                    <label className='text-gray-500 leading-8'>{PASSPORT_PICS}</label>
                    <div className='flex w-full px-3 mt-3 border'>
                      <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                        <div
                          className='text-center px-2'
                          style={{ wordWrap: 'break-word', maxWidth: '190px', minWidth: '180px' }}
                        >
                          {input.photo ? (
                            <div>
                              {!(input.photo?.split('.').indexOf('pdf') > -1) ? (
                                <img
                                  width={200}
                                  height={100}
                                  src={input.photo}
                                  onClick={() =>
                                    setPreviewDocs({
                                      src: <img src={input.photo} />,
                                      previewVisible: true
                                    })
                                  }
                                />
                              ) : (
                                <iframe
                                  width={200}
                                  height={100}
                                  src={input.photo}
                                  onClick={() =>
                                    setPreviewDocs({
                                      src: <iframe src={input.photo} />,
                                      previewVisible: true
                                    })
                                  }
                                />
                              )}
                              <i
                                onClick={() => setInput({ ...input, photo: '' })}
                                className='far fa-trash-alt'
                                aria-hidden='true'
                              />
                              <i
                                onClick={() =>
                                  setPreviewDocs({
                                    src:
                                      input.photo?.split('.').indexOf('pdf') > -1 ? (
                                        <iframe width={900} height={500} src={input.photo} />
                                      ) : (
                                        <img src={input.photo} />
                                      ),
                                    previewVisible: true
                                  })
                                }
                                className='far fa-eye ml-3 text-lg'
                                aria-hidden='true'
                              />
                            </div>
                          ) : (
                            <Upload {...uploadProps} onChange={info => handleChange(info, 'photo')}>
                              <p className='text-center font-normal pt-6'>Drag file here </p>
                              <p className='text-center my-6 font-normal'>Or </p>
                              <p className='text-center font-normal text-base text-primary underline'>
                                Browse File
                              </p>
                            </Upload>
                          )}
                        </div>
                        {error.photo ? <small className='text-red-500'>{error.photo}</small> : ''}
                      </div>
                    </div>
                  </div>
                  <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
                    <label className='text-gray-500 leading-8'>{GOVERNMENT_ID}</label>
                    <div className='flex w-full px-3 mt-3 border'>
                      <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                        <div
                          className='text-center px-2'
                          style={{ wordWrap: 'break-word', maxWidth: '190px', minWidth: '180px' }}
                        >
                          {input.government_id ? (
                            <div>
                              {!(input.government_id?.split('.').indexOf('pdf') > -1) ? (
                                <img
                                  width={200}
                                  height={100}
                                  src={input.government_id}
                                  onClick={() =>
                                    setPreviewDocs({
                                      src: <img src={input.government_id} />,
                                      previewVisible: true
                                    })
                                  }
                                />
                              ) : (
                                <iframe
                                  width={200}
                                  height={100}
                                  src={input.government_id}
                                  onClick={() =>
                                    setPreviewDocs({
                                      src: <iframe src={input.government_id} />,
                                      previewVisible: true
                                    })
                                  }
                                />
                              )}
                              <i
                                onClick={() => setInput({ ...input, government_id: '' })}
                                className='far fa-trash-alt'
                                aria-hidden='true'
                              />
                              <i
                                onClick={() =>
                                  setPreviewDocs({
                                    src:
                                      input.government_id?.split('.').indexOf('pdf') > -1 ? (
                                        <iframe
                                          width={900}
                                          height={500}
                                          src={input.government_id}
                                        />
                                      ) : (
                                        <img src={input.government_id} />
                                      ),
                                    previewVisible: true
                                  })
                                }
                                className='far fa-eye ml-3 text-lg'
                                aria-hidden='true'
                              />
                            </div>
                          ) : (
                            <Upload
                              {...uploadProps}
                              onChange={info => handleChange(info, 'government_id')}
                            >
                              <p className='text-center font-normal pt-6'>Drag file here </p>
                              <p className='text-center my-6 font-normal'>Or </p>
                              <p className='text-center font-normal text-base text-primary underline'>
                                {BROWSE_FILE}
                              </p>
                            </Upload>
                          )}
                        </div>
                        {error.government_id ? (
                          <small className='text-red-500'>{error.government_id}</small>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full flex mt-6 mb-8 justify-start md:ml-4'>
                  <button className='btn-primary' onClick={handleSubmit}>
                    {directorLoading && <i className='fa fa-spinner animate-spin mr-3' />}
                    Update Director
                  </button>
                  <button onClick={() => setEdit(!edit)} className='btn-primary ml-3'>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

DirectorDetails.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  setModalVisible: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    title: PropTypes.string,
    birth_date: PropTypes.string,
    bank_veri_no: PropTypes.string,
    address: PropTypes.string,
    gender: PropTypes.string,
    years_of_experience: PropTypes.string,
    marital_status: PropTypes.string,
    education_level: PropTypes.string,
    photo: PropTypes.string,
    government_id: PropTypes.string,
    no_of_kids: PropTypes.string,
    num_kids: PropTypes.string
  })
}

export default DirectorDetails
