/* eslint-disable react/display-name */
/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react'
import { notification, Table } from 'antd'
import PropTypes from 'prop-types'
import Loans from '../../services/loans'
import {
  ACTIONS,
  AMOUNT_PAYBACK,
  LOAN_COLLECTED,
  LOAN_DURATION,
  LOAN_ID,
  MONTHS,
  STARTING_DATE,
  VIEW_TRANSACTIONS,
  INTEREST_AMOUNT
} from './constant'
import Transactions from './Transactions'
import { calculateRateInPercent, formatCurrency } from '../../utils/helpers'
import DueSoon from '../../sharedComponents/DueSoon'

export const getLoanColumns = setNewTransaction => [
  {
    title: <span className='font-bold'>{LOAN_ID}</span>,
    dataIndex: 'loan_app_id',
    key: 'loan_app_id',
    render: loan_app_id => <span>{loan_app_id}</span>
  },
  {
    title: <span className='font-bold'>{LOAN_COLLECTED}</span>,
    dataIndex: 'loan_principal_amount_fixed',
    key: 'loan_principal_amount_fixed',
    render: loan_principal_amount_fixed => (
      <span className='lowercase'>₦{formatCurrency(loan_principal_amount_fixed)}</span>
    )
  },
  {
    title: <span className='font-bold'>{AMOUNT_PAYBACK}</span>,
    key: 'principal_amount_paid',
    dataIndex: 'principal_amount_paid',
    render: principal_amount_paid => <span>₦{formatCurrency(principal_amount_paid)}</span>
  },
  {
    title: <span className='font-bold'>{INTEREST_AMOUNT}</span>,
    key: 'interest_amount',
    render: records => (
      <span>
        ₦
        {formatCurrency(
          calculateRateInPercent(records.loan_interest_rate, records.loan_principal_amount_fixed)
        )}
      </span>
    )
  },
  {
    title: <span className='font-bold'>{LOAN_DURATION}</span>,
    key: 'loan_duration',
    dataIndex: 'loan_duration',
    render: loan_duration => (
      <span>
        {loan_duration} {MONTHS}
      </span>
    )
  },
  {
    title: <span className='font-bold'>{STARTING_DATE}</span>,
    key: 'start_date',
    dataIndex: 'start_date',
    render: start_date => <span>{start_date.slice(0, 10)}</span>
  },
  {
    title: <span className='font-bold'>Next Interest Date</span>,
    key: 'next_interest_date',
    dataIndex: 'next_interest_date',
    render: next_interest_date => <DueSoon nextDate={next_interest_date} />,
    sorter: (a, b) => new Date(a.next_interest_date) - new Date(b.next_interest_date)
  },
  {
    title: <span className='font-bold capitalize'>{ACTIONS}</span>,
    key: 'action',
    render: records => (
      <button
        onClick={() => setNewTransaction(records)}
        className='px-6 py-2 border text-primary hover:text-white rounded-lg hover:bg-primary text-sm'
      >
        {VIEW_TRANSACTIONS}
      </button>
    )
  }
]

const LoanTable = ({ tableData, loading }) => {
  const [data, setData] = useState([])
  const [repaymentData, setRepaymentData] = useState()
  const [selectedData, setCurrentDataSelected] = useState({})
  const [modalVisibility, setModalVisible] = useState(false)
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })
  const [historyLoading, setHistoryLoading] = useState([])

  const setNewTransaction = async record => {
    setCurrentDataSelected(record)
    setModalVisible(true)
    setHistoryLoading(true)
    try {
      const { data } = await Loans.getRepaymentHistory(record.approved_loan_id)
      setRepaymentData(data.data)
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: data?.message || 'Cannot fetch payment history'
      })
      setHistoryLoading(false)
    }
    setHistoryLoading(false)
  }

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  return (
    <div>
      <Transactions
        repayment={repaymentData}
        historyLoading={historyLoading}
        visible={modalVisibility}
        data={selectedData}
        handleClose={() => setModalVisible(false)}
      />
      <Table
        columns={getLoanColumns(setNewTransaction)}
        onChange={value => setPagination(value)}
        pagination={pagination}
        loading={loading}
        dataSource={data}
      />
    </div>
  )
}

LoanTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  loading: PropTypes.bool
}

export default LoanTable
