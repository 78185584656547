import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SaasCustomerCsvModal from './modal/SaasCustomerCsvModal'

function SaasCustomerAction({ userId }) {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <div>
      <button
        onClick={() => setModalVisible(true)}
        className='bg-primary text-base py-1 px-4 border rounded-lg hover:bg-primary-200 opacity-90 text-white hover:text-gray-50;'
      >
        Export CSV
      </button>
      <SaasCustomerCsvModal
        userId={userId}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
      />
    </div>
  )
}

SaasCustomerAction.propTypes = {
  userId: PropTypes.string.isRequired
}

export default SaasCustomerAction
