import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ProfileTab = ({ setCurrentProfileTab }) => {
  const tabHeaders = ['Profile', 'Password', 'Transaction Pin']
  const [currrentButton, setCurrentButton] = useState('profile-0')

  const handleCurrentButton = value => {
    setCurrentProfileTab(value)
    setCurrentButton(value)
  }
  return (
    <section className='flex justify-center'>
      <div className='bg-white shadow inline-flex gap-8 py-2 items-center justify-center px-10 rounded-md pt-3'>
        {tabHeaders.map((header, index) => (
          <button
            className={`font-bold text-sm md:w-40 ${
              currrentButton === `profile-${index}`
                ? 'btn-primary-200 opacity-100'
                : 'btn-secondary text-primary '
            } opacity-90 transition-opacity duration-300 select-none`}
            key={index}
            onClick={() => handleCurrentButton(`profile-${index}`)}
          >
            {header}
          </button>
        ))}
      </div>
    </section>
  )
}

ProfileTab.propTypes = {
  setCurrentProfileTab: PropTypes.func.isRequired
}

export default ProfileTab
