import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-lodash-debounce'
import { dispatch } from '../../../redux/store'

const ApplicationSearch = ({ url }) => {
  const [input, setInput] = useState('')

  const debouncedInput = useDebounce(input, 1000)

  // TODO: search to be refactored with new endpoint.
  /** @deprecated this search function will be removced */
  const handleSearch = async () => {
    if (debouncedInput === '') {
      // dispatch.applications.adminAppStatistics()
      return false
    }

    await dispatch.applications.searchApplication({
      amount: debouncedInput.split(',').join(''),
      url
    })
  }
  useEffect(
    async function () {
      await handleSearch()
    },
    [debouncedInput]
  )
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-1 text-lg mx-4'>
      <div className='relative mt-6 border rounded-lg mr-6 ml-3 col-span-3'>
        <div className='absolute top-4 left-3 pl-2'>
          <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />{' '}
        </div>
        <input
          type='text'
          // to be removed when the new search is refactored
          disabled
          onChange={e => setInput(e.target.value)}
          value={input}
          className='h-14 w-full pl-14 pr-20 rounded-lg z-0 focus:shadow focus:outline-none'
          placeholder='Search by amount, business name, etc'
        />
      </div>
    </div>
  )
}
ApplicationSearch.propTypes = {
  url: PropTypes.string
}

export default ApplicationSearch
