import React from 'react'
import logo from '../../../images/blog/vittaslogo.svg'
import EmailIcon from '../../../images/icons/ic_round-email.svg'
import PhoneIcon from '../../../images/icons/material-symbols_call.svg'
import PlaceIcon from '../../../images/icons/mdi_address-marker.svg'
import { FooterWriteup } from '../constants'

const BlogFooter = () => {
  return (
    <div className='bg-secondary w-screen   p-6 py-12 flex flex-col lg:flex-row md:justify-between lg:items-center md:px-12'>
      <div className=' '>
        <img
          src={logo}
          alt='logo'
          // className='object-contain h-[20px] w-[20px] md:inline lg:h-[280px] lg:w-[360px]'
        />
        <p className='text-xl lg:max-w-sm leading-normal mt-4'>{FooterWriteup}</p>
      </div>

      <div className='text-start mt-8 '>
        {/* <div className=''> */}
        <p className='font-bold text-2xl text-gray-600 mb-6'>About</p>
        <div className='space-y-3'>
          <p>
            {' '}
            <a href='/login' className='text-gray-600 text-lg'>
              Login
            </a>
          </p>

          <p>
            <a href='/sign-up' className='text-gray-600 text-lg'>
              Sign Up
            </a>
          </p>
          <p>
            <a href='/blog' className='text-gray-600 text-lg'>
              Blog
            </a>
          </p>
          <p>
            <a href='/terms-and-conditions' className='text-gray-600 text-lg'>
              Terms and Conditions
            </a>
          </p>
          <p>
            <a href='/privacy-policy' className='text-gray-600 text-lg'>
              Privacy Policy
            </a>
          </p>
        </div>
        {/* </div> */}
      </div>

      <div className=''>
        <div className=''>
          <p className='font-bold text-2xl text-gray-600 mb-6'>Socials</p>
          <div className='space-y-3'>
            <p>
              {' '}
              <a
                href='https://web.facebook.com/VittasInternational'
                className='text-gray-600 text-lg'
              >
                Facebook
              </a>
            </p>

            <p>
              <a href='https://www.linkedin.com/company/vittas/' className='text-gray-600 text-lg'>
                Linkedln
              </a>
            </p>
            <p>
              <a href='https://twitter.com/' className='text-gray-600 text-lg'>
                Twitter
              </a>
            </p>
            <p>
              <a href='https://www.youtube.com/' className='text-gray-600 text-lg'>
                Youtube
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className='mb-7'>
        <div className=''>
          <p className='font-bold text-2xl text-gray-600 mb-6'>Contact</p>
          <div className='space-y-3'>
            <p>
              <a
                href='mailto:contact@vittasinternational.com'
                className='text-gray-600 text-lg flex gap-2 items-center'
              >
                <span>
                  {' '}
                  <img src={EmailIcon} alt='' />{' '}
                </span>{' '}
                <span>contact@vittasinternational.com </span>
              </a>
            </p>
            <p>
              <a
                href='tel:+2348060805026'
                className='text-gray-600 text-lg flex gap-2 items-center'
              >
                <span>
                  <img src={PhoneIcon} alt='' />{' '}
                </span>{' '}
                <span> +234 806 080 5026 </span>
              </a>
            </p>
            <p>
              <span className='text-gray-600 text-lg flex gap-2 items-center'>
                <span>
                  <img src={PlaceIcon} alt='' />{' '}
                </span>{' '}
                <span>Lagos, Nigeria </span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogFooter
