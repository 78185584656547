import React from 'react'
import { useParams } from 'react-router-dom'
import { blogs } from '../constants'
import moment from 'moment'
import Newsletter from './Newsletter'
import { BlogItem } from './SectionOne'
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon
} from 'react-share'

import BlogFooter from './BlogFooter'
const BlogPage = () => {
  const { title } = useParams()
  const blog = blogs.find(blog => blog.title === title)
  // Creating a new array with three blog items to display for the You may also Like
  const relatedBlogs = blogs.filter(blog => blog.title !== title).slice(0, 3)
  const shareUrl = `/blog/${blog.title}`

  return (
    <div className='flex flex-col justify-center items-center '>
      <div className='max-w-screen-xl flex flex-col justify-center items-center px-2 py-32'>
        <p className='text-header-txt text-xl font-medium'>
          Published {moment(blog.date).format('MMMM D, YYYY')}
        </p>
        <h1 className='text-xl md:text-2xl lg:text-4xl lg:max-w-3xl lg:leading-snug font-bold'>
          {blog.title}
        </h1>

        <div className='max-w-6xl'>
          <img className='rounded-xl w-full py-8' src={blog.image} alt={blog.title} />
        </div>
        {blog?.paragraphs?.map((item, idx) => (
          <p
            key={idx}
            className='leading-loose text-xl px-2   mt-2 md:text-2xl text-header-txt text-justify'
          >
            {item}
          </p>
        ))}
        <span className='w-full bg-gray-400 mt-12 h-0.5'> </span>
        <div className='flex flex-col md:flex-row justify-between w-full px-3 py-8'>
          <div className='flex gap-6 items-center'>
            <div className='bg-gray-200 w-20 h-20 rounded-full'> </div>
            <div>
              <p className='text-gray-400 font-bold'>WRITTEN BY</p>
              <h1 className='text-xl font-black'>{blog.publishedBy}</h1>
            </div>
          </div>
          <div>
            <span className='flex mt-4 text-lg'>
              Share on:{' '}
              <div className='flex gap-4 ml-2'>
                <span>
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={40} />
                  </FacebookShareButton>
                </span>
                <span>
                  <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={40} />
                  </TwitterShareButton>
                </span>
                <span>
                  <LinkedinShareButton url={shareUrl}>
                    <LinkedinIcon size={40} />
                  </LinkedinShareButton>
                </span>
                <span>
                  <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size={40} />
                  </WhatsappShareButton>
                </span>
              </div>
            </span>
          </div>
        </div>
        <span className='w-full bg-gray-400 mt-4 h-0.5'> </span>
      </div>
      {/* Rendering the related blog items */}
      <div className='w-full max-w-screen-2xl flex flex-col justify-center items-center px-2'>
        <h2 className='text-3xl md:text-4xl font-bold mb-6'>You may also like</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-6 w-full'>
          {relatedBlogs.map((item, index) => (
            <BlogItem key={index} item={item} />
          ))}
        </div>
      </div>
      <div className='w-full'>
        <Newsletter />
      </div>
      <div className='w-full'>
        <BlogFooter />
      </div>
    </div>
  )
}

export default BlogPage
