import React from 'react'
import PropTypes from 'prop-types'

import { copyToClipboard } from '../../utils/helpers'

const IdsComponent = ({ id }) => {
  return (
    <span title={id}>
      {id?.substr(0, 8)}{' '}
      <i className='fas fa-copy' title='copy' onClick={() => copyToClipboard(id, 'ID Copied')} />
    </span>
  )
}

export default IdsComponent

IdsComponent.propTypes = {
  id: PropTypes.string
}
