import React from 'react'
import PropTypes from 'prop-types'

const Export = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='text-base py-3 px-5 duration-200 bg-primary text-white rounded font-manrope'
    >
      Export <i className='fa fa-download ml-2' />
    </button>
  )
}

export default Export

Export.propTypes = {
  onClick: PropTypes.func.isRequired
}
