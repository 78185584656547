import React, { useEffect } from 'react'
import Loading from 'react-loading'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { dispatch } from '../../redux/store'
import { getQueryParams } from '../../utils/customeReactHook'
import errorEmoji from '../../images/error/error-emoji.gif'

const ConfirmSignupByLink = () => {
  const history = useHistory()
  const query = getQueryParams()

  const loading = useSelector(({ loading }) => loading.effects.auth.confirmSignUp)

  useEffect(() => {
    if (!query.get('token')) {
      history.push('/login')
    } else {
      dispatch.auth.confirmSignUp({ history, token: query.get('token') })
    }
  }, [])

  return (
    <div className='flex justify-items-center justify-center pt-40'>
      {loading ? (
        <Loading color='blue' type='bars' width={500} />
      ) : (
        <div className='px-5'>
          <h1>
            <img className='mx-auto' src={errorEmoji} alt='error emoji' />
          </h1>
          <h1 className='text-xl text-center mt-5'>
            <span className='md:text-2xl'>Oops! </span> sorry, this token has expired or something
            went wrong from our end{' '}
          </h1>
          <p className='text-center text-lg'>
            Please try logging in again <Link to='/login'> Login</Link>
          </p>
        </div>
      )}
    </div>
  )
}

export default ConfirmSignupByLink
