import React from 'react'

import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import CreateAdministration from './CreateAdministration'
import MakeUserAdmin from './MakeUserAdmin'
import RegisterCompany from './RegisterCompany'
import UpdateAdmin from './UpdateAdmin'

const Administration = () => {
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 md:flex mt-4'>
          <CreateAdministration />
          <RegisterCompany />
        </div>
        <div className='border bg-gray-300' />
        <div className=' ml-1 md:flex my-10'>
          <MakeUserAdmin />
          <UpdateAdmin />
        </div>
      </main>
    </DashboardLayout>
  )
}

export default Administration
