import React, { useState, useEffect } from 'react'
import { notification, Tooltip, Table } from 'antd'
import { stepTwoValidator } from '../../utils/inputValidator'
import PropTypes from 'prop-types'
import { getColums } from '../../pages/documents/ListDocs'
import { useSelector } from 'react-redux'
import AddDocuments from './AddDocuments'
import DocDetails from '../../pages/documents/DocDetails'
import { select } from '../../redux/store'
import { useLocation } from 'react-router-dom'

const StepTwo = ({ nextKey }) => {
  const { bankStatementFromMono /* authUser */ } = useSelector(({ auth: { authUser } }) => ({
    authUser,
    bankStatementFromMono: authUser.userInfo?.bank_statements?.length
      ? JSON.stringify(authUser.userInfo?.bank_statements[0])
      : ''
  }))

  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })
  const [initialSelectedDocs, setInitialSelectedDocs] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [selectedData, setCurrentDataSelected] = useState({})
  const [modalVisibility, setModalVisible] = useState(false)
  const [input, setInput] = useState([])
  const [customer, setCustomerDocs] = useState({})
  const { state } = useLocation()
  const { documents, isAdmin } = useSelector(({ auth: { authUser } }) => ({
    documents: authUser.userInfo?.data?.documents,
    isAdmin: authUser.userInfo?.is_admin
  }))

  const allCustomers = useSelector(select.users.getUsersNotAdmin)

  // Initialised the store for selected directors
  let selectedDocs = initialSelectedDocs

  // Handle Table selection
  let newSelectedDocs = []

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      newSelectedDocs = selectedRows
    },
    renderCell(checked, record, index, node) {
      if (selectedDocs?.indexOf(record.id) > -1) {
        return (
          <Tooltip title='You had previously selected this director. Click "Clear Selections" to remove previously selected Directors. You can add more directors or click "Next" to keep these previously selected ones'>
            {node}
          </Tooltip>
        )
      }
      return node
    },

    getCheckboxProps: record => ({
      disabled: selectedDocs?.indexOf(record.id) > -1,
      // Column configuration not to be checked
      id: record.id
    })
  }

  // Clear Table selections
  const clearSelectionsHandler = () => {
    const buzInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))
    const applyForLoan = {
      ...buzInfo,
      businessDocuments: []
    }
    window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
    setInitialSelectedDocs([])
    selectedDocs = []
  }

  const handleAddDocument = () => {
    setOpenModal(true)
  }

  const handleSubmit = async () => {
    selectedDocs = [...selectedDocs, ...newSelectedDocs]
    const documentId = selectedDocs.map(eachDoc => eachDoc?.id)
    const documentTypes = selectedDocs.map(eachDoc => eachDoc?.type)

    const bankStatements = documentTypes.filter(
      eachBankStatement => eachBankStatement === 'bank_statement'
    )
    const cac2 = documentTypes.filter(eachCac => eachCac === 'cac_2')
    const cac3 = documentTypes.filter(eachCac => eachCac === 'cac_3')
    const cac7 = documentTypes.filter(eachCac => eachCac === 'cac_7')
    const moa = documentTypes.filter(eachMoa => eachMoa === 'moa')
    const aoa = documentTypes.filter(eachAoa => eachAoa === 'aoa')
    const pharmacistLicense = documentTypes.filter(
      eachPharmacistLicense => eachPharmacistLicense === 'pharmacist_license'
    )
    const businessLicenses = documentTypes.filter(
      eachBusinessLicenses => eachBusinessLicenses === 'business_licenses'
    )
    if (
      cac2.length >= 1 &&
      cac3.length >= 1 &&
      cac7.length >= 1 &&
      moa.length >= 1 &&
      aoa.length >= 1 &&
      pharmacistLicense.length >= 1 &&
      businessLicenses.length >= 1 &&
      bankStatements.length >= 1
    ) {
      if (window.localStorage.getItem('apply-for-loan')) {
        const buzInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))

        const cleaned = Array.from(new Set(documentId))

        const applyForLoan = {
          ...buzInfo,
          businessDocuments: cleaned
        }
        window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
        nextKey(3)
      } else {
        nextKey(1)
      }
    } else {
      if (cac2.length < 1) {
        notification.error({
          message: 'CAC Document',
          description: 'Please select at least one document of type "CAC 2"'
        })
      }
      if (cac3.length < 1) {
        notification.error({
          message: 'CAC Document',
          description: 'Please select at least one document of type "CAC 3"'
        })
      }
      if (cac7.length < 1) {
        notification.error({
          message: 'CAC Document',
          description: 'Please select at least one document of type "CAC 7"'
        })
      }
      if (moa.length < 1) {
        notification.error({
          message: 'Memorandum of Association Document',
          description: 'Please select at least one document of type "MEMORANDUM OF ASSOCIATION"'
        })
      }
      if (aoa.length < 1) {
        notification.error({
          message: 'Article of Association Document',
          description: 'Please select at least one document of type "ARTICLES OF ASSOCIATION"'
        })
      }
      if (pharmacistLicense.length < 1) {
        notification.error({
          message: 'Pharmacist License Document',
          description: 'Please select at least one document of type "PHARMACIST LICENSE"'
        })
      }
      if (businessLicenses.length < 1) {
        notification.error({
          message: 'Business License Document',
          description: 'Please select at least one document of type "BUSINESS LICENSE"'
        })
      }
      if (bankStatements.length < 1) {
        notification.error({
          message: 'Bank Statement Document',
          description: 'Please select at least one document of type "BANKS STATEMENT"'
        })
      }
      selectedDocs = initialSelectedDocs
    }
  }
  useEffect(() => {
    if (bankStatementFromMono) {
      setInput([...input, bankStatementFromMono.id])
    }
  }, [bankStatementFromMono])

  const setDetails = record => {
    setCurrentDataSelected(record)
    setModalVisible(true)
  }

  useEffect(() => {
    if (window.localStorage.getItem('apply-for-loan')) {
      const businessDocuments = JSON.parse(
        window.localStorage.getItem('apply-for-loan')
      ).businessDocuments
      setInitialSelectedDocs(businessDocuments || [])
    } else {
    }
  }, [])

  useEffect(() => {
    if (isAdmin) {
      setCustomerDocs(allCustomers.find(item => item.id === state?.user?.id))
    }
  }, [isAdmin, allCustomers])

  const tableData = isAdmin ? customer?.documents : documents

  return (
    <div className='w-full mx-auto px-1 md:px-6 rounded-xl bg-theme1-400'>
      <div className='flex flex-wrap mx-2 md:mx-5 lg:mx-6 mt-5 mb-2'>
        <p
          className={`w-full text-center text-base ${
            stepTwoValidator(input).valid ? 'text-green-500' : 'text-blue-500'
          }`}
        >
          {stepTwoValidator(input).valid
            ? 'Attached files are still availabe. Do you wish to change any document? Else proceed to next step.'
            : '* Kindly upload a clear document below. (File size: 30mb max.)'}
        </p>
      </div>
      <div className='overflow-auto p-4 mx-auto border-2 border-gray-400 rounded-lg'>
        <AddDocuments openModal={openModal} setOpenModal={setOpenModal} />
        <DocDetails
          visible={modalVisibility}
          data={selectedData}
          handleClose={() => setModalVisible(false)}
        />
        <Table
          rowSelection={rowSelection}
          columns={getColums(setDetails)}
          onChange={value => setPagination(value)}
          pagination={pagination}
          dataSource={tableData?.map(item => ({ ...item, key: item.id }))}
        />
        <button
          onClick={() => clearSelectionsHandler()}
          className='px-4 py-2 transition-all duration-300 bg-gray-300 active:bg-gray-400 rounded-lg font-bold mb-5 mr-5'
        >
          {' '}
          Clear Selections
        </button>
        <button
          onClick={() => handleAddDocument()}
          className='px-4 py-2 transition-all duration-300 bg-gray-300 active:bg-gray-400 rounded-lg font-bold mb-5 mr-5'
        >
          Add New Document
        </button>
      </div>
      <div className='w-full gap-6 flex my-8 md:ml-6 justify-startpr-10'>
        <button className='text-primary' onClick={() => nextKey(1)}>
          Go Back
        </button>
        <button className='btn-primary bg-primary text-white' onClick={handleSubmit}>
          Next
        </button>
      </div>
    </div>
  )
}
StepTwo.propTypes = {
  nextKey: PropTypes.func
}

export default StepTwo
