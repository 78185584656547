/* eslint-disable prettier/prettier */
import { notification } from 'antd'
import { ContactUs } from '../../services'
import { reducerActions } from '../reducer'

const initialState = {
  contactMsg: []
}

export const contact = {
  state: initialState,
  reducers: reducerActions,
  effects: (dispatch) => ({
    async contactList() {
      dispatch.contact.setError(null)
      try {
        const { data: { data } } = await ContactUs.getContactList()
        dispatch.contact.setState({
          contactMsg: [...data.contact_messages].reverse()
        })
      } catch ({ response, message }) {
        notification.error({
          message: 'An error occured',
          description: message
        })
      }
    }
  })
}
