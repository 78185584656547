/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import { Button, Input, Space } from 'antd'
import Highlighter from 'react-highlight-words'

export const getColumnSearchProps = ({
  dataIndex,
  searchInput,
  handleSearch,
  handleReset,
  setSearchText,
  searchedColumn,
  searchText,
  setSearchedColumn,
  title
}) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div
      style={{
        padding: 8
      }}
    >
      <Input
        ref={searchInput}
        placeholder={`Search by ${title}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block'
        }}
      />
      <Space>
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<i className='fa fa-search' aria-hidden='true' />}
          size='small'
          style={{
            width: 90
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size='small'
          style={{
            width: 90
          }}
        >
          Reset
        </Button>
        <Button
          type='link'
          size='small'
          onClick={() => {
            confirm({
              closeDropdown: false
            })
            setSearchText(selectedKeys[0])
            setSearchedColumn(dataIndex)
          }}
        >
          Filter
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => (
    <i
      className='fa fa-search'
      aria-hidden='true'
      style={{
        color: filtered ? '#1890ff' : undefined
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput?.current?.select(), 100)
    }
  },
  render: text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )
})
