/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import AddDirectorForm from '../directors/AddDirectorForm'
import ListDirectors from '../directors/ListDirectors'

const AddDirectorToDistributor = ({ distributorRecords }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  return (
    <div>
      <button onClick={() => setIsModalVisible(true)}>
        <i className='fa fa-plus mr-2' />
        Add Director
      </button>
      <Modal
        title='Add New Director'
        onCancel={() => setIsModalVisible(false)}
        width='70%'
        open={isModalVisible}
        footer={false}
      >
        <div className='mt-8 mb-16 overflow-x-auto'>
          <ListDirectors user={distributorRecords} />
          <AddDirectorForm distributorRecords={distributorRecords} />
        </div>
      </Modal>
    </div>
  )
}

AddDirectorToDistributor.propTypes = {
  distributorRecords: PropTypes.shape({
    key: PropTypes.string
  })
}
export default AddDirectorToDistributor
