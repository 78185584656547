import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { BACK_TO_DASHBOARD, SUCCESS_APPLICATION } from './constants'
import successImg from '../../images/success.gif'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'

const ApplicationSuccess = () => {
  const history = useHistory()

  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))
  const handleClick = () => {
    if (isAdmin) {
      history.push('/admin-dashboard', { shouldUseCache: false })
    } else {
      history.push('/dashboard')
    }
  }

  useEffect(() => {
    dispatch.applications.adminGetLoanByStatus({
      status: 'pending',
      shouldUseCache: false
    })
  }, [])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={handleClick} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {BACK_TO_DASHBOARD}
          </h3>
        </div>
        <div className='mt-4 mb-16 px-4 overflow-x-auto h-screen'>
          <div className='flex flex-wrap justify-center items-center text-base'>
            <div className='mt-4'>
              <h2 className='text-center text-green-600 text-2xl font-bold'>
                {SUCCESS_APPLICATION}
              </h2>
              <p className='ml-10'>
                <img src={successImg} alt='success' className='max-h-80' />
              </p>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default ApplicationSuccess
