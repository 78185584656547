import React, { useState } from 'react'
import { InputField } from '../../../../sharedComponents/input-field'

const DTIAnalysis = () => {
  const [fundsAvailable, setFundsAvailable] = useState('')
  const [dtiRatioAnalysis, setDtiRatioAnalysis] = useState('')
  const [loanRequest, setLoanDuration] = useState('')
  const [interest, setInterest] = useState('')
  const [principalAndInterest, setPrincipalAndInterest] = useState('')
  const [principalAndInterestMonthly, setPrincipalAndInterestMonthly] = useState('')
  const [totalExternalCommitment, setTotalExternalCommitment] = useState('')
  const [loanEligibilityAmount, setLoanEligilityAmount] = useState('')
  const [comment, setComment] = useState('')

  const handleInputChange = setter => event => {
    setter(event.target.value)
  }

  return (
    <div>
      <div className='flex justify-end text-primary-200 font-semibold mr-4 cursor-pointer'>
        Edit Analysis
      </div>
      <div className='flex flex-wrap'>
        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setFundsAvailable)}
            value={fundsAvailable}
            type='number'
            name='fundsAvailable'
            label='Funds available'
            placeholder='3.00'
            className='form-control bg-white'
          />
        </div>
        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setDtiRatioAnalysis)}
            value={dtiRatioAnalysis}
            type='number'
            name='dtiRatioAnalysis'
            label='DTI ratio analysis at 36%'
            placeholder='12 Month'
            className='form-control bg-white'
          />
        </div>
        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setLoanDuration)}
            value={loanRequest}
            type='text'
            name='loanRequest'
            label='Loan Request'
            placeholder='Enter loan request'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setInterest)}
            value={interest}
            type='number'
            name='interest'
            label='Interest (12 Month)'
            placeholder='12 000'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setPrincipalAndInterest)}
            value={principalAndInterest}
            type='number'
            name='principalAndInterest'
            label='Principal and interest'
            placeholder='12 Month'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setPrincipalAndInterestMonthly)}
            value={principalAndInterestMonthly}
            type='number'
            name='principalAndInterestMonthly'
            label='Principal and interest monthly'
            placeholder='12 Month'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setTotalExternalCommitment)}
            value={totalExternalCommitment}
            type='number'
            name='totalExternalCommitment'
            label='Principal and interest monthly'
            placeholder='12 Month'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setLoanEligilityAmount)}
            value={loanEligibilityAmount}
            type='number'
            name='loanEligibilityAmount'
            label='loan Eligibility Amount'
            placeholder='12 Month'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setComment)}
            value={comment}
            type='text'
            name='comment'
            label='Comment'
            placeholder='Enter comment here...'
            className='form-control bg-white'
          />
        </div>

        <div className='inline-flex flex-col md:px-4 mt-8'>
          <div>
            <p>Status</p>
            <div className='bg-lightGreen py-1 px-4 rounded-full text-white font-semibold mt-2'>
              Approved
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DTIAnalysis
