import React, { useState, useEffect } from 'react'
import Icon from '../../../../../../images/wrapper.svg'
import InfoIcon from '../../../../../../images/info-circle.svg'
import { notification } from 'antd'
import { adminServices } from '../../../../../../services'
import PropTypes from 'prop-types'

const Confirmation = ({ customeBizInfo }) => {
  const [isPending, setIsPending] = useState(true)
  const [loading, setLoading] = useState(false)

  const deactivateAccountPnd = async () => {
    setLoading(true)
    try {
      const { data } = await adminServices.deactivateAccountPnd(customeBizInfo?.userId)
      if (data.success) {
        setIsPending(false)
        notification.success({
          message: 'Success',
          description: 'Account released from PND successfully'
        })
      } else {
        notification.error({
          message: 'An error occured',
          description: data?.message || 'Something went wrong!'
        })
      }

      setLoading(false)
    } catch (err) {
      if (err?.response?.data?.message === 'BVN consent not given') {
        setIsPending(true)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    async function fetch() {
      await deactivateAccountPnd()
    }
    fetch()
  }, [])

  return (
    <main>
      {loading ? (
        <div className='text-black text-center h-44 text-base font-bold my-6'>
          Wait while we get consent from {customeBizInfo?.vfd_data?.companyName}
        </div>
      ) : isPending ? (
        <div className='py-20 flex flex-col justify-center items-center'>
          <div className='flex justify-center items-center'>
            <img src={InfoIcon} alt='info icon' className='' />
          </div>
          <div className='mt-8 w-48 text-center text-overPaid text-base font-bold leading-[23px]'>
            Your request is still pending, kindly provide BVN consent.
          </div>
        </div>
      ) : (
        <div className='py-20 flex flex-col justify-center items-center'>
          <div className='flex justify-center items-center'>
            <img src={Icon} alt='success icon' className='' />
          </div>
          <div className='mt-8 w-48 text-center text-indigo-700 text-base font-bold leading-[23px]'>
            Your request from {customeBizInfo?.vfd_data?.companyName} is Successful
          </div>
        </div>
      )}
    </main>
  )
}

Confirmation.propTypes = {
  customeBizInfo: PropTypes.any
}

export default Confirmation
