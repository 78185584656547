import React from 'react'
import { Statistics } from '../../../sharedComponents/statistics-admin'
import { superData } from '../../../utils/statistics'
import { DoughnutChart, LineChartAdmin } from '../../../sharedComponents/chartjs'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'

import UpcomingPaymentTable from '../../../sharedComponents/statistics-table-admin/upcoming payment/UpcomingPaymentTable'
import PendingDisbursementTable from '../../../sharedComponents/statistics-table-admin/pending disbursement/PendingDisbursementTable'

const AdminDashboard = () => {
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <Statistics data={superData} />
        <div className='flex flex-wrap mb-16'>
          <div className='w-full md:w-1/2 mt-16 px-2 md:px-4'>
            <LineChartAdmin />
          </div>
          <div className='w-full md:w-1/2 mt-12 px-2 md:px-4'>
            <DoughnutChart />
          </div>
        </div>
        <div className='flex flex-wrap mb-16'>
          <div className='w-full md:w-1/2 mt-5 px-2 md:px-4 shadow-xl shadow-left shadow-right shadow-bottom"'>
            <UpcomingPaymentTable />
          </div>
          <div className='w-full md:w-1/2 mt-5 px-2 md:px-4 shadow-lg shadow-bottom'>
            <PendingDisbursementTable />
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default AdminDashboard
