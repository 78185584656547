import React from 'react'
import PropTypes from 'prop-types'
import successImg from '../../../images/check-circle.svg'

const SuccessModal = ({ onClose }) => {
  return (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-700 bg-opacity-50'>
      <div className='bg-white w-full max-w-xs p-6 rounded-lg flex flex-col justify-center items-center'>
        <div className='w-24'>
          <img src={successImg} alt='Success' className='w-full' />
        </div>
        <h2 className='text-2xl font-bold mb-4'>Success</h2>
        <p className='text-xl px-6 text-center'>password has been reset</p>
        <button
          onClick={onClose}
          className='btn-primary px-6 text-primary border-2 flex self-center justify-center border-blue-400 p-2 mt-4 rounded'
        >
          Continue
        </button>
      </div>
    </div>
  )
}
SuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default SuccessModal
