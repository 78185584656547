/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import React from 'react'
import IdsComponent from '../../../sharedComponents/others/IdsComponent'
import { calculateRateInPercent } from '../../../utils/helpers'
import DueSoon from '../../../sharedComponents/DueSoon'
import { getColumnSearchProps } from '../../../sharedComponents/antSearch/search'
import AdminResetPwdForCustomer from './AdminResetPwdForCustomer'
import DropDown from '../../../sharedComponents/drop-down/DropDown'

const RESET_LABEL_PWD = 'New Password'
const PWD_BTN = 'Reset Password'
const expandedColumns = setModalVisibleWithRecord => [
  {
    title: <span className='font-bold'>S/N</span>,
    dataIndex: 'serialNumber',
    key: 'serialNumber',
    render: (__, _, index) => <span>{index + 1}</span>
  },
  {
    title: <span className='font-bold'>Loan ID</span>,
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: loan_id => <IdsComponent id={loan_id} />
  },

  {
    title: <span className='font-bold'>Loan Collected</span>,
    dataIndex: 'loan_amount',
    key: 'loan_amount',
    render: loan_amount => <span className='lowercase'>₦{loan_amount}</span>
  },

  {
    title: <span className='font-bold'>Amount PaidBack</span>,
    key: 'principal_amount_paid',
    dataIndex: 'principal_amount_paid',
    render: principal_amount_paid => <span>₦ {principal_amount_paid}</span>
  },
  {
    title: <span className='font-bold'>Interest Amount</span>,
    key: 'loan_interest_rate',
    dataIndex: 'loan_interest_rate',
    render: (loan_interest_rate, record) => (
      <span>
        ₦{calculateRateInPercent(loan_interest_rate, record?.loan_principal_amount_fixed)}
      </span>
    )
  },
  {
    title: <span className='font-bold'>Loan Duration</span>,
    key: 'loan_duration',
    dataIndex: 'loan_duration',
    render: loan_duration => <span>{loan_duration} Months</span>
  },
  {
    title: <span className='font-bold'>Application Date</span>,
    key: 'time_submitted',
    dataIndex: 'time_submitted',
    render: time_submitted => <span>{time_submitted?.split(' ')[0]}</span>
  },
  {
    title: <span className='font-bold'>Capital repayment date</span>,
    key: 'capital_repayment_date',
    dataIndex: 'capital_repayment_date'
  },
  {
    title: <span className='font-bold'>Next Interest Date</span>,
    key: 'next_interest_date',
    dataIndex: 'next_interest_date',
    render: next_interest_date => <DueSoon nextDate={next_interest_date} numOfDays={7} />
  },
  {
    title: <span className='font-bold'>Loan status</span>,
    key: 'loan_app_status',
    dataIndex: 'loan_app_status'
  },
  {
    title: <span className='font-bold capitalize'>ACTIONS</span>,
    key: 'action',
    render: records => {
      const isNotDisbursed = records?.loan_app_status !== 'Disbursed'
      return (
        <button
          disabled={isNotDisbursed}
          onClick={() => setModalVisibleWithRecord({ records, visible: true })}
          className={`${
            isNotDisbursed
              ? 'hover:bg-gray-400  pointer-events-none text-gray-500'
              : 'hover:bg-primary text-primary'
          } px-6 py-2 border hover:text-white rounded-lg  text-sm`}
        >
          View Transactions
        </button>
      )
    }
  }
]

const DATA = [
  {
    loan_app_id: 'Qw32',
    loan_principal_amount_fixed: '1,000,000',
    principal_amount_paid: '1,000,000',
    interest_amount: '300,000',
    start_date: '2014-12-24 23:12:00'
  }
]

const userColumn = (showBankStatement, searchObject) => [
  {
    title: 'Business Name',
    dataIndex: 'business_name',
    key: 'business_name',
    defaultSortOrder: 'ascend',
    width: '10%',
    ...getColumnSearchProps({
      dataIndex: 'business_name',
      ...searchObject,
      title: 'Business Name'
    }),
    sorter: (a, b) => {
      const nameA = a.business_name.toUpperCase()
      const nameB = b.business_name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }
  },
  {
    title: 'Admin Name',
    dataIndex: 'name',
    key: 'name',
    ...getColumnSearchProps({
      dataIndex: 'name',
      ...searchObject,
      title: 'Admin Name'
    })
  },
  { title: 'Updated At', dataIndex: 'updated_at', key: 'updated_at' },
  {
    title: 'Date created',
    dataIndex: 'created_at',
    key: 'created_at',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at)
  },
  { title: 'Phone Number', dataIndex: 'phone_number', key: 'phone_number' },
  {
    title: 'Is account confirmed',
    key: 'is_account_confirmed',
    dataIndex: 'is_account_confirmed',
    filters: [
      {
        text: 'Yes',
        value: 'yes'
      },
      {
        text: 'No',
        value: 'No'
      }
    ],
    onFilter: (value, record) => record.is_account_confirmed.indexOf(value) === 0
  },
  { title: 'Email', dataIndex: 'email', key: 'email' },
  {
    title: 'Bank Statement',
    dataIndex: 'bank_statement',
    key: 'bank_statement',
    render: bank_statement => {
      return bank_statement ? (
        <button
          onClick={() => showBankStatement(bank_statement)}
          className='bg-green-400 text-base hover:bg-green-600 hover:text-white px-5 hover:no-underline rounded-md py-1'
        >
          {' '}
          open{' '}
        </button>
      ) : (
        'Unavailable'
      )
    }
  },
  {
    title: 'Customer ID',
    key: 'id',
    dataIndex: 'id',
    render: id => <IdsComponent id={id} />
  },
  {
    title: 'Image',
    key: 'image',
    render: record => (
      <img
        src={record.photo}
        width={100}
        height={100}
        className='transform duration-700 hover:scale-150'
      />
    )
  },
  {
    title: 'Action',
    key: 'action',
    render: record => {
      return (
        <DropDown>
          <AdminResetPwdForCustomer userRecords={record} />
        </DropDown>
      )
    }
  }
]
const bankStatementColumn = [
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    sorter: (a, b) => a.amount - b.amount,
    defaultSortOrder: 'descend'
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    sorter: (a, b) => a.balance - b.balance,
    defaultSortOrder: 'descend'
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    defaultSortOrder: 'descend'
  },
  { title: 'Narration', dataIndex: 'narration', key: 'narration' },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    filters: [
      {
        text: 'Debit',
        value: 'debit'
      },
      {
        text: 'Credit',
        value: 'credit'
      }
    ],
    onFilter: (value, record) => record.type.indexOf(value) === 0
  },
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
    render: id => <IdsComponent id={id} />
  }
]

const transactionLoanColumns = [
  {
    title: 'S/N',
    dataIndex: 'serialNumber',
    key: 'serialNumber',
    render: (__, _, index) => <span>{index + 1}</span>
  },
  {
    title: 'Recorded By',
    dataIndex: 'admin_who_recorded_transaction',
    key: 'admin_who_recorded_transaction'
  },
  {
    title: 'Amount paid',
    dataIndex: 'amount_paid',
    key: 'amount_paid'
  },
  {
    title: 'Expected amount',
    dataIndex: 'required_payment',
    key: 'required_payment'
  },
  {
    title: 'Month',
    dataIndex: 'payment_month',
    key: 'payment_month'
  },
  {
    title: 'Date',
    dataIndex: 'payment_date',
    key: 'payment_date'
  },
  {
    title: 'Payment Type',
    dataIndex: 'payment_type',
    key: 'payment_type'
  },
  {
    title: 'Payment Ref',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    render: payId => <IdsComponent id={payId} />
  }
]

export {
  RESET_LABEL_PWD,
  PWD_BTN,
  DATA,
  expandedColumns,
  userColumn,
  bankStatementColumn,
  transactionLoanColumns
}
