/* eslint-disable indent */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { InputField } from '../../sharedComponents/input-field'
import { Divider, Typography, notification } from 'antd'
import { stepFourValidator } from '../../utils/inputValidator'
import PropTypes from 'prop-types'
import { dispatch } from '../../redux/store'
import {
  BACK,
  ERROR_MESSAGE,
  LOAN_AMOUNT,
  LOAN_DURATION,
  LOAN_PURPOSE,
  PHARMACEUTICAL_LICENSE_DATE,
  SUCCESS_MESSAGE,
  SALES_REP,
  DISTRIBUTORS
} from './constant'
import PreviewApplication from '../modal/PreviewApplication'
import { useSelector } from 'react-redux'
import { FormSelect } from '../form-select'
import { AntDesignFormSelect } from '../form-select/FormSelect'

const StepFour = props => {
  const history = useHistory()
  const [error, setError] = useState({})
  const { state } = useLocation()
  const { success, error: formError } = {}

  const [input, setInput] = useState({
    loanAmount: '',
    loanPurpose: '',
    loanDuration: '',
    licenseExp: '',
    sales_rep: ''
  })
  const [selectedDistributors, setSelectedDistributors] = useState([])
  const exitingSalesRep = useSelector(({ salesRep }) => salesRep.exitingSalesRep)

  const adminGetExitingDistributors = useSelector(
    ({ distributors }) => distributors.adminGetDistributors
  )
  const customerGetExitingDistributors = useSelector(
    ({ distributors }) => distributors.customerGetDistributors
  )
  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))

  const amountDiscrepancyError =
    selectedDistributors.length > 1 &&
    +input.loanAmount !== +selectedDistributors.reduce((pre, curr) => pre + +curr?.amount, 0)

  const onChangeText = ({ target }) => {
    setInput({ ...input, [target.name]: target.value })
    setError({ ...error, [target.name]: false })
  }
  const handleSubmit = async () => {
    const validator = stepFourValidator(input)
    if (validator.valid && !amountDiscrepancyError) {
      if (window.localStorage.getItem('apply-for-loan')) {
        const applyForLoan = JSON.parse(window.localStorage.getItem('apply-for-loan'))
        const { ...data } = applyForLoan
        dispatch.loans.applyForLoan({
          data: { distributors: selectedDistributors, customer_id: state?.user?.id, ...data },
          history
        })
      } else {
        props.nextKey(1)
      }
    } else {
      setError({ ...validator.error })
      notification.error({
        message: 'An error occured',
        description:
          validator.error.firstName ||
          validator.error.lastName ||
          validator.error.title ||
          validator.error.email ||
          validator.error.phone ||
          validator.error.birthdate ||
          validator.error.bankVeriNo ||
          validator.error.homeAddress ||
          validator.error.gender ||
          validator.error.yearsOfExperience ||
          validator.error.maritalStatus ||
          validator.error.kids ||
          validator.error.educationLevel ||
          validator.error.passportPics ||
          validator.error.governmentID ||
          (amountDiscrepancyError && 'Check if you filled the distributors amounts correctly')
      })
    }
  }
  const handleBack = () => {
    if (window.localStorage.getItem('apply-for-loan')) {
      props.nextKey(3)
    } else {
      props.nextKey(1)
    }
  }

  const handleDistributorsChange = (idx, event) => {
    const targetData = [...selectedDistributors]
    targetData[idx].amount = event.target.value
    setSelectedDistributors(targetData)
  }

  useEffect(() => {
    if (success) {
      setInput({
        loanAmount: '',
        loanPurpose: '',
        loanDuration: '',
        licenseExp: '',
        sales_rep: '',
        distributorId: ''
      })
      setTimeout(() => history.push('loans'), 6000)
    } else {
      if (window.localStorage.getItem('apply-for-loan')) {
        const loanDetails = JSON.parse(window.localStorage.getItem('apply-for-loan')).loanDetails
          ? JSON.parse(window.localStorage.getItem('apply-for-loan')).loanDetails
          : {}
        Object.keys(loanDetails).length > 0 && setInput(loanDetails)
      } else {
        props.nextKey(1)
      }
    }
  }, [success, history, props])

  useEffect(() => {
    if (isAdmin) {
      dispatch.distributors.adminGetAllDistributors()
    } else {
      dispatch.distributors.customerGetAllDistributors()
    }
    dispatch.salesRep.getAllSales()
  }, [])

  return (
    <div className='w-full mx-auto md:px-4 rounded-xl bg-theme1-400'>
      <div className='flex flex-wrap md:mx-3'>
        <div className='w-full md:w-1/2 mt-8 px-2'>
          <InputField
            onChange={onChangeText}
            value={input.loanAmount}
            type='number'
            name='loanAmount'
            placeholder={LOAN_AMOUNT}
            label={LOAN_AMOUNT + ' *'}
            className='form-control'
          />
          {error.loanAmount ? <small className='text-red-500'>{error.loanAmount}</small> : ''}
        </div>
        <div className='w-full md:w-1/2 mt-8 px-2'>
          <InputField
            onChange={onChangeText}
            value={input.loanPurpose}
            type='text'
            name='loanPurpose'
            placeholder={LOAN_PURPOSE}
            label={LOAN_PURPOSE + ' *'}
            className='form-control'
          />
          {error.loanPurpose ? <small className='text-red-500'>{error.loanPurpose}</small> : ''}
        </div>
        <div className='w-full md:w-1/2 mt-8 px-2'>
          <InputField
            onChange={onChangeText}
            value={input.loanDuration}
            type='number'
            name='loanDuration'
            label={LOAN_DURATION + ' *'}
            placeholder={LOAN_DURATION}
            className='form-control'
          />
          {error.loanDuration ? <small className='text-red-500'>{error.loanDuration}</small> : ''}
        </div>
        <div className='w-full md:w-1/2 mt-8 px-2'>
          <InputField
            onChange={onChangeText}
            value={input.licenseExp}
            type='date'
            name='licenseExp'
            label={PHARMACEUTICAL_LICENSE_DATE + ' *'}
            placeholder='Pharmacist License expiration date'
            className='form-control'
          />
          {error.licenseExp ? <small className='text-red-500'>{error.licenseExp}</small> : ''}
        </div>
        <div className='w-full md:w-1/2 mt-8 px-2'>
          <label className='text-gray-500 leading-8'>{DISTRIBUTORS}</label>
          <AntDesignFormSelect
            options={(() => {
              return isAdmin
                ? adminGetExitingDistributors.map(distributor => ({
                    label: distributor.name,
                    value: distributor.id
                  }))
                : customerGetExitingDistributors.map(distributor => ({
                    label: distributor.name,
                    value: distributor.id
                  }))
            })()}
            value={input.distributorId}
            onChange={(value, options) => {
              const selectedOptions = options.map(item => ({
                distributorId: item.value,
                label: item.label,
                amount:
                  options.length > 1
                    ? selectedDistributors.find(dist => dist.distributorId === item.value)
                        ?.amount || '0'
                    : input.loanAmount
              }))
              setSelectedDistributors(selectedOptions)
            }}
            className='form-control'
            placeholder='Select distributor'
            mode='multiple'
            name='distributorId'
          />
        </div>
        <div className='w-full md:w-1/2 mt-8 px-2'>
          <label className='text-gray-500 leading-8'>{SALES_REP + ' (optional)'}</label>
          <FormSelect
            options={exitingSalesRep.map(user => ({
              name: `${user.first_name + ' ' + user.last_name}`,
              value: user.id
            }))}
            value={input.sales_rep}
            onChange={onChangeText}
            className='form-control '
            label='Select sales Person'
            name='sales_rep'
          />
        </div>
        <div className='w-full mt-8 px-2'>
          {success === 1 ? (
            <p className='text-lg text-green-500 text-center my-3'>
              {SUCCESS_MESSAGE} <i className='fas fa-check-circle' />
            </p>
          ) : formError === 1 ? (
            <p className='text-lg text-red-500'>
              <i className='fas fa-ban' /> {ERROR_MESSAGE}
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
      {selectedDistributors.length > 1 && (
        <>
          <Typography>
            Please input the amounts you want for each of the selected distributors
          </Typography>
          {amountDiscrepancyError && (
            <p className='text-red-600'>Total Amount must equal loan Amount when summed</p>
          )}
          <Divider orientation='center' type='horizontal' />
          <div className='grid gap-5 grid-cols-2'>
            {selectedDistributors.map((item, idx) => {
              return (
                <div key={idx}>
                  <InputField
                    key={idx}
                    onChange={event => handleDistributorsChange(idx, event)}
                    value={item.amount}
                    type='number'
                    placeholder={LOAN_AMOUNT}
                    label={`${idx + 1}. ${item.label}`}
                    className='form-control'
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
      <div className='w-full flex mt-4 mb-8 md:ml-6 justify-start'>
        <button className='btn-primary mr-5' onClick={handleBack}>
          <i className='fas fa-arrow-alt-circle-left' /> {BACK}
        </button>

        <PreviewApplication
          headerTitle='Preview Application'
          input={input}
          distributors={selectedDistributors}
          amountDiscrepancyError={amountDiscrepancyError}
          nextKey={props.nextKey}
          handleSubmit={handleSubmit}
          setError={setError}
        />
      </div>
    </div>
  )
}

StepFour.propTypes = {
  nextKey: PropTypes.func.isRequired
}
export default StepFour
