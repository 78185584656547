import React, { useState } from 'react'
import PropTypes from 'prop-types'

const RiskManagementTabComponent = ({ tabs, contents }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div>
      <div className='flex mb-4'>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 py-2 font-medium border transition-colors duration-200
              ${index === 0 ? 'rounded-l-md' : ''}
              ${index === tabs.length - 1 ? 'rounded-r-md' : ''}
              ${
                activeTab === index
                  ? 'bg-vittasBlue text-white'
                  : 'bg-white text-newGray hover:bg-gray-100'
              }`}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className='p-4 bg-white rounded-md shadow'>{contents[activeTab]}</div>
    </div>
  )
}

RiskManagementTabComponent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  contents: PropTypes.arrayOf(PropTypes.node).isRequired
}

export default RiskManagementTabComponent
