import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { DashboardLayout } from '../../../../sharedComponents/dashboardLayout'
import VfdAccount from './vfd/VfdAccount'
import { adminServices } from '../../../../services'
import { notification } from 'antd'

export default function CreateAccountNav() {
  const [customeBizInfo, setCustomeBizInfo] = useState({})
  const history = useHistory()
  const userIdFromParams = useParams().id

  const [activeBar, setActiveBar] = useState('VFD Bank')

  const handleTabClick = tab => {
    setActiveBar(tab)
  }

  const getCustomerDBizInfo = async () => {
    try {
      const { data } = await adminServices.getCustomerBizInfo(userIdFromParams)
      if (data.success) {
        setCustomeBizInfo(data.data.business_information)
      } else {
        notification.error({
          message: 'Business Information not Found',
          description: 'Fill the missing business information for the customer'
        })
      }
    } catch (error) {
      notification.error({
        message: 'Business Information not Found',
        description: 'Fill the missing business information for the customer'
      })
    }
  }

  useEffect(() => {
    async function fetch() {
      await getCustomerDBizInfo()
    }

    fetch()
  }, [])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-transparent'>
        <div className='font-bold text-2xl py-6'>{customeBizInfo?.buz_name}</div>
        <div className='ml-1 mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </h3>
        </div>
        <div className='mt-5 flex justify-start space-x-24 ml-8 w-full gap-5'>
          <button
            onClick={() => handleTabClick('VFD Bank')}
            className={`cursor-pointer ${
              activeBar === 'VFD Bank'
                ? 'border-b-4 text-lg border-blue-700 text-gray-500'
                : ' text-gray-500 px-3 text-lg py-2'
            }`}
          >
            VFD Bank
          </button>
          {/* NOTE: we only support VFD for now <button
            onClick={() => handleTabClick('Payhippo')}
            className={`cursor-pointer ${
              activeBar === 'Payhippo'
                ? 'border-b-4 text-lg border-blue-700  text-gray-500'
                : ' text-gray-500 px-3 text-lg py-2'
            }`}
          >
            Payhippo
          </button>
          <button
            onClick={() => handleTabClick('Kuda MFB')}
            className={`cursor-pointer ${
              activeBar === 'Kuda MFB'
                ? 'border-b-4 border-blue-700 text-lg  text-gray-500'
                : ' text-gray-500 px-3 text-lg py-2'
            }`}
          >
            Kuda MFB
          </button>
          <button
            onClick={() => handleTabClick('Opay')}
            className={`cursor-pointer ${
              activeBar === 'Opay'
                ? 'border-b-4 border-blue-700 text-lg  text-gray-500'
                : ' text-gray-500 px-3 py-2 text-lg'
            }`}
          >
            Opay
          </button> */}
        </div>
        <div className=' border-b-2 border-gray-100 mt-5' />
        <div className='w-full overflow-hidden'>
          {activeBar === 'VFD Bank' && <VfdAccount />}
          {/*  NOTE: we only support VFD for now {activeBar === 'Payhippo' && 'Payhippo'}
          {activeBar === 'Kuda MFB' && 'Kuda MFB'}
          {activeBar === 'Opay' && 'Opay'} */}
        </div>
      </main>
    </DashboardLayout>
  )
}
