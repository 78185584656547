import React, { useEffect, useState } from 'react'
import CameraIcon from '../../../images/camera.svg'
import DefaultImage from '../../../images/human_avatar.webp'
import { handleUploadile } from '../../../utils/helpers'

import PropTypes from 'prop-types'
import { Loader } from '../../../sharedComponents'
import { notification } from 'antd'
import { UPLOAD_FILE_TYPES } from '../../../utils/constants'

const ProfilePicture = ({ setProfileUrl, photo }) => {
  const [imageDetails, setImageDetails] = useState({
    fileUrl: photo || DefaultImage,
    uploading: false
  })

  const handleImageUpload = async e => {
    const file = e.target.files[0]
    setProfileUrl('')
    setImageDetails({ fileUrl: '', uploading: true })
    try {
      const fileUrl = await handleUploadile(file, UPLOAD_FILE_TYPES.IMAGES)

      setImageDetails({ fileUrl, uploading: false })

      setProfileUrl(fileUrl)
    } catch (error) {
      notification.error({
        message: 'Error Uploading Image',
        description: error.message
      })
      setImageDetails({ fileUrl: photo, uploading: false })
    }
  }

  useEffect(() => {
    const handleBeforeUnload = e => {
      e.preventDefault()
      e.returnValue = '' // This is for older browsers
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <div className='relative w-32 h-32 bg-white rounded-full'>
      {imageDetails.uploading ? (
        <Loader color='blue' height={100} width={100} />
      ) : (
        <img
          src={imageDetails.fileUrl}
          alt='Profile'
          className='rounded-full w-full h-full object-cover'
        />
      )}
      <label
        htmlFor='imageUpload'
        className='absolute right-0 bottom-0 w-12 h-12 bg-white rounded-full cursor-pointer flex items-center justify-center'
      >
        <input
          type='file'
          id='imageUpload'
          accept='image/*'
          className='hidden'
          onChange={handleImageUpload}
        />
        <img src={CameraIcon} alt='Upload Image' className='w-full text-white' />
      </label>
    </div>
  )
}

export default ProfilePicture

ProfilePicture.propTypes = {
  setProfileUrl: PropTypes.func,
  photo: PropTypes.string
}
