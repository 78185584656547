/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Icon } from '@iconify/react'
import LockIcon from '../../../../../images/Lock.svg'
import { dispatch } from '../../../../../redux/store'
import { useSelector } from 'react-redux'
import OtpInput from 'react-otp-input'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}
const NewPin = ({ visible, onCancel, title, description, onContinue }) => {
  const [otp, setOtp] = useState('')
  const [success, setSuccess] = useState(false)

  const loading = useSelector(({ loading }) => loading.effects.users.verifyUserTransactionPin)

  const verifyUserTransactionPin = async () => {
    const isSuccessResponse = await dispatch.users.verifyUserTransactionPin({
      pin: otp
    })

    if (isSuccessResponse) {
      setSuccess(true)
      onContinue()
      setSuccess(false)
    }
  }
  const handleContinue = async () => {
    try {
      setSuccess(false)
      await verifyUserTransactionPin()
    } catch (err) {
      setSuccess(false)
    }
  }

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      centered
      className='info-modal'
      closeIcon={<Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />}
      bodyStyle={bodyStyle}
      width={500}
    >
      <div className='modal-header bg-primary px-6 py-2 flex justify-between'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>{title}</h2>
      </div>
      <div className='py-8'>
        <p className='text-black text-base text-center font-bold'>{description}</p>
        <div className='w-12 self-center justify-center flex mx-auto'>
          <img src={LockIcon} alt='icon' className='' />
        </div>
        <div className='Pin my-8'>
          <OtpInput
            value={otp}
            inputStyle='otpInputStyle'
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span className='' />}
            renderInput={props => <input {...props} type='password' />}
          />
        </div>
        <div>
          {success ? (
            <p className='text-green-700 text-sm font-normal text-center leading-normal'>
              Successful
            </p>
          ) : (
            <p className='text-red-600 text-sm font-normal text-center leading-normal'>
              Your account will be locked after unsuccessful 3 trial
            </p>
          )}
        </div>

        <button
          className='bg-primary flex mx-auto text-base  mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
          onClick={handleContinue}
        >
          {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''} Continue
        </button>
      </div>
    </Modal>
  )
}

NewPin.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired
}

export default NewPin
