import loadingPlugin from '@rematch/loading'
import selectPlugin from '@rematch/select'
import { init } from '@rematch/core'

import * as models from './models'
import { persistStorage } from './persist'

const store = init({
  models,
  plugins: [loadingPlugin(), selectPlugin(), persistStorage]
})

export const { dispatch, select } = store

export default store
