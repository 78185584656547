// FAQ.js
import React, { useState, useRef } from 'react'
import exclamationIcon from '../../../images/icons/exclamation.svg'

import { FAQ_SEARCH_BUTTON, FAQ_SEARCH_PLACEHOLDER, faqData } from '../constants'
import FaqSection from './FaqSection'

const FaqComponent = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [currentFaq, setCurrentFaq] = useState(1)
  const [filteredFaqs, setFilteredFaqs] = useState(faqData)
  const faqRef = useRef()

  const handleSearch = query => {
    const searchTerm = query.toLowerCase()

    if (!searchTerm) {
      setFilteredFaqs(faqData)
      return
    }

    const filtered = faqData.filter(category => {
      // Search in category title and description
      if (
        category.title.toLowerCase().includes(searchTerm) ||
        category.description.toLowerCase().includes(searchTerm)
      ) {
        return true
      }

      // Search in items
      const hasMatchingItems = category.items.some(
        item =>
          item.question.toLowerCase().includes(searchTerm) ||
          item.answer.toLowerCase().includes(searchTerm)
      )

      return hasMatchingItems
    })

    setFilteredFaqs(filtered)
  }

  const handleInputChange = e => {
    const value = e.target.value
    setSearchQuery(value)
    handleSearch(value)
  }

  const changeCurrentFaq = id => {
    if (faqRef.current) {
      faqRef.current.style.opacity = 0
      setTimeout(() => {
        setCurrentFaq(id)
        faqRef.current.style.opacity = 1
      }, 300)
    }
  }

  return (
    <section className='bg-white'>
      {/* Search Section */}
      <div className='pt-8 pb-8 mx-1'>
        <div className='flex flex-wrap'>
          <div className='w-full text-center text-black mx-auto'>
            <h1 className='font-bold text-xl md:text-4xl pt-6 pb-0 px-6'>
              Frequently Asked Questions
            </h1>
            <form className='w-full my-16' onSubmit={e => e.preventDefault()}>
              <div className='w-11/12 md:w-4/5 lg:w-1/2 mx-auto'>
                <div className='relative'>
                  <input
                    className='appearance-none text-base block w-full bg-gray-100 border border-gray-200 rounded py-3 px-4 focus:outline-none focus:bg-white focus:border-gray-900'
                    type='search'
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder={FAQ_SEARCH_PLACEHOLDER}
                  />
                  <button
                    type='submit'
                    className='bg-gray-900 hover:bg-black text-white px-3 sm:px-5 md:px-10 lg:px-20 py-2 text-sm font-bold rounded absolute top-0 right-0 h-full'
                  >
                    <i className='fa fa-search mr-2' />
                    {FAQ_SEARCH_BUTTON}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* FAQ Content */}
      <div className='container mx-auto md:px-16 px-3'>
        <div className='grid grid-cols-1 md:grid-cols-12 gap-10 mt-10'>
          {/* Category Selection */}
          <div className='md:col-span-5'>
            {filteredFaqs.map(category => (
              <div
                key={category.id}
                className={`${
                  currentFaq === category.id ? 'bg-primary' : 'bg-info'
                } mb-5 p-5 rounded-lg cursor-pointer duration-150`}
                onClick={() => changeCurrentFaq(category.id)}
              >
                <div className='flex'>
                  <div className='tab-items-lg'>
                    <figure className='tab-items'>
                      <img src={exclamationIcon} alt='exclamation' />
                    </figure>
                  </div>
                  <div>
                    <h4
                      className={`${
                        currentFaq === category.id ? 'text-white' : 'text-black'
                      } text-xl font-semibold`}
                    >
                      {category.title}
                    </h4>
                    <p className={`${currentFaq === category.id ? 'text-white' : 'text-gray-900'}`}>
                      {category.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* FAQ Content */}
          <div className='md:col-span-7 duration-300' ref={faqRef}>
            {filteredFaqs.map(category => (
              <FaqSection
                key={category.id}
                category={category}
                currentFaq={currentFaq}
                searchQuery={searchQuery}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqComponent
