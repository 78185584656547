import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import totalImg from '../../images/icons/total.png'
import approvedImg from '../../images/icons/approved.png'
import pendingImg from '../../images/icons/pending.png'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'

const Statistics = () => {
  const { applicationCount: info, isServerError } = useSelector(({ applications }) => applications)

  const [count, setCount] = useState({
    allApplications: -1,
    approvedApplications: -1,
    pendingApplications: -1,
    disbursedApplications: -1
  })

  useEffect(() => {
    if (!info?.allApplications?.length || isServerError) {
      dispatch.applications.applicationCount()
    }
  }, [])

  useEffect(() => {
    setCount({
      allApplications: info?.allApplications?.length >= 0 ? info?.allApplications?.length : -1,
      approvedApplications:
        info?.approvedApplications?.length >= 0 ? info?.approvedApplications?.length : -1,
      pendingApplications:
        info?.pendingApplications?.length >= 0 ? info?.pendingApplications?.length : -1,
      disbursedApplications:
        info?.disbursedApplications?.length >= 0 ? info?.disbursedApplications?.length : -1
    })
  }, [info])

  const data = [
    {
      id: 1,
      title: 'Total Applications',
      image: totalImg,
      link: '/applications/all-application',
      applications:
        count.allApplications !== -1 ? (
          count.allApplications
        ) : (
          <i className='fa fa-spin fa-spinner text-gray-500' />
        )
    },
    {
      id: 2,
      title: 'Approved Applications',
      image: approvedImg,
      link: '/applications/approved-application',
      applications:
        count.approvedApplications !== -1 ? (
          count.approvedApplications
        ) : (
          <i className='fa fa-spin fa-spinner text-gray-500' />
        )
    },
    {
      id: 3,
      title: 'Pending Applications',
      image: pendingImg,
      link: '/applications/application-in-review',
      applications:
        count.pendingApplications !== -1 ? (
          count.pendingApplications
        ) : (
          <i className='fa fa-spin fa-spinner text-gray-500' />
        )
    },
    {
      id: 4,
      title: 'Active Loans',
      image: pendingImg,
      link: '/transactions',
      applications:
        count.disbursedApplications !== -1 ? (
          count.disbursedApplications
        ) : (
          <i className='fa fa-spin fa-spinner text-gray-500' />
        )
    }
  ]

  return (
    <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mx-2 mt-4'>
      {data.map(d => (
        <Link key={d.id} to={{ pathname: d.link, state: { type: d.type } }}>
          <div className='card-box hover:scale-101'>
            <div>
              <div className='text-3xl text-black font-bold'>{d.applications}</div>
              <div className='text-lg md:text-xl text-gray-600'>{d.title}</div>
            </div>
            <div className='pt-4'>
              <img src={d.image} alt='total' width='50' height='50' className='inline-block mr-2' />
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default Statistics
