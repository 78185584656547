import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
// import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { NEW_APP } from './constants'
import Collapse from 'antd/lib/collapse'
import StepOne from '../../sharedComponents/entries/StepOne'
import StepTwo from '../../sharedComponents/entries/StepTwo'
import StepThree from '../../sharedComponents/entries/StepThree'
import StepFour from '../../sharedComponents/entries/StepFour'
import PropTypes from 'prop-types'

const { Panel } = Collapse

const NewApplications = ({ shouldShowBackButton }) => {
  const history = useHistory()
  const [activeKey, setActiveKey] = useState('1')
  const { state } = useLocation()

  // TODO: fix mono statment being asked when its available
  // TODO: Fix search to throttle and stop making request on each key stroke
  // TODO: Fix mono on prod

  useEffect(() => {
    window.localStorage.removeItem('apply-for-loan')
  }, [])
  return (
    <main className='w-full flex-grow p-4 bg-white'>
      {shouldShowBackButton && (
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {NEW_APP}
          </h3>
        </div>
      )}
      <p className='ml-8 font-bold mt-4'>Kindly provide the following documents:</p>
      <Link to='form-preview' className='ml-8 underline mt-4 text-md'>
        Preview Required Documents?
      </Link>
      <div className='mt-4 mb-16 px-2 overflow-x-auto'>
        <div className='grid grid-cols-1 gap-2'>
          <div className='mt-4'>
            <Collapse
              defaultActiveKey='1'
              activeKey={activeKey}
              expandIconPosition='right'
              collapsible
              onChange={v => {
                if (v !== undefined && activeKey > v) {
                  setActiveKey(v)
                }
              }}
              accordion
            >
              <Panel className='collapse-panel' header='Business Information' key='1'>
                <StepOne user={state?.user} nextKey={k => setActiveKey(k)} />
              </Panel>
              <Panel className='collapse-panel' header='Business Document' key='2'>
                <StepTwo nextKey={k => setActiveKey(k)} />
              </Panel>
              <Panel className='collapse-panel' header='Directors' key='3'>
                <StepThree user={state?.user} nextKey={k => setActiveKey(k)} />
              </Panel>
              <Panel className='collapse-panel' header='Loan Details' key='999'>
                <StepFour nextKey={k => setActiveKey(k)} />
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </main>
  )
}

NewApplications.propTypes = {
  shouldShowBackButton: PropTypes.bool,
  user: PropTypes.shape({
    phone_number: PropTypes.string
  })
}

export default NewApplications
