import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import PropTypes from 'prop-types'
import AddDirectorForm from './AddDirectorForm'
import ListDirectors from './ListDirectors'

const Directors = () => {
  const { state } = useLocation()
  const history = useHistory()
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </h3>
        </div>
        <div className='mt-8 mb-16 overflow-x-auto'>
          <ListDirectors user={state?.user} />
          <AddDirectorForm user={state?.user} />
        </div>
      </main>
    </DashboardLayout>
  )
}

Directors.propTypes = {
  user: PropTypes.shape({
    phone_number: PropTypes.string,
    email: PropTypes.string
  })
}

export default Directors
