import { notification } from 'antd'
import React, { useState } from 'react'
import { adminServices } from '../../services'
import BvnCheckModal from '../../pages/admin-pages/applications/modal/BvnCheckModal'
import BvnCheckSuccessModal from '../../pages/admin-pages/applications/modal/BvnCheckSuccessModal'
import BvnCheckStatusModal from '../../pages/admin-pages/applications/modal/BvnCheckStatusModal'

function CheckBvn() {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalSuccessVisible, setModalSuccessVisible] = useState(false)
  const [selectedUsername, setSelectedUsername] = useState(null)
  const [userId, setUserId] = useState(null)
  const [bvn, setBvn] = useState(null)
  const [bankData, setBankData] = useState([])
  const [modalStatusVisible, setModalStatusVisible] = useState(false)
  const [link, setLink] = useState(null)

  const closeAllModals = () => {
    setModalVisible(false)
    setModalSuccessVisible(false)
    setModalStatusVisible(false)
  }

  const handleCheckBvn = async () => {
    await adminServices
      .bvnAccountLookup({
        user_id: userId,
        bvn
      })
      .then(res => {
        if (res?.data?.status === 'waiting customer consent') {
          notification.info({
            message: 'Info',
            description: res?.data?.status
          })
          return
        }

        notification.success({
          message: 'Success',
          description: 'Account lookup successfully'
        })

        closeAllModals()
        setBankData(res?.data)
        setModalSuccessVisible(true)
      })
      .catch(error => {
        notification.error({
          message: 'Something went wrong',
          description: error?.response?.data?.message || error?.message
        })
      })
  }

  return (
    <div>
      <button
        className='bg-primary px-8 py-2 text-white rounded-md'
        onClick={() => setModalVisible(true)}
      >
        Check BVN
      </button>
      <BvnCheckModal
        visible={modalVisible}
        onCancel={closeAllModals}
        action={(username, userId, bvn, consentLink) => {
          closeAllModals()
          setModalStatusVisible(true)
          setSelectedUsername(username)
          setLink(consentLink)
          setUserId(userId)
          setBvn(bvn)
        }}
      />

      <BvnCheckStatusModal
        visible={modalStatusVisible}
        onCancel={closeAllModals}
        handleCheckBvn={handleCheckBvn}
        propData={{
          userId,
          link,
          username: selectedUsername
        }}
      />

      <BvnCheckSuccessModal
        visible={modalSuccessVisible}
        onCancel={closeAllModals}
        selectedUsername={selectedUsername}
        bankData={bankData}
      />
    </div>
  )
}

export default CheckBvn
