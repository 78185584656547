import ArrowUp from '../../../images/arrow-up.svg'
import Failed from '../../../images/close-circle.svg'

export const docsFieldtoName = {
  aoa: 'Article of Association',
  pharmacist_license: 'Pharmaceutical license',
  business_licenses: 'Business License',
  bank_statement: '6 Month Bank Statement',
  moa: ' Memorandum of Association (MOA)',
  cac_7: ' CAC Form 7 (Appointment of Directors)',
  cac_3: ' CAC Form 3 (change of registered address)',
  cac_2: 'CAC Form 2 (Statement of share capital)',
  optional: ' Other Document(Optional)'
}

export const docsFieldToDocumentType = {
  aoa: 'aoa',
  pharmacist_license: 'pharmacist_license',
  business_licenses: 'business_licenses',
  bank_statement: 'bank_statement',
  moa: 'moa',
  cac_7: 'cac_7',
  cac_3: 'cac_3',
  cac_2: 'cac_2',
  optional: 'optional'
}
export const DOC_TYPE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  UPLOADED: 'UPLOADED',
  UPLOADING: 'UPLOADING',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  ERROR: 'ERROR'
}
export const historyData = {
  icon: ArrowUp,
  title: 'Transfer to VITTAS VFD',
  timestamp: 'Dec 12, 2023 12:51:12 AM',
  amount: '12,000,000.00',
  status: 'Successful'
}

export const historyData2 = {
  icon: Failed,
  title: 'Transfer to VITTAS VFD',
  timestamp: 'Dec 12, 2023 12:51:12 AM',
  amount: '12,000,000.00',
  status2: 'failed'
}
