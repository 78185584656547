import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'
import 'antd/dist/antd.css'
import { Spin, Table } from 'antd'
import { distributorsColumn } from './constants'
import UpdateDistributors from './UpdateDistributors'

const ListOfDistributors = () => {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [selectedData, setCurrentDataSelected] = useState({})
  const [modalVisibility, setModalVisible] = useState(false)
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })
  const searchInput = useRef(null)

  const exitingDistributors = useSelector(({ distributors }) => distributors.adminGetDistributors)
  const isLoading = useSelector(({ loading }) => loading.effects.distributors.getAllDistributors)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }
  const setDetails = record => {
    setCurrentDataSelected({ data: record })
    setModalVisible(true)
  }

  useEffect(() => {
    if (exitingDistributors.length <= 0) {
      dispatch.distributors.adminGetAllDistributors()
    }
  }, [])

  const data = exitingDistributors.map(({ id, ...more }) => ({
    key: id,
    ...more
  }))

  return isLoading ? (
    <Spin size='large' />
  ) : (
    <>
      <UpdateDistributors
        visible={modalVisibility}
        data={selectedData}
        handleClose={() => setModalVisible(false)}
      />
      <Table
        rowKey={record => record.key}
        className='components-table'
        onChange={value => setPagination(value)}
        pagination={pagination}
        columns={distributorsColumn(setDetails, {
          searchInput,
          handleSearch,
          handleReset,
          setSearchText,
          searchedColumn,
          searchText,
          setSearchedColumn
        })}
        dataSource={data}
      />
    </>
  )
}

export default ListOfDistributors
