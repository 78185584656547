import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { START_APPLICATION } from './constants'
import { useSelector } from 'react-redux'
import { select } from '../../redux/store'

const FormPreview = () => {
  const history = useHistory()
  const currentUser = useSelector(select.auth.getCurrentUserInfo)
  const loanAppRoute = currentUser.data.has_disbursed_loan
    ? `/apply-express-loan/${currentUser.data.id}`
    : `/apply-for-loan/${currentUser.data.id}`

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-6'>
          <i
            onClick={() => history.goBack()}
            className='fas fa-arrow-left mr-3 block text-lg cursor-pointer'
          />
          <h3 className='font-bold text-lg'>{START_APPLICATION}</h3>
        </div>
        <p className='ml-8 text-primary-200 font-bold mt-4 inline-block text-md'>
          Here are the documents you will need to apply for a loan; note that the items in asterisk
          are compulsory.
        </p>
        <Link to={loanAppRoute} className='ml-2 mt-4 text-lg italic underline'>
          Apply now{' '}
        </Link>
        <div className='mt-4 mb-16 px-4 overflow-x-auto'>
          <div className='grid grid-cols-1 mx-2'>
            <div className='mb-6'>
              <h3 className='bg-primary-200 text-white font-bold p-2'>
                Step One (Business Information)
              </h3>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-2 mx-3 text-base'>
            <div className='mt-6'>
              Business Name <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Business Email <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Business Phone No <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Number of Employees <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Incorporation Date <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Date when the business was founded <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Business Description <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Business Industry <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Business RC number <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Location of the business (if more than one location, please specify the location in
              CAC, followed by all other locations) <sup className='text-red-700'>*</sup>
            </div>
          </div>

          <div className='grid grid-cols-1 mx-2 mt-10'>
            <div className='mb-6'>
              <h3 className='bg-primary-200 text-white font-bold p-2'>
                Step Two (Business Document)
              </h3>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-2 mx-3 text-base'>
            <div className='mt-6'>Memorandum of Association</div>
            <div className='mt-6'>Articles of Association</div>
            <div className='mt-6'>
              Pharmaceutical License <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Business License <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              6 Month Bank Statement (PDF or Excel) <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              CAC form 2 (Statement of share capital) <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>CAC form 3 (change of registered address)</div>
            <div className='mt-6'>
              CAC form 7(Appointment of directors) <sup className='text-red-700'>*</sup>
            </div>
          </div>

          <div className='grid grid-cols-1 mx-2 mt-10'>
            <div className='mb-6'>
              <h3 className='bg-primary-200 text-white font-bold p-2'>
                Step Three (Business Signatories)
              </h3>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-2 mx-3 text-base'>
            <div className='mt-6'>
              First name<sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Last name<sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Email <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Phone number<sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Birthdate <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Bank verification number<sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Years of Experience<sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Gender <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Marital Status <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Number of Kids <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Highest educational degree earned <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Home Address <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Passport Picture (File size: 30mb max.) <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Government ID (File size: 30mb max.) <sup className='text-red-700'>*</sup>
            </div>
          </div>

          <div className='grid grid-cols-1 mx-2 mt-10'>
            <div className='mb-6'>
              <h3 className='bg-primary-200 text-white font-bold p-2'>Step Four (Loan Details)</h3>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-2 mx-3 text-base'>
            <div className='mt-6'>
              Loan amount <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Loan Purpose <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Loan Duration (# months) <sup className='text-red-700'>*</sup>
            </div>
            <div className='mt-6'>
              Pharmaceutical License expiration date <sup className='text-red-700'>*</sup>
            </div>
          </div>

          <div className='grid grid-cols-1 mx-2 mt-8'>
            <div className='d-flex'>
              <button
                onClick={() => history.push('new-application')}
                className='border-primary-200 mr-3 mt-6 text-base w-40 py-3 border rounded-lg bg-primary-200 hover:bg-primary text-white'
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default FormPreview
