/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Modal, Table, notification } from 'antd'
import PropTypes from 'prop-types'
import { Loans } from '../../../services'
import { transactionLoanColumns } from './constants'

const LoanTable = ({ modalVisibilityAndCurrLoanRecord, setModalVisibleWithRecord }) => {
  const [data, setData] = useState([])

  const viewTransaction = async () => {
    try {
      const {
        data: { data }
      } = await Loans.getLoanPaymentHistory(
        modalVisibilityAndCurrLoanRecord.records?.approved_loan_id
      )
      setData(data)
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: error?.message || 'Cannot fetch payment history'
      })
    }
  }
  useEffect(() => {
    if (modalVisibilityAndCurrLoanRecord.records?.loan_id) {
      viewTransaction()
    }
  }, [modalVisibilityAndCurrLoanRecord.records])

  return (
    <>
      <Modal
        width='70%'
        onCancel={() => setModalVisibleWithRecord({ visible: false, records: [] })}
        open={modalVisibilityAndCurrLoanRecord.visible}
        footer={false}
      >
        <Table
          scroll={{ x: '1000' }}
          columns={transactionLoanColumns}
          pagination={false}
          dataSource={data || []}
        />
      </Modal>
    </>
  )
}

LoanTable.propTypes = {
  modalVisibilityAndCurrLoanRecord: PropTypes.object.isRequired,
  setModalVisibleWithRecord: PropTypes.func
}

export default LoanTable
