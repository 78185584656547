import React from 'react'
import PropTypes from 'prop-types'

const HistoryCard = ({ icon, title, timestamp, amount, status, status2 }) => {
  return (
    <main>
      <div className='history-card py-4 px-3 my-2 flex gap-3 bg-white shadow-lg '>
        <div>
          <img src={icon} alt='icon ' />
        </div>
        <div className='flex justify-between w-full'>
          <div>
            <div className='text-black text-sm font-medium '>{title}</div>
            <div className='text-stone-500 text-xs font-light tracking-wide'>{timestamp}</div>
          </div>
          <div>
            <div className='text-center'>
              <span className='text-black text-sm font-semibold '>- </span>
              <span className='text-black text-sm font-bold '>₦</span>
              <span className='text-black text-sm font-semibold '>{amount}</span>
            </div>
            <div className='text-green-700 flex justify-end text-sm font-light '>{status}</div>
            <div className='text-red-500 flex justify-end text-sm font-light '>{status2}</div>
          </div>
        </div>
      </div>
    </main>
  )
}
HistoryCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  status2: PropTypes.string.isRequired
}
export default HistoryCard
