import React from 'react'
import headerBackground from '../../images/background.png'
import PropTypes from 'prop-types'

const HeaderBackground = ({ children }) => {
  return (
    <div
      className='flex w-screen py-20 px-5 sm:px-10 md:px-24 sm:bg-white'
      style={{
        backgroundImage: `url(${headerBackground})`,
        backgroundSize: 'cover'
      }}
    >
      {children}
    </div>
  )
}

HeaderBackground.propTypes = {
  children: PropTypes.any
}

export default HeaderBackground
