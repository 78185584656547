import React from 'react'
import PropTypes from 'prop-types'
import { RightOutlined } from '@ant-design/icons'
const BankStatementCard = ({ image, title, description, annualReturn }) => {
  return (
    <div className='bg-white shadow-md rounded-lg p-4 flex flex-col mb-4'>
      <img src={image} alt='Bank Icon' className='mr-4 w-12 h-12' />
      <div className='flex-grow mt-4'>
        <h2 className='font-semibold text-xl text-newBlack'>{title}</h2>
        <div className='flex justify-between mt-2'>
          <p className='text-xs text-newGray2 '>{description}</p>
          <span className='text-blue-500'>
            <RightOutlined className='text-newOrange' />
          </span>
        </div>
      </div>

      {/* Divider Line */}
      <div className='border-t border-gray-300 mt-10 mb-4 w-full' />

      {/* Annual Returns */}
      <div className='flex justify-between w-full'>
        <span className='font-medium text-xs text-newGray2'>Annual Returns</span>
        <span className='font-semibold text-base text-newGreen'>{annualReturn}</span>
      </div>
    </div>
  )
}
BankStatementCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  annualReturn: PropTypes.string.isRequired
}

export default BankStatementCard
