import React, { useEffect } from 'react'
import { Select } from 'antd'
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import { InputField } from '../../../../../sharedComponents/input-field'
import { DISTRIBUTORS, DISTRIBUTOR_AMOUNT } from '../../../../../sharedComponents/entries/constant'
import { inputFieldformatCurrency } from '../../../../../utils/helpers'
import { dispatch } from '../../../../../redux/store'

const { Option } = Select
const AddDistributors = ({ setDistributorData, savedData }) => {
  const { isAdmin, customerGetExitingDistributors, adminGetDistributors } = useSelector(
    ({ auth: { authUser }, distributors }) => ({
      isAdmin: authUser.userInfo?.is_admin,
      customerGetExitingDistributors: distributors.customerGetDistributors,
      adminGetDistributors: distributors.adminGetDistributors
    })
  )

  const validationSchema = Yup.object().shape({
    forms: Yup.array().of(
      Yup.object().shape({
        distributorId: Yup.string(),
        distributor_amount: Yup.string().when('distributorId', {
          is: function (value) {
            return Boolean(value)
          },
          then: Yup.string().required('Amount is required when distributor is selected')
        })
      })
    )
  })

  useEffect(() => {
    if (!adminGetDistributors.length && isAdmin) {
      dispatch.distributors.adminGetAllDistributors()
    } else {
      dispatch.distributors.customerGetAllDistributors()
    }
  }, [])

  return (
    <div>
      <Formik
        initialValues={{
          forms: savedData || [{ distributor_amount: '', distributorId: '' }]
        }}
        validationSchema={validationSchema}
        onSubmit={setDistributorData}
        enableReinitialize
        validateOnMount
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form>
            <FieldArray name='forms'>
              {({ push, remove }) => (
                <div key='form-array'>
                  {values.forms.map((_, index) => (
                    <div key={index} className='flex flex-wrap' onBlur={handleSubmit}>
                      <div className='w-full max-w-sm mt-8 px-3'>
                        <label className='text-black text-base leading-8'>{DISTRIBUTORS}</label>
                        <Field
                          as={Select}
                          name={`forms.${index}.distributorId`}
                          className='form-control border-0 mr-0 py-0 px-0 mt-0'
                          placeholder='Select distributor'
                          size='large'
                          onSelect={value => {
                            setFieldValue(`forms.${index}.distributorId`, value)
                            handleSubmit()
                          }}
                        >
                          <Option value=''>Select distributor</Option>
                          {(isAdmin ? adminGetDistributors : customerGetExitingDistributors).map(
                            (item, key) => (
                              <Option
                                key={key}
                                disabled={values.forms.some(
                                  distr => distr.distributorId === item.id
                                )}
                                value={item.id}
                              >
                                {item.name}
                              </Option>
                            )
                          )}
                        </Field>
                        <ErrorMessage
                          name={`forms.${index}.distributorId`}
                          component='small'
                          className='text-red-500'
                        />
                      </div>

                      <div className='w-full max-w-sm mt-8 px-3'>
                        <Field
                          as={InputField}
                          type='text'
                          name={`forms.${index}.distributor_amount`}
                          label={
                            <label className='text-black text-base'>{DISTRIBUTOR_AMOUNT}</label>
                          }
                          placeholder='Amount for the distributor'
                          onChange={e => {
                            handleSubmit()
                            setFieldValue(
                              `forms.${index}.distributor_amount`,
                              inputFieldformatCurrency(e.target.value)
                            )
                          }}
                          className='form-control bg-white'
                        />
                        <ErrorMessage
                          name={`forms.${index}.distributor_amount`}
                          component='small'
                          className='text-red-500'
                        />
                      </div>
                      <button
                        onClick={() => {
                          handleSubmit()
                          remove(index)
                        }}
                        className='mt-16 ml-5'
                      >
                        <i className='fas fa-trash-alt text-red-700' />
                      </button>
                    </div>
                  ))}
                  <div className='grid grid-cols-6'>
                    <button
                      onClick={() => {
                        handleSubmit()
                        push({ distributor_amount: '', distributorId: '' })
                      }}
                      className=' text-sm mt-10 mx-3 btn-primary bg-primary md:px-5 py-2 text-white md:col-start-5 col-start-3'
                    >
                      <i className='fas fa-plus ml-1 mr-2' />{' '}
                      {values.forms?.length > 0 ? 'Add more' : 'Add Distributor'}
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AddDistributors

AddDistributors.propTypes = {
  setDistributorData: PropTypes.func.isRequired,
  savedData: PropTypes.object
}
