import { notification } from 'antd'
import feedback from '../../services/feedback'
import { reducerActions } from '../reducer'

const initialState = {
  feedbacks: [],
  isServerError: null
}

export const feedbacks = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async getFeedbacks() {
      dispatch.feedbacks.setError(null)
      try {
        const { data } = await feedback.getFeedbacks()
        dispatch.feedbacks.setState({
          feedbacks: data.data
        })
      } catch ({ response, message }) {
        dispatch.feedbacks.setError(response)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.feedbacks.setState(initialState)])
    }
  }),
  selectors: slice => ({
    selectCustomerFeedbacks() {
      return slice(feedbacks => feedbacks.feedbacks)
    }
  })
}
