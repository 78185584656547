/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import AppTable from '../../../sharedComponents/table/AppTable'
import { ALL_ADMIN_APPLICATIONS as getAllAdminsApplication } from '../../applications/constant'
import { LOAN_APPLICATION } from './constants'
import { getQueryParams } from '../../../utils/customeReactHook'
import { notification } from 'antd'
import useGetLoanStatus from './useGetLoanStatus'
import DropdownBtn from './buttonDropdown/DropdownBtn'
import { searchArrayOrMakeCallToAPI } from '../../../utils/helpers'
import _debounce from 'lodash/debounce'

const AdminPendingApp = () => {
  const history = useHistory()
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const { permissions } = useSelector(({ auth }) => auth.authUser)
  const query = getQueryParams()

  const searchInput = useRef(null)
  const shouldUseCache = query.get('shouldUseCache')

  const loadingSearch = useSelector(({ loading }) => loading.effects.applications.searchApplication)
  const { pending: pendingLoanData, loading } = useGetLoanStatus('pending', !shouldUseCache)
  const [pendingAppData, setPendingAppData] = useState([])

  const handleSearchBiz = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const handleSearch = async ({ target }) => {
    if (!target.value) {
      return setPendingAppData(pendingLoanData)
    }
    const resultFound = await searchArrayOrMakeCallToAPI({
      array: pendingLoanData,
      searchTerm: target.value,
      makeSearchCall: []
    })
    return setPendingAppData(resultFound)
  }

  const searchUserDebounce = _debounce(handleSearch, 400)

  useEffect(() => {
    if (query.get('status') === 'SUCCESS') {
      notification.success({
        message: query.get('status'),
        description: 'Mou has been sent to customers email',
        placement: 'topRight'
      })
    } else if (query.get('status') === 'FAILED') {
      notification.error({
        message: query.get('status'),
        description: 'Failed to send mou to customers email',
        placement: 'topRight'
      })
    }
    setPendingAppData(pendingLoanData)
  }, [loading])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-between mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {LOAN_APPLICATION}
          </h3>
          <div className='p-4'>
            <DropdownBtn />
          </div>
        </div>
        {/* <ApplicationSearch url='admin/search-pending-loan' /> */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-1 text-lg mx-4'>
          <div className='relative mt-6 border rounded-lg mr-6 ml-3 col-span-3'>
            <div className='absolute top-4 left-3 pl-2'>
              <button onCanPlay={() => searchInput.current.focus()}>
                <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />{' '}
              </button>
            </div>
            <input
              type='text'
              ref={searchInput}
              onChange={searchUserDebounce}
              className='h-14 w-full pl-14 pr-20 rounded-lg z-0 focus:shadow focus:outline-none'
              placeholder='Search by amount, business name, etc'
            />
          </div>
        </div>
        <div className='mb-6 px-4 overflow-x-auto'>
          <AppTable
            columns={getAllAdminsApplication(permissions, {
              searchInput,
              handleSearchBiz,
              handleReset,
              setSearchText,
              searchedColumn,
              searchText,
              setSearchedColumn
            })}
            tableData={pendingAppData?.sort(
              (a, b) => new Date(b.time_submitted) - new Date(a.time_submitted)
            )}
            loading={loading || loadingSearch}
          />
        </div>
      </main>
    </DashboardLayout>
  )
}

export default AdminPendingApp
