import React from 'react'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'

import NewApplications from './NewApplications'

const NewApplication = () => {
  return (
    <DashboardLayout>
      <NewApplications shouldShowBackButton />
    </DashboardLayout>
  )
}

export default NewApplication
