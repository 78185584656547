import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { bankStatementColumn } from './constants'
import { downloadCSV } from '../../../sharedComponents/export/bankStatement'

const ListBankStatements = ({ statements, loading, setCurrentBankStatement }) => {
  return (
    <div>
      {statements.map((item, i) => {
        return (
          <div key={i} className='flex my-3 border-b'>
            <p className='w-2/3'>{i + 1}. Mono bank statement</p>
            <div className=''>
              <Button
                onClick={() => setCurrentBankStatement({ data: item?.file, visible: true })}
                type='link'
                className='mr-10 '
              >
                View
              </Button>
              <Button onClick={() => downloadCSV(bankStatementColumn, item?.file)} type='default'>
                Download XSL
              </Button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ListBankStatements

ListBankStatements.propTypes = {
  statements: PropTypes.array,
  loading: PropTypes.bool,
  setCurrentBankStatement: PropTypes.func
}
