import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as adminConst from './constants'
import { notification } from 'antd'
import { adminServices } from '../../../services'
import { FormSelect } from '../../../sharedComponents/form-select'
import { useSelector } from 'react-redux'

const CreateAdministration = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [showPwd, setShowPwd] = useState(false)

  const { allRoles } = useSelector(({ roles }) => roles)
  const userObj = {
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    role_id: '',
    password: ''
  }

  const [user, setUser] = useState(userObj)

  const handleCancle = () => {
    setUser(userObj)
  }

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.nativeEvent.keyCode === 13) {
      handleSubmit()
    }
  }

  const onChangeText = ({ target }) => {
    setUser({ ...user, [target.name]: target.value })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const { data } = await adminServices.createAdmin(user)

      if (data.success) {
        notification.success({
          message: 'Thanks',
          description: data.message
        })
        setUser(userObj)
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Something went wrong'
      })
      setLoading(false)
    }
  }

  return (
    <main className='w-full flex-grow p-4 bg-white shadow-lg'>
      <div className='ml-1 flex justify-start mt-4'>
        <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
          <i className='fas fa-arrow-left mr-3' />
          {adminConst.CREATE_ADMIN}
        </h3>
      </div>
      <div className=' w-full mt-8 mb-16 px-4 overflow-x-auto'>
        <div className='flex justify-between flex-wrap'>
          <div className='w-full md:w-3/4 mx-2'>
            <div className='mt-4'>
              <div className='md:grid grid-cols-1 mb-4'>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>
                    {adminConst.FIRST_NAME}
                  </label>
                  <input
                    className='form-control'
                    name='first_name'
                    type='text'
                    onChange={onChangeText}
                    value={user.first_name}
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900 font-semibold'>
                    {adminConst.LAST_NAME}
                  </span>
                  <input
                    className='form-control'
                    name='last_name'
                    type='text'
                    onChange={onChangeText}
                    value={user.last_name}
                  />
                </div>

                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900  font-semibold'>
                    {adminConst.PHONE_NUMBER}
                  </span>
                  <input
                    className='form-control'
                    name='phone_number'
                    type='tel'
                    onChange={onChangeText}
                    value={user.phone_number}
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900  font-semibold'>{adminConst.EMAIL}</span>
                  <input
                    className='form-control'
                    name='email'
                    type='email'
                    onChange={onChangeText}
                    value={user.email}
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900  font-semibold'>{adminConst.ROLE}</span>
                  <FormSelect
                    options={allRoles.map(({ role_id: roleId, role_title: roleTitle }) => ({
                      name: roleTitle,
                      value: roleId
                    }))}
                    name='role_id'
                    value={user.role_id}
                    onChange={onChangeText}
                    label='Select Roles'
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900  font-semibold'>
                    {adminConst.PASSWORD}
                  </span>
                  <div className='relative mt-2'>
                    <input
                      className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:shadow-outline focus:bg-white'
                      type={showPwd ? 'text' : 'password'}
                      value={user.password}
                      onChange={onChangeText}
                      name='password'
                      onKeyPress={handleKeypress}
                    />
                    <button
                      onClick={() => setShowPwd(!showPwd)}
                      type='submit'
                      className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                    >
                      {showPwd ? <i className='fas fa-eye-slash' /> : <i className='fas fa-eye' />}
                    </button>
                  </div>
                </div>
                <div className='d-flex'>
                  <button
                    onClick={handleCancle}
                    className='border-gray-500 mr-3 mt-6 text-base w-40 py-3 border rounded-lg hover:bg-gray-400 text-gray-700 hover:text-gray-50;'
                  >
                    {adminConst.CANCEL}
                  </button>
                  <button
                    onClick={handleSubmit}
                    className='bg-primary text-base mt-6 py-3 px-4 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
                  >
                    {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                    {adminConst.CREATE_ADMIN}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default CreateAdministration
