import React, { useState } from 'react'
import { Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import good from '../../images/good.png'
import pending from '../../images/pending.png'
import awaitImg from '../../images/await.png'
import { dispatch } from '../../redux/store'

const CheckStatusModal = ({ children, data, isDisbursed }) => {
  const [visible, setModal] = useState(false)
  const [reason, setReason] = useState('')

  const handleAccept = () => {
    dispatch.applications.customerAcceptLoan(data.loan_id)
  }

  const handleDecline = () => {
    if (reason.trim().length < 5) {
      return notification.error({
        message: 'Validation Failed:',
        description: 'Declined Reason is Required!'
      })
    }
    dispatch.applications.customerRejectLoan({
      loanId: data.loan_id,
      rejectReason: { reject_reason: reason }
    })
  }

  return (
    <>
      <button
        onClick={() => setModal(true)}
        className='px-6 py-2 border bg-yellow-100 text-yellow-600 hover:text-yellow-700 rounded-lg hover:bg-yellow-100 text-sm'
      >
        {children}
      </button>

      <Modal open={visible} onCancel={() => setModal(false)} footer={false}>
        <div className='flex flex-wrap mt-2'>
          <div className='block w-full text-white'>
            <h1 className='text-black text-2xl mt-2 font-bold text-center'>LOAN STATUS</h1>
            <p className='mt-4 text-black text-md text-center'>
              {data.loan_app_status === 'Pending' && 'This loan is yet to be approved by Admin'}
              {data.loan_app_status === 'Approved' && 'This loan has been approved'}
              {data.loan_app_status === 'Awaiting Disbursement' &&
                'This loan will soon be disbursed'}
            </p>
            <p className='mt-4 text-black'>
              {data.loan_app_status === 'Pending' && (
                <img src={pending} alt='Pending check' className='block mx-auto' width={60} />
              )}
              {data.loan_app_status === 'Approved' && (
                <img src={good} alt='Good check' className='block mx-auto' width={60} />
              )}
              {data.loan_app_status === 'Awaiting Disbursement' && (
                <img src={awaitImg} alt='Good check' className='block mx-auto' width={60} />
              )}
            </p>
            {data.loan_app_status !== 'Pending' && !isDisbursed ? (
              <>
                <div className='mt-5'>
                  <span className='text-sm text-black text-left'>Wish to decline?</span>
                  <textarea
                    className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'
                    value={reason}
                    name='reason'
                    onChange={({ target }) => setReason(target.value)}
                    placeholder='Drop decline reason...'
                  />
                </div>
                <div className='mt-10 flex justify-center'>
                  <button
                    onClick={() => handleAccept(data.loan_id)}
                    disabled={data.loan_app_status === 'Awaiting Disbursement'}
                    className={`px-6 block py-2 mx-4 border text-white bg-primary-200 rounded-lg hover:bg-primary text-sm ${
                      data.loan_app_status === 'Awaiting Disbursement' ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    Accept Loan
                  </button>
                  <button
                    onClick={() => handleDecline()}
                    className='px-6 block py-2 mx-4 border bg-yellow-100 text-yellow-600 hover:text-yellow-700 rounded-lg hover:bg-yellow-100 text-sm'
                  >
                    Decline Loan
                  </button>
                </div>
              </>
            ) : (
              Boolean(isDisbursed) && (
                <p className='text-black text-center text-xl'>
                  Loan already disbursed see <Link to='/transactions'>transactions</Link>{' '}
                </p>
              )
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

CheckStatusModal.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object,
  isDisbursed: PropTypes.bool
}

export default CheckStatusModal
