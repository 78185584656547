import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { DashboardLayout } from '../../../../sharedComponents/dashboardLayout'
import VfdAccountTransfer from './vfd/VfdAccountTransfer'

const AccountTransferNav = () => {
  const history = useHistory()

  const [activeBar, setActiveBar] = useState('VFD Bank')

  const handleTabClick = tab => {
    setActiveBar(tab)
  }
  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-transparent'>
        <div className='ml-1 mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            Back
          </h3>
        </div>
        <div className='mt-5 flex justify-start space-x-24 ml-8 w-full gap-5'>
          <button
            onClick={() => handleTabClick('VFD Bank')}
            className={`cursor-pointer ${
              activeBar === 'VFD Bank'
                ? 'border-b-4 text-lg border-blue-700 text-gray-500'
                : ' text-gray-500 px-3 text-lg py-2'
            }`}
          >
            VFD Bank
          </button>
          <button
            onClick={() => handleTabClick('Payhippo')}
            className={`cursor-pointer ${
              activeBar === 'Payhippo'
                ? 'border-b-4 text-lg border-blue-700  text-gray-500'
                : ' text-gray-500 px-3 text-lg py-2'
            }`}
          >
            Payhippo
          </button>
          <button
            onClick={() => handleTabClick('Kuda MFB')}
            className={`cursor-pointer ${
              activeBar === 'Kuda MFB'
                ? 'border-b-4 border-blue-700 text-lg  text-gray-500'
                : ' text-gray-500 px-3 text-lg py-2'
            }`}
          >
            Kuda MFB
          </button>
          <button
            onClick={() => handleTabClick('Opay')}
            className={`cursor-pointer ${
              activeBar === 'Opay'
                ? 'border-b-4 border-blue-700 text-lg  text-gray-500'
                : ' text-gray-500 px-3 py-2 text-lg'
            }`}
          >
            Opay
          </button>
        </div>
        <div className=' border-b-2 border-gray-100 mt-5' />
        <div className='w-full overflow-hidden'>
          {activeBar === 'VFD Bank' && <VfdAccountTransfer />}
          {activeBar === 'Payhippo' && 'Payhippo'}
          {activeBar === 'Kuda MFB' && 'Kuda MFB'}
          {activeBar === 'Opay' && 'Opay'}
        </div>
      </main>
    </DashboardLayout>
  )
}

export default AccountTransferNav
