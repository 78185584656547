const SIGN_UP_LABEL_FULL_NAME = 'First Name'
const SIGN_UP_LABEL_LASTNAME = 'Last Name'
const SIGN_UP_LABEL_EMAIL = 'Email Address'
const SIGN_UP_LABEL_PHONE_NO = 'Phone Number'
const SIGN_UP_LABEL_PWD = 'Password'
const SIGN_UP_BTN = 'Sign up'
const CONTINUE_USING_GOOGLE = 'Continue Using Google'
const ALREADY_HAVE_AN_ACCOUNT = 'Already Have an Account?'
const LOGIN = 'Log In'
const FORGOT_YOUR_PWD = 'Forgot your password?'
const SIGN_UP_TEXT = 'Get Started'
const SIGN_UP_TITLE = 'Create an Account With Us'
const SIGN_UP_SUB_TITLE = 'You’re few minutes away from getting a loan!'
const TROUBLE_SIGNING_UP = 'Trouble Signing Up?'
const CONTACT_SUPPORT = 'Contact Support'
const SIGN_UP_LABEL_BUSINESS_NAME = 'Business Name'
// exports
export {
  SIGN_UP_LABEL_FULL_NAME,
  SIGN_UP_LABEL_LASTNAME,
  SIGN_UP_LABEL_EMAIL,
  SIGN_UP_LABEL_PHONE_NO,
  SIGN_UP_LABEL_PWD,
  SIGN_UP_BTN,
  CONTINUE_USING_GOOGLE,
  ALREADY_HAVE_AN_ACCOUNT,
  LOGIN,
  FORGOT_YOUR_PWD,
  SIGN_UP_TEXT,
  SIGN_UP_SUB_TITLE,
  SIGN_UP_TITLE,
  TROUBLE_SIGNING_UP,
  CONTACT_SUPPORT,
  SIGN_UP_LABEL_BUSINESS_NAME
}
