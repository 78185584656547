import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import {
  SETTINGS,
  ACTION_EMAIL,
  FEEDBACK_EMAIL,
  REMINDER_EMAIL,
  actionEmail,
  reminderEmail,
  inputValidator
} from './constants'
import { notification } from 'antd'

const Settings = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const [action, setAction] = useState({
    name: '',
    email: '',
    password: '',
    confirm: ''
  })
  const [reminder, setReminder] = useState({
    name: '',
    email: '',
    password: '',
    confirm: ''
  })
  const [feedback, setFeedback] = useState({
    name: '',
    email: '',
    password: '',
    confirm: ''
  })
  const handleChange = ({ target }) => {
    setAction({ ...action, [target.name]: target.value })
  }
  const handleReminder = ({ target }) => {
    setReminder({ ...reminder, [target.name]: target.value })
  }
  const handleFeedback = ({ target }) => {
    setFeedback({ ...feedback, [target.name]: target.value })
  }

  const FORM = actionEmail(action, handleChange)
  const REMINDER = reminderEmail(reminder, handleReminder)
  const FEEDBACK = reminderEmail(feedback, handleFeedback)

  const handleSubmit = async type => {
    const input = type === 'action' ? action : type === 'reminder' ? reminder : feedback
    const { err, isValid } = inputValidator(input, type)
    if (!isValid) {
      notification.error({
        message: 'Error Occurred',
        description: err
      })
      return
    }

    try {
      // actions here
    } catch (error) {
      notification.error({
        message: 'Something went wrong'
      })
      setLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {SETTINGS}
          </h3>
        </div>
        <div className='mt-8 mb-16 px-4 overflow-x-auto'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mx-2'>
            {/* ACTION NAME */}
            <div className='mt-4'>
              <div className='mb-6'>
                <span className='text-gray-900 font-bold text-primary pb-6 inline-block'>
                  {ACTION_EMAIL}
                </span>
                {FORM.map((item, i) => (
                  <div key={i} className='w-full md:w-full px-2 mb-6'>
                    <label className='text-sm text-gray-900 font-semibold'>{item.title}</label>
                    <input
                      className='form-control'
                      name={item.name}
                      type={item.type}
                      value={item.value}
                      onChange={item.handleChange}
                      placeholder={item.placeholder}
                      spellCheck='false'
                      data-ms-editor='true'
                    />
                  </div>
                ))}
              </div>

              <div className='d-flex'>
                <button
                  onClick={() => handleSubmit('action')}
                  className='bg-primary text-base mt-6 w-full py-3 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
                >
                  {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                  Submit
                </button>
              </div>
            </div>
            {/* REMINDER EMAIL */}
            <div className='mt-4'>
              <div className='mb-6'>
                <span className='text-gray-900 font-bold text-primary pb-6 inline-block'>
                  {REMINDER_EMAIL}
                </span>
                {REMINDER.map((item, i) => (
                  <div key={i} className='w-full md:w-full px-2 mb-6'>
                    <label className='text-sm text-gray-900 font-semibold'>{item.title}</label>
                    <input
                      className='form-control'
                      name={item.name}
                      type={item.type}
                      value={item.value}
                      onChange={item.handleChange}
                      placeholder={item.placeholder}
                      spellCheck='false'
                      data-ms-editor='true'
                    />
                  </div>
                ))}
              </div>

              <div className='d-flex'>
                <button
                  onClick={() => handleSubmit('reminder')}
                  className='bg-primary text-base mt-6 w-full py-3 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
                >
                  {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                  Submit
                </button>
              </div>
            </div>
            {/* FEEDBACK EMAIL */}
            <div className='mt-4'>
              <div className='mb-6'>
                <span className='text-gray-900 font-bold text-primary pb-6 inline-block'>
                  {FEEDBACK_EMAIL}
                </span>
                {FEEDBACK.map((item, i) => (
                  <div key={i} className='w-full md:w-full px-2 mb-6'>
                    <label className='text-sm text-gray-900 font-semibold'>{item.title}</label>
                    <input
                      className='form-control'
                      name={item.name}
                      type={item.type}
                      value={item.value}
                      onChange={item.handleChange}
                      placeholder={item.placeholder}
                      spellCheck='false'
                      data-ms-editor='true'
                    />
                  </div>
                ))}
              </div>

              <div className='d-flex'>
                <button
                  onClick={() => handleSubmit('feedback')}
                  className='bg-primary text-base mt-6 w-full py-3 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
                >
                  {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default Settings
