/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Modal, Spin, notification } from 'antd'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { dispatch } from '../../../redux/store'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SuccessModal from './SuccessModal'

const PasswordResetModal = ({ onClose }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
  const userIdFromParams = useParams().id

  const bodyStyle = {
    padding: 0
  }

  const initialValues = {
    newPassword: '',
    confirmPassword: ''
  }
  const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().min(8, 'Too Short!').max(16, 'Too Long!').required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match') // This enforces password matching
      .required('Required')
  })
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const handleResetPassword = async values => {
    if (initialValues.newPassword === initialValues.confirmPassword) {
      const details = { user_id: userIdFromParams, new_password: values.newPassword }
      await dispatch.auth.resetPasswordForCustomer(details)
      setIsSuccessModalOpen(true)
    }
  }

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false)
  }

  return (
    <Modal
      open
      onCancel={onClose}
      closeIcon={
        <Icon
          onClick={onClose}
          icon='iconamoon:close-thin'
          className='text-white text-4xl mt-4 mr-8 rounded-full bg-white cursor-pointer'
          color='#b1b1ff'
        />
      }
      bodyStyle={bodyStyle}
      footer={null}
    >
      <div className='header_modal bg-primary w-full flex justify-between px-6 py-4'>
        <h2 className='text-white font-bold tracking-tighter text-2xl'>Reset Password</h2>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordSchema}
        onSubmit={handleResetPassword}
      >
        {({ values }) => (
          <Form>
            <div className='flex flex-col justify-center w-full px-8 py-9'>
              <h2 className='font-semibold text-lg'>Must be at least 8 characters</h2>
              <div className='flex w-full max-w-md flex-col mt-6'>
                <label className='text-base'>New Password</label>
                <div className='relative'>
                  <Field
                    type={passwordVisible ? 'text' : 'password'}
                    name='newPassword'
                    value={values.newPassword}
                    placeholder='New Password'
                    className='form-control'
                  />
                  <span
                    className='absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer'
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </span>
                </div>
                <ErrorMessage name='newPassword' component='small' className='text-red-500' />
              </div>
              <div className='mt-4'>
                <label className='text-base'>Confirm New Password</label>
                <div className='relative'>
                  <Field
                    type='password'
                    vale={values.confirmPassword}
                    name='confirmPassword'
                    placeholder='Confirm Password'
                    className='form-control'
                  />
                </div>
                <ErrorMessage name='confirmPassword' component='small' className='text-red-500' />
              </div>
              <button
                type='submit'
                className='mt-6 btn-primary text-white flex justify-between self-center bg-primary'
              >
                Reset
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* Render the SuccessModal if isSuccessModalOpen is true */}
      {isSuccessModalOpen && <SuccessModal onClose={handleCloseSuccessModal} />}
    </Modal>
  )
}
PasswordResetModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default PasswordResetModal
