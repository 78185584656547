/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { notification } from 'antd'

import WarningModal from '../modal/ModalContent'
import SuccessModal from '../modal/ApplyForLoanModal'

const Step5 = ({ onGoBack, onNext, dataForNext = false, stepForNext = 5 }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [modalVisible, setModalVisibility] = useState(true)

  const formSubmitting = useSelector(({ loading }) => loading.global)

  const handleSubmit = async () => {
    try {
      await onNext(dataForNext, stepForNext)
      setModalVisibility(false)
      setFormSubmitted(true)
    } catch (error) {
      setFormSubmitted(false)
      notification.warn({
        message: 'An error occured, try again',
        description: error?.response?.data?.msg || error?.message || 'Something went wrong',
        onClose: () => onGoBack()
      })
    }
  }

  const handleCancel = () => {
    setModalVisibility(false)
    onGoBack()
  }

  return (
    <>
      {!formSubmitted && (
        <WarningModal
          visible={modalVisible} // Show the warning modal when both modalVisible and showWarningModal are true
          onNext={handleSubmit} // Show the success modal when "Yes" is clicked
          onCancel={handleCancel} // Close the warning modal when "No" is clicked
          loading={formSubmitting}
        />
      )}

      {/* Render SuccessModal when user has confirmed */}
      {formSubmitted && <SuccessModal loading={formSubmitting} />}
    </>
  )
}

export default Step5

Step5.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  stepForNext: PropTypes.number,
  dataForNext: PropTypes.any
}
