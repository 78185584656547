import ApiHandler from './ApiHandler'

export default {
  customer_add_director: (data, endpoint) => ApiHandler.post(endpoint, data),
  resendConfirmationEmail: data => ApiHandler.post('resend-confirmation', data),
  confirmDirectors: data => ApiHandler.get(`director-email-confirmation/${data.id}/${data.token}`),
  customer_update_director: (data, endpoint) => ApiHandler.post(endpoint, data),
  customer_delete_director: directorId =>
    ApiHandler.put(`customer/deactivate-director/${directorId}`),
  director_accept_loan: (directorId, loanId) =>
    ApiHandler.get(`director-confirmation/${directorId}/${loanId}`),
  director_view_loan: (directorId, loanId) =>
    ApiHandler.get(`director/view-loan/${directorId}?${loanId}`),
  admin_get_customer_director: userId => ApiHandler.get(`admin/user-director/${userId}`),
  sendEmailConfirmation: data => ApiHandler.post('send-confirmation', data)
}
