/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import * as USER from './constant'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../../redux/store'
import { notification, Select } from 'antd'

const CustomerDirectors = () => {
  const history = useHistory()
  const [user, setUser] = useState({
    email: '',
    phone_number: '',
    id: '',
    is_admin: '',
    first_name: '',
    last_name: '',
    business_name: ''
  })
  const exitingUsers = useSelector(select.users.getUsersNotAdmin)
  const isUserLoading = useSelector(({ loading }) => loading.effects.users.getAllusers)

  useEffect(() => {
    dispatch.users.getAllusers({ shouldUseCache: false, showAll: true })
  }, [])

  const onChangeText = value => {
    setUser(exitingUsers.find(user => value === user.id))
  }

  const handleSubmit = () => {
    if (!user?.email) {
      return notification.error({
        message: 'An error occured',
        description: 'Please Select User!'
      })
    } else {
      history.push('/directors', { user })
    }
  }

  return (
    <DashboardLayout>
      <main className='w-full h-full flex-grow p-4 bg-white'>
        <div className='ml-1 mt-4'>
          <h3
            onClick={() => history.push('/admin-dashboard')}
            className='font-bold text-lg cursor-pointer'
          >
            <i className='fas fa-arrow-left mr-3' />
            Back to Dashboard
          </h3>
        </div>
        <div className='mb-6 px-4 overflow-x-auto'>
          <div className='w-full md:w-8/12 px-2 my-10'>
            <span className='text-xl text-gray-900 font-extrabold my-52'>{USER.USER}</span>
            <Select
              showSearch
              className='form-control mt-72 '
              placeholder='Select User'
              optionFilterProp='children'
              onChange={onChangeText}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {exitingUsers.map(({ first_name, last_name, business_name, id }) => {
                const name = business_name
                  ? `${business_name + ' ' + '-' + ' ' + first_name + ' ' + last_name}`
                  : `${first_name + ' ' + last_name}`
                return (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
        </div>
        <div className='border' />
        <div className='w-full flex mt-6 mb-8 justify-start pr-10 md:ml-5'>
          <button
            disabled={isUserLoading || !user?.email}
            className='btn-primary'
            onClick={handleSubmit}
          >
            {isUserLoading ? 'Loading users ...' : USER.NEXT}
          </button>
        </div>
      </main>
    </DashboardLayout>
  )
}
export default CustomerDirectors
