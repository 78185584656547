const LOGIN_LABEL_EMAIL = 'Your email'
const LOGIN_LABEL_PWD = 'Password'
const LOGIN_BTN = 'Log In'
const CONTINUE_USING_GOOGLE = 'Continue Using Google'
const DONT_HAVE_AN_ACCOUNT = 'Don’t Have an Account?'
const SIGN_UP = 'Sign Up'
const OR = 'OR'
const FORGOT_YOUR_PWD = 'Forgot your password?'
const REMEMBER_ME = 'Keep me logged in'
const LOGIN_TEXT = 'Login'
const VITTAS_INTERNATIONAL = 'Vittas International'

export {
  LOGIN_LABEL_EMAIL,
  LOGIN_LABEL_PWD,
  LOGIN_BTN,
  CONTINUE_USING_GOOGLE,
  DONT_HAVE_AN_ACCOUNT,
  SIGN_UP,
  OR,
  FORGOT_YOUR_PWD,
  LOGIN_TEXT,
  VITTAS_INTERNATIONAL,
  REMEMBER_ME
}
