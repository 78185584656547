import React, { useState, useEffect } from 'react'
import { Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import forgotPwd from '../../images/login/confirm-account.png'
import {
  CONFIRM_NOW_BTN,
  CODE_LABEL,
  SIGN_UP_ACCOUNT_CONFIRMATION,
  CONFIRM_ACC_SUB_TITLE
} from './constant'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ConfirmSignUpModal = ({ headerTitle }) => {
  const history = useHistory()
  const loading = useSelector(({ loading }) => loading.effects.auth.confirmSignUp)
  const resendLoading = useSelector(({ loading }) => loading.effects.auth.resendConfirmSignUp)
  const confirmSignUpModal = useSelector(({ auth }) => auth.confirmSignUpModal)

  const [visible, setModal] = useState(false)
  const [resendVisible, setResendVisible] = useState(false)

  const [token, setToken] = useState('')

  const handleCancel = () => {
    setModal(false)
    dispatch.auth.confirmSignUpModalBox({ visible: false, email: null })
  }

  const onChangeText = ({ target }) => {
    setToken(target.value)
  }

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setResendVisible(true)
    }, 10000)
    setModal(confirmSignUpModal.visible)

    return () => {
      clearTimeout(timeOut)
    }
  }, [confirmSignUpModal])

  const submitFormHandle = () => {
    if (token) {
      dispatch.auth.confirmSignUp({ history, token })
    } else {
      notification.error({
        message: 'An error occured',
        description: 'Token is required!'
      })
    }
  }

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.nativeEvent.keyCode === 13) {
      submitFormHandle()
    }
  }

  const handleResendToken = async () => {
    try {
      await dispatch.auth.resendConfirmSignUp({ email: confirmSignUpModal.email })
      setResendVisible(false)

      setTimeout(() => {
        setResendVisible(true)
      }, 10000)
    } catch (error) {}
  }

  return (
    <>
      <Modal open={visible} title={headerTitle} onCancel={handleCancel} footer={[]}>
        <div className='flex flex-wrap mt-2'>
          <div className='w-full text-white'>
            <img src={forgotPwd} alt='forgot password' className='block mx-auto' />
            <h1 className='text-black text-2xl mt-2 font-bold text-center'>
              {SIGN_UP_ACCOUNT_CONFIRMATION}
            </h1>
            <p className='mt-2 text-black text-lg text-center'>{CONFIRM_ACC_SUB_TITLE}</p>
            <div className='mt-5'>
              <span className='text-sm text-black text-left'>{CODE_LABEL}</span>
              <input
                className='w-full text-center bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                type='text'
                value={token}
                onChange={onChangeText}
                name='token'
                onKeyPress={handleKeypress}
              />
            </div>

            <div className='mt-5'>
              <button
                onClick={submitFormHandle}
                className='py-3 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
              >
                {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                {CONFIRM_NOW_BTN}
              </button>
              {resendVisible && (
                <button
                  disabled={!confirmSignUpModal.email}
                  onClick={handleResendToken}
                  className='text-primary py-3'
                >
                  {resendLoading && (
                    <i className='fa fa-spin fa-spinner text-primary mr-2 font-bold text-lg' />
                  )}
                  Resend
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

ConfirmSignUpModal.propTypes = {
  headerTitle: PropTypes.string.isRequired
}

export default ConfirmSignUpModal
