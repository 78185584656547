/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Select, Spin, notification } from 'antd'
import { dispatch } from '../../../../redux/store'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'

const ExistingLoanSearchModal = ({ isOpen, onClose }) => {
  const history = useHistory()
  const [user_id, setUserID] = useState('') // Only user_id is stored in state
  const getApprovedLoansUsers = useSelector(({ loans }) => loans.approvedLoanUsers)
  const isUserLoading = useSelector(({ loading }) => loading.effects.users.approvedLoanUsers)

  const onSelect = value => {
    // Setting the selected user's user_id
    setUserID(value)
  }

  const handleSubmit = () => {
    if (!user_id) {
      return notification.error({
        message: 'An error occurred',
        description: 'Please Select User!'
      })
    } else {
      onClose()
      // Navigate to the next page with user_id as a URL parameter
      history.push(`/apply-express-loan/${user_id}`, { user_id })
    }
  }

  useEffect(() => {
    if (getApprovedLoansUsers.length <= 0) {
      dispatch.loans.getApprovedLoansUsers()
    }
  }, [])

  return (
    <div className={`fixed inset-0 z-30 ${isOpen ? 'block' : 'hidden'}`}>
      <div className='flex items-center justify-center h-screen bg-black bg-opacity-50'>
        <div className='bg-white w-full max-w-xl'>
          <div className='bg-primary w-full flex justify-between px-3 py-5'>
            <h2 className='text-white font-bold tracking-tighter text-2xl'>Application</h2>
            <span>
              <Icon
                onClick={onClose}
                icon='iconamoon:close-thin'
                className='text-white text-4xl rounded-full bg-white cursor-pointer'
                color='#b1b1ff'
              />
            </span>
          </div>
          <div className='px-8 py-5'>
            <h2 className='text-xl font-semibold'>Apply for Existing Customer</h2>
          </div>
          <h2 className='px-8 text-base'>Enter customer Name</h2>
          <div className='px-8 flex flex-col'>
            <div className='py-5'>
              <Select
                showSearch
                className='form-control border-0 mr-0 py-0 px-0 mt-0 w-full max-w-sm'
                placeholder=''
                optionFilterProp='children'
                onSelect={onSelect} // Using onSelect to handle dropdown selection
                defaultOpen
                filterOption={(input, option) =>
                  option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                }
              >
                {getApprovedLoansUsers?.map(({ user_name, user_id }) => {
                  return (
                    <Select.Option key={user_id} value={user_id}>
                      {user_name}
                    </Select.Option>
                  )
                })}
                {isUserLoading && (
                  <Select.Option key='' value='' disabled>
                    <Spin /> Please wait
                  </Select.Option>
                )}
              </Select>
            </div>
            <div className='py-8'>
              <button
                className='px-6 py-2 bg-primary text-white rounded-md'
                disabled={isUserLoading || !user_id}
                onClick={handleSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ExistingLoanSearchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ExistingLoanSearchModal
