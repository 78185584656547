import ApiHandler from './ApiHandler'

export default {
  pending_application: () => ApiHandler.get('customer/pending-apps'),
  adminGetLoansByStatus: (status, shouldUseCache = false) =>
    ApiHandler.get(
      `admin/applications?type=${status}`,
      {},
      { 'Cache-Control': shouldUseCache ? null : 'no-cache' }
    ),
  adminGetAllApplicationCount: (shouldUseCache = true) =>
    ApiHandler.get('admin/applications-count', {}, { 'Cache-Control': 'no-cache' }),
  all_applications: () => ApiHandler.get('customer/applications'),
  admin_applications: (shouldUseCache = true) =>
    ApiHandler.get(
      'admin/applications',
      {},
      { 'Cache-Control': shouldUseCache ? null : 'no-cache' }
    ),
  declined_applications: () => ApiHandler.get('customer/declined-apps'),
  approved_aplications: () => ApiHandler.get('customer/approved-apps'),
  applicationDetail: (slug, isAdmin) =>
    ApiHandler.get(`${isAdmin ? 'admin' : 'customer'}/view-loan/${slug}`),
  feedbacks: data => ApiHandler.post('customer/feedback', data),
  searchApp: (data, url) => ApiHandler.post(url, data),
  searchByDateApp: data => ApiHandler.post('customer/search-loan/filter-date', data),
  admin_decline_application: data => ApiHandler.post('admin/decline-loan', data),
  admin_approve_application: data => ApiHandler.post('admin/approve-loan', data),
  admin_app_line_chart: year => ApiHandler.get(`admin/admin-chart?year=${year}`),
  customer_accept_loan: loanId => ApiHandler.get(`customer/accept-loan/${loanId}`),
  customer_reject_loan: ({ loanId, rejectReason }) =>
    ApiHandler.post(`customer/reject-loan/${loanId}`, rejectReason),
  customer_upload_document: data => ApiHandler.post('/customer/upload-document', data),
  customer_delete_document: documentId =>
    ApiHandler.delete(`/customer/delete-document/${documentId}`),
  admin_upload_document: data => ApiHandler.post('admin/upload-document', data),
  admin_delete_document: (customerId, documentId) =>
    ApiHandler.delete(`admin/delete-document/${customerId}/${documentId}`),
  upload_docs: data => ApiHandler.put('admin/edit-loan', data),
  delete_loan: id => ApiHandler.delete(`admin/delete_loan_app/${id}`),
  admin_get_closed_loan: (shouldUseCache = true) =>
    ApiHandler.get(
      'admin/closed-disbursed-applications',
      {},
      { 'Cache-Control': shouldUseCache ? null : 'no-cache' }
    ),
  unapproved_loan: data => ApiHandler.put('admin/disapprove-loan', data),
  admin_get_customer_documents: customerId => ApiHandler.get(`/admin/user-document/${customerId}`)
}
