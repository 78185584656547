export const ROLES = [
  { name: 'delete_loan', title: 'Delete Loan', isChecked: false },
  {
    name: 'view_loan_applications',
    title: 'View Loan Applications',
    isChecked: false
  },
  {
    name: 'approve_reject_loan',
    title: 'APPROVE OR REJECT LOAN',
    isChecked: false
  },
  {
    name: 'modify_loan_application',
    title: 'Modify Loan Application',
    isChecked: false
  },
  { name: 'disburse_loan', title: 'Disburse Loan', isChecked: false },
  { name: 'modify_roles', title: 'Modify Roles', isChecked: false },
  { name: 'view_roles', title: 'View Roles', isChecked: false },
  { name: 'view_users', title: 'View Users', isChecked: false },
  { name: 'modify_payments', title: 'Modify Payments', isChecked: false },
  {
    name: 'grant_revoke_admin_access',
    title: 'Grant & Revoke Admin Access',
    isChecked: false
  },
  {
    name: 'interest_tracker',
    title: 'Interest Tracker',
    isChecked: false
  },
  {
    name: 'close_loan',
    title: 'Close Loan',
    isChecked: false
  },
  {
    name: 'apply_for_loan',
    title: 'Apply for Loan',
    isChecked: false
  },
  {
    name: 'view_dashboard',
    title: 'View Dashboard',
    isChecked: false
  },
  {
    name: 'view_administration',
    title: 'View Administration',
    isChecked: false
  },
  { name: 'view_templates', title: 'View transaction data', isChecked: false },
  {
    name: 'view_system_logs',
    title: 'View System Logs',
    isChecked: false
  },
  {
    name: 'view_feedback',
    title: 'View Feedback',
    isChecked: false
  },
  {
    name: 'send_reminder',
    title: 'Send Reminder',
    isChecked: false
  }
]
const DELETE_ROLE = 'Delete Role'
const UPDATE_ROLE = 'Update Role'
const ROLE = 'Role'

export { UPDATE_ROLE, ROLE, DELETE_ROLE }
