import React, { useState } from 'react'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import VfdMfb from './VfdMfb'
export default function FinanceNav() {
  const [activeBar, setActiveBar] = useState('VFD MFB')

  const handleTabClick = tab => {
    setActiveBar(tab)
  }

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-transparent'>
        <div className='flex items-center justify-between w-full'>
          <div className='mt-5 flex justify-start bg-white px-6 py-3 rounded-lg gap-5 ml-8 '>
            <button
              onClick={() => handleTabClick('VFD MFB')}
              className={`cursor-pointer ${
                activeBar === 'VFD MFB'
                  ? 'text-lg bg-primary rounded-lg px-4 py-2 text-gray-100'
                  : ' text-gray-500 px-3 text-lg py-2'
              }`}
            >
              VFD MFB
            </button>
            {/*  NOTE: we only support VFD for now <button
              onClick={() => handleTabClick('Fidelity Bank')}
              className={`cursor-pointer ${
                activeBar === 'Fidelity Bank'
                  ? 'text-lg bg-primary rounded-lg px-4 py-2 text-gray-100'
                  : ' text-gray-500 px-3 text-lg py-2'
              }`}
            >
              Fidelity Bank
            </button>
            <button
              onClick={() => handleTabClick('Opay MFB')}
              className={`cursor-pointer ${
                activeBar === 'Opay MFB'
                  ? 'text-lg bg-primary rounded-lg px-4 py-2 text-gray-100'
                  : ' text-gray-500 px-3 text-lg py-2'
              }`}
            >
              Opay MFB
            </button> */}
          </div>
        </div>
        <div className=' border-b-2 border-gray-100 mt-5' />
        <div className='w-full overflow-hidden'>
          {activeBar === 'VFD MFB' && <VfdMfb />}
          {/*  NOTE: we only support VFD for now {activeBar === 'Payhippo' && 'Payhippo'}
          {activeBar === 'Kuda MFB' && 'Kuda MFB'}
          {activeBar === 'Opay' && 'Opay'} */}
        </div>
      </main>
    </DashboardLayout>
  )
}
