import React, { useState, useEffect } from 'react'
import { Progress, Upload, message } from 'antd'
import { adminUploadValidator } from '../../utils/inputValidator'
import { SUPPORTED_IMAGE_TYPE } from '../../utils/data'
import { BROWSE_FILE } from '../../sharedComponents/entries/constant'
import { UPLOAD_BASE_URL } from '../../utils/constants'
import { SALES_DATA, SAVE, DRAG_FILES, OR, DISTRIBUTORS_LEDGER, LOAN_AGREEMENT } from './constant'
import { dispatch } from '../../redux/store'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const UploadDocs = ({ loanData, setIsModalVisible }) => {
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState({})
  const [doc, setDoc] = useState({
    distributor_vendor_ledger: loanData.distributor_vendor_ledger || '',
    sales_data: loanData.sales_data || '',
    agreement_doc_url: loanData.agreement_doc_url || '',
    loan_app_id: loanData.loan_id || ''
  })

  const loading = useSelector(({ loading }) => loading.effects.applications.uploadDocument)

  const uploadProps = {
    action: `${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        return message.error(`${file.name} is not supported`)
      }
      if (file.size / 1000000 > 35) {
        return message.error('This file size is more than the required 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }
  const handleChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        const ImgUrl = info.file.response.data.url
        if (type === 'distributor_vendor_ledger') {
          setDoc({ ...doc, distributor_vendor_ledger: ImgUrl })
        } else if (type === 'sales_data') {
          setDoc({ ...doc, sales_data: ImgUrl })
        } else if (type === 'agreement_doc_url') {
          setDoc({ ...doc, agreement_doc_url: ImgUrl })
        }
      }
    }
  }

  const validator = adminUploadValidator(doc)

  const handleSubmit = async () => {
    for (const key in doc) {
      if (!doc[key]) {
        delete doc[key]
      }
    }
    setIsModalVisible(false)
    await dispatch.applications.uploadDocument(doc)
    setError({})
    setProgress(0)
  }

  useEffect(() => {
    if (Object.keys(doc).length) {
      if (validator.isValid) setProgress(100)
    }
  }, [doc])

  return (
    <div className='w-full'>
      <div className=''>
        <div className='flex flex-wrap mt-4 justify-center'>
          <div className='w-full md:w-2/5 lg:w-2/5 px-4'>
            <label className='text-sm text-gray-900  font-semibold'>{SALES_DATA}</label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progress <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{
                      wordWrap: 'break-word',
                      maxWidth: '190px',
                      minWidth: '180px'
                    }}
                  >
                    <Upload.Dragger
                      {...uploadProps}
                      onChange={info => handleChange(info, 'sales_data')}
                    >
                      <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                      <p className='text-center my-6 font-normal'>{OR} </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        {BROWSE_FILE}
                      </p>
                    </Upload.Dragger>
                  </div>
                ) : (
                  <Progress type='circle' percent={progress} />
                )}
                {error.sales_data ? <small className='text-red-500'>{error.sales_data}</small> : ''}
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/5 lg:w-2/5 px-4'>
            <label className='text-sm text-gray-900  font-semibold'>{DISTRIBUTORS_LEDGER}</label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progress <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{
                      wordWrap: 'break-word',
                      maxWidth: '190px',
                      minWidth: '180px'
                    }}
                  >
                    <Upload.Dragger
                      {...uploadProps}
                      onChange={info => handleChange(info, 'distributor_vendor_ledger')}
                    >
                      <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                      <p className='text-center my-6 font-normal'>{OR} </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        {BROWSE_FILE}
                      </p>
                    </Upload.Dragger>
                  </div>
                ) : (
                  <Progress type='circle' percent={progress} />
                )}
                {error.distributor_vendor_ledger ? (
                  <small className='text-red-500'>{error.distributor_vendor_ledger}</small>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/5 lg:w-2/5 px-4 mt-10'>
            <label className='text-sm text-gray-900  font-semibold'>{LOAN_AGREEMENT}</label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progress <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{
                      wordWrap: 'break-word',
                      maxWidth: '190px',
                      minWidth: '180px'
                    }}
                  >
                    <Upload.Dragger
                      {...uploadProps}
                      onChange={info => handleChange(info, 'agreement_doc_url')}
                    >
                      <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                      <p className='text-center my-6 font-normal'>{OR} </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        {BROWSE_FILE}
                      </p>
                    </Upload.Dragger>
                  </div>
                ) : (
                  <Progress type='circle' percent={progress} />
                )}
                {error.agreement_doc_url ? (
                  <small className='text-red-500'>{error.agreement_doc_url}</small>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className='w-full flex mt-6 mb-8 justify-center'>
            <button className='w-full md:w-2/5 px-2 btn-primary' onClick={handleSubmit}>
              {SAVE}{' '}
              {loading ? (
                <i className='fa fa-spinner' />
              ) : (
                <i className='fa fa-save ml-2' aria-hidden='true' />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

UploadDocs.propTypes = {
  loanData: PropTypes.object,
  setIsModalVisible: PropTypes.func
}
export default UploadDocs
