/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-expressions */

import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Dropdown, Menu, Modal, Table, notification } from 'antd'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

import { handleUploadile } from '../../../utils/helpers'
import { DOC_TYPE_STATUS, docsFieldtoName } from './constant'
import applications from '../../../services/applications'
import PreviewModal from '../apply-for-loan/New-apply-loan/modal/PreviewModal'
import closeIcon from '../../../images/icons/modal-close-icon.svg'
import { dispatch } from '../../../redux/store'
import { UPLOAD_FILE_TYPES } from '../../../utils/constants'

const CustomerDocs = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [fileError, setFileError] = useState({})
  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  const [previewFileUrl, setPreviewFileUrl] = useState('')
  const [customerDocs, setCustomerDocs] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [modalDataSource, setModalDataSource] = useState(null)

  const [fileUploadedStatus, setFileUploadedStatus] = useState({
    aoa: DOC_TYPE_STATUS.NOT_AVAILABLE,
    pharmacist_license: DOC_TYPE_STATUS.NOT_AVAILABLE,
    business_licenses: DOC_TYPE_STATUS.NOT_AVAILABLE,
    bank_statement: DOC_TYPE_STATUS.NOT_AVAILABLE,
    moa: DOC_TYPE_STATUS.NOT_AVAILABLE,
    cac_7: DOC_TYPE_STATUS.NOT_AVAILABLE,
    cac_3: DOC_TYPE_STATUS.NOT_AVAILABLE,
    cac_2: DOC_TYPE_STATUS.NOT_AVAILABLE,
    optional: DOC_TYPE_STATUS.NOT_AVAILABLE
  })

  const userIdFromParams = useParams().id
  const saveRef = useRef(null)

  const fileData = useMemo(() => {
    const data = { optional: [] }
    Object.keys(docsFieldtoName).map(key => {
      data[key] = customerDocs
        .filter(({ type }) => type === key)
        .map(({ title, created_at: dateCreated, file_url: fileUrl, ...others }) => {
          return {
            title,
            dateCreated,
            fileUrl,
            ...others
          }
        })
    })

    Object.keys(fileUploadedStatus).forEach(key => {
      if (data[key]?.length) {
        setFileUploadedStatus(prev => ({
          ...prev,
          [key]: DOC_TYPE_STATUS.AVAILABLE
        }))
      }
    })
    return data
  }, [customerDocs])

  // Function to open the modal and set selected document and preview URL

  const handleViewDocument = (url, field) => {
    setPreviewFileUrl(url)
    setPreviewModalVisible(true)
  }

  // Function to delete to be implemented
  const handleDelete = async id => {
    await dispatch.applications.adminDeleteDocument({
      customerId: userIdFromParams,
      documentId: id
    })
    setModalVisible(false)
    getCustomerDocuments()
  }

  const handleBeforeUnload = event => {
    if (unsavedChanges) {
      // Display a confirmation message if unsaved changes exist
      event.preventDefault()
      event.returnValue = 'You have unsavedDOcuments' // This message will be shown in the confirmation dialog
    }
  }

  const handleFileChange = async event => {
    const file = event.target.files[0]
    const documentType = event.target.name
    const docTypeStatus = fileUploadedStatus[documentType]

    setFileUploadedStatus(prevState => ({
      ...prevState,
      [documentType]: DOC_TYPE_STATUS.UPLOADING
    }))

    try {
      const fileUrl = await handleUploadile(file, UPLOAD_FILE_TYPES.DOCS)
      setFileError(prevState => ({
        ...prevState,
        [documentType]: ''
      }))

      // Set file uploaded to true
      setFileUploadedStatus(prevState => ({
        ...prevState,
        [documentType]: DOC_TYPE_STATUS.UPLOADED
      }))

      setUploadedFiles(prevFiles => [...prevFiles, { type: documentType, url: fileUrl }])

      setPreviewFileUrl(fileUrl)
      setUnsavedChanges(true)
    } catch (error) {
      setFileUploadedStatus(prevState => ({
        ...prevState,
        [documentType]: docTypeStatus
      }))

      notification.error({
        message: 'An error occured while uploading file',
        description: `${error?.message}`
      })
    }
  }

  const handleSave = async () => {
    try {
      const docsForUpload = uploadedFiles.map(file => ({
        type: file.type,
        file_url: file.url,
        customer_id: userIdFromParams,
        title: file?.url?.split('/')[file?.url?.split('/').length - 1]?.split('-')[1]
      }))
      // eslint-disable-next-line no-unused-vars
      await dispatch.applications.adminUploadDocument(docsForUpload)
      getCustomerDocuments()
      setUnsavedChanges(false)
    } catch (error) {
      console.error('Error uploading documents:', error)
    }
  }

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = previewFileUrl
    link.download = 'file-download'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getCustomerDocuments = async () => {
    const { data } = await applications.admin_get_customer_documents(userIdFromParams)
    if (data.success) {
      setCustomerDocs(data.data.user_documents)
    }
  }

  useEffect(() => {
    if (saveRef.current && unsavedChanges) {
      saveRef.current.scrollIntoView({ behavior: 'smooth' })
      saveRef.current?.focus()
    }
  }, [unsavedChanges, fileUploadedStatus])

  useEffect(() => {
    // Attaching the beforeunload event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      // Removing the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    getCustomerDocuments()
  }, [])

  const modalColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      dkey: 'title'
    },
    {
      title: 'Date Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated'
    },
    {
      title: 'Action',
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key='view' onClick={() => handleViewDocument(record.fileUrl)}>
                View Document
              </Menu.Item>
              <Menu.Item key='delete' onClick={() => handleDelete(record?.id)}>
                <span className='text-red-700'>Delete</span>
              </Menu.Item>
            </Menu>
          }
        >
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            <i className='fa fa-ellipsis-v' />
          </a>
        </Dropdown>
      )
    }
  ]

  return (
    <div className='my-4'>
      <div className='md:mt-12 md:ml-8'>
        {/* Rendering cards here, modifying the corresponding state and props */}
        <div className='grid w-full max-w-5xl md:gap-14 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:mt-7'>
          {/* All cards Mapped here */}
          {Object.keys(docsFieldtoName).map((field, index) => {
            return (
              <div className='w-full' key={index}>
                <div className='flex gap-4'>
                  <h2 className='text-base tracking-tight font-medium mb-4'>
                    {docsFieldtoName[field]}
                  </h2>
                </div>

                <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                  {fileUploadedStatus[field] === DOC_TYPE_STATUS.UPLOADING && (
                    <p className='mb-2 text-center'>Uploading...</p>
                  )}

                  {fileUploadedStatus[field] === DOC_TYPE_STATUS.UPLOADED && (
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                  )}

                  {[DOC_TYPE_STATUS.AVAILABLE, DOC_TYPE_STATUS.UPLOADED].includes(
                    fileUploadedStatus[field]
                  ) && (
                    <>
                      <p className='mb-2'>
                        <a
                          href='#'
                          onClick={() => {
                            setModalDataSource({
                              title: docsFieldtoName[field],
                              data: fileData[field]
                            })
                            setModalVisible(true)
                          }}
                          className='text-blue-500'
                        >
                          View Details
                        </a>
                      </p>
                      <label
                        htmlFor={'fileInput' + field}
                        className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                      >
                        Add more file
                      </label>
                    </>
                  )}

                  {fileUploadedStatus?.[field] === DOC_TYPE_STATUS.NOT_AVAILABLE && (
                    <>
                      <p className='mb-2 text-center'>Drag and drop your document</p>
                      <p className='text-gray-500 text-sm mt-2'>OR</p>
                      <label
                        htmlFor={'fileInput' + field}
                        className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                      >
                        Browse file
                      </label>
                    </>
                  )}
                  <input
                    type='file'
                    name={field}
                    id={'fileInput' + field}
                    className='hidden'
                    accept='image/*, .pdf, .doc, .docx, .ppt, .pptx, .txt, .xls, .xlsx'
                    onChange={handleFileChange}
                  />
                </div>
                {fileError[field] && <small className='text-red-500'>{fileError[field]}</small>}
              </div>
            )
          })}
        </div>
      </div>
      {/* Conditionally render the "Save" button */}
      {unsavedChanges && ( // Display the "Save" button if any new document is added
        <div className='w-full flex mt-12 mb-8 justify-center pr-10 md:ml-5 shake' ref={saveRef}>
          <button
            className='btn-primary mb-6 bg-primary px-8 py-2 text-white'
            type='submit'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      )}
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={700}
        pagination={false}
        destroyOnClose
        title={
          <div className='bg-primary text-white p-2 m-0 inset-0'>{modalDataSource?.title}</div>
        }
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        className='preview-modal'
        closeIcon={
          <span>
            <img className='mt-4' src={closeIcon} alt='close icon' />
          </span>
        }
      >
        <Table
          columns={modalColumns}
          dataSource={modalDataSource?.data?.map(item => ({
            ...item,
            key: item.id
          }))}
        />
      </Modal>
      <PreviewModal
        modalVisible={previewModalVisible}
        previewFileUrl={previewFileUrl}
        setModalVisible={setPreviewModalVisible}
        handleDownload={handleDownload}
        onCancel={() => setPreviewModalVisible(false)}
      />
    </div>
  )
}

export default CustomerDocs
