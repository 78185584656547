import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { monthNames } from '../../utils/data'

const LineChart = ({ newDATA }) => {
  const [data, setData] = useState({
    datasets: [
      {
        backgroundColor: '#D6FAE0',
        data: [],
        label: 'Amount Collected',
        fill: true
      },
      {
        backgroundColor: '#FDDFE0',
        data: [],
        label: 'Amount Payback',
        fill: true
      }
    ],
    labels: []
  })

  const labels1 = []
  const mainData1 = []
  const labels2 = []
  const mainData2 = []

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  useEffect(() => {
    if (newDATA) {
      if (Object.keys(newDATA).length === 0) return false
      const entry1 = []
      Object.entries(newDATA?.breakdown_collected_amount).filter(item => {
        entry1.push(item[0].charAt(0) === '0' ? [item[0].slice(1), item[1]] : item)
      })
      const entry2 = []
      Object.entries(newDATA?.breakdown_paid_amount).filter(item => {
        entry2.push(item[0].charAt(0) === '0' ? [item[0].slice(1), item[1]] : item)
      })
      const collected = Object.fromEntries(entry1) || {}
      const paid = Object.fromEntries(entry2) || {}

      for (const key in collected) {
        if (Object.hasOwnProperty.call(collected, key)) {
          // Convert to month and push to label
          labels1.push(key - 1)
          mainData1.push(collected[key])
        }
      }

      for (const key in paid) {
        if (Object.hasOwnProperty.call(paid, key)) {
          // Convert to month and push to label
          labels2.push(key - 1)
          mainData2.push(paid[key])
        }
      }

      setData({
        ...data,
        labels: [...data.labels, ...labels1, ...labels2]
          .filter(onlyUnique)
          .sort()
          .map(d => monthNames[d]),
        datasets: [
          { ...data.datasets[0], data: mainData1 },
          { ...data.datasets[1], data: mainData2 }
        ]
      })
    }
  }, [newDATA])

  return (
    <div>
      <Line data={data} height={370} options={{ maintainAspectRatio: false }} />
    </div>
  )
}

LineChart.propTypes = {
  newDATA: PropTypes.object
}

export default LineChart
