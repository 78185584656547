import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { notification } from 'antd'
import * as Yup from 'yup'

import Banner from '../../images/leadsBanner.png'
import { LeadsApi } from '../../services'
import { InputField } from '../../sharedComponents/input-field'
import SuccessModal from '../../sharedComponents/modal/SuccessModal'

const LeadsForm = () => {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const history = useHistory()

  const initialValues = {
    business_name: '',
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    locations: ''
  }

  const validationSchema = Yup.object().shape({
    business_name: Yup.string().required('Business name is required'),
    email: Yup.string().email('Invalid email'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    phone_number: Yup.string().min(5).required('Enter valid phone number'),
    locations: Yup.string()
  })

  const handleSubmit = async values => {
    setLoading(true)
    try {
      await LeadsApi.postLead(values)
      setSubmitted(true)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response.data.message || 'Something went wrong'
      })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setSubmitted(false)
    history.push('/')
  }

  return (
    <main>
      {submitted ? <SuccessModal subTitle='Submitted.' onClose={handleCloseModal} /> : ''}
      <div className='w-full relative'>
        <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50' />
        <img src={Banner} alt='Leads' className='w-full' />
        <div className='md:w-full w-52 text-base md:max-w-3xl absolute bottom-5 left-7 md:bottom-32 md:left-28 text-white md:text-5xl font-medium '>
          A Few Minutes from You: Kindly help us fill the form
        </div>
      </div>
      <div className='w-central-container mx-auto py-10'>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          <Form>
            <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
              <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
                <Field
                  as={InputField}
                  type='text'
                  name='first_name'
                  label={<label className='text-black text-base'>First name</label>}
                  placeholder='First name'
                  className='form-control bg-white'
                />
                <ErrorMessage name='first_name' component='small' className='text-red-500' />
              </div>
              <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
                <Field
                  as={InputField}
                  type='text'
                  name='last_name'
                  label={<label className='text-black text-base'>Last name</label>}
                  placeholder='Last name'
                  className='form-control bg-white'
                />
                <ErrorMessage name='last_name' component='small' className='text-red-500' />
              </div>
              <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
                <Field
                  as={InputField}
                  type='text'
                  name='phone_number'
                  label={<label className='text-black text-base'>Phone number</label>}
                  placeholder='Phone number'
                  className='form-control bg-white'
                />
                <ErrorMessage name='phone_number' component='small' className='text-red-500' />
              </div>
              <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
                <Field
                  as={InputField}
                  type='text'
                  name='email'
                  label={<label className='text-black text-base'>Email</label>}
                  placeholder='email'
                  className='form-control bg-white'
                />
              </div>
              <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
                <Field
                  as={InputField}
                  type='text'
                  name='business_name'
                  label={<label className='text-black text-base'>Business name</label>}
                  placeholder='Business Name'
                  className='form-control bg-white'
                />
                <ErrorMessage name='business_name' component='small' className='text-red-500' />
              </div>
              <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
                <Field
                  as={InputField}
                  type='text'
                  name='locations'
                  label={
                    <label className='text-black text-base'>Business location (optional)</label>
                  }
                  placeholder='Address'
                  className='form-control bg-white'
                />
              </div>
            </div>
            <div className='flex justify-center items-center py-10'>
              <button type='submit' className='btn-primary text-white bg-primary px-12'>
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </main>
  )
}

export default LeadsForm
