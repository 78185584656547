import React from 'react'
import PropTypes from 'prop-types'

function DistinctiveAspectCard({ iconUrl, title, description }) {
  return (
    <div className='flex flex-col md:flex-row gap-7 md:items-center'>
      <div>
        <img src={iconUrl} alt='aspect card' />
      </div>
      <div className='flex flex-col gap-4'>
        <p className='text-2xl font-bold'>{title}</p>
        <p>{description}</p>
      </div>
    </div>
  )
}

DistinctiveAspectCard.propTypes = {
  iconUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}

export default DistinctiveAspectCard
