import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useState } from 'react'
import { InputField } from '../../../../sharedComponents/input-field'
import { copyToClipboard } from '../../../../utils/helpers'
import PropTypes from 'prop-types'

const LoanDetailsStep = ({ currentDebtAccount }) => {
  const [copied, setCopied] = useState(false)
  const handleCopyLink = () => {
    copyToClipboard(currentDebtAccount?.agreement_document)
    setCopied(true)
  }

  const initialValues = {
    source: currentDebtAccount?.source,
    debt_amount: currentDebtAccount?.debt_amount,
    tenure: currentDebtAccount?.tenure,
    rate: currentDebtAccount?.rate,
    start_date: currentDebtAccount?.start_date,
    end_date: currentDebtAccount?.end_date,
    status: currentDebtAccount?.status,
    agreement_document: currentDebtAccount?.agreement_document
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }
  }, [copied])
  return (
    <main>
      <Formik initialValues={initialValues}>
        <Form>
          <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
              <Field
                as={InputField}
                type='text'
                name='source'
                label={<label className='text-black text-base'>Source</label>}
                placeholder='VFD'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='source' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
              <Field
                as={InputField}
                type='text'
                name='debt_amount'
                label={<label className='text-black text-base'>Debt Amount</label>}
                placeholder='₦1,691.000.56'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='debt_amount' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
              <Field
                as={InputField}
                type='text'
                name='start_date'
                label={<label className='text-black text-base'>Start Date</label>}
                placeholder='12/12/2023'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='start_date' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
              <Field
                as={InputField}
                type='text'
                name='tenure'
                label={<label className='text-black text-base'>Duration</label>}
                placeholder='6 month'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='tenure' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
              <Field
                as={InputField}
                type='text'
                name='end_date'
                label={<label className='text-black text-base'>End Date</label>}
                placeholder='13/12/2023'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='end_date' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 '>
              <Field
                as={InputField}
                type='text'
                name='rate'
                label={<label className='text-black text-base'>Interest Rate</label>}
                placeholder='2.60%'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='rate' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 relative mt-8'>
              <Field
                as={InputField}
                type='text'
                name='status'
                label={<label className='text-black text-base'>Status</label>}
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='status' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 relative md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='agreement_document'
                label={<label className='text-black text-base'>Loan Agreement</label>}
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='agreement_document' component='small' className='text-red-500' />
              <button
                type='button'
                className={`${
                  copied ? 'bg-green-800' : 'bg-blue-800'
                } absolute right-4 top-9 text-white px-4 py-2 mt-2`}
                onClick={handleCopyLink}
              >
                {copied ? 'Copied' : 'Copy Link'}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </main>
  )
}

LoanDetailsStep.propTypes = {
  currentDebtAccount: PropTypes.object.isRequired
}

export default LoanDetailsStep
