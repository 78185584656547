import { DownOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import LoanDetails from './modals/LoanDetails'
import AddDebtModal from './modals/AddDebtModal'
import { adminServices } from '../../../services'
import { formatName, formatNumberToNGNCurrency, maskString } from '../../../utils/helpers'
import PropTypes from 'prop-types'

const FilterDropdown = ({ setFilter }) => {
  const menu = (
    <Menu className=''>
      <Menu.Item key='1' onClick={() => setFilter('1')}>
        All
      </Menu.Item>
      <Menu.Item key='2' onClick={() => setFilter('2')}>
        Active
      </Menu.Item>
      <Menu.Item key='3' onClick={() => setFilter('3')}>
        Repaid
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement='bottomLeft'>
      <span className='flex cursor-pointer'>
        Filter
        <DownOutlined style={{ fontSize: '12px', marginLeft: '5px' }} />
      </span>
    </Dropdown>
  )
}

const VfdMfb = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDebtModalVisible, setIsDebtModalVisible] = useState(false)
  const [loanAnalytic, setLoanAnalytic] = useState({
    total_active: 0.0,
    total_amount: 0.0,
    total_repaid: 0.0
  })
  const [loanAnalyticLoading, setLoanAnalyticLoading] = useState(true)

  const [vdfDebtAccounts, setVdfDebtAccounts] = useState([])
  const [listVdfDebtAccountsLoading, setListVdfDebtAccountsLoading] = useState(true)
  const [vdfLoans, setVdfLoans] = useState([])
  const [listVdfLoansLoading, setVdfLoansLoading] = useState(true)
  const [filter, setFilter] = useState('1')
  const [currentDebtAccount, setCurrentDebtAccount] = useState({})

  const showModal = debtAccountItem => {
    setCurrentDebtAccount(debtAccountItem)
    setIsModalVisible(true)
  }

  const showDebtModal = () => {
    setIsDebtModalVisible(true)
  }
  const handleDebtModalCancel = () => {
    setIsDebtModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const fetchLoanAnalytics = async () => {
    try {
      const { data } = await adminServices.getVdfLoanAnalytics('VFD')
      if (data.success) {
        setLoanAnalytic(data.data)
        setLoanAnalyticLoading(false)
      }
    } catch (err) {
      setLoanAnalyticLoading(false)
    }
  }

  const fetchVdfDebtAccounts = async status => {
    try {
      const { data } = await adminServices.getVdfDebtAccounts(status)
      if (data.success) {
        setVdfDebtAccounts(data.data)
        setListVdfDebtAccountsLoading(false)
      }
    } catch (err) {
      setListVdfDebtAccountsLoading(false)
    }
  }

  const fetchVdfDebtLoans = async () => {
    try {
      const res = await adminServices.getVdfLoans('VFD')
      const data = res?.data
      if (data.success) {
        setVdfLoans(data.data)
        setVdfLoansLoading(false)
      }
    } catch (err) {
      setVdfLoansLoading(false)
    }
  }

  useEffect(() => {
    setListVdfDebtAccountsLoading(true)
    async function fetch() {
      const debtFilter = filter === '2' ? 'ACTIVE' : filter === '3' ? 'REPAID' : null

      if (!debtFilter) {
        await fetchVdfDebtAccounts()
      } else {
        await fetchVdfDebtAccounts(debtFilter)
      }

      // Clear states
      setListVdfDebtAccountsLoading(false)
    }

    fetch()
  }, [filter])

  useEffect(() => {
    async function fetch() {
      await fetchLoanAnalytics()
      await fetchVdfDebtLoans()

      // Clear all states
      setLoanAnalyticLoading(false)
    }

    fetch()
  }, [])
  return (
    <main>
      <div className='flex py-10 gap-8 px-9'>
        <div className='left w-3/12 px-6 py-8 rounded-lg bg-white h-card-group overflow-y-auto'>
          <div className='flex justify-between'>
            <h1 className='text-black text-base font-semibold  leading-normal'>Debt</h1>
            <div className='text-gray-500 text-xs font-normal'>
              <FilterDropdown setFilter={setFilter} />
            </div>
          </div>

          <div className=' px-7 py-4 w-full bg-primary rounded-xl shadow flex-col justify-start items-start gap-3 inline-flex'>
            <div className='text-white text-sm font-semibold leading-normal'>VFD Debt Summary</div>

            {loanAnalyticLoading ? (
              <Spin />
            ) : (
              <div className='self-stretch flex-col justify-start items-start gap-1.5 inline-flex'>
                <div className='justify-center items-center gap-8 inline-flex'>
                  <div className='text-zinc300 text-xs font-light '>Total Amount</div>
                  <div className='text-white text-xs font-bold '>
                    {formatNumberToNGNCurrency(loanAnalytic?.total_amount)}
                  </div>
                </div>
                <div className='justify-start items-start gap-9 inline-flex'>
                  <div className='text-zinc300 text-xs font-light '>Total Repaid</div>
                  <div className='text-white text-xs font-bold '>
                    {formatNumberToNGNCurrency(loanAnalytic?.total_repaid)}
                  </div>
                </div>
                <div className='justify-start items-start gap-10 inline-flex'>
                  <div className='text-zinc300 text-xs font-light '>Total Active</div>
                  <div className='text-white text-xs font-bold '>
                    {formatNumberToNGNCurrency(loanAnalytic?.total_active)}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='w-full mt-8 mb-4 py-1.5 rounded border border-indigo-700 justify-center items-center gap-2.5 inline-flex'>
            <button onClick={showDebtModal} className='text-indigo-700 text-sm font-normal'>
              Add Debt Account
            </button>
          </div>

          {listVdfDebtAccountsLoading ? (
            <div className='flex justify-start px-6'>
              <Spin />
            </div>
          ) : (
            vdfDebtAccounts?.map(debtAccountItem => (
              <div
                className='mt-6 w-full px-7 py-4 bg-white rounded-xl shadow flex-col justify-start items-start gap-3 inline-flex'
                key={debtAccountItem?.id}
              >
                <div className='flex w-full  justify-between items-center'>
                  <div className='text-gray-500 text-sm font-semibold leading-normal'>VFD MFB</div>
                  <span className=''>
                    <EllipsisOutlined onClick={() => showModal(debtAccountItem)} />
                  </span>
                </div>
                <div className='self-stretch flex-col justify-start items-start gap-1.5 inline-flex mb-5'>
                  <div className='justify-center items-center gap-8 inline-flex'>
                    <div className='text-gray-500 text-xs font-light '>Total Amount</div>
                    <div className='text-black text-xs font-semibold '>
                      {formatNumberToNGNCurrency(debtAccountItem?.debt_amount)}
                    </div>
                  </div>
                  <div className='justify-start items-start gap-14 inline-flex'>
                    <div className='text-gray-500 text-xs font-light '>Duration</div>
                    <div className='text-black text-xs font-semibold '>
                      {debtAccountItem?.tenure} Months
                    </div>
                  </div>
                  <div className='justify-start items-start gap-9 inline-flex'>
                    <div className='text-gray-500 text-xs font-light '>Interest rate</div>
                    <div className='text-black text-xs font-semibold '>
                      {debtAccountItem?.rate}%
                    </div>
                  </div>
                  <div className='justify-start items-start gap-16 inline-flex'>
                    <div className='text-gray-500 text-xs font-light '>Status</div>
                    <div className='text-black text-xs font-semibold '>
                      {debtAccountItem?.status || '-'}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className='right w-2/5 px-6 py-8 bg-white h-card-group overflow-y-auto'>
          <h1 className='text-black text-base font-semibold  leading-normal'>Customers</h1>
          {listVdfLoansLoading ? (
            <Spin />
          ) : (
            <div>
              {vdfLoans?.map(loan => (
                <div
                  className='w-full bg-white jus rounded-md my-5 shadow px-4 py-3 justify-between  items-start inline-flex'
                  key={loan?.approved_loan_id}
                >
                  <div className='flex gap-4'>
                    <div className='w-10 h-10 flex justify-center items-center bg-black rounded-full'>
                      <span className='text-white text-xl font-semibold '>
                        {formatName(loan?.approved_loan_user?.user_name)}
                      </span>
                    </div>
                    <div className=''>
                      <div className='text-black text-sm font-medium '>
                        {loan?.approved_loan_user?.business_name}
                      </div>
                      <div>
                        <span className='text-stone-500 text-xs font-light '>Debt ID:</span>
                        <span
                          className='text-zinc-800 text-xs font-semibold '
                          title={loan?.approved_loan_id}
                        >
                          {' '}
                          {maskString(loan?.approved_loan_id)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <span className='text-stone-500 text-xs font-light '>Amount Disbursed: </span>
                    <span className='text-stone-500 text-xs font-bold '>
                      {formatNumberToNGNCurrency(loan?.loan_principal_amount_fixed)}
                    </span>
                  </div>
                </div>
              ))}

              {!vdfLoans?.length ? <div>No entry</div> : ''}
            </div>
          )}
        </div>
      </div>
      <LoanDetails
        visible={isModalVisible}
        onCancel={handleCancel}
        title='VFD Debt Account'
        currentDebtAccount={currentDebtAccount}
      />
      <AddDebtModal
        visible={isDebtModalVisible}
        onCancel={handleDebtModalCancel}
        title='Add Debt Account'
        description='Kindly provide the following Information:'
      />
    </main>
  )
}

FilterDropdown.propTypes = {
  setFilter: PropTypes.func.isRequired
}

export default VfdMfb
