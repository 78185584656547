import React from 'react'
import { Modal, Table } from 'antd'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import {
  calculateAmortization,
  calculateBulletLoanPaymentsWithDetails,
  formatNumberToNGNCurrency,
  removeCurrencyFormatting
} from '../../../../../utils/helpers'
import { exportFile } from '../../../../../utils/export'

const LoanTypeModal = ({ modalVisible, setModalVisible, previewData }) => {
  const amount = removeCurrencyFormatting(previewData.loanAmount)

  let dataSource
  let column
  let totalInterest
  let totalInterestPlusCapital

  switch (previewData.loanType) {
    case 'Bullet Loan':
      dataSource = calculateBulletLoanPaymentsWithDetails({
        loanTermInMonths: Number(previewData.loanDuration),
        principal: amount,
        startDate: previewData.startDate || new Date()
      })
      column = columnsBullet
      totalInterest = dataSource.reduce((prev, curr) => prev + +curr.interestPayment, 0)
      totalInterestPlusCapital = totalInterest + amount
      break
    case 'Amortized Loan':
      dataSource = calculateAmortization({
        loanAmount: amount,
        loanTermMonths: Number(previewData.loanDuration),
        startDate: previewData.startDate || new Date()
      })
      column = columnsAmortized
      totalInterest = dataSource?.reduce((prev, curr) => prev + +curr.interest, 0)
      totalInterestPlusCapital = totalInterest + amount
      break
  }

  return (
    <Modal
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
      title={
        <div className='bg-primary text-white p-2 m-0 inset-0 text-lg'>
          {previewData.loanType} Schedule
        </div>
      }
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      className='preview-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          className='mt-5 text-white text-4xl rounded-full bg-white cursor-pointer'
          color='#b1b1ff'
        />
      }
      width={1000}
      destroyOnClose
    >
      <p className='my-2 text-base'>
        Total Interest:{' '}
        <span className='font-bold'>{formatNumberToNGNCurrency(totalInterest)}</span>
      </p>
      <p className='my-2 text-base'>
        Total Interest Plus Principal:{' '}
        <span className='font-bold'>{formatNumberToNGNCurrency(totalInterestPlusCapital)}</span>
      </p>
      <Table
        columns={column}
        pagination={false}
        dataSource={dataSource}
        rowClassName={(_, idx) => (idx % 2 === 0 ? 'bg-primary bg-opacity-10 text-black' : '')}
      />

      <button
        className='btn-primary border-primary border hover:bg-primary text-primary px-8 py-2 flex w-17 mt-6 mx-auto self-center justify-center hover:text-white'
        onClick={() => exportFile({ data: dataSource, column: column }, '.csv', true)}
      >
        Download
      </button>
    </Modal>
  )
}

export default LoanTypeModal

LoanTypeModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  previewData: PropTypes.object.isRequired
}

const columnsAmortized = [
  {
    title: <span>Month</span>,
    dataIndex: 'month',
    key: 'month'
  },
  {
    title: <span>Payment Date</span>,
    dataIndex: 'paymentDate',
    key: 'paymentDate'
  },
  {
    title: <span> ₦ Payment Installments</span>,
    dataIndex: 'payment',
    key: 'payment'
  },
  {
    title: <span> ₦ Principal</span>,
    dataIndex: 'principal',
    key: 'principal'
  },
  {
    title: <span> ₦ Interest</span>,
    dataIndex: 'interest',
    key: 'interest'
  },
  {
    title: <span> ₦ Balance</span>,
    dataIndex: 'balance',
    key: 'balance'
  }
]
const columnsBullet = [
  {
    title: <span>Month</span>,
    dataIndex: 'month',
    key: 'month'
  },
  {
    title: <span> ₦ Payment Date</span>,
    dataIndex: 'paymentDate',
    key: 'paymentDate'
  },
  {
    title: <span> ₦ Interest Payment</span>,
    dataIndex: 'interestPayment',
    key: 'interestPayment'
  },
  {
    title: <span> ₦ Total Payments</span>,
    dataIndex: 'totalPayments',
    key: 'totalPayments'
  }
]
