import React, { useState, useEffect } from 'react'
import {
  BUSINESS_NAME,
  DRAG_FILES,
  OR,
  BROWSE_FILE,
  PHARMACIST_LICENSE,
  PHARMATICAL_LICENSE_EXPIRY,
  BUSINESS_INFORMATION,
  UPDATE_BUSINESS_INFO
} from '../constants'
import { Progress, Upload, message, notification } from 'antd'
import { FlagFilled } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../redux/store'
import { SUPPORTED_IMAGE_TYPE } from '../../../utils/data'
import { UPLOAD_BASE_URL } from '../../../utils/constants'
import { bizInfoValidator } from '../../../utils/inputValidator'
import { InputField } from '../../../sharedComponents/input-field'

const BusinessInformation = props => {
  const { data } = useSelector(({ auth: { authUser } }) => ({ data: authUser.userInfo?.data }))
  const loading = useSelector(({ loading }) => loading.effects.auth.bizProfileInfoUpdate)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState({})
  const [show, setShow] = useState(false)
  const [user, setUser] = useState({
    businessName: data?.business_name,
    licenseExp: '',
    pharmacistLicense: ''
  })

  const uploadProps = {
    action: `//${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        return message.error('Not supported')
      }
      if (file.size / 1000000 > 35) {
        return message.error('File size more than 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const onChangeBizName = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
    setError({ ...error, [e.target.name]: false })
  }
  const onChangeExpDate = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
    setError({ ...error, [e.target.name]: false })
    setShow(true)
  }

  const handleChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        const ImgUrl = info.file.response.data.url
        if (type === 'pharmacistLicense') {
          setUser({ ...user, pharmacistLicense: ImgUrl })
        }
      }
    }
  }
  const validator = bizInfoValidator(user)

  const handleSubmit = () => {
    if (!user.businessName) {
      return notification.error({
        message: 'An error occured',
        description: 'Business Name is required!'
      })
    }
    dispatch.auth.bizProfileInfoUpdate({
      business_name: user.businessName
    })
  }

  useEffect(() => {
    if (Object.keys(user).length) {
      if (validator.valid) setProgress(100)
    }
  }, [props, user])

  return (
    <div className='profile-form'>
      <div className='mb-10'>
        <span className='cursor-pointer text-xl font-bold border-b-2 border-blue-800'>
          {BUSINESS_INFORMATION}
        </span>
      </div>
      <div className=''>
        <div className='flex flex-wrap items-center mb-4'>
          <div className='w-full md:w-2/5 px-2 mb-6'>
            <label className='text-sm text-gray-900  font-semibold'>{BUSINESS_NAME + ' *'}</label>
            <input
              className='form-control'
              name='businessName'
              type='text'
              onChange={onChangeBizName}
              value={user.businessName}
            />
          </div>
          <div className='w-full md:w-2/5 px-2 mb-6'>
            <label className='text-sm text-gray-900  font-semibold'>
              {PHARMATICAL_LICENSE_EXPIRY + ' *'}
            </label>
            <InputField
              onChange={onChangeExpDate}
              value={user.licenseExp}
              type='date'
              name='licenseExp'
              max={new Date().toISOString()}
              className='form-control'
            />
          </div>

          <div className='cursor'>
            <FlagFilled style={{ fontSize: '50px', color: 'red' }} />
          </div>

          {show && (
            <div className='w-full md:w-2/5 lg:w-2/5 px-4 mt-8'>
              <label className='text-sm text-gray-900  font-semibold'>
                {PHARMACIST_LICENSE + '*'}
              </label>
              <div className='flex w-full px-3 mt-3 border'>
                <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                  {progress <= 0 ? (
                    <div
                      className='text-center px-2'
                      style={{
                        wordWrap: 'break-word',
                        maxWidth: '190px',
                        minWidth: '180px'
                      }}
                    >
                      <Upload.Dragger
                        {...uploadProps}
                        onChange={info => handleChange(info, 'pharmacistLicense')}
                      >
                        <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                        <p className='text-center my-6 font-normal'>{OR} </p>
                        <p className='text-center font-normal text-base text-primary underline'>
                          {BROWSE_FILE}
                        </p>
                      </Upload.Dragger>
                    </div>
                  ) : (
                    <Progress type='circle' percent={progress} />
                  )}
                  {error.pharmacistLicense ? (
                    <small className='text-red-500'>{error.pharmacistLicense}</small>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          )}
          <div className='w-full flex mt-6 mb-8 justify-start'>
            <button className='btn-primary-200' onClick={handleSubmit}>
              {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
              {UPDATE_BUSINESS_INFO}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BusinessInformation
