import React, { useEffect, useState } from 'react'
import { Steps, notification } from 'antd'
import PropTypes from 'prop-types'

import PreviewModal from '../apply-for-loan/New-apply-loan/modal/PreviewModal'
import { DOC_TYPE_STATUS } from '../customer-revamp/constant'
import { handleUploadile } from '../../../utils/helpers'
import { UPLOAD_FILE_TYPES } from '../../../utils/constants'

const { Step } = Steps

const BusinessDocs = ({ handleCustomerInfoButton, handleNext }) => {
  const [fileError, setFileError] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [previewFileUrl, setPreviewFileUrl] = useState('')
  const [uploadedFiles, setUploadedFiles] = useState([])

  const [fileUploadedStatus, setFileUploadedStatus] = useState({
    pharmacist_license: DOC_TYPE_STATUS.NOT_AVAILABLE,
    bank_statement: DOC_TYPE_STATUS.NOT_AVAILABLE,
    optional: DOC_TYPE_STATUS.NOT_AVAILABLE
  })

  const handleFileChange = async event => {
    const file = event.target.files[0]
    const documentType = event.target.name
    const docTypeStatus = fileUploadedStatus[documentType]

    setFileUploadedStatus(prevState => ({
      ...prevState,
      [documentType]: DOC_TYPE_STATUS.UPLOADING
    }))

    try {
      const fileUrl = await handleUploadile(file, UPLOAD_FILE_TYPES.DOCS)
      setFileError(prevState => ({
        ...prevState,
        [documentType]: ''
      }))

      // Set file uploaded to true
      setFileUploadedStatus(prevState => ({
        ...prevState,
        [documentType]: DOC_TYPE_STATUS.UPLOADED
      }))

      const otherFiles = uploadedFiles.filter(file => file.type !== documentType)

      setUploadedFiles([...otherFiles, { type: documentType, fileUrl }])

      setPreviewFileUrl(fileUrl)
    } catch (error) {
      setFileUploadedStatus(prevState => ({
        ...prevState,
        [documentType]: docTypeStatus
      }))

      notification.error({
        message: 'An error occured while uploading file',
        description: `${error?.message}`
      })
    }
  }

  useEffect(() => {
    const localUploadedFiles = window.localStorage.getItem('uploadedFiles')
    const localData = JSON.parse(localUploadedFiles)

    if (localData) {
      Object.keys(fileUploadedStatus).forEach(key => {
        if (localData?.find(file => file.type === key)) {
          setFileUploadedStatus(prev => ({
            ...prev,
            [key]: DOC_TYPE_STATUS.AVAILABLE
          }))
        }
      })
      setUploadedFiles(localData)
    }
  }, [])

  return (
    <div className='md:my-12'>
      <div className='w-1/2'>
        <Steps current={0} className='custom-steps'>
          <Step title='Business Documents' />
          <Step title='Loan Details' disabled />
        </Steps>
      </div>
      <div className=''>
        <div className='my-10'>
          <span className='text-lg font-extrabold flex justify-start'>
            Kindly upload a clear documentbelow. (File size: 30mb max.)
          </span>
          <button onClick={handleCustomerInfoButton} className='text-primary underline '>
            View customer information
          </button>
          {/* Rendering cards here, modifying the corresponding state and props */}
          <div className='grid w-full max-w-5xl md:gap-14 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:mt-7'>
            {/* Start of  Pharmaceutical license */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  Pharmaceutical license
                </h2>
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {(fileUploadedStatus.pharmacist_license === DOC_TYPE_STATUS.AVAILABLE ||
                  fileUploadedStatus.pharmacist_license === DOC_TYPE_STATUS.UPLOADED) && (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(
                            uploadedFiles.find(item => item.type === 'pharmacist_license')?.fileUrl
                          )
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='pharmacist_license'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                )}

                {fileUploadedStatus.pharmacist_license === DOC_TYPE_STATUS.UPLOADING && (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}

                {fileUploadedStatus.pharmacist_license === DOC_TYPE_STATUS.NOT_AVAILABLE && (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='pharmacist_license'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                )}

                <input
                  type='file'
                  name='pharmacist_license'
                  id='pharmacist_license'
                  className='hidden'
                  onChange={event => handleFileChange(event)}
                />
              </div>
              {fileError.pharmacist_license && (
                <small className='text-red-500'>{fileError.pharmacist_license}</small>
              )}
            </div>

            {/* Start of 6 Month Bank Statement */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  6 Month Bank Statement
                </h2>
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {(fileUploadedStatus.bank_statement === DOC_TYPE_STATUS.AVAILABLE ||
                  fileUploadedStatus.bank_statement === DOC_TYPE_STATUS.UPLOADED) && (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(
                            uploadedFiles.find(item => item.type === 'bank_statement')?.fileUrl
                          )
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='bank_statement'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                )}

                {fileUploadedStatus.bank_statement === DOC_TYPE_STATUS.UPLOADING && (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}

                {fileUploadedStatus.bank_statement === DOC_TYPE_STATUS.NOT_AVAILABLE && (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='bank_statement'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                )}

                <input
                  type='file'
                  name='bank_statement'
                  id='bank_statement'
                  className='hidden'
                  onChange={event => handleFileChange(event)}
                />
              </div>
              {fileError.bank_statement && (
                <small className='text-red-500'>{fileError.bank_statement}</small>
              )}
            </div>

            {/* Other Documents (Optional) */}
            <div className='w-full'>
              <div className='flex gap-4'>
                <h2 className='text-base tracking-tight font-medium mb-4'>
                  Other Documents (Optional)
                </h2>
              </div>
              <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
                {(fileUploadedStatus.optional === DOC_TYPE_STATUS.AVAILABLE ||
                  fileUploadedStatus.optional === DOC_TYPE_STATUS.UPLOADED) && (
                  <>
                    <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
                    <p className='mb-2'>
                      <a
                        href='#'
                        onClick={() => {
                          setModalVisible(true)
                          setPreviewFileUrl(
                            uploadedFiles.find(item => item.type === 'optional')?.fileUrl
                          )
                        }}
                        className='text-blue-500'
                      >
                        View
                      </a>
                    </p>
                    <label
                      htmlFor='optional'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Change file
                    </label>
                  </>
                )}

                {fileUploadedStatus.optional === DOC_TYPE_STATUS.UPLOADING && (
                  <p className='mb-2 text-center'>Uploading...</p>
                )}

                {fileUploadedStatus.optional === DOC_TYPE_STATUS.NOT_AVAILABLE && (
                  <>
                    <p className='mb-2 text-center'>Drag and drop your document</p>
                    <p className='text-gray-500 text-sm mt-2'>OR</p>
                    <label
                      htmlFor='optional'
                      className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
                    >
                      Browse file
                    </label>
                  </>
                )}

                <input
                  type='file'
                  name='optional'
                  id='optional'
                  className='hidden'
                  onChange={event => handleFileChange(event)}
                />
              </div>
              {fileError.optional && <small className='text-red-500'>{fileError.optional}</small>}
            </div>
          </div>
        </div>
      </div>

      {modalVisible && (
        <PreviewModal
          modalVisible={modalVisible}
          previewFileUrl={previewFileUrl}
          setModalVisible={setModalVisible}
        />
      )}

      <button
        className='text-white text-lg bg-primary-200 rounded py-2 px-5 absolute bottom-0 mb-4 mr-4'
        onClick={() => handleNext(uploadedFiles, 1)}
      >
        Next
      </button>
    </div>
  )
}

BusinessDocs.propTypes = {
  handleCustomerInfoButton: PropTypes.func,
  handleNext: PropTypes.func
}

export default BusinessDocs
