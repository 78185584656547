/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Icon } from '@iconify/react'

const bodyStyle = {
  padding: 0,
  maxHeight: 700,
  overflowY: 'auto'
}

const BvnCheckSuccessModal = ({ visible, onCancel, selectedUsername, bankData }) => {
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className='info-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          color='#b1b1ff'
          className='mt-4 mr-8 text-white text-4xl rounded-full bg-white'
        />
      }
      bodyStyle={bodyStyle}
      width={800}
    >
      <div className='modal-header bg-primary px-6 flex justify-between pb-4'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>
          Bank accounts associated with {selectedUsername}&apos;s BVN
        </h2>
      </div>
      <div className='px-4 pb-8 mt-8'>
        <div className='flex flex-col gap-8 justify-center px-8'>
          {bankData.map(bank => (
            <div key={bank?.accountnumber}>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <p className='font-bold'>Account Designation Name </p>
                  <p>{bank?.AccountDesignationName}</p>
                </div>
                <div>
                  <p className='font-bold'>Account Type Name </p>
                  <p>{bank?.AccountTypeName}</p>
                </div>
                <div>
                  <p className='font-bold'>Account Name </p>
                  <p>{bank?.accountname}</p>
                </div>

                <div>
                  <p className='font-bold'>Bank Name </p>
                  <p>{bank?.BankName}</p>
                </div>

                <div>
                  <p className='font-bold'>Account Number </p>
                  <p>{bank?.accountnumber}</p>
                </div>
              </div>

              <hr className='my-4' />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

BvnCheckSuccessModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedUsername: PropTypes.string,
  bankData: PropTypes.array.isRequired
}

export default BvnCheckSuccessModal
