import { notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../../redux/store'
import { adminServices } from '../../../services'
import { FormSelect } from '../../../sharedComponents/form-select'
import { UPDATE_ADMIN, ADMIN, ROLE } from './constants'

const UpdateAdmin = () => {
  const requestObject = {
    role_id: '',
    admin_id: ''
  }
  const [user, setUser] = useState(requestObject)

  const [loading, setLoading] = useState(false)
  const { allRoles } = useSelector(({ roles }) => roles)
  const existingAdmins = useSelector(select.users.getUsersIsAdmin)

  const onChangeText = ({ target }) => {
    setUser({ ...user, [target.name]: target.value })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const { data } = await adminServices.updateAdmin(user)

      if (data.success) {
        notification.success({
          message: 'Thanks',
          description: data.message
        })
        setUser(requestObject)
      }
      dispatch.users.getAllusers({ shouldUseCache: false, showAll: true })

      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Something went wrong'
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (allRoles.length <= 0) {
      dispatch.roles.getAllRoles()
    }
    dispatch.users.getAllusers({ shouldUseCache: false, showAll: true })
  }, [])

  return (
    <main className='w-full flex-grow p-4 bg-white shadow-lg'>
      <div className='mt-4'>
        <h3 className='font-bold text-lg'>{UPDATE_ADMIN}</h3>
      </div>

      <div className='mt-8'>
        <div className='w-full md:w-8/12 px-2 mb-6'>
          <span className='text-sm text-gray-900  font-semibold'>{ADMIN}</span>
          <FormSelect
            options={existingAdmins.map(({ first_name: firstName, last_name: lastName, id }) => ({
              name: `${firstName + ' ' + lastName}`,
              value: id
            }))}
            className='form-control'
            value={user.admin_id}
            onChange={onChangeText}
            label='Select Admin'
            name='admin_id'
          />
        </div>
        <div className='w-full md:w-8/12 px-2 mb-6 m-0'>
          <span className='text-sm text-gray-900 font-semibold'>{ROLE}</span>
          <FormSelect
            options={allRoles.map(({ role_id: roleId, role_title: roleTitle }) => ({
              name: roleTitle,
              value: roleId
            }))}
            name='role_id'
            value={user.role_id}
            onChange={onChangeText}
            label='Select Roles'
            className='form-control'
          />
        </div>
        <div>
          <button
            onClick={handleSubmit}
            className='bg-primary text-base mt-6 py-3 px-4 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
          >
            {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
            {UPDATE_ADMIN}
          </button>
        </div>
      </div>
    </main>
  )
}
export default UpdateAdmin
