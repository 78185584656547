import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import ListDocs from './ListDocs'
import UploadDoc from './UploadDoc'

const DocumentsPage = () => {
  const history = useHistory()
  const { state } = useLocation()

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          {state?.user?.id ? (
            <button
              onClick={() => history.push('/admin-upload-docs')}
              className='font-bold text-lg cursor-pointer bg-primary text-white p-2 rounded-md my-3'
            >
              <i className='fas fa-arrow-left mr-3 ' />
              Back to select customers
            </button>
          ) : (
            ''
          )}
        </div>
        <ListDocs customerId={state?.user?.id} />
        <UploadDoc customerId={state?.user?.id} />
      </main>
    </DashboardLayout>
  )
}

export default DocumentsPage
