import React from 'react'
import DistinctiveAspectCard from './cards/DistinctiveAspectCard'
import ICON_D1 from '../../images/landing/d_1.png'
import ICON_D2 from '../../images/landing/d_2.png'
import ICON_D3 from '../../images/landing/d_3.png'
import ICON_D4 from '../../images/landing/d_4.png'
import ICON_D5 from '../../images/landing/d_5.png'

function DistinctiveAspectSection() {
  const data = [
    {
      iconUrl: ICON_D1,
      title: 'Direct Supplier Payment',
      description: 'Loan value directly remitted to equipment supplier.'
    },
    {
      iconUrl: ICON_D2,
      title: 'Collateral-Free',
      description: 'No additional assets required as security.'
    },
    {
      iconUrl: ICON_D3,
      title: 'Flexible Repayment',
      description: 'Fixed repayment schedule with monthly principal and interest payments.'
    },
    {
      iconUrl: ICON_D4,
      title: 'USD Convenience',
      description: 'Loans and repayments in US dollars for ease of transactions.'
    },
    {
      iconUrl: ICON_D5,
      title: 'Strategic Growth',
      description: 'Ideal for clinicians with proven revenue history and growth plans.'
    }
  ]
  return (
    <section>
      <div className='mb-67px'>
        <p className='font-bold text-3xl md:text-4xl text-center'>Distinctive Aspects</p>
      </div>
      <div className='flex justify-center w-full'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-16'>
          {data.slice(0, 4).map((aspect, index) => (
            <DistinctiveAspectCard {...aspect} key={index} />
          ))}
        </div>
      </div>

      <div className='flex justify-center mt-67px'>
        <DistinctiveAspectCard {...data[4]} />
      </div>
    </section>
  )
}

export default DistinctiveAspectSection
