export const validateReminder = values => {
  let valid = true
  let error = ''
  for (const key in values) {
    if (values[key].trim().length === 0) {
      valid = false
      error = `${key} cannot be empty!`
      break
    }
  }
  return { valid, error }
}
