import { Button, message, notification, Spin, Upload } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { UPLOAD_BASE_URL } from '../../utils/constants'
import { SUPPORTED_IMAGE_TYPE } from '../../utils/data'
import { dispatch } from '../../redux/store'

const UploadDoc = ({ customerId }) => {
  const [formValue, setFormValue] = useState([])
  const [addBtnDiabled, setDisableAddBtn] = useState(false)
  const [removing, setRemoving] = useState(false)

  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))

  const uploadProps = {
    action: `//${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        message.error('Not supported')
        return false
      }
      if (file.size / 1000000 > 35) {
        message.error('File size more than 30mb')
        return false
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const { token, permissions } = useSelector(({ auth: authUser }) => authUser.authUser)
  const loading = useSelector(({ loading }) => loading.effects.applications.customerUploadDocument)

  const handleChange = useCallback(
    (info, selectedItem) => {
      if (info?.file && info?.file.status === 'done') {
        if (info.file.response.success) {
          const imgUrl = info.file.response.data.url
          let uploadObj = {}
          const currentItemInForm = formValue.find(item => item?.id === selectedItem?.id)
          if (currentItemInForm) {
            uploadObj = { ...currentItemInForm, file_url: imgUrl }
          } else {
            uploadObj = { ...selectedItem, file_url: imgUrl }
          }
          const updatedForm = formValue.filter(item => item.id !== uploadObj.id)
          setFormValue([...updatedForm, { ...uploadObj, title: info.file.name || 'N/A' }])

          const docsNotFilled = updatedForm.filter(
            item => !item.type || !item.file_url || !item.title
          )
          if (formValue.length > 0 && docsNotFilled.length > 0) {
            setDisableAddBtn(true)
          } else {
            setDisableAddBtn(false)
          }
        }
      } else {
        const updatedForm = formValue.filter(item => item.id !== selectedItem.id)
        setFormValue([...updatedForm, { ...selectedItem }])

        const docsNotFilled = updatedForm.filter(
          item => !item.type || !item.file_url || !item.title
        )
        if (formValue.length > 0 && docsNotFilled.length > 0) {
          setDisableAddBtn(true)
        } else {
          setDisableAddBtn(false)
        }
      }
    },
    [formValue]
  )

  const handleSubmit = async () => {
    const docsNotFilled = formValue.filter(item => !item.type || !item.file_url || !item.title)

    if (formValue.length >= 1 && !docsNotFilled.length) {
      if (!isAdmin) {
        token.length &&
          dispatch.applications.customerUploadDocument({ token, permissions, formValue })
      } else {
        dispatch.applications.adminUploadDocument(
          formValue.map(item => ({ ...item, customer_id: customerId }))
        )
      }
      setFormValue([])
    } else {
      notification.error({
        message: 'Please fill all fields',
        description: 'All fields are required to upload your document(s)'
      })
    }
  }

  const addFormFields = useCallback(() => {
    const docsNotFilled = formValue.filter(item => !item.type || !item.file_url || !item.title)
    if (formValue.length > 0 && docsNotFilled.length > 0) {
      setDisableAddBtn(true)
    } else {
      setDisableAddBtn(false)
      setFormValue([...formValue, { type: '', id: uuidv4(), title: '', file_url: '' }])
    }
  }, [formValue])

  const removeFormFields = id => {
    setRemoving(true)
    const updatedForm = formValue.filter(item => item.id !== id)
    setFormValue(updatedForm)

    const docsNotFilled = updatedForm.filter(item => !item.type || !item.file_url || !item.title)
    if (formValue.length > 0 && docsNotFilled.length > 0) {
      setDisableAddBtn(true)
    } else {
      setDisableAddBtn(false)
    }
    setTimeout(() => {
      setRemoving(false)
    }, 500)
  }

  useEffect(() => {
    const docsNotFilled = formValue.filter(item => !item.type || !item.file_url || !item.title)
    if (formValue.length > 0 && docsNotFilled.length > 0) {
      setDisableAddBtn(true)
    } else {
      setDisableAddBtn(false)
    }
  }, [formValue])

  return (
    <div className='w-full border p-5 shadow-md rounded-md'>
      <div className='text-xl font-bold'>Upload New Documents</div>
      <div className='text-base mt-3 text-blue-700'>
        Click the &#34;<span className='text-2xl font-bold'>+</span>&#34; button below to start
        adding new documents
      </div>
      <div className='flex flex-wrap py-8'>
        {removing ? (
          <Spin />
        ) : (
          formValue?.map((item, index) => (
            <div key={index} className='w-full md:w-1/2 lg:w-1/3 px-4'>
              <div className='w-full p-3 border'>
                <div
                  className='text-center px-2'
                  style={{
                    wordWrap: 'break-word',
                    maxWidth: '590px',
                    minWidth: '180px'
                  }}
                >
                  <select
                    disabled={Boolean(item.type)}
                    value={item.type}
                    onChange={({ target }) =>
                      handleChange(null, {
                        ...item,
                        type: target.value
                      })
                    }
                    className='py-2 mb-3 px-3 w-full'
                  >
                    <option className='text-center' value='' disabled selected>
                      SELECT DOCUMENT TYPE
                    </option>
                    <option className='text-center' value='moa'>
                      MEMORANDUM OF ASSOCIATION
                    </option>
                    <option className='text-center' value='aoa'>
                      ARTICLES OF ASSOCIATION
                    </option>
                    <option className='text-center' value='bank_statement'>
                      BANKS STATEMENT
                    </option>
                    <option className='text-center' value='pharmacist_license'>
                      PHARMACIST LICENSE
                    </option>
                    <option className='text-center' value='business_licenses'>
                      BUSINESS LICENSES
                    </option>
                    <option className='text-center' value='cac_2'>
                      CAC 2
                    </option>
                    <option className='text-center' value='cac_3'>
                      CAC 3
                    </option>
                    <option className='text-center' value='cac_7'>
                      CAC 7
                    </option>
                  </select>
                  <div className='flex w-full mb-3'>
                    {item.file_url ? (
                      <p className='text-center mx-auto text-green-500'>{item.title}</p>
                    ) : (
                      <Upload
                        style={{
                          wordWrap: 'break-word',
                          maxWidth: '190px',
                          minWidth: '180px'
                        }}
                        className='mx-auto'
                        {...uploadProps}
                        progress={{
                          success: { strokeColor: 'blue' }
                        }}
                        onChange={info => handleChange(info, item)}
                      >
                        <Button icon={<i className='fa fa-upload mr-2' />}>Click to Upload</Button>
                      </Upload>
                    )}
                  </div>
                  <button
                    type='button'
                    className='px-5 py-2 bg-red-600 text-gray-100 rounded font-bold'
                    onClick={() => removeFormFields(item.id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
              {addBtnDiabled && (!item.file_url || !item.type) ? (
                <p className='text-red-600 text-center'>Please fill this before adding another</p>
              ) : (
                ''
              )}
            </div>
          ))
        )}
        <div className='my-auto px-4 pt-6'>
          <button
            className='px-5 py-2 bg-green-600 text-gray-100 rounded font-bold'
            type='button'
            title={`${addBtnDiabled ? 'Please finished the previous docs on the left' : ''}`}
            disabled={addBtnDiabled}
            onClick={() => addFormFields()}
          >
            <i className='fa fa-plus' />
          </button>
        </div>
        <div className='w-full flex justify-center mt-2'>
          <button className='btn-primary text-white bg-blue-700' onClick={handleSubmit}>
            {loading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />} SAVE
          </button>
        </div>
      </div>
    </div>
  )
}

export default UploadDoc

UploadDoc.propTypes = {
  customerId: PropTypes.string
}
