import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Icon } from '@iconify/react'
import TransferNav from '../TransferNav'
import OtpModal from './OtpModal'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}

const TransferModal = ({ visible, onCancel, title, setRefreshKey }) => {
  const [showOtp, setShowOtp] = useState(false)
  const handleCancel = () => {
    onCancel()
  }

  return (
    <>
      <Modal
        open={visible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        centered
        className='info-modal'
        closeIcon={
          <Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />
        }
        bodyStyle={bodyStyle}
        width={800}
      >
        <div className='modal-header bg-primary px-6 py-2 flex justify-between'>
          <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>{title}</h2>
        </div>
        <div className=' px-8'>
          <div>
            <TransferNav cancelTransferModal={handleCancel} setRefreshKey={setRefreshKey} />
          </div>
        </div>
      </Modal>

      <OtpModal
        visible={showOtp}
        onCancel={() => setShowOtp(false)}
        title='OTP'
        description='Enter the OTP sent to you Via SMS'
      />
    </>
  )
}

TransferModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setRefreshKey: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default TransferModal
