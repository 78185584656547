const PERSONAL_INFORMATION = 'Personal Information'
const SECURITY_INFORMATION = 'Security Information'
const BUSINESS_INFORMATION = 'Business Information'
const NEW_PASSWORD = 'New Password'
const CURRENT_PASSWORD = 'Current Password'
const CONFIRM_PASSWORD = 'Confirm Password'
const UPDATE_CHANGES = 'Update Changes'
const CANCEL = 'Cancel'
const FIRST_NAME = 'First Name'
const LAST_NAME = 'Last Name'
const USERNAME = 'Username'
const EMAIL = 'Email Address'
const PHONE_NO_ONE = 'Phone Number 1'
const PHONE_NO_TWO = 'Phone Number 2'
const UPDATE_IMAGE = 'Update Image'
const SELECT_IMAGE = 'Select Image'
const BUSINESS_NAME = 'Business Name'
const BANK_STATEMENT_UPDATE = 'Bank Statement Update'
const DRAG_FILES = 'Drag File'
const OR = 'Or'
const BROWSE_FILE = 'Browse File'
const PHARMACIST_LICENSE = 'Pharmacist/Medical License'
const PHARMATICAL_LICENSE_EXPIRY = 'Pharmatical License Expiring Date'
const DISTRIBUTORS = 'Distributors'
const USER_PROFILE_UPDATE_TAB = 'profile-0'
const USER_PASSWORD_UPDATE_TAB = 'profile-1'
const USER_PIN_UPDATE_TAB = 'profile-2'
const NEW_PIN = 'New Pin'
const OLD_PIN = 'Old Pin'
const CONFIRM_NEW_PIN = 'Confirm New Pin'
const CREATE_PIN = 'Create Pin'
const CHANGE_PIN = 'Change Pin'
const UPDATE_BUSINESS_INFO = 'Update Business Information'

export {
  PHARMACIST_LICENSE,
  DISTRIBUTORS,
  UPDATE_IMAGE,
  SELECT_IMAGE,
  NEW_PASSWORD,
  CURRENT_PASSWORD,
  CONFIRM_PASSWORD,
  UPDATE_CHANGES,
  CANCEL,
  FIRST_NAME,
  LAST_NAME,
  USERNAME,
  EMAIL,
  PHONE_NO_ONE,
  PHONE_NO_TWO,
  PERSONAL_INFORMATION,
  SECURITY_INFORMATION,
  BUSINESS_NAME,
  BANK_STATEMENT_UPDATE,
  DRAG_FILES,
  OR,
  BROWSE_FILE,
  PHARMATICAL_LICENSE_EXPIRY,
  BUSINESS_INFORMATION,
  USER_PROFILE_UPDATE_TAB,
  USER_PASSWORD_UPDATE_TAB,
  USER_PIN_UPDATE_TAB,
  NEW_PIN,
  CONFIRM_NEW_PIN,
  CREATE_PIN,
  CHANGE_PIN,
  OLD_PIN,
  UPDATE_BUSINESS_INFO
}
