import ApiHandler from './ApiHandler'

export default {
  admin_add_distributors: data => ApiHandler.post('admin/add_distributor', data),
  admin_update_distributors: data => ApiHandler.post('admin/update_distributor', data),
  admin_delete_distributors: key => ApiHandler.delete(`admin/delete_distributor/${key}`),
  adminGetExistingDistributors: (shouldUseCache = true) =>
    ApiHandler.get(
      'admin/distributors',
      {},
      { 'Cache-Control': shouldUseCache ? null : 'no-cache' }
    ),
  customerGetExistingDistributors: () => ApiHandler.get('customer/distributors')
}
