import { notification } from 'antd'
import { Directors } from '../../services'
import { reducerActions } from '../reducer'

const initialState = {
  adminGetCustomerDirectors: []
}

export const directors = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async customerAddDirector(payload) {
      const { details, endpoint } = payload
      dispatch.directors.setError(null)

      try {
        const { data } = await Directors.customer_add_director(details, endpoint)
        if (data.success) {
          notification.success({
            message: data.message || 'Director added successfully'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while adding Director'
          })
        }

        return data
      } catch (error) {
        dispatch.directors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async customerUpdateDirector(payload) {
      const { token, permissions, details, endpoint } = payload
      dispatch.directors.setError(null)

      try {
        const { data } = await Directors.customer_update_director(details, endpoint)
        if (data.success) {
          notification.success({
            message: data.message || 'Director updated successfully'
          })

          if (!endpoint.includes('admin')) {
            await dispatch.auth.fetchUserInfo({ token, permissions })
          }
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while updating Director'
          })
        }
      } catch (error) {
        dispatch.directors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async customerDeleteDirector(payload) {
      const { token, permissions, id, isAdmin } = payload
      dispatch.directors.setError(null)

      try {
        const { data } = await Directors.customer_delete_director(id)
        if (data.success) {
          notification.success({
            message: data.message || 'Director deleted successfully'
          })
          if (!isAdmin) {
            await dispatch.auth.fetchUserInfo({ token, permissions })
          }
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while deleting Director'
          })
        }
      } catch (error) {
        dispatch.directors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async resendConfirmationEmail(payload) {
      const { email } = payload
      dispatch.directors.setError(null)

      try {
        const { data } = await Directors.resendConfirmationEmail({ email })
        if (data.success) {
          notification.success({
            message: data.message || 'Email sent successfully'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured'
          })
        }
      } catch (error) {
        dispatch.directors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async sendEmailConfirmation(payload) {
      const { email } = payload
      dispatch.directors.setError(null)

      try {
        const { data } = await Directors.sendEmailConfirmation({ email })
        if (data.success) {
          notification.success({
            message: data.message || 'Email sent successfully'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured'
          })
        }
      } catch (error) {
        dispatch.directors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async confirmDirectors(payload) {
      dispatch.directors.setError(null)

      try {
        const { data } = await Directors.confirmDirectors({ id: payload.id, token: payload.token })
        const { success, message } = data
        if (!success) {
          throw new Error(message || 'Could not complete action. Please try again!')
        }
        notification.info({
          message: message || 'Account confirmed successfully!'
        })
        if (success) {
          payload.history.push('/login')
        }
      } catch (error) {
        dispatch.directors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    }
  })
}
