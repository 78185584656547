/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Select, Spin, notification } from 'antd'
import { dispatch, select } from '../../../../redux/store'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'
import { getLocalStorageLoanApplication } from '../../../../utils/helpers'
import { applyLoanlocalStorageKey, steps } from '../../apply-for-loan/constants'

const SearchModal = ({ isOpen, onClose }) => {
  const history = useHistory()
  const [existingLoan, setExistingLoan] = useState(null)
  const [user, setUser] = useState({
    email: '',
    phone_number: '',
    id: '',
    is_admin: '',
    first_name: '',
    last_name: '',
    business_name: ''
  })
  const exitingUsers = useSelector(select.users.getUsersNotAdmin)
  const isUserLoading = useSelector(({ loading }) => loading.effects.users.getAllusers)

  const onChangeText = value => {
    setUser(exitingUsers.find(user => value === user.id))
  }

  const onSelect = value => {
    setUser(exitingUsers.find(user => value === user.id))
  }

  const handleSubmit = () => {
    if (!user?.email) {
      return notification.error({
        message: 'An error occured',
        description: 'Please Select User!'
      })
    } else {
      onClose()
      history.push(`/apply-for-loan/${user.id}`, { user })
    }
  }

  const clearCurrentLaonApplication = () => {
    window.localStorage.removeItem(applyLoanlocalStorageKey)
    setExistingLoan(null)
  }

  useEffect(() => {
    if (existingLoan && exitingUsers.length > 0) {
      setUser(exitingUsers.find(user => existingLoan[steps[1]]?.userId === user.id))
    }
  }, [exitingUsers, existingLoan])

  useEffect(() => {
    dispatch.users.getAllusers({
      showAll: true
    })

    const currentLoanApplication = getLocalStorageLoanApplication()
    if (currentLoanApplication) {
      setExistingLoan(currentLoanApplication)
    }
  }, [])

  return (
    <div className={`fixed inset-0 z-30 ${isOpen ? 'block' : 'hidden'}`}>
      <div className='flex items-center  justify-center h-screen bg-black bg-opacity-50'>
        <div className='bg-white w-full max-w-xl'>
          <div className='bg-primary w-full flex justify-between px-3 py-5'>
            <h2 className='text-white font-bold tracking-tighter text-2xl'>Application</h2>
            <span>
              <Icon
                onClick={onClose}
                icon='iconamoon:close-thin'
                className='text-white text-4xl rounded-full bg-white cursor-pointer'
                color='#b1b1ff'
              />
            </span>
          </div>
          {!existingLoan ? (
            <>
              <div className='px-8 py-5'>
                <h2 className='text-xl font-semibold'>Apply for Customer</h2>
              </div>
              <h2 className='px-8 text-base'>Enter customer Name</h2>
              <div className='px-8 flex flex-col'>
                <div className='py-5'>
                  <Select
                    showSearch
                    className='form-control border-0 mr-0 py-0 px-0 mt-0 w-full max-w-sm'
                    placeholder=''
                    optionFilterProp='children'
                    onChange={onChangeText}
                    onSelect={onSelect}
                    defaultOpen
                    filterOption={(input, option) =>
                      option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                    }
                  >
                    {exitingUsers.map(({ first_name, last_name, business_name, id }) => {
                      const name = business_name
                        ? `${business_name + ' - ' + first_name + ' ' + last_name}`
                        : `${first_name + ' ' + last_name}`
                      return (
                        <Select.Option key={id} value={id}>
                          {name}
                        </Select.Option>
                      )
                    })}
                    {isUserLoading && (
                      <Select.Option key='' value='' disabled>
                        <Spin /> Please wait
                      </Select.Option>
                    )}
                  </Select>
                </div>
                <div className='py-8'>
                  <button
                    className='px-6 py-2 bg-primary text-white rounded-md'
                    disabled={isUserLoading || !user?.email}
                    onClick={handleSubmit}
                  >
                    Continue
                  </button>
                </div>
              </div>{' '}
            </>
          ) : (
            <div className='p-4'>
              <h3 className='text-black text-lg'>
                You have a loan application in progress for {existingLoan?.[steps[1]]?.bizName}
              </h3>
              <p className='text-black w-3/4'>
                Do you want to continue or start a fresh application for another customer
              </p>
              <div className='flex justify-between w-1/2 mt-10 p-2'>
                <button onClick={handleSubmit} className='btn-primary'>
                  Continue{isUserLoading && <Spin size='small' />}
                </button>
                <button
                  onClick={clearCurrentLaonApplication}
                  className='hover:bg-primary hover:bg-opacity-10 px-2 rounded-md text-primary text-lg'
                >
                  Start Afresh
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

SearchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default SearchModal
