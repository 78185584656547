import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getQueryParams } from '../../utils/customeReactHook'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { dispatch } from '../../redux/store'
import Loading from 'react-loading'
import errorEmoji from '../../images/error/error-emoji.gif'
import { Link } from 'react-router-dom'

const ConfirmDirectorByLink = () => {
  const history = useHistory()
  const query = getQueryParams()
  const token = query.get('token')
  const id = query.get('id')

  const loading = useSelector(({ loading }) => loading.effects.auth.confirmSignUp)

  useEffect(() => {
    if (!token || !id) {
      history.push('/login')
    } else {
      dispatch.directors.confirmDirectors({
        history,
        token,
        id
      })
    }
  }, [token, id])

  return (
    <div className='flex justify-items-center justify-center pt-40'>
      {loading ? (
        <Loading color='blue' type='bars' width={500} />
      ) : (
        <div className='px-5'>
          <h1>
            <img className='mx-auto' src={errorEmoji} alt='error emoji' />
          </h1>
          <h1 className='text-xl text-center mt-5'>
            <span className='md:text-2xl'>Oops! </span> sorry, this token has expired or something
            went wrong from our end{' '}
          </h1>
          <p className='text-center text-lg'>
            Please try logging in again <Link to='/login'> Login</Link>
          </p>
        </div>
      )}
    </div>
  )
}

export default ConfirmDirectorByLink
