/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import {
  ADD_INTEREST_PAYMENT,
  PAYMENT_MONTH,
  LOAN_MESSAGE,
  PAY_AMOUNT,
  PAYMENT_DATE,
  PAYMENT_DESCRIPTION,
  ADD_PENALTY_PAYMENT,
  ADD_PRINCIPAL_PAYMENT,
  DEFAULTED_MONTH
} from './constant'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { addLoanPaymentValidator } from '../../utils/inputValidator'
import { calculateRateInPercent } from '../../utils/helpers'
import FormSelect from '../form-select/FormSelect'
import { Months } from '../../utils/constants'

const AdminAddPaymentModal = ({ type, buttonText, data, disabled }) => {
  const interestLoading = useSelector(
    ({ loading }) => loading.effects.payments.adminAddInterestPayment
  )

  const principalLoading = useSelector(
    ({ loading }) => loading.effects.payments.adminAddPrincipalPayment
  )

  const penaltyLoading = useSelector(
    ({ loading }) => loading.effects.payments.adminAddPenaltyPayment
  )

  const [interest, setInterest] = useState(false)
  const [visible, setModal] = useState(false)

  const [loan, setLoan] = useState({
    loan_id: data.approved_loan_id,
    pay_amount: '',
    payment_month: '',
    payment_date: '',
    description: '',
    defaulted_month: ''
  })

  useEffect(() => {
    setInterest(calculateRateInPercent(data?.loan_interest_rate, data?.loan_principal_amount_fixed))
    setLoan({
      ...loan,
      loan_id: data.approved_loan_id
    })
  }, [data])

  const submitFormHandle = () => {
    const info =
      type === 'addPenaltyPayment'
        ? {
            // Payload for Penalty payment
            loan_id: String(data.approved_loan_id),
            pay_amount: loan.pay_amount,
            defaulted_month:
              loan.defaulted_month.charAt(0).toUpperCase() + loan.defaulted_month.slice(1),
            payment_date: loan.payment_date
              ? new Date(loan?.payment_date).toISOString().slice(0, 10)
              : '',
            description: String(loan.description)
          }
        : {
            // Payload for other Interest and Principal Payment
            loan_id: String(data.approved_loan_id),
            pay_amount: type === 'addInterestPayment' ? String(interest) : loan.pay_amount,
            payment_month: loan.payment_month.charAt(0).toUpperCase() + loan.payment_month.slice(1),
            payment_date: loan.payment_date
              ? new Date(loan?.payment_date).toISOString().slice(0, 10)
              : '',
            description: String(loan.description)
          }

    if (type === 'addInterestPayment') {
      const validator = addLoanPaymentValidator(info)
      if (!validator.isValid) {
        notification.error({
          message: 'An error occured',
          description: validator.error
        })
        return false
      }

      dispatch.payments.adminAddInterestPayment(info)
    }
    if (type === 'addPrincipalPayment') {
      const validator = addLoanPaymentValidator(info)
      if (!validator.isValid) {
        notification.error({
          message: 'An error occured',
          description: validator.error
        })
        return false
      }
      if (Number(info.pay_amount) > Number(data.loan_principal_amount)) {
        notification.error({
          message: 'Cannot pay higher than remaining Principal'
        })
      } else {
        dispatch.payments.adminAddPrincipalPayment(info)
      }
    }
    if (type === 'addPenaltyPayment') {
      const validator = addLoanPaymentValidator(info)
      if (!validator.isValid) {
        notification.error({
          message: 'An error occured',
          description: validator.error
        })
        return false
      }
      if (Number(info.pay_amount) > Number(data.loan_principal_amount)) {
        notification.error({
          message: 'Cannot pay higher than remaining Principal'
        })
      } else {
        dispatch.payments.adminAddPenaltyPayment(info)
      }
    }
  }

  return (
    <>
      <span
        onClick={() => (disabled ? null : setModal(true))}
        className='text-gray-800 hover:text-blue-600'
      >
        {buttonText}
      </span>
      <Modal open={visible} onCancel={() => setModal(false)} footer={false}>
        <div className='flex flex-wrap mt-2'>
          <div className='block w-full text-white'>
            <h1 className='text-black text-2xl mt-2 font-bold text-center'>
              {type === 'addInterestPayment'
                ? ADD_INTEREST_PAYMENT
                : type === 'addPrincipalPayment'
                ? ADD_PRINCIPAL_PAYMENT
                : ADD_PENALTY_PAYMENT}
            </h1>
            <p className='mt-2 text-black text-lg text-center'>{LOAN_MESSAGE}</p>
            <>
              <div className='mt-5'>
                <span className='text-sm text-black text-left'>{PAY_AMOUNT}</span>
                {type === 'addInterestPayment' ? (
                  <div className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'>
                    {' '}
                    {interest}
                  </div>
                ) : (
                  <input
                    className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'
                    type='number'
                    value={loan.pay_amount}
                    name='pay_amount'
                    onChange={({ target }) => setLoan({ ...loan, pay_amount: target.value })}
                  />
                )}
              </div>
              <div className='mt-5'>
                <span className='text-sm text-black text-left'>
                  {type === 'addPenaltyPayment' ? DEFAULTED_MONTH : PAYMENT_MONTH}
                </span>
                {type === 'addPenaltyPayment' ? (
                  <input
                    className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'
                    type='string'
                    value={loan.defaulted_month}
                    name='defaulted_month'
                    onChange={({ target }) => setLoan({ ...loan, defaulted_month: target.value })}
                  />
                ) : (
                  <FormSelect
                    className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'
                    value={loan.payment_month}
                    onChange={({ target }) => setLoan({ ...loan, payment_month: target.value })}
                    options={Months.map(item => ({ name: item, value: item }))}
                    name='payment_month'
                  />
                )}
              </div>
              <div className='mt-5'>
                <span className='text-sm text-black text-left'>{PAYMENT_DATE}</span>
                <input
                  className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'
                  type='date'
                  value={loan.payment_date}
                  name='payment_date'
                  onChange={({ target }) => setLoan({ ...loan, payment_date: target.value })}
                />
              </div>
              <div className='mt-5'>
                <span className='text-sm text-black text-left'>{PAYMENT_DESCRIPTION}</span>
                <textarea
                  className='w-full border-gray-600 hover:border-gray-700 border text-gray-900 mt-2 py-2 px-4 rounded-lg'
                  value={loan.description}
                  name='description'
                  type='string'
                  onChange={({ target }) => setLoan({ ...loan, description: target.value })}
                />
              </div>
            </>
            <div className='mt-5'>
              <button
                onClick={submitFormHandle}
                className='py-3 bg-primary font-bold text-white rounded-md w-full focus:outline-none focus:shadow-outline'
              >
                {interestLoading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
                {principalLoading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
                {penaltyLoading && <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />}
                {type === 'addInterestPayment'
                  ? ADD_INTEREST_PAYMENT
                  : type === 'addPrincipalPayment'
                  ? ADD_PRINCIPAL_PAYMENT
                  : ADD_PENALTY_PAYMENT}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

AdminAddPaymentModal.propTypes = {
  buttonText: PropTypes.any,
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  disabled: PropTypes.bool
}

export default AdminAddPaymentModal
