/* eslint-disable camelcase */
import { notification } from 'antd'
import _groupBy from 'lodash.groupby'

import { Applications } from '../../services'
import { monthNames } from '../../utils/data'
import { sortByMonth, transformBusinessInformation } from '../../utils/helpers'
import { reducerActions } from '../reducer'
import { allLoanStatus, responseMapStatus } from './constants'

const initialState = {
  allApplications: [],
  adminApplications: [],
  adminAppStatistics: [],
  declinedApplications: [],
  pendingApplications: [],
  approvedApplications: [],
  isServerError: null,
  appDetails: {},
  adminLineChart: {},
  applicationCount: {},
  searchedApplication: [],
  feedbacks: false,
  allApplicationCount: null,
  pendingLoans: [],
  approvedLoans: [],
  declinedLoans: [],
  disbursedLoans: [],
  closedLoans: []
}

export const applications = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async pendingApp() {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.pending_application()
        if (data.success) {
          dispatch.applications.setState({
            pendingApplications: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching pending applications'
          })
        }
        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async unApproveLoan(payload) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.unapproved_loan(payload)

        if (!data.success) {
          return notification.error({
            message: 'An error occured while fetching pending applications'
          })
        }
        notification.success({
          message: data.data
        })

        await dispatch.applications.adminGetLoanByStatus({
          status: 'approved',
          shouldUseCache: false
        })

        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description: error?.response?.data?.msg || error?.message || error?.data?.message
        })
      }
    },
    async allApplications() {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.all_applications()
        if (data.success) {
          dispatch.applications.setState({
            allApplications: data.data.sort(
              (a, b) => new Date(b.time_submitted) - new Date(a.time_submitted)
            )
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching all applications'
          })
        }

        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    /** @deprecated  This function needs to be removed and then refactored with the new API for appications */
    async adminApplications({ shouldUseCache = true }) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_applications(shouldUseCache)
        if (data.success) {
          dispatch.applications.setState({
            adminApplications: data.data.pending_apps.sort((a, b) => b.loan_id - a.loan_id)
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching all applications'
          })
        }

        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    /**
     * Get loans by given status
     * @param {Object} payload the payload object
     * @param {string} payload.status the current status
     * @returns {void}
     */
    async adminGetLoanByStatus(payload) {
      const { status } = payload
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.adminGetLoansByStatus(status, payload?.shouldUseCache)
        if (data.success) {
          dispatch.applications.setState({
            [allLoanStatus[status]]: data.data?.[responseMapStatus[status]]?.map(
              transformBusinessInformation
            )
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching all applications'
          })
        }

        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.response?.data?.message ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    /**
     * Gets the total count of the applications
     * @returns {void}
     */
    async adminGetAllApplicationCount({ shouldUseCache = true }) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.adminGetAllApplicationCount(shouldUseCache)
        if (data.success) {
          dispatch.applications.setState({
            allApplicationCount: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching all applications'
          })
        }

        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.response?.data?.message ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    /** @deprecated  This function needs to be removed and then refactored with the new API for appications */
    async adminAppStatistics({ shouldUseCache = true }) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_applications(shouldUseCache)
        if (data.success) {
          dispatch.applications.setState({
            adminAppStatistics: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching all applications'
          })
        }

        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async declinedApplications() {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.declined_applications()
        if (data.success) {
          dispatch.applications.setState({
            declinedApplications: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching declined applications'
          })
        }
        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async approvedApplications() {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.approved_aplications()
        if (data.success) {
          dispatch.applications.setState({
            approvedApplications: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching approved applications'
          })
        }
        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async applicationDetail(payload) {
      dispatch.applications.setError(null)
      const { slug, isAdmin } = payload
      try {
        const { data } = await Applications.applicationDetail(slug, isAdmin)
        if (data.success) {
          dispatch.applications.setState({
            appDetails: {
              ...data.data.loan_data,
              signatories: data.data.signatories,
              loan_business_information: data.data.loan_business_information
            }
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching approved applications'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async adminApproveApplication(payload) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_approve_application(payload)
        if (data.success) {
          notification.success({
            message: data.message || 'Application approved successfully'
          })
          await dispatch.applications.adminGetLoanByStatus({
            status: 'pending',
            shouldUseCache: false
          })
          return { success: true, id: data.data?.approved_loan_id }
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while approving application'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async customerUploadDocument(payload) {
      const { formValue } = payload
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.customer_upload_document(formValue || payload)
        if (data.success) {
          notification.success({
            message: data.message || 'Document Uploaded'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'Document upload error'
          })
        }
        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async adminUploadDocument(payload) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_upload_document(payload)
        if (data.success) {
          notification.success({
            message: data.message || 'Document Uploaded'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'Document upload error'
          })
        }
        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: error?.response?.data?.message,
          description: error?.response?.data?.msg || error?.message || error?.data?.message
        })
      }
    },
    async uploadDocument(payload) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.upload_docs(payload)
        if (data.success) {
          await dispatch.applications.adminGetLoanByStatus({
            status: 'pending',
            shouldUseCache: false
          })
          notification.success({
            message: data.message || 'Document Uploaded'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'Error ocured while uploading documents'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: error?.response?.data?.message,
          description: error?.response?.data?.msg || error?.message || error?.data?.message
        })
      }
    },

    async customerDeleteDocument(payload) {
      const { token, permissions, documentId } = payload
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.customer_delete_document(documentId)
        if (data.success) {
          notification.success({
            message: data.message || 'Document Deleted'
          })
          await dispatch.auth.fetchUserInfo({ token, permissions })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'Error deleting document'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async adminDeleteDocument(payload) {
      const { customerId, documentId } = payload
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_delete_document(customerId, documentId)
        if (data.success) {
          notification.success({
            message: data.message || 'Document Deleted'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'Error deleting document'
          })
        }
        await dispatch.users.getAllusers({ shouldUseCache: false, showAll: true })
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async adminDeclineApplication(payload) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_decline_application(payload)
        if (data.success) {
          notification.success({
            message: data.message || 'Application declined successfully'
          })
          await dispatch.applications.adminGetLoanByStatus({
            status: 'pending',
            shouldUseCache: false
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while declining application'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async applicationCount() {
      dispatch.applications.setError(null)
      try {
        const { data: allLoanApplications } = await Applications.all_applications()
        const groupedData = _groupBy(allLoanApplications?.data, item => item.loan_app_status)

        dispatch.applications.setState({
          applicationCount: {
            approvedApplications: groupedData?.Approved || [],
            pendingApplications: groupedData?.Pending || [],
            disbursedApplications: groupedData?.Disbursed || [],
            allApplications: allLoanApplications.data
          }
        })
        dispatch.loans.getCustomerDisbursedLoan()
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async adminAppLineChart(payload) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_app_line_chart(payload)

        if (data.success) {
          const disbursedWithMonthsLabels = []
          const receivedWithMonthsLabels = []
          for (const key in data.data?.disbursed_amount_chart) {
            if (Object.hasOwnProperty.call(data.data?.disbursed_amount_chart, key)) {
              // Convert to month and push to label
              // data: [{x: 10, y: 20}, {x: 15, y: null}, {x: 20, y: 10}]
              disbursedWithMonthsLabels.push({
                x: monthNames[+key - 1],
                y: data.data?.disbursed_amount_chart[key]
              })
            }
          }

          for (const key in data.data?.received_amount_chart) {
            if (Object.hasOwnProperty.call(data.data?.received_amount_chart, key)) {
              // Convert to month and push to label
              // https://www.chartjs.org/docs/latest/general/data-structures.html
              // data: [{x: 10, y: 20}, {x: 15, y: null}, {x: 20, y: 10}]
              receivedWithMonthsLabels.push({
                x: monthNames[+key - 1],
                y: data.data?.received_amount_chart[key]
              })
            }
          }

          dispatch.applications.setState({
            adminLineChart: {
              disbursedWithMonthsLabels: sortByMonth(disbursedWithMonthsLabels, +payload),
              totalDisbursedFund: data.data?.disbursed_funds,
              totalReceivedFund: data.data?.received_funds,
              receivedWithMonthsLabels: sortByMonth(receivedWithMonthsLabels, +payload)
            }
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async customerAcceptLoan(loanId) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.customer_accept_loan(loanId)
        if (data.success) {
          notification.success({
            message: 'Success:',
            description: data.message
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async customerRejectLoan(payload) {
      dispatch.applications.setError(null)

      try {
        const { data } = await Applications.customer_reject_loan(payload)
        if (data.success) {
          notification.success({
            message: 'Success:',
            description: data.message
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async resetApplication() {
      dispatch.applications.setError(null)
      try {
        dispatch.applications.setState({
          allApplications: [],
          declinedApplications: [],
          pendingApplications: [],
          approvedApplications: []
        })
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async feedbacks(payload) {
      if (!payload.type) {
        return dispatch.applications.setState({
          feedbacks: false
        })
      }
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.feedbacks(payload)
        if (data.success) {
          notification.success({
            message: data.message
          })
          dispatch.applications.setState({ feedbacks: true })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured, please try again!'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async searchApplication(payload) {
      dispatch.applications.setError(null)
      const { amount, url } = payload
      try {
        const { data } = await Applications.searchApp({ query: amount }, url)
        if (data.success) {
          dispatch.applications.setState({
            searchedApplication: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured, please try again!'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async adminClosedLoans() {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.admin_get_closed_loan()
        if (data.success) {
          dispatch.applications.setState({
            closedLoans: data.data.closed_disbursed_apps
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching closed applications'
          })
        }
        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async searchApplicationByDate(payload) {
      dispatch.applications.setError(null)
      try {
        const { data } = await Applications.searchByDateApp(payload)
        if (data.success) {
          dispatch.applications.setState({
            searchedApplication: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured, please try again!'
          })
        }
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.applications.setState(initialState)])
    }
  })
}
