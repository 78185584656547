const FEEDBACK = 'Leave a Feedback'
const FEEDBACK_TYPE = 'Feedback Type'
const COMMENT = 'Comment'
const QUESTION = 'Question'
const MESSAGE = 'Message'
const BUG_REPORT = 'Bug Report'
const ADD_DIRECTOR = 'Add Director'
const HIGHEST_LEVEL_EDUCATION = 'Education'
const TITLE = 'Title/Role'
const NAME = 'Name'
const APPLICATION_DATE = 'Application Date :'
const VIEW_APPLICATION = 'Loan Application Details'
const EMAILCONFIRMATION = 'Email Confirmation'

export {
  FEEDBACK,
  HIGHEST_LEVEL_EDUCATION,
  ADD_DIRECTOR,
  FEEDBACK_TYPE,
  COMMENT,
  BUG_REPORT,
  QUESTION,
  MESSAGE,
  TITLE,
  NAME,
  VIEW_APPLICATION,
  APPLICATION_DATE,
  EMAILCONFIRMATION
}
