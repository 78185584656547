import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { ProfileInformation, SecurutyInformation } from '.'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import ProfileTab from './components/ProfileTab'
import { USER_PASSWORD_UPDATE_TAB, USER_PIN_UPDATE_TAB, USER_PROFILE_UPDATE_TAB } from './constants'
import TransactionPin from './components/TransactionPin'

const Profile = () => {
  const [currentProfileTab, setCurrentProfileTab] = useState(USER_PROFILE_UPDATE_TAB)
  const { token, permissions } = useSelector(({ auth: authUser }) => authUser.authUser)
  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))

  useEffect(() => token.length && dispatch.auth.fetchUserInfo({ token, permissions }), [token])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <ProfileTab setCurrentProfileTab={setCurrentProfileTab} />

        {currentProfileTab === USER_PROFILE_UPDATE_TAB && (
          <section className='flex flex-col items-center justify-center w-full'>
            <ProfileInformation isAdmin={isAdmin} />
          </section>
        )}
        {currentProfileTab === USER_PASSWORD_UPDATE_TAB && (
          <section className='flex justify-center w-full'>
            <SecurutyInformation />
          </section>
        )}

        {currentProfileTab === USER_PIN_UPDATE_TAB && (
          <section className='flex justify-center w-full'>
            <TransactionPin />
          </section>
        )}
      </main>
    </DashboardLayout>
  )
}

export default Profile
