// Vitalis
const FREQUENTLY_ASK_QUESTION = 'Frequently Asked Questions'
const LOAN_ENQUIRIES = 'Loan Enquiries'
const LOAN_ENQUIRIES_DESC = 'You can find answers relating to loan enquries here'
const FREQUENTLY_ASK_QUESTION_SUB = `Find answers to frequently asked questions 
  regarding`
const FAQ_SEARCH_PLACEHOLDER = 'Search answers here ...'
const FAQ_SEARCH_BUTTON = 'Search'
const VITTAS_INTERNATIONAL = 'Vittas international'

// Apply loan
const APPLY_FOR_LOAN_HEADER = 'Apply For Loan'
const APPLY_FOR_LOAN = 'Questions about applying for loan can be found here.'

const FILING_OUT_A_LOAN_HEADER = 'How long does it take to apply for a loan?'
const FILING_OUT_A_LOAN =
  'Filling out a loan application online takes approximately 10-15 minutes to complete.'

const LOAN_APPROVAL_HEADER = 'How long does it take to get a loan?'
const LOAN_APPROVAL =
  'At Vittas, loan approval and funding can occur in as little as 48 hours provided that the applicant has met the documentation requirements and is eligible to access the loan'

const BORROW_LIMIT_HEADER = 'How much can I borrow?'
const BORROW_LIMIT =
  'Depending on the size of your business, credit rating and payment history with us (for existing customers only), you can access up to N30 million naira in a single loan disbursement.'

const LENGTH_OF_BORROWING_HEADER = 'How long can I borrow the money for?'
const LENGTH_OF_BORROWING = 'Our loan term is usally from 6 to 12 months.'

const COLLATERAL_HEADER = 'Do I require a collateral?'
const COLLATERAL = 'No you do not. Our loans are uncollaterized!'

const LOAN_DOCUMENT_HEADER = 'What documents do I need to apply for a loan?'
const LOAN_DOCUMENT_1 =
  '1. Certified true copy of certificate of incorporation, CAC Form 2, 3 and 7 of the Borrower and Bank verification number.'

const LOAN_DOCUMENT_2 =
  '2. A certified copy of the valid and subsistinglicense issued by the Pharmacists Council of Nigeria and any other license required to be held by the Borrower in relation to its business.'

const LOAN_DOCUMENT_3 =
  '3. Duly filled KYC documents indicating all necessary information of the Borrower including but not limited to its permanent address, registered phone number, bank account detail.'

const LOAN_DOCUMENT_4 =
  '4. Certified copies of the Borrower’s Bank Statement for all its bank accounts and an attached schedule depicting all accounts receivables and payables. Such Bank Statements should cover the 6 months immediately preceding the Commencement Date.'

const LOAN_DOCUMENT_5 =
  '5. Certified copies of sales report showing monthly sales figures for the last 6 months at the minimum.'

const LOAN_DOCUMENT_6 =
  '6. Certified copy of the Borrower’s inventory immediately prior to the Commencement Date. Such inventory should depict its medication and stock turnover.'

const LOAN_DOCUMENT_7 =
  '7. A certified schedule of its assets together with receipts showing date of purchase, encumbrance (if any) and maintenance routine.'

const LOAN_DOCUMENT_8 =
  '8. Duly executed postdated cheques issued by the Borrower in favour of the Lender covering both the Loan Amount and the interest payable on same.'

const LOAN_DOCUMENT_9 =
  '9. Duly executed resolution (Schedule 3) of the directors of the Borrower approving the transaction contemplated in this Agreement.'

const LOAN_DOCUMENT_10 =
  '10. Signatory Mandate Letter appointing a representative of the Lender as signatory to all its bank accounts.'

const LOAN_DOCUMENT_11 =
  '11. An undertaking issued by the Borrower stating that the Borrower shall not during the subsistence of this Agreement or any time the Loan Amount remains outstanding, open another bank account or change the bank Account into which any remittance due from its customers is to be made.'

const LOAN_DOCUMENT_12 =
  '12. Duly filled Data Requirements Form and a corresponding Letter of Undertaking in connection with contents of the Data Requirements Form in the form acceptable to the Lender and'

const LOAN_DOCUMENT_13 =
  '13. A duly certified schedule of the medication to be purchased by the Borrower with the Loan Amount and a corresponding Lien created in respect of themedication in favour of Lender.'

const PAYMENT_FEES_HEADER = 'Are there any origination or prepayment fees?'
const PAYMENT_FEES =
  'The Borrower shall also pay upfront, a one-time management fee of 0.5% and maintenance fee of 0.5%, totaling 1% of the Loan Amount on or before the loan is disbursed. The both fees shall be deducted from the Loan Amount before it is disbursed to the Borrower.'

const APPROVAL_FEES_HEADER = 'How long will it take to get funds after my loan is approved?'
const APPROVAL_FEES =
  'At Vittas, loan approval and funding can occur in as little as 48 hours provided that the applicant has met the documentation requirements and is eligible to access the loan.'

// Loan Basics
const LOAN_BASICS_HEADER = 'Loan basics'
const LOAN_BASICS = 'Question about applying for loan basics can be found here'

const LOAN_WORK_HEADER = 'How do loan works?'
const LOAN_WORK =
  'First you have to signup on our web page. Then you must submit an application with relevant documentations and then your application will go through a review and approval process. After approval, and all required documents have been submitted and vetted by our team, funds will be disbursed to the relevant bank account.'

const LOAN_INTEREST_HEADER = 'How does interest work on a loan?'
const LOAN_INTEREST =
  'Paying interest is the cost of taking out a loan. Our loans have a monthly simple, fixed interest rate of 3% on the principal of the loan. To calculate how much interest you will pay over time, multiply your loan principal by your interest rate. You will pay interest as part of your monthly payment. Use our monthly payment calculator to determine how much interest you will pay based on the interest rate and terms of your loan.'

// maintaining a loan
const MAINTAINING_A_LOAN_HEADER = 'Maintaining a loan'
const MAINTAINING_A_LOAN = 'Question about maintaining a loan can be found here'

const LOAN_PAYMENT_HEADER = 'What is my monthly loan payment?'
const LOAN_PAYMENT =
  'Your monthly payment is the result from the multiplication of your principal loan amount and your monthly interest. If you are unsure of what your payment amount is, it is available as part of your account details in your online profile. Also your monthlypayment will also be contained in your signed loan agreement.'

const LOAN_INFORMATION_HEADER =
  'Can I access information about my loan history through online platform?'
const LOAN_INFORMATION =
  'Yes, you can access a variety of information about your loan on your online banking profile, including: 1. Principal Balance 2. Cummulative Interest Due 3. Next Payment Date and Amount 4. Total Interest Paid to Date 5. Interest Rate'

const PAY_OF_LOAN_HEADER = 'Can I pay off my loan early? '
const PAY_OF_LOAN = 'Yes you can liquidtate your loan before the end date'

const INTEREST_ON_TIME_HEADER = 'What if I am not able to pay interest on time?'
const INTEREST_ON_TIME =
  'A 1% penalty is applicable 6 days after the interest payment due date. If interest is still not paid subsequently, we shall proceed to recover the loan and any accrued interest.'

const REFERAL_BONUS_HEADER = 'Is there any referral bonus?'

const LOAN_EARLY_HEADER = 'Are there applied charges if I decide to pay off my loan early?'
const LOAN_EARLY =
  'No charges applies. Interest is only calculated up to the last date before liquidation'

const PRINCIPAL_HEADER = 'When do I pay back the principal?'
const PRINCIPAL =
  'Principal is paid back 100% at the end of the loan tenure along with the final interest'

export {
  // main header
  FREQUENTLY_ASK_QUESTION,
  FREQUENTLY_ASK_QUESTION_SUB,
  VITTAS_INTERNATIONAL,
  FAQ_SEARCH_PLACEHOLDER,
  FAQ_SEARCH_BUTTON,
  // loan enquires header
  APPLY_FOR_LOAN_HEADER,
  FILING_OUT_A_LOAN_HEADER,
  LOAN_APPROVAL_HEADER,
  BORROW_LIMIT_HEADER,
  LENGTH_OF_BORROWING_HEADER,
  COLLATERAL_HEADER,
  LOAN_DOCUMENT_HEADER,
  PAYMENT_FEES_HEADER,
  APPROVAL_FEES_HEADER,
  // Loan enquires
  LOAN_ENQUIRIES,
  LOAN_ENQUIRIES_DESC,
  FILING_OUT_A_LOAN,
  LOAN_APPROVAL,
  BORROW_LIMIT,
  COLLATERAL,
  LENGTH_OF_BORROWING,
  LOAN_DOCUMENT_1,
  LOAN_DOCUMENT_2,
  LOAN_DOCUMENT_3,
  LOAN_DOCUMENT_4,
  LOAN_DOCUMENT_5,
  LOAN_DOCUMENT_6,
  LOAN_DOCUMENT_7,
  LOAN_DOCUMENT_8,
  LOAN_DOCUMENT_9,
  LOAN_DOCUMENT_10,
  LOAN_DOCUMENT_11,
  LOAN_DOCUMENT_12,
  LOAN_DOCUMENT_13,
  PAYMENT_FEES,
  APPROVAL_FEES,
  APPLY_FOR_LOAN,
  // loan basics header
  LOAN_BASICS_HEADER,
  LOAN_WORK_HEADER,
  LOAN_INTEREST_HEADER,
  // Loan basics
  LOAN_BASICS,
  LOAN_WORK,
  LOAN_INTEREST,
  // loan maintaninance header
  MAINTAINING_A_LOAN_HEADER,
  LOAN_PAYMENT_HEADER,
  LOAN_INFORMATION_HEADER,
  PAY_OF_LOAN_HEADER,
  INTEREST_ON_TIME_HEADER,
  REFERAL_BONUS_HEADER,
  LOAN_EARLY_HEADER,
  PRINCIPAL_HEADER,
  // loan maintainance
  MAINTAINING_A_LOAN,
  LOAN_PAYMENT,
  LOAN_INFORMATION,
  PAY_OF_LOAN,
  INTEREST_ON_TIME,
  LOAN_EARLY,
  PRINCIPAL
}

export const faqData = [
  {
    id: 1,
    category: 'loan-basics',
    title: 'Loan Basics',
    description: 'Questions about loan basics can be found here',
    items: [
      {
        question: 'How do loans work?',
        answer:
          'First you have to signup on our web page. Then you must submit an application with relevant documentations and then your application will go through a review and approval process. After approval, and all required documents have been submitted and vetted by our team, funds will be disbursed to the relevant bank account.'
      },
      {
        question: 'How does interest work on a loan?',
        answer:
          'Paying interest is the cost of taking out a loan. Our loans have a monthly simple, fixed interest rate of 3% on the principal of the loan. To calculate how much interest you will pay over time, multiply your loan principal by your interest rate. You will pay interest as part of your monthly payment. Use our monthly payment calculator to determine how much interest you will pay based on the interest rate and terms of your loan.'
      }
    ]
  },
  {
    id: 2,
    category: 'maintaining-loan',
    title: 'Maintaining a Loan',
    description: 'Questions about maintaining a loan can be found here',
    items: [
      {
        question: 'What is my monthly loan payment?',
        answer:
          'Your monthly payment is the result from the multiplication of your principal loan amount and your monthly interest. If you are unsure of what your payment amount is, it is available as part of your account details in your online profile. Also your monthly payment will also be contained in your signed loan agreement.'
      },
      {
        question: 'Can I access information about my loan history through online platform?',
        answer:
          'Yes, you can access a variety of information about your loan on your online banking profile, including: 1. Principal Balance 2. Cumulative Interest Due 3. Next Payment Date and Amount 4. Total Interest Paid to Date 5. Interest Rate'
      },
      {
        question: 'Can I pay off my loan early?',
        answer: 'Yes you can liquidate your loan before the end date'
      },
      {
        question: 'What if I am not able to pay interest on time?',
        answer:
          'A 1% penalty is applicable 6 days after the interest payment due date. If interest is still not paid subsequently, we shall proceed to recover the loan and any accrued interest.'
      },
      {
        question: 'When do I pay back the principal?',
        answer:
          'Principal is paid back 100% at the end of the loan tenure along with the final interest'
      }
    ]
  },
  {
    id: 3,
    category: 'apply-loan',
    title: 'Apply For Loan',
    description: 'Questions about applying for loan can be found here',
    items: [
      {
        question: 'How long does it take to apply for a loan?',
        answer:
          'Filling out a loan application online takes approximately 10-15 minutes to complete.'
      },
      {
        question: 'How long does it take to get a loan?',
        answer:
          'At Vittas, loan approval and funding can occur in as little as 48 hours provided that the applicant has met the documentation requirements and is eligible to access the loan'
      },
      {
        question: 'How much can I borrow?',
        answer:
          'Depending on the size of your business, credit rating and payment history with us (for existing customers only), you can access up to N30 million naira in a single loan disbursement.'
      },
      {
        question: 'Do I require a collateral?',
        answer: 'No you do not. Our loans are uncollaterized!'
      }
    ]
  }
]
