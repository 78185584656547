const EXPORT = 'Export'
const ALL_TRANSACTIONS = 'All Transactions'
const VIEW_TRANSACTIONS = 'View Transactions'
const LOAN_ID = 'Loan ID'
const LOAN_COLLECTED = 'Loan Collected'
const AMOUNT_PAYBACK = 'Amount PaidBack'
const INTEREST_AMOUNT = 'Interest Amount'
const LOAN_DURATION = 'Loan Duration'
const STARTING_DATE = 'Starting Date'
const DUE_DATE = 'Due Date'
const MONTHS = 'Months'
const ACTIONS = 'ACTIONS'

export {
  ALL_TRANSACTIONS,
  EXPORT,
  VIEW_TRANSACTIONS,
  LOAN_ID,
  LOAN_COLLECTED,
  AMOUNT_PAYBACK,
  LOAN_DURATION,
  STARTING_DATE,
  MONTHS,
  DUE_DATE,
  ACTIONS,
  INTEREST_AMOUNT
}
