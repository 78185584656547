/* eslint-disable react/prop-types */
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return null
}

export default withRouter(ScrollToTop)

ScrollToTop.propTypes = {
  history: PropTypes.object.isRequired
}
