import React, { useState } from 'react'
import { notification } from 'antd'
import 'antd/dist/antd.css'
import { dispatch } from '../../../redux/store'
import { registerCompanyValidator } from '../../../utils/inputValidator'
import {
  REGISTER_COMPANY,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  REGISTER_COMPANY_BTN,
  BUSINESS_NAME
} from './constants'
import { useSelector } from 'react-redux'

const RegisterCompany = () => {
  const loading = useSelector(({ loading }) => loading.effects.registerCompany.register)

  const userObj = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    business_name: ''
  }
  const [user, setUser] = useState(userObj)

  const onChangeText = ({ target }) => {
    setUser({ ...user, [target.name]: target.value })
  }
  const validator = registerCompanyValidator(user)

  const submitFormHandle = () => {
    if (validator.isValid) {
      dispatch.registerCompany.register(user)
      setUser(userObj)
    } else {
      notification.error({
        message: 'An error occured',
        description: validator.error
      })
    }
  }

  return (
    <main className='w-full flex-grow p-4 bg-white shadow-lg'>
      <div className='ml-1 flex justify-start mt-4'>
        <h3 className='font-bold mr-5  text-lg cursor-pointer'>{REGISTER_COMPANY}</h3>
      </div>
      <div className=' w-full mt-8 mb-16 px-4 overflow-x-auto'>
        <div className='flex flex-wrap'>
          <div className='w-full md:w-3/4 mx-2'>
            <div className='mt-4'>
              <div className='md:grid grid-cols-1 mb-4'>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>{FIRST_NAME}</label>
                  <input
                    className='form-control'
                    name='first_name'
                    type='text'
                    onChange={onChangeText}
                    value={user.first_name}
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900 font-semibold'>{LAST_NAME}</span>
                  <input
                    className='form-control'
                    name='last_name'
                    type='text'
                    onChange={onChangeText}
                    value={user.last_name}
                  />
                </div>

                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900  font-semibold'>{PHONE_NUMBER}</span>
                  <input
                    className='form-control'
                    name='phone_number'
                    type='text'
                    onChange={onChangeText}
                    value={user.phone_number}
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900  font-semibold'>{EMAIL}</span>
                  <input
                    className='form-control'
                    name='email'
                    type='email'
                    onChange={onChangeText}
                    value={user.email}
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <span className='text-sm text-gray-900  font-semibold'>{BUSINESS_NAME}</span>
                  <input
                    className='form-control'
                    type='text'
                    value={user.business_name}
                    onChange={onChangeText}
                    name='business_name'
                    maxLength='35'
                  />
                </div>
                <div className='w-full md:w-12/12 px-2 mb-6'>
                  <div className='relative mt-10'>
                    <button
                      onClick={submitFormHandle}
                      className='py-2 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline text-xl'
                    >
                      {loading ? (
                        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                      ) : (
                        ''
                      )}{' '}
                      {REGISTER_COMPANY_BTN}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default RegisterCompany
