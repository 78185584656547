/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { DatePicker, Steps } from 'antd'
import moment from 'moment'
import { InputField } from '../../../../../sharedComponents/input-field'
import PropTypes from 'prop-types'
import {
  LOAN_AMOUNT,
  LOAN_DURATION,
  LOAN_PURPOSE,
  LOAN_TYPE,
  PHARMACEUTICAL_LICENSE_EXP,
  SALES_PERSON
} from '../../../../../sharedComponents/entries/constant'
import { FormSelect } from '../../../../../sharedComponents/form-select'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { inputFieldformatCurrency } from '../../../../../utils/helpers'
import AddDistributors from './AddDistributors'
import { dispatch } from '../../../../../redux/store'
import LoanTypeModal from '../modal/LoanTypeModal'

const { Step } = Steps

const Step4 = ({ onGoBack, savedData, onNext, disableStepThree }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [distributors, setDistributors] = useState([])
  const [previewModalVisible, setModalVisible] = useState(false)

  const exitingSalesRep = useSelector(({ salesRep }) => salesRep.exitingSalesRep)

  const validationSchema = Yup.object().shape({
    loanAmount: Yup.string().required('Loan Amount is required'),
    loanDuration: Yup.number().required('Loan Duration is required'),
    loanPurpose: Yup.string().required('Loan Purpose is required'),
    licenseExp: Yup.string().required('Pharmaceutical Licence Exp is required'),
    sales_rep: Yup.string().optional(),
    loanType: Yup.string().required('Loan type is required'),
    distributorForms: Yup.array().of(
      Yup.object().shape({
        distributorId: Yup.string().required('Distributor is required'),
        distributor_amount: Yup.number().required('Distributor amount is required')
      })
    )
  })

  const setDistributorData = values => {
    setDistributors(values.forms || [])
  }

  const formik = useFormik({
    initialValues: {
      loanAmount: savedData?.loanAmount || '',
      loanPurpose: savedData?.loanPurpose || '',
      loanDuration: savedData?.loanDuration || '',
      licenseExp: savedData?.licenseExp || '',
      sales_rep: savedData?.sales_rep || '',
      loanType: savedData?.loanType || ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      const newDistributors = distributors.filter(distributor => Boolean(distributor.distributorId))

      const requestPayload = {
        ...values,
        distributors: newDistributors
      }
      await onNext(requestPayload, 4)
      setFormSubmitted(true)
    }
  })
  const { values, errors, handleChange } = formik

  const handleSubmit = () => {
    setFormSubmitted(true) // Set the flag to true when the submit button is clicked
    formik.handleSubmit()
  }

  /**
   * @description loads the stored step values from local storage when the component mounts
   */
  useEffect(() => {
    if (savedData?.distributors) {
      setDistributors(savedData?.distributors)
    }

    dispatch.salesRep.getAllSales()
  }, [])

  return (
    <div>
      <Steps current={3} className='custom-steps'>
        <Step title='Business information' />
        <Step title='Business Documents' />
        {!disableStepThree ? <Step title='Business Signatories' /> : null}
        <Step title='Loan Details' />
      </Steps>

      {previewModalVisible && (
        <LoanTypeModal
          modalVisible={previewModalVisible}
          previewData={values}
          setModalVisible={setModalVisible}
        />
      )}

      <div className='my-4'>
        <div className='w-full mx-auto md:px-4 rounded-xl bg-theme1-400'>
          <div className='flex justify-between xxl:max-w-6xl max-w-3xl'>
            <h1 className='font-semibold mt-2 text-lg'>Kindly provide the following information</h1>
            <button
              onClick={() => setModalVisible(true)}
              className='border border-primary text-primary px-4 py-2 hover:bg-primary hover:text-white'
            >
              View loan schedule
            </button>
          </div>
          <div className='flex flex-wrap md:mx-3'>
            <div className='w-full max-w-sm mt-8 px-2'>
              <label className='text-gray-500 leading-8'>{LOAN_TYPE}</label>
              <FormSelect
                options={[
                  { name: 'Bullet Loan', value: 'Bullet Loan' },
                  { name: 'Amortized Loan', value: 'Amortized Loan' }
                ]}
                value={values.loanType}
                onChange={handleChange}
                className='form-control  bg-white text-gray-400'
                label='Loan type'
                name='loanType'
              />
              {formSubmitted && errors.loanType ? (
                <small className='text-red-500'>{errors.loanType}</small>
              ) : (
                ''
              )}
            </div>
            <div className='w-full max-w-sm mt-8 px-2'>
              <InputField
                name='loanAmount'
                type='text'
                placeholder={LOAN_AMOUNT}
                label={LOAN_AMOUNT}
                // pattern='[0-9]'
                className='form-control bg-white'
                value={inputFieldformatCurrency(values.loanAmount)}
                onChange={handleChange}
              />
              {formSubmitted && errors.loanAmount ? (
                <small className='text-red-500'>{errors.loanAmount}</small>
              ) : (
                ''
              )}
            </div>
            <div className='w-full max-w-sm  mt-8 px-2'>
              <InputField
                name='loanDuration'
                type='number'
                label='Loan Duration'
                placeholder={LOAN_DURATION}
                className='form-control bg-white'
                value={values.loanDuration}
                onChange={handleChange}
              />
              {formSubmitted && errors.loanDuration ? (
                <small className='text-red-500'>{errors.loanDuration}</small>
              ) : (
                ''
              )}
            </div>
            <div className='w-full max-w-sm mt-8 px-2'>
              <InputField
                name='loanPurpose'
                type='text'
                placeholder={LOAN_PURPOSE}
                label={LOAN_PURPOSE}
                className='form-control bg-white'
                value={values.loanPurpose}
                onChange={handleChange}
              />
              {formSubmitted && errors.loanPurpose ? (
                <small className='text-red-500'>{errors.loanPurpose}</small>
              ) : (
                ''
              )}
            </div>
            <div className='w-full max-w-sm md:mt-12 mt-8 px-2'>
              <label className='text-gray-500 leading-8 '>{PHARMACEUTICAL_LICENSE_EXP}</label>
              <DatePicker
                name='licenseExp'
                size='large'
                picker='month'
                className='form-control'
                value={values.licenseExp ? moment(values.licenseExp) : null}
                onChange={(date, dateString) => {
                  // Using dateString to update the formik field
                  formik.setFieldValue('licenseExp', dateString)
                }}
              />
              {formSubmitted && errors.licenseExp ? (
                <small className='text-red-500'>{errors.licenseExp}</small>
              ) : (
                ''
              )}
            </div>
            <div className='w-full max-w-sm mt-8 px-2'>
              <label className='text-gray-500 leading-8'>
                {SALES_PERSON} {'(optional)'}
              </label>
              <FormSelect
                options={[
                  ...exitingSalesRep.map(user => ({
                    name: `${user.first_name} ${user.last_name}`,
                    value: user.id
                  }))
                ]}
                value={values.sales_rep}
                onChange={handleChange}
                className='form-control border-0 mr-0 py-0 px-0 mt-0  bg-white text-gray-400'
                label='Sales Person'
                name='sales_rep'
              />
              {formSubmitted && errors.sales_rep ? (
                <small className='text-red-500'>{errors.sales_rep}</small>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='mt-16'>
            <div className='grid items-center justify-between'>
              <h1 className='font-bold text-xl'>Distributors</h1>
              <h3 className='text-base'>
                Add Distributors allows you to split the fund to different distributors
              </h3>
            </div>
            <AddDistributors
              savedData={savedData?.distributors}
              setDistributorData={setDistributorData}
            />
          </div>
          <div className='flex md:gap-12 gap-8 md:mt-12 md:bottom-16'>
            <button
              className='text-primary text-base'
              onClick={() => onGoBack(disableStepThree ? 2 : 3)}
            >
              Go back
            </button>
            <button className='btn-primary bg-primary px-8 py-2 text-white' onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

Step4.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  savedData: PropTypes.any,
  onNext: PropTypes.func.isRequired,
  disableStepThree: PropTypes.bool
}

export default Step4
