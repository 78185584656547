import React from 'react'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import RiskManagementTabComponent from './RiskManagementTabComponent'
import ReviewTable from './review/ReviewTable'
import { sampleData } from './constants'
import ApprovedTable from './approved/ApprovedTable'
import DisbursedTable from './disbursed/DisbursedTable'
import DeclinedTable from './declined/DeclinedTable'

const RiskManagement = () => {
  const tabs = ['Review', 'Approved', 'Disbursed', 'Declined']

  const contents = [
    // Review tab content
    <div key='Review'>
      <ReviewTable tableData={{ data: sampleData }} />
    </div>,

    // Approved tab content
    <div key='Approved'>
      <ApprovedTable tableData={{ data: sampleData }} />
    </div>,

    // Disbursed tab content
    <div key='Disbursed'>
      <DisbursedTable tableData={{ data: sampleData }} />
    </div>,

    // Declined tab content
    <div key='Declined'>
      <DeclinedTable tableData={{ data: sampleData }} />
    </div>
  ]

  return (
    <DashboardLayout>
      <div className='p-4'>
        <h1 className='text-2xl font-bold mb-4'>Risk Management</h1>
        <RiskManagementTabComponent tabs={tabs} contents={contents} />
      </div>
    </DashboardLayout>
  )
}

export default RiskManagement
