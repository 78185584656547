import React from 'react'

export const leadsColumns = [
  {
    title: <span className='font-bold'>First Name</span>,
    dataIndex: 'first_name',
    key: 'first_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.first_name?.localeCompare(b.name)
  },
  {
    title: <span className='font-bold'>Last Name</span>,
    dataIndex: 'last_name',
    key: 'last_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.last_name?.localeCompare(b.last_name)
  },
  {
    title: <span className='font-bold'>Business Name</span>,
    dataIndex: 'business_name',
    key: 'business_name',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.business_name?.localeCompare(b.business_name)
  },
  {
    title: <span className='font-bold'>Email address</span>,
    dataIndex: 'email',
    key: 'email',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.email?.localeCompare(b.email)
  },
  {
    title: <span className='font-bold'>Phone Number</span>,
    dataIndex: 'phone_number',
    key: 'phone_number'
  },
  {
    title: <span className='font-bold'>Location</span>,
    dataIndex: 'locations',
    key: 'locations'
  },
  {
    title: <span className='font-bold'>Created At</span>,
    dataIndex: 'created_at',
    key: 'created_at',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at)
  }
]
