/* prettier-ignore */
import React from 'react'
import { Collapse } from 'antd'
import PropTypes from 'prop-types'
import exclamationIcon from '../../../images/icons/exclamation.svg'

const FaqSection = ({ category, currentFaq, searchQuery }) => {
  const { Panel } = Collapse
  // Filter items based on search query
  /* prettier-ignore */
  const filteredItems = searchQuery
    ? category.items.filter(
      item =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.answer.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : category.items

  if (currentFaq !== category.id) {
    return null
  }

  return (
    <div className='bg-info pb-5 rounded-lg'>
      <div className='p-5'>
        <div className='flex'>
          <div className='flex flex-col items-center justify-center mr-5'>
            <figure className='m-0 p-0 bg-blue-200 rounded-full w-4 h-4 grid place-items-center'>
              <img src={exclamationIcon} alt='exclamation' />
            </figure>
          </div>
          <div>
            <h4 className='text-black text-xl font-semibold'>{category.title}</h4>
            <p className='text-gray-900'>{category.description}</p>
          </div>
        </div>
      </div>
      <Collapse
        accordion
        className='pl-10 bg-white border-0'
        expandIconPosition='right'
        expandIcon={item =>
          item.isActive ? (
            <span className='plus-and-minus'>
              <i className='fa fa-minus' />
            </span>
          ) : (
            <span className='plus-and-minus'>
              <i className='fa fa-plus' />
            </span>
          )
        }
      >
        {filteredItems.map((item, index) => (
          <Panel className='text-lg font-manrope' header={item.question} key={index}>
            <p className='apply-for-loan'>{item.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

FaqSection.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  currentFaq: PropTypes.number.isRequired,
  searchQuery: PropTypes.string
}

export default FaqSection
