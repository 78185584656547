import React, { useState, useEffect } from 'react'
import { Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import forgotPwd from '../../images/login/forgot-pwd.png'
import { forgotPwdValidator } from '../../utils/inputValidator'
import { PWD_BTN, EMAIL_LABEL, FORGOT_PASSWORD, SUB_TITLE } from './constant'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ForgotPwdModalBox = ({ btnTitle, headerTitle }) => {
  const history = useHistory()
  const loading = useSelector(({ loading }) => loading.effects.auth.forgotPwd)
  const pwdModal = useSelector(({ auth }) => auth.pwdModal)

  const [visible, setModal] = useState(false)

  const [email, setEmail] = useState('')

  const showModal = () => {
    setModal(true)
    dispatch.auth.forgotPasswordModal(true)
  }

  const handleCancel = () => {
    setModal(false)
    dispatch.auth.forgotPasswordModal(false)
  }

  const onChangeText = ({ target }) => {
    setEmail(target.value)
  }

  useEffect(() => {
    setModal(pwdModal)
  }, [pwdModal])

  const submitFormHandle = () => {
    const validator = forgotPwdValidator(email)
    if (validator.isValid) {
      dispatch.auth.forgotPwd({ history, email })
    } else {
      notification.error({
        message: 'An error occured',
        description: validator.error
      })
    }
  }

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.nativeEvent.keyCode === 13) {
      submitFormHandle()
    }
  }

  return (
    <>
      <p
        onClick={showModal}
        className='font-medium text-primary hover:text-primary-200 cursor-pointer mb-0'
      >
        {btnTitle}
      </p>
      <Modal open={visible} onCancel={handleCancel} footer={false}>
        <div className='flex flex-wrap mt-2'>
          <div className='block w-full text-white'>
            <img src={forgotPwd} alt='forgot password' className='block mx-auto' />
            <h1 className='text-black text-2xl mt-2 font-bold text-center'>{FORGOT_PASSWORD}</h1>
            <p className='mt-2 text-black text-lg text-center'>{SUB_TITLE}</p>
            <div className='mt-5'>
              <span className='text-sm text-black text-left'>{EMAIL_LABEL}</span>
              <input
                className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                type='email'
                value={email}
                onChange={onChangeText}
                name='email'
                onKeyPress={handleKeypress}
              />
            </div>

            <div className='mt-5'>
              <button
                onClick={submitFormHandle}
                className='py-3 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
              >
                {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                {PWD_BTN}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

ForgotPwdModalBox.propTypes = {
  btnTitle: PropTypes.string.isRequired,
  headerTitle: PropTypes.string.isRequired
}

export default ForgotPwdModalBox
