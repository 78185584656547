import React from 'react'
import PropTypes from 'prop-types'

const InputField = ({
  label,
  onChange,
  disabled,
  name,
  type,
  className,
  value,
  placeholder,
  max,
  maxLength,
  ...others
}) => {
  return (
    <>
      {label && <label className='text-gray-500 leading-8'>{label}</label>}
      <input
        {...others}
        className={className}
        type={type}
        value={value || undefined}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        max={max}
        disabled={disabled}
        maxLength={maxLength}
      />
    </>
  )
}

InputField.propTypes = {
  label: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool
}

export default InputField
