import React from 'react'
import { notification, Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import { Loans } from '../../services'
import { dispatch } from '../../redux/store'
import { DEFAULT_LOAN } from './constant'

const DefaultedLoan = ({ approvedLoanId }) => {
  const cancel = () => {
    notification.warn({
      message: 'Loan not marked as defaulted'
    })
  }

  const markAsDefaultedLoan = async () => {
    try {
      const { data } = await Loans.setDefaultLoan(approvedLoanId)
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message || 'Loan marked as defaulted'
        })
        // to be removed
        await dispatch.applications.adminApplications()
      } else {
        notification.error({
          message: 'Error occured ',
          description: data.message
        })
      }
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: error?.response?.message
      })
    }
  }

  return (
    <Popconfirm
      title='Are you sure to mark this loan as defaulted?'
      onConfirm={markAsDefaultedLoan}
      onCancel={cancel}
      okText='Yes'
      cancelText='No'
    >
      <button className='w-full text-left nav-sm-link hover:text-blue-800'>
        <i className='fa fa-check mr-2' aria-hidden='true' />
        {DEFAULT_LOAN}{' '}
      </button>
    </Popconfirm>
  )
}
DefaultedLoan.propTypes = {
  approvedLoanId: PropTypes.object
}
export default DefaultedLoan
