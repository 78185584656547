import React, { useState } from 'react'
import { InputField } from '../../../../sharedComponents/input-field'

const LoanDetails = () => {
  const [loanAmount, setLoanAmount] = useState('')
  const [loanInterest, setLoanInterest] = useState('')
  const [loanDuration, setLoanDuration] = useState('')
  const [loanPurpose, setLoanPurpose] = useState('')
  const [distributor, setDistributor] = useState('')

  const handleInputChange = setter => event => {
    setter(event.target.value)
  }

  return (
    <div className='flex flex-wrap'>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanAmount)}
          value={loanAmount}
          type='number'
          name='loanAmount'
          label='Loan Amount'
          placeholder='Enter loan amount'
          className='form-control bg-white'
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanInterest)}
          value={loanInterest}
          type='number'
          name='loanInterest'
          label='Loan Interest (%)'
          placeholder='Enter loan interest rate'
          className='form-control bg-white'
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanDuration)}
          value={loanDuration}
          type='number'
          name='loanDuration'
          label='Loan Duration (years)'
          placeholder='Enter loan duration'
          className='form-control bg-white'
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setLoanPurpose)}
          value={loanPurpose}
          type='text'
          name='loanPurpose'
          label='Loan Purpose'
          placeholder='Enter loan purpose'
          className='form-control bg-white'
        />
      </div>
      <div className='w-full sm:w-1/2 md:w-1/3 md:px-4 mt-8'>
        <InputField
          onChange={handleInputChange(setDistributor)}
          value={distributor}
          type='text'
          name='distributor'
          label='Distributor'
          placeholder='Enter distributor name'
          className='form-control bg-white'
        />
      </div>
    </div>
  )
}

export default LoanDetails
