import { notification } from 'antd'
import { FeatureToggles } from '../../services'
import { reducerActions } from '../reducer'

const initialState = {
  features: null
}

export const featureToggle = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async getAllFeatureToggles() {
      dispatch.contact.setError(null)
      try {
        const features = await FeatureToggles.getFeatureToggles()
        dispatch.featureToggle.setState({ features: features })
      } catch ({ response, message }) {
        dispatch.contact.setError({
          message: 'An error occured',
          description: message
        })
        notification.error({
          message: 'An error occured',
          description: message
        })
      }
    },

    async reset() {
      await Promise.all([dispatch.featureToggle.setState(initialState)])
    }
  })
}
