import React from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

const AuthGuard = ({ component: Component, ...rest }) => {
  const user = useSelector(({ auth }) => auth.authUser)
  const now = moment().unix()
  const history = useHistory()

  return (
    <Route
      {...rest}
      render={props => {
        const expTokenTime = user?.userInfo?.exp
        if (expTokenTime && expTokenTime > now) {
          // don't allow user go to auth page if they're already logged in
          return history.push('/')
        }

        // not authorised, then allow user
        return <Component {...props} />
      }}
    />
  )
}

AuthGuard.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object
}

export default AuthGuard
