import React from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'

const Retry = () => {
  const { loading } = useSelector(({ applications, loading }) => ({
    loading: loading.global
  }))

  return (
    <div className='flex justify-center mt-60 '>
      <Button loading={loading} onClick={() => window.location.reload()}>
        Retry
      </Button>
    </div>
  )
}

export default Retry
