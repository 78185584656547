import React, { useState } from 'react'

import { Title } from './constant'
import useGetLoanStatus from '../../../pages/admin-pages/applications/useGetLoanStatus'
import dayjs from 'dayjs'

const PendingDisbursementTable = () => {
  const { approved: approvedLoanData, loading } = useGetLoanStatus('approved')
  const [showAll, setShowAll] = useState(false)

  const visibleData = showAll ? approvedLoanData : approvedLoanData?.slice(0, 5)

  return (
    <div className='w-full p-2'>
      <div className='flex justify-between mb-4'>
        <div className='text-lg font-medium'>{Title}</div>
        <button
          className='text-blue-800 text-lg mr-6 border-b-2 border-blue-600'
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'View less' : 'View All'}
        </button>{' '}
        {/* toggle showAll state when the button is clicked */}
      </div>
      {!loading && approvedLoanData.length > 0 && (
        <table className='border-separate border-0 flex flex-col'>
          <tbody>
            {visibleData?.map(
              (
                item // map over visibleData instead of approvedLoanData
              ) => (
                <tr key={item.id} className='h-full mt-2 flex justify-between items-center'>
                  <td className='w-full'>
                    <div className='font-bold w-48'>{item?.business_name}</div>
                    <div className='flex flex-row gap-3 w-full'>
                      {' '}
                      <span>Amount: ₦{item?.loan_principal_amount_fixed} </span>
                      <span>Approved Date: {dayjs(item?.approval_date).format('MM-DD-YYYY')}</span>
                      <span className='bg-green-50 py-1 rounded-md text-xs text-blue-800 mx-auto px-3 whitespace-nowrap'>
                        {item?.loan_duration} Months
                      </span>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default PendingDisbursementTable
