/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import { stepFourValidator } from '../../utils/inputValidator'
import { useSelector } from 'react-redux'
import { PREVIEW_DETAILS } from '../../pages/applications/ApplicationsDetails'
import pdfImage from '../../images/profile.jpg'
import loader from '../../images/load.gif'

import {
  BIZ_DOCUMENTS,
  BIZ_INFORMATION,
  GENDER,
  DATE_OF_BIRTH,
  HOUSE_ADDRESS,
  MARITAL_STATUS,
  NO_OF_KIDS,
  GOVERNMENT_ID
} from '../../pages/applications/constant'
import { formatCurrency } from '../../utils/helpers'
import { downloadCSV } from '../export/bankStatement'
import { bankStatementColumn } from '../../pages/admin-pages/customers/constants'
import { NAME } from '../../pages/directors/constants'
import { cleanType } from '../../utils/cleanTypes'
import { dispatch } from '../../redux/store'
import { useLocation } from 'react-router-dom'

const PreviewApplication = ({
  headerTitle,
  input,
  nextKey,
  handleSubmit,
  amountDiscrepancyError,
  setError,
  distributors
}) => {
  const loading = useSelector(({ loading }) => loading.effects.loans.applyForLoan)

  const { state } = useLocation()

  const [visible, setVisible] = useState(false)
  const [details, setDetails] = useState({})
  const [directorDetails, setDirectorDetails] = useState([])
  const [documentDetails, setDocumentDetails] = useState([])
  const { directorData } = useSelector(({ auth: { authUser } }) => ({
    directorData: authUser.userInfo?.data?.directors
  }))
  const { documents } = useSelector(({ auth: { authUser } }) => ({
    documents: authUser.userInfo?.data?.documents
  }))

  const exitingUsers = useSelector(({ users }) => users.exitingUsers)

  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))

  const handlePreview = async () => {
    const directorId = JSON.parse(window.localStorage.getItem('apply-for-loan')).directors
      ? JSON.parse(window.localStorage.getItem('apply-for-loan')).directors
      : []

    if (directorId) {
      const setDir = isAdmin
        ? state?.user?.directors
        : directorData.filter(eachDirector => directorId.indexOf(eachDirector.id) !== -1)
      setDirectorDetails(setDir)
    }

    const localStorageDocIds = JSON.parse(window.localStorage.getItem('apply-for-loan')).directors
      ? JSON.parse(window.localStorage.getItem('apply-for-loan')).businessDocuments
      : []

    if (localStorageDocIds) {
      const currentUserDocuments = isAdmin
        ? exitingUsers.find(user => state?.user?.id === user.id)?.documents
        : documents

      const docs = currentUserDocuments.filter(
        eachDocument => localStorageDocIds.indexOf(eachDocument.id) !== -1
      )
      setDocumentDetails(docs)
    }
    const loanDetails = input
    const validator = stepFourValidator(loanDetails)
    if (validator.valid) {
      if (window.localStorage.getItem('apply-for-loan')) {
        const buzInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))
        const previewDetails = {
          ...buzInfo.businessInformation,
          ...buzInfo.businessDocuments,
          ...buzInfo.loanDetails,
          ...loanDetails
        }

        setDetails(previewDetails)
        const applyForLoan = { ...buzInfo, loanDetails }
        window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
        setVisible(true)
      } else {
        nextKey(1)
      }
    } else {
      setError({ ...validator.error })
    }
  }
  useEffect(() => {
    if (isAdmin) {
      dispatch.users.getAllusers({
        shouldUseCache: false,
        showAll: true
      })
    }
  }, [])
  return (
    <>
      <button className='btn-primary px-4' onClick={handlePreview}>
        Preview Now
      </button>
      <Modal
        title={headerTitle}
        centered
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
        footer={[]}
      >
        <div>
          {Object.keys(details).length > 0 ? (
            <div className='ml-1 flex flex-wrap mt-2 mb-10'>
              <div className='w-full'>
                <h2 className='mb-5 text-xl font-bold text-primary'>{BIZ_INFORMATION}</h2>
              </div>
              <div className='w-full md:w-1/2'>
                {PREVIEW_DETAILS.map((detail, i) => (
                  <div className='mb-3' key={i}>
                    <strong>{detail.title}: </strong> <span>{details[detail.key]}</span>
                    <hr className='mt-2' />
                  </div>
                ))}
              </div>
              <div className='w-full'>
                <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>{BIZ_DOCUMENTS}</h2>
              </div>
              <div className='w-full lg:w-4/5'>
                <div className='flex flex-wrap'>
                  {documentDetails.map(eachDocument => (
                    <p
                      key={eachDocument.id}
                      className='w-full md:w-1/2 lg:w-1/3 justify-between md:justify-around flex px-3 mb-8'
                    >
                      <span className='mr-6  '>{cleanType(eachDocument.type)}</span>

                      {eachDocument.file_url ? (
                        <a
                          href={eachDocument.file_url}
                          target='_blank'
                          rel='noopener noreferrer'
                          download={eachDocument.type}
                        >
                          <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                        </a>
                      ) : (
                        <button onClick={() => downloadCSV(bankStatementColumn, eachDocument.file)}>
                          <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                        </button>
                      )}
                    </p>
                  ))}
                </div>
              </div>
              {directorDetails.length > 0 ? (
                <>
                  <div className='w-full'>
                    <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>Directors</h2>
                  </div>
                  <div className='w-full md:w-5/6 lg:w-4/5'>
                    <div className='flex flex-wrap'>
                      {directorDetails.map((user, index) => (
                        <div
                          key={index}
                          className={`w-full mb-6 ${
                            directorDetails.length === 1 ? 'p-8 bg-gray-100 md:w-4/5' : 'md:w-1/2'
                          }`}
                        >
                          <table>
                            <tbody>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>
                                  {user?.photo.split('.')[user?.photo.split('.').length - 1] ===
                                  'pdf' ? (
                                    <a
                                      href={user?.photo}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      download='Picture'
                                      className='pointer'
                                    >
                                      <img src={pdfImage} alt='profile' width='100px' />
                                    </a>
                                  ) : (
                                    <img
                                      src={user?.photo?.length > 0 ? user?.photo : pdfImage}
                                      alt='profile'
                                      width='100px'
                                    />
                                  )}
                                </th>
                                <td className=''>
                                  <i>
                                    {user.title} {user.lastName} {user.firstName}
                                  </i>
                                  <br />
                                  <i>{user.email} </i>
                                  <br />
                                  <i>{user.phone}</i>
                                </td>
                              </tr>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>{NAME}</th>
                                <td className=''>
                                  {user.first_name} {user.last_name}
                                </td>
                              </tr>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>{HOUSE_ADDRESS}</th>
                                <td className=''>{user.address ? user.address : 'Null'}</td>
                              </tr>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>
                                  {MARITAL_STATUS}
                                </th>
                                <td className=''>{user.marital_status}</td>
                              </tr>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>{DATE_OF_BIRTH}</th>
                                <td className=''>{user.birth_date}</td>
                              </tr>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>{GENDER}</th>
                                <td className=''>{user.gender}</td>
                              </tr>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>{NO_OF_KIDS}</th>
                                <td className=''>{user.num_kids}</td>
                              </tr>
                              <tr className='leading-7'>
                                <th className=' pr-6 text-right text-light-200'>{GOVERNMENT_ID}</th>
                                <td className=''>
                                  <a
                                    href={user.government_id}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    download='CAC'
                                  >
                                    <i className='fas fa-download text-primary text-lg bg-light-blue-1 px-3 pt-2 cursor-pointer' />
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className='w-full'>
                <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>Loan Details</h2>
              </div>

              <div className='w-full'>
                <p>
                  <strong className='text-light-200 font-extrabold'>Loan Amount: </strong>{' '}
                  <span>₦{formatCurrency(details.loanAmount)}</span>
                </p>
                <p>
                  <strong className='text-light-200 font-extrabold'>Loan Duration: </strong>{' '}
                  <span>{details.loanDuration} months</span>
                </p>
                <p>
                  <strong className='text-light-200 font-extrabold'>
                    Pharmacist License Expiration Date:{' '}
                  </strong>{' '}
                  <span>{details.licenseExp}</span>
                </p>
                <p>
                  <strong className='text-light-200 font-extrabold'>Loan Purpose: </strong>{' '}
                  <span>{details.loanPurpose}</span>
                </p>
              </div>
            </div>
          ) : (
            <div className='flex justify-center items-center' style={{ height: '60vh' }}>
              <h1>
                <img src={loader} className='w-14' alt='Loading...' />
              </h1>
            </div>
          )}
        </div>
        {distributors.length > 0 && (
          <>
            <div className='w-full'>
              <h2 className='mt-5 mb-5 text-xl font-bold text-light-200'>Selected Distributors</h2>
            </div>
            <div className='grid gap-5 grid-cols-3 '>
              {distributors.map(item => (
                <div key={item.value} className='border-r border-b'>
                  <p>
                    <span className='font-extrabold'>Distributor : </span>
                    {item.label}
                  </p>
                  <p>
                    <span className='font-extrabold'>Amount : </span> {item.amount}
                  </p>
                </div>
              ))}
              {amountDiscrepancyError && (
                <p className='text-red-600'>Total Amount must equal loan Amount when summed</p>
              )}
            </div>
          </>
        )}
        <div className='w-full flex mt-4 mb-8 md:ml-6 justify-start'>
          <button className='btn-primary mr-4' onClick={() => setVisible(false)}>
            CLOSE
          </button>
          <button className='btn-primary px-4' onClick={() => handleSubmit()}>
            {loading && <i className='fas fa-spin fa-spinner text-primary mr-2' />}
            Submit Now
          </button>{' '}
        </div>
      </Modal>
    </>
  )
}
PreviewApplication.propTypes = {
  headerTitle: PropTypes.string,
  input: PropTypes.object,
  handleSubmit: PropTypes.func,
  nextKey: PropTypes.func,
  setError: PropTypes.func,
  amountDiscrepancyError: PropTypes.bool,
  distributors: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
}
export default PreviewApplication
