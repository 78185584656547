import React from 'react'
import PropTypes from 'prop-types'
import emailImg from '../../../images/ic_baseline-email.svg'
import { Spin } from 'antd'

const EmailVerificationMaodal = ({ onClose, onSubmit, sendingVeirifcation }) => {
  return (
    <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-700 bg-opacity-50'>
      <div className='bg-white w-full max-w-xs p-6 rounded-lg flex flex-col justify-center items-center'>
        <div className='w-24'>
          <img src={emailImg} alt='Success' className='w-full' />
        </div>
        <h2 className='text-2xl font-bold mb-4'>Confirm Email</h2>
        <p className='text-xl px-6 text-center'>Do you want to send confirmation email?</p>
        <div className='flex justify-between gap-5'>
          <button
            onClick={onClose}
            className=' px-6 border-2 flex font-medium self-center justify-center p-2 mt-4 rounded'
          >
            No
          </button>
          <button
            type='submit'
            onClick={onSubmit}
            disabled={sendingVeirifcation}
            className='px-6 border-2 font-medium flex self-center justify-center p-2 mt-4 rounded'
          >
            Yes {sendingVeirifcation && <Spin size='small' />}
          </button>
        </div>
      </div>
    </div>
  )
}
EmailVerificationMaodal.propTypes = {
  onClose: PropTypes.func.isRequired,
  sendingVeirifcation: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}

export default EmailVerificationMaodal
