/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, notification } from 'antd'
import { Icon } from '@iconify/react'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../../../redux/store'
import { AntDesignFormSelect } from '../../../../sharedComponents/form-select/FormSelect'
import { LoadingOutlined } from '@ant-design/icons'
import { SaasCustomerService } from '../../../../services'

const bodyStyle = {
  padding: 0
}

const AddCustomerSaaSProfileModal = ({ visible, onCancel }) => {
  const [selectedUser, setSelectedUser] = useState(null)
  const existingUsers = useSelector(select.users.getUsersNotAdmin)
  const [terminalProfile, setTerminalProfile] = useState('')
  const [loading, setLoading] = useState(false)

  const handleUserChange = selectedUser => {
    setSelectedUser(selectedUser)
  }

  const handleSubmission = async () => {
    if (!selectedUser || !terminalProfile) {
      notification.error({
        message: 'Validation Error',
        description: 'All fields are required'
      })

      return
    }

    setLoading(true)

    await SaasCustomerService.addCustomerPosTerminal({
      user: selectedUser,
      terminal: terminalProfile
    })
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Customer profile added successfully'
        })
        setSelectedUser(null)
        setTerminalProfile(null)
        onCancel()
      })
      .catch(error => {
        notification.error({
          message: 'Something went wrong',
          description: error?.response?.data?.message || error?.message
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    // Check if existingUsers is empty or null
    if (!existingUsers || existingUsers.length === 0) {
      dispatch.users.getAllusers({
        showAll: true
      })
    }
  }, [])

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className='info-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          color='#b1b1ff'
          className='mt-4 mr-8 text-white text-4xl rounded-full bg-white'
        />
      }
      bodyStyle={bodyStyle}
      width={800}
    >
      <div className='modal-header bg-primary px-6 flex justify-between pb-4'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>
          Create Terminal Profile
        </h2>
      </div>
      <div className='px-4 pb-8'>
        <p className='font-bold text-lg py-8 pl-8'>Kindly provide the following Information:</p>
        <div className='flex flex-col gap-8 justify-center px-8'>
          <div>
            <p className='py-2 font-bold'>User </p>
            <AntDesignFormSelect
              options={existingUsers.map(({ first_name: firstName, last_name: lastName, id }) => ({
                label: `${firstName} ${lastName}`,
                value: id
              }))}
              value={selectedUser}
              onChange={handleUserChange}
              placeholder='Select Users'
              name='users'
            />
          </div>

          <div>
            <p className='py-2 font-bold'>Terminal Profile </p>
            <input
              type='text'
              placeholder='Enter Terminal ID'
              name='message_header'
              value={terminalProfile}
              onChange={e => setTerminalProfile(e?.target?.value)}
              className='w-full border-b py-3 border-gray-300 px-3 focus:outline-none focus:border-blue-500 mb-2'
            />
          </div>

          <div className='flex justify-center'>
            <button
              onClick={handleSubmission}
              className='bg-primary rounded-md md:px-12 py-2 text-white outline border border-gray-300 flex items-center justify-center'
            >
              {loading ? <LoadingOutlined spin /> : 'Create Profile '}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

AddCustomerSaaSProfileModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default AddCustomerSaaSProfileModal
