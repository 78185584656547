/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { dispatch } from '../../redux/store'
import RefreshToken from './RefreshToken'
import { checkIsAdminRoute } from '../../utils/helpers'

const CustomerRoutes = ({ component: Component, ...rest }) => {
  const { auth } = useSelector(state => ({ auth: state.auth, state }))
  const history = useHistory()

  const now = moment().unix()

  return (
    <Route
      {...rest}
      render={props => {
        const expTokenTime = auth.authUser?.userInfo?.exp
        const refreshToken = auth.authUser?.refreshToken

        if (
          (!expTokenTime || expTokenTime <= now) &&
          (!refreshToken?.token || refreshToken?.details?.exp < now)
        ) {
          // not logged in so redirect to login page with the return url

          dispatch({ type: 'auth/logout' })
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // You're admin trying to access user route, please redirect back to admin route
        // const isAdminRoute = checkIsAdminRoute(props.location.pathname)
        // if (!isAdminRoute && auth.authUser?.userInfo?.is_admin === 1) {
        //   return <Redirect to={{ pathname: '/admin-dashboard' }} />
        // }

        // authorised so return component
        return (
          <>
            <RefreshToken />
            <Component {...props} />
          </>
        )
      }}
    />
  )
}

CustomerRoutes.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object
}

export default CustomerRoutes
