import { Formik, Form, Field, ErrorMessage } from 'formik'
import React from 'react'
import { InputField } from '../../../../sharedComponents/input-field'
import { Select } from 'antd'
const OthersTransfer = () => {
  const { Option } = Select
  return (
    <main>
      <div className='text-red-600 text-base font-bold my-6'>
        Confirm account name before sending
      </div>
      <Formik enableReinitialize>
        <Form>
          <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-12'>
              <div>
                <label className='text-black  text-base'>Bank Name</label>
                <Field
                  as={Select}
                  placeholder='Bank Name'
                  name='bankName'
                  size='large'
                  className='form-control bg-white'
                >
                  <Option value='' disabled selected>
                    Select Bank
                  </Option>
                  <Option value='bank1'>Bank 1</Option>
                  <Option value='bank2'>Bank 2</Option>
                  <Option value='bank3'>Bank 3</Option>
                </Field>
              </div>
              <ErrorMessage name='bankName' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='number'
                name='name'
                label={<label className='text-black text-base'>Account Number</label>}
                placeholder='123156278'
                className='form-control bg-white'
              />
              <ErrorMessage name='name' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='name'
                label={<label className='text-black text-base'>Account Name</label>}
                placeholder='Vittas VFD'
                className='form-control bg-white'
              />
              <ErrorMessage name='name' component='small' className='text-red-500' />
            </div>

            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='name'
                label={<label className='text-black text-base'>Amount</label>}
                placeholder='example'
                className='form-control bg-white'
              />
              <ErrorMessage name='name' component='small' className='text-red-500' />
            </div>
          </div>
        </Form>
      </Formik>
    </main>
  )
}
export default OthersTransfer
