import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import totalImg from '../../images/icons/total.png'
import approvedImg from '../../images/icons/approved.png'
import pendingImg from '../../images/icons/pending.png'
import declinedImg from '../../images/icons/declined.png'
import loanDisbursedImg from '../../images/icons/loan-disbursed.png'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'

const Statistics = () => {
  const { allApplicationCount, loading } = useSelector(({ applications, loading }) => ({
    loading: loading.effects.applications.adminGetAllApplicationCount,
    allApplicationCount: applications.allApplicationCount
  }))

  const { state } = useLocation()

  const data = [
    {
      id: 1,
      title: 'Total Applications',
      image: totalImg,
      link: '/#',
      isDisabled: true,
      applications: !loading ? (
        allApplicationCount?.total_apps
      ) : (
        <i className='fa fa-spin fa-spinner text-gray-500' />
      )
    },
    {
      id: 2,
      title: 'Approved Applications',
      image: approvedImg,
      link: '/admin-approved',
      isDisabled: false,
      applications: !loading ? (
        allApplicationCount?.approved_apps
      ) : (
        <i className='fa fa-spin fa-spinner text-gray-500' />
      )
    },
    {
      id: 3,
      title: 'Pending Applications',
      image: pendingImg,
      link: '/admin-pending',
      isDisabled: false,
      applications: !loading ? (
        allApplicationCount?.pending_apps
      ) : (
        <i className='fa fa-spin fa-spinner text-gray-500' />
      )
    },
    {
      id: 4,
      title: 'Disbursed Loans',
      image: loanDisbursedImg,
      link: '/admin-disbursed',
      isDisabled: false,
      applications: !loading ? (
        allApplicationCount?.disbursed_apps
      ) : (
        <i className='fa fa-spin fa-spinner text-gray-500' />
      )
    },
    {
      id: 5,
      title: 'Declined Loans',
      image: declinedImg,
      link: '/admin-declined',
      isDisabled: false,
      applications: !loading ? (
        allApplicationCount?.declined_apps
      ) : (
        <i className='fa fa-spin fa-spinner text-gray-500' />
      )
    }
  ]
  useEffect(() => {
    dispatch.applications.adminGetAllApplicationCount({ shouldUseCache: state?.shouldUseCache })
  }, [])

  return (
    <div className='grid grid-cols-2 md:grid-cols-5 gap-2 mx-2 mt-4'>
      {data.map((d, i) => {
        return d.isDisabled ? (
          <div key={i} className='card-box hover:scale-101'>
            <div>
              <div className='text-3xl text-black font-bold'>{d.applications}</div>
              <div className='text-lg md:text-xl text-gray-600'>{d.title}</div>
            </div>
            <div className='pt-4'>
              <img src={d.image} alt='total' width='50' height='50' className='inline-block mr-2' />
            </div>
          </div>
        ) : (
          <Link key={d.id} to={{ pathname: d.link, state: { type: d.type } }}>
            <div className='card-box hover:scale-101'>
              <div>
                <div className='text-3xl text-black font-bold'>{d.applications}</div>
                <div className='text-lg md:text-xl text-gray-600'>{d.title}</div>
              </div>
              <div className='pt-4'>
                <img
                  src={d.image}
                  alt='total'
                  width='50'
                  height='50'
                  className='inline-block mr-2'
                />
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default Statistics
