import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import forgotPwd from '../../images/login/pwd-feedback.png'
import {
  FEEDBACK_RESEND_BTN,
  FEEDBACK_LABEL,
  FEEDBACK_SUB_TITLE,
  FEEDBACK_I_HAVE_TOKEN
} from './constant'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'

const PasswordModalBoxFeedback = ({ headerTitle }) => {
  const { isVisible, email } = useSelector(({ auth }) => auth.pwdModalFeedback)

  const [visible, setModal] = useState(false)

  useEffect(() => {
    setModal(isVisible)
  }, [isVisible])

  const handleCancel = () => {
    setModal(false)
    dispatch.auth.forgotPwdModalFeedback({ visible: true, email })
  }

  const showPwdModalHandle = () => {
    dispatch.auth.forgotPwdModalFeedback({ visible: false, email })
    dispatch.auth.forgotPasswordModal(true)
  }

  const changePwdHandle = () => {
    dispatch.auth.forgotPwdModalFeedback({ visible: false, email })
    dispatch.auth.changePwdModal(true)
  }

  return (
    <>
      <Modal open={visible} title={headerTitle} onCancel={handleCancel} footer={[]} zIndex='900'>
        <div className='flex flex-wrap mt-2'>
          <div className='w-full text-white'>
            <img src={forgotPwd} alt='forgot password' className='block mx-auto' />
            <h1 className='text-black text-2xl mt-2 font-bold text-center'>{FEEDBACK_LABEL}</h1>
            <p className='mt-2 text-black text-lg text-center'>
              {FEEDBACK_SUB_TITLE}
              <span className='text-gray-500'> {email}</span>
            </p>
            <div className='flex w-full'>
              <div className='w-1/2 mt-5 mx-2'>
                <button
                  onClick={showPwdModalHandle}
                  className='py-3 font-bold bg-teal-600 text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                >
                  {FEEDBACK_RESEND_BTN}
                </button>
              </div>
              <div className='w-1/2 mt-5 mx-2'>
                <button
                  onClick={changePwdHandle}
                  className='py-3 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                >
                  {FEEDBACK_I_HAVE_TOKEN}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
PasswordModalBoxFeedback.propTypes = {
  headerTitle: PropTypes.string.isRequired
}

export default PasswordModalBoxFeedback
