/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Select, Spin, Steps, notification } from 'antd'
import PropTypes from 'prop-types'
import { InputField } from '../../../../../sharedComponents/input-field'
import { Formik, Field, ErrorMessage, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import {
  BANK_VERIFICATION_NO,
  BIRTHDATE,
  BROWSE_FILE,
  EMAIL,
  FIRST_NAME,
  GENDER,
  HOME_ADDRESS,
  LAST_NAME,
  MARITAL_STATUS,
  PHONE_NO
} from '../../../../../sharedComponents/entries/constant'
import { Upload } from '../../../../../services'
import { validateFileTypes } from '../../../../../utils/helpers'
import { dispatch } from '../../../../../redux/store'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PreviewModal from '../modal/PreviewModal'
const { Step } = Steps
const { Option } = Select
const phoneRegExp = /^([0]{1})[0-9]{10}$/

const Step3 = ({ onNext, onGoBack, savedData, clearCurrentStep }) => {
  const [uploading, setUploading] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [previewFileUrl, setPreviewFileUrl] = useState('')

  const userIdFromParams = useParams().userId

  Yup.addMethod(Yup.array, 'uniqueProperty', function (propertyPath, message) {
    return this.test('unique', '', function (list) {
      const errors = []

      list.forEach((item, index) => {
        const propertyValue = _.get(item, propertyPath)

        if (propertyValue && _.filter(list, [propertyPath, propertyValue]).length > 1) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message
            })
          )
        }
      })

      if (!_.isEmpty(errors)) {
        throw new Yup.ValidationError(errors)
      }

      return true
    })
  })

  const validationSchema = Yup.object().shape({
    forms: Yup.array()
      .of(
        Yup.object().shape({
          first_name: Yup.string().required('First name is required'),
          last_name: Yup.string().required('Last name is required'),
          phone: Yup.string()
            .matches(phoneRegExp, 'Invalid phone number.')
            .required('Phone number is required'),
          email: Yup.string().email('Invalid email').required('Please enter email'),
          birth_date: Yup.date().required('Birthdate is required'),
          gender: Yup.string().required('Gender is required'),
          marital_status: Yup.string().required('Marital status is required'),
          address: Yup.string().required('Home address is required'),
          bank_veri_no: Yup.string().required('Bank verification number is required'),
          education_level: Yup.string().required('Education Level is required'),
          photo: Yup.string().required('Passport Photograph is required'),
          government_id: Yup.string().required('Government ID is required')
        })
      )
      .uniqueProperty('email', 'Duplicate email is not allowed')
  })

  const { data, isAdmin, token, permissions, loadingDirector } = useSelector(
    ({ auth: { authUser }, loading }) => ({
      isAdmin: authUser.userInfo?.is_admin,
      data: authUser.userInfo?.data,
      token: authUser.token,
      permissions: authUser.permissions,
      loadingDirector: loading.effects.directors.customerAddDirector
    })
  )

  const initialSignatoryData = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    birth_date: '',
    bank_veri_no: '',
    address: '',
    gender: '',
    marital_status: '',
    education_level: '',
    photo: '',
    government_id: ''
  }

  const getOrdinal = index => {
    const ordinals = [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
      'Sixth',
      'Seventh',
      'Eighth',
      'Ninth',
      'Tenth'
    ]
    return ordinals[index] || ''
  }

  /**
   * @description handles the form submission for step 1
   * @param {*} values the form values
   * @returns {void}
   */
  const handleSubmit = async values => {
    const endpoint = isAdmin ? 'admin/add-directors' : 'customer/add-directors'

    // TODO: add pop up modal telling them that they can't edit this step after submitting. They need to make sure all the info are correct.
    // Also disable this step once submited

    if (!_.isEqual(values?.forms, savedData?.directors)) {
      const { data } = await dispatch.directors.customerAddDirector({
        token,
        permissions,
        details: isAdmin
          ? values?.forms?.map(item => ({ ...item, user_id: userIdFromParams }))
          : values?.forms,
        endpoint
      })

      if (!loadingDirector) {
        // to be reviewed
        return await onNext(
          {
            directors: values?.forms,
            directorIds: data?.directorIds
          },
          3
        )
      }
    }

    return await onNext(false, 3)
  }

  const handleUploadFile = (file, setFileInFormik, fieldName) => {
    if (
      !validateFileTypes(file, ['jpeg', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'png', 'jpg', 'webp'])
    ) {
      notification.error({
        message: 'Invalid file type',
        description:
          'Please upload a valid file type, we only accept images, pdfs, excel files and docs'
      })
      return
    }

    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('file', file)
    Upload.uploadFile(formData)
      .then(({ data: { data } }) => {
        setFileInFormik(fieldName, data.url)
      })
      .catch(error => {
        setFileInFormik(fieldName, '')
        setUploading({ ...uploading, [fieldName]: '' })
        notification.error({
          message: 'Error',
          description: error.message
        })
      })
  }

  return (
    <div className='flex flex-col'>
      <Steps current={2} className='custom-steps'>
        <Step title='Business information' />
        <Step title='Business Documents' />
        <Step title='Business Signatories' />
        <Step title='Loan Details' disabled />
      </Steps>

      <div className='w-full mx-auto md:px-6 rounded-xl'>
        <div className='grid grid-cols-5'>
          <button
            onClick={() => clearCurrentStep(3)}
            className='text-blue-500 text-lg mt-10 text-right col-start-4'
          >
            Clear all information
          </button>
        </div>
        <div className='mx-auto rounded-xl bg-transparent'>
          <Formik
            initialValues={{
              forms:
                Array.isArray(savedData?.directors) && !_.isEmpty(savedData?.directors)
                  ? savedData?.directors
                  : [{ ...initialSignatoryData }, { ...initialSignatoryData }]
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnMount
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <FieldArray name='forms'>
                  {({ push, remove }) => (
                    <>
                      {values.forms.map((_, index) => (
                        <div key={index}>
                          <div className='mt-20 grid grid-cols-3'>
                            <div className='col-span-2'>
                              <h1 className='text-2xl font-extrabold'>
                                {getOrdinal(index)} Signatories
                              </h1>
                              <p className='text-base'>Fill the following information</p>
                            </div>

                            {index > 1 && (
                              <button
                                onClick={() => remove(index)}
                                className='ml-10 h-10 box-border text-red-700 p-0 rounded-md col-span-1 text-righ'
                              >
                                <i className='fa fa-trash mr-2 t text-red-700' /> Remove
                              </button>
                            )}
                          </div>

                          <div key={index} className='flex flex-wrap md:gap-8 md:mx-auto'>
                            <div className='w-full max-w-sm mt-4'>
                              <Field
                                as={InputField}
                                type='text'
                                name={`forms.${index}.first_name`}
                                maxLength='35'
                                label={<label className='text-black text-base'>{FIRST_NAME}</label>}
                                placeholder={FIRST_NAME}
                                className='form-control bg-white'
                              />
                              <ErrorMessage
                                name={`forms.${index}.first_name`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <Field
                                as={InputField}
                                type='text'
                                name={`forms.${index}.last_name`}
                                maxLength='35'
                                label={<label className='text-black text-base'>{LAST_NAME}</label>}
                                placeholder={LAST_NAME}
                                className='form-control bg-white'
                              />
                              <ErrorMessage
                                name={`forms.${index}.last_name`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <Field
                                as={InputField}
                                type='text'
                                name={`forms.${index}.phone`}
                                maxLength='15'
                                label={<label className='text-black text-base'>{PHONE_NO}</label>}
                                placeholder={PHONE_NO}
                                className='form-control bg-white'
                              />
                              <ErrorMessage
                                name={`forms.${index}.phone`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <Field
                                as={InputField}
                                type='email'
                                name={`forms.${index}.email`}
                                maxLength='45'
                                label={<label className='text-black text-base'>{EMAIL}</label>}
                                placeholder={EMAIL}
                                className='form-control bg-white'
                              />
                              <ErrorMessage
                                name={`forms.${index}.email`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <Field
                                as={InputField}
                                type='date'
                                label={<label className='text-black text-base'>{BIRTHDATE} </label>}
                                name={`forms.${index}.birth_date`}
                                max={new Date().toISOString().slice(0, 10)}
                                placeholder={BIRTHDATE}
                                className='form-control bg-white'
                              />
                              <ErrorMessage
                                name={`forms.${index}.birth_date`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <label className='text-black text-base leading-8'>{GENDER}</label>
                              <Field
                                as={Select}
                                name={`forms.${index}.gender`}
                                className='form-control border-0 mr-0 py-0 px-0 mt-0'
                                placeholder='Select gender'
                                size='large'
                                onSelect={value => {
                                  setFieldValue(`forms.${index}.gender`, value)
                                }}
                              >
                                <Option value=''>Select gender</Option>
                                <Option value='Male'>Male</Option>
                                <Option value='Female'>Female</Option>
                              </Field>
                              <ErrorMessage
                                name={`forms.${index}.gender`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>

                            <div className='w-full max-w-sm mt-4'>
                              <label className='text-black text-base leading-8'>
                                {MARITAL_STATUS}
                              </label>
                              <Field
                                as={Select}
                                name={`forms.${index}.marital_status`}
                                className='form-control border-0 mr-0 py-0 px-0 mt-0 '
                                placeholder='Marital Status'
                                size='large'
                                onSelect={value => {
                                  setFieldValue(`forms.${index}.marital_status`, value)
                                }}
                              >
                                <Option value=''>Marital Status</Option>
                                <Option value='Single'>Single</Option>
                                <Option value='Married'>Married</Option>
                                <Option value='Others'>Others</Option>
                              </Field>
                              <ErrorMessage
                                name={`forms.${index}.marital_status`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <Field
                                as={InputField}
                                type='text'
                                name={`forms.${index}.address`}
                                label={
                                  <label className='text-black text-base'>{HOME_ADDRESS} </label>
                                }
                                placeholder={HOME_ADDRESS}
                                className='form-control bg-white'
                              />
                              <ErrorMessage
                                name={`forms.${index}.address`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <Field
                                as={InputField}
                                type='text'
                                name={`forms.${index}.bank_veri_no`}
                                maxLength='11'
                                label={
                                  <label className='text-black text-base'>
                                    {BANK_VERIFICATION_NO}{' '}
                                  </label>
                                }
                                placeholder={BANK_VERIFICATION_NO}
                                className='form-control bg-white'
                              />
                              <ErrorMessage
                                name={`forms.${index}.bank_veri_no`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <label className='ml-2 text-black text-base leading-8'>
                                Education Degree
                              </label>
                              <Field
                                as={Select}
                                name={`forms.${index}.education_level`}
                                className='form-control border-0 mr-0 py-0 px-0 mt-0 '
                                placeholder='Select one'
                                size='large'
                                onSelect={value => {
                                  setFieldValue(`forms.${index}.education_level`, value)
                                }}
                              >
                                <Option value=''>None</Option>
                                <Option value='Higer Education'>Higer Education</Option>
                                <Option value='Bachelor'>Bachelor</Option>
                                <Option value='Masters'>Masters</Option>
                                <Option value='Ph.D'>Ph.D</Option>
                              </Field>
                              <ErrorMessage
                                name={`forms.${index}.education_level`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <div className='flex flex-col'>
                                <div className='relative'>
                                  <label
                                    htmlFor='images'
                                    className='block text-black text-base leading-8'
                                  >
                                    Passport Photograph
                                  </label>
                                  <input
                                    type='file'
                                    id={`photo_id_${index}`}
                                    name={`forms.${index}.photo`}
                                    accept='image/*, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .webp'
                                    onChange={event => {
                                      const file = event.target.files[0]
                                      setUploading({
                                        ...uploading,
                                        [`forms.${index}.photo`]: file?.name
                                      })

                                      handleUploadFile(file, setFieldValue, `forms.${index}.photo`)
                                    }}
                                    className='mt-1 sr-only' // Hide the default input appearance
                                  />
                                  {Boolean(values.forms[index].photo) && (
                                    <span
                                      className='pr-3 text-primary block absolute mt-3 ml-5 cursor-pointer hover:bg-primary hover:bg-opacity-50 rounded-md px-2 hover:text-white'
                                      onClick={() => {
                                        setPreviewFileUrl(values.forms[index].photo)
                                        setModalVisible(true)
                                      }}
                                    >
                                      view
                                    </span>
                                  )}
                                  <label
                                    htmlFor={`photo_id_${index}`}
                                    className='bg-white rounded-md pt-2 cursor-pointer w-full block text-right border border-gray-200 text-black px-3 h-12 align-middle leading-loose'
                                  >
                                    {/* If not uploading, then make text to be browse file */}
                                    {!values.forms[index].photo &&
                                      uploading[`forms.${index}.photo`] === undefined &&
                                      BROWSE_FILE}
                                    {/* if uploaded, then change text to change file */}
                                    {Boolean(values.forms[index].photo) && (
                                      <>
                                        <span className='pr-3'>
                                          {' '}
                                          {(
                                            uploading[`forms.${index}.photo`] ||
                                            values.forms[index].photo?.split('/')[
                                              values.forms[index].photo?.split('/').length - 1
                                            ]
                                          ).substring(0, 15)}
                                          ...
                                        </span>{' '}
                                        <span className='text-blue-700 underline'>Change file</span>
                                      </>
                                    )}
                                    {/* if its uploading, then change text to uploading */}
                                    {uploading[`forms.${index}.photo`] &&
                                      !values.forms[index].photo &&
                                      'Uploading...'}
                                  </label>
                                </div>
                                <ErrorMessage
                                  name={`forms.${index}.photo`}
                                  component='small'
                                  className='text-red-500'
                                />
                              </div>
                            </div>
                            <div className='w-full max-w-sm mt-4'>
                              <div className='relative'>
                                <label
                                  htmlFor='images'
                                  className='block text-black text-base leading-8'
                                >
                                  Government ID
                                </label>
                                <input
                                  type='file'
                                  id={`government_id_${index}`}
                                  name={`forms.${index}.government_id`}
                                  accept='image/*, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .png, .jpg, .webp'
                                  onChange={event => {
                                    const file = event.target.files[0]
                                    setUploading({
                                      ...uploading,
                                      [`forms.${index}.government_id`]: file?.name
                                    })

                                    handleUploadFile(
                                      file,
                                      setFieldValue,
                                      `forms.${index}.government_id`
                                    )
                                  }}
                                  className='mt-1 sr-only' // Hide the default input appearance
                                />
                                {Boolean(values.forms[index].government_id) && (
                                  <span
                                    className='pr-3 text-primary block absolute mt-3 ml-5 cursor-pointer hover:bg-primary hover:bg-opacity-50 rounded-md px-2 hover:text-gray-200'
                                    onClick={() => {
                                      setPreviewFileUrl(values.forms[index].government_id)
                                      setModalVisible(true)
                                    }}
                                  >
                                    view
                                  </span>
                                )}
                                <label
                                  htmlFor={`government_id_${index}`}
                                  className='bg-white rounded-md pt-2 cursor-pointer w-full block text-right border border-gray-200 text-black px-3 h-12 align-middle leading-loose'
                                >
                                  {/* If not uploading, then make text to be browse file */}
                                  {!values.forms[index].government_id &&
                                    uploading[`forms.${index}.government_id`] === undefined &&
                                    BROWSE_FILE}
                                  {/* if uploaded, then change text to change file */}
                                  {Boolean(values.forms[index].government_id) && (
                                    <>
                                      <span className='pr-3'>
                                        {' '}
                                        {(
                                          uploading[`forms.${index}.government_id`] ||
                                          values.forms[index].government_id?.split('/')[
                                            values.forms[index].government_id?.split('/').length - 1
                                          ]
                                        ).substring(0, 15)}{' '}
                                        ...
                                      </span>{' '}
                                      <span className='text-blue-700 underline'>Change file</span>
                                    </>
                                  )}
                                  {/* if its uploading, then change text to uploading */}
                                  {uploading[`forms.${index}.government_id`] &&
                                    !values.forms[index].government_id &&
                                    'Uploading...'}
                                </label>
                              </div>
                              <ErrorMessage
                                name={`forms.${index}.government_id`}
                                component='small'
                                className='text-red-500'
                              />
                            </div>
                            {/* ... other input fields for the signatory */}
                            {values.forms.length - 1 === index && (
                              <div className='flex justify-end md:mt-8 xxl:ml-44 w-3/4'>
                                <button
                                  className='btn-primary rounded-none bg-primary px-8 py-2 text-white'
                                  onClick={() => push(initialSignatoryData)}
                                >
                                  Add New Signatory
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                      {/* Only show the next button under the last item */}

                      <div className='w-full flex py-8 mt-8 gap-12 justify-start md:ml-4'>
                        <button className='text-base text-primary' onClick={onGoBack}>
                          Go back
                        </button>
                        <button
                          className='btn-primary bg-primary px-8 py-2 text-white'
                          type='submit'
                        >
                          Next{' '}
                          {loadingDirector ? (
                            <Spin size='small' />
                          ) : (
                            <i className='fa fa-arrow-right ml-2' />
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <PreviewModal
        modalVisible={modalVisible}
        previewFileUrl={previewFileUrl}
        setModalVisible={setModalVisible}
      />
    </div>
  )
}

Step3.propTypes = {
  onNext: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  savedData: PropTypes.arrayOf(PropTypes.object),
  clearCurrentStep: PropTypes.func
}

export default Step3
