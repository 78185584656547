import React from 'react'
import YouTube from 'react-youtube'
import { InlineWidget } from 'react-calendly'
import HAPPY_LADY_IMAGE from '../../images/landing/happy_lady.png'
import { useMediaQuery } from 'react-responsive'

function HealthFinanceLandingSuccess() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const onPlayerReady = event => {
    event.target.pauseVideo()
  }

  const opts = {
    width: !isDesktopOrLaptop ? '300' : '600',
    height: '390',
    playerVars: {
      autoplay: 1
    }
  }

  return (
    <div className='mx-3 md:mx-16 pt-16'>
      <p className='font-bold text-3xl md:text-4xl text-center'>You&apos;re in! 🎉 </p>
      <p className='text-center text-lg'>
        Get ready to fast track your confidence, growth and income.
      </p>

      <div className='flex justify-center mt-70px'>
        <YouTube videoId='6vWOSCVRMX0' opts={opts} onReady={onPlayerReady} />
      </div>
      <div className='flex justify-center'>
        <p className='mt-63px text-center text-lg md:max-w-800px'>
          Are you a clinician or medical practice in Lagos looking to enhance your capabilities with
          cutting-edge biomedical equipment? Look no further! Vittas International offers biomedical
          loans ranging from $25,000 to $35,000, exclusively designed to empower clinicians and
          clinics with the latest tools for improved diagnostics and therapies.
        </p>
      </div>

      <div className='mt-16 mb-8'>
        <p className='font-bold text-2xl md:text-3xl text-center md:text-left'>
          Want to get a head start?
        </p>
        <p className='text-center md:text-left'>We&apos;re offering a free 1:1 consultation.</p>
      </div>

      <section className='flex flex-col gap-4 md:flex-row items-center'>
        <div>
          <img src={HAPPY_LADY_IMAGE} alt='happy lady' />
        </div>
        <div className='flex-grow w-full  md:w-201px'>
          <InlineWidget url='https://calendly.com/contact-kqob/book-a-call' />
        </div>
      </section>

      <div className='my-32' />
    </div>
  )
}

export default HealthFinanceLandingSuccess
