import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

const DoughnutChart = () => {
  const { allApplicationCount, loading } = useSelector(({ applications, loading }) => ({
    loading: loading.effects.applications.adminGetAllApplicationCount,
    allApplicationCount: applications.allApplicationCount
  }))

  const data = {
    datasets: [
      {
        data: [
          allApplicationCount?.approved_apps,
          allApplicationCount?.pending_apps,
          allApplicationCount?.disbursed_apps
        ],
        backgroundColor: ['#71F1C6', '#F3D780', '#F67B7B'],
        borderWidth: 1,
        borderColor: 'white',
        hoverBorderColor: 'white'
      }
    ],
    labels: ['Approved', 'Pending', 'Disbursed']
  }

  const options = {
    animation: true,
    cutoutPercentage: 100,
    layout: { padding: 0 },
    legend: {
      display: true
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: 'blue',
      bodyFontColor: 'white',
      borderColor: 'red',
      borderWidth: 1,
      enabled: true,
      footerFontColor: 'white',
      intersect: false,
      mode: 'index',
      titleFontColor: 'yellow'
    }
  }
  return (
    <div style={{ padding: '10px' }} className='h-full'>
      <Doughnut data={data} options={{ ...options, loading }} />
    </div>
  )
}

export default DoughnutChart
