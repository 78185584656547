/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import SearchModal from '../modal/SearchModal'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import ExistingLoanSearchModal from '../modal/ExistingLoanSearchModal'

const DropdownBtn = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isExistingCustomerModalOpen, setIsExistingCustomerModalOpen] = useState(false)

  const options = ['Register a Company', 'Apply for loan', 'Apply for existing customer']

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionSelect = option => {
    setSelectedOption(option)
    setIsOpen(false)
    if (option === 'Apply for loan') {
      setShowModal(true)
      setIsExistingCustomerModalOpen(false)
    } else if (option === 'Apply for existing customer') {
      setIsExistingCustomerModalOpen(true)
      setShowModal(false)
    } else {
      setShowModal(false)
      setIsExistingCustomerModalOpen(false)
    }
  }

  const closeModal = () => {
    setShowModal(false)
    setIsExistingCustomerModalOpen(false)
  }

  return (
    <div className='relative z-10'>
      <button
        className='px-4  btn-primary bg-primary text-white rounded-md flex items-center'
        onClick={handleDropdownToggle}
      >
        New Application
        <span className='ml-2 mb-2'>
          {isOpen ? (
            <>
              <UpOutlined />
            </>
          ) : (
            <>
              {' '}
              <DownOutlined />
            </>
          )}
        </span>
      </button>
      {isOpen && (
        <div className='absolute top-12 right-0 mt-2  bg-white shadow-lg rounded-md z-20'>
          {options.map((option, index) => {
            const shouldDisable = option !== 'Apply for loan'
            const shouldDisables = option !== 'Apply for existing customer'
            return (
              <button
                key={index}
                disabled={shouldDisable && shouldDisables}
                className={`${
                  shouldDisable && shouldDisables ? 'cursor-not-allowed' : ''
                } block w-full px-4 py-2 text-gray-800 hover:bg-blue-200 border-b-2 text-left`}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </button>
            )
          })}
        </div>
      )}
      {showModal && selectedOption === 'Apply for loan' && (
        <SearchModal isOpen={showModal} onClose={closeModal} />
      )}
      {isExistingCustomerModalOpen && (
        <ExistingLoanSearchModal isOpen={isExistingCustomerModalOpen} onClose={closeModal} />
      )}
    </div>
  )
}

export default DropdownBtn
