import React from 'react'
import PropTypes from 'prop-types'

function WhoShouldApplySection({ executeScroll }) {
  const applicationReasons = [
    'Proven revenue history',
    'Predictable Growth Trajectory',
    'Focus on improving patient care through advanced equipment.'
  ]
  return (
    <section>
      <div>
        <p className='font-bold text-3xl md:text-4xl text-center'>Who Should Apply</p>
        <p className='text-lightStone text-center text-2xl mt-4'>
          Clinicians or existing practices in Lagos with
        </p>
      </div>
      <div className='flex gap-6 flex-wrap justify-center mb-16 md:mb-131px mt-42px'>
        {applicationReasons.map((reason, index) => (
          <div
            key={index}
            className='shadow-2xl w-358px text-center px-4 h-106px  bg-white text-vdfHtexts font-bold flex justify-center items-center'
          >
            {reason}
          </div>
        ))}
      </div>

      <section className='bg-newBgBlue h-screen md:h-80vh flex flex-col gap-6 justify-center px-4'>
        <p className='text-white text-3xl md:text-3xl font-bold text-center md:leading-10'>
          Apply Now and Elevate Your Practice!
        </p>
        <div className='flex justify-center'>
          <p className='text-center text-lightGray md:max-w-1000px md:text-2xl'>
            Take your clinic to new heights with Vittas International biomedical loans. Apply now
            and revolutionize your capabilities for better patient outcomes.
          </p>
        </div>
        <div className='flex justify-center'>
          <button
            className='bg-lightGray text-newBgBlue font-bold  w-130px h-48px rounded-12px'
            onClick={executeScroll}
          >
            Apply Now
          </button>
        </div>
      </section>
    </section>
  )
}

WhoShouldApplySection.propTypes = {
  executeScroll: PropTypes.func
}

export default WhoShouldApplySection
