/* eslint-disable camelcase */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import { DatePicker, Dropdown, Table } from 'antd'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import { interestTrackertColums } from './constants'
import { useSelector } from 'react-redux'
import iconImg from '../../../images/icons/angle-down.png'
import loanIcon from '../../../images/icons/loan 2.png'
import approvedIcon from '../../../images/icons/approved.png'
import { dispatch } from '../../../redux/store'
import { exportFile } from '../../../utils/export'
import { pastSixMonths, todaysDate } from '../../../utils/dateRange'
import { formatCurrency } from '../../../utils/helpers'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { getQueryParams } from '../../../utils/customeReactHook'

const { RangePicker } = DatePicker

const InterestTracker = () => {
  const history = useHistory()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [rangeValues, setRangeValues] = useState([pastSixMonths, todaysDate])
  const { loanId } = useParams()
  const query = getQueryParams()

  const searchInputRef = React.useRef(null)

  const {
    totalPaidAmount,
    interestTrackerData,
    totalExpectedAmount,
    isServerError,
    interestTrackerLoading,
    loanInterestTrackerLoading,
    loanPaymentTracker,
    customTotalExpectedAmount,
    customTotalPaidAmount
  } = useSelector(({ payments, loading, loans }) => ({
    interestTrackerLoading: loading.effects.payments.interestTracker,
    loanInterestTrackerLoading: loading.effects.loans.viewLoanInterestTrackerPayment,
    interestTrackerData:
      payments?.interestTracker?.interest_tracker?.map(item => ({
        ...item,
        key: item.payment_id
      })) || [],
    totalExpectedAmount: payments?.interestTracker?.totals?.total_expected_amount,
    totalPaidAmount: payments?.interestTracker?.totals?.total_paid_amount,
    customTotalExpectedAmount: loans?.viewLoanInterestPaymentTrackerTotal?.total_expected_amount,
    customTotalPaidAmount: loans?.viewLoanInterestPaymentTrackerTotal?.total_interest_paid,
    isServerError: payments.isServerError,
    loanPaymentTracker: loans?.viewLoanInterestPaymentTracker
  }))
  const [dataSource, setDataSource] = useState([])

  const onSelectChange = (newSelectedRowKeys, selectedrecords) => {
    setSelectedRowKeys(newSelectedRowKeys)

    // TODO: What do we want to do with the selected records
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }
  const onDateChange = (_, dateString) => {
    setRangeValues(dateString)
  }

  const handleSearch = e => {
    const currValue = e.target.value
    const filteredData = interestTrackerData.filter(entry =>
      entry.business_name.toLocaleLowerCase().includes(currValue)
    )
    setDataSource(filteredData)
  }

  useEffect(() => {
    if ((!interestTrackerData?.length || isServerError || rangeValues) && !interestTrackerLoading) {
      const startEndDate = {
        start: rangeValues[0],
        end: rangeValues[1]
      }

      const start = query.get('start')
      const duration = Number(query.get('duration')) + 1

      const customStartEndDate = {
        start,
        end: moment(start, 'YYYY-MM-DD').add(duration, 'months').format('YYYY-MM-DD'),
        ...(loanId && { approved_loan_id: loanId })
      }

      loanId
        ? dispatch.loans.viewLoanInterestTrackerPayment(customStartEndDate)
        : dispatch.payments?.interestTracker(startEndDate)
    }
  }, [rangeValues, isServerError, loanId])

  useEffect(() => {
    if (
      (!interestTrackerLoading && interestTrackerData.length > 0) ||
      (!loanInterestTrackerLoading && loanPaymentTracker.length > 0)
    ) {
      setDataSource(loanId ? loanPaymentTracker : interestTrackerData)
    }
  }, [interestTrackerLoading, loanInterestTrackerLoading])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        {loanId && (
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' /> Back
          </h3>
        )}
        <div className='mb-10'>
          <div className='grid md:grid-cols-3 gap-8'>
            <div className='flex border-2 p-8 px-5 rounded-md'>
              <div className='grid'>
                <span className='text-2xl font-bold'>
                  ₦{formatCurrency(loanId ? customTotalPaidAmount || 0 : totalPaidAmount)}
                </span>{' '}
                Total Interest Paid
              </div>
              <div>
                <img className='ml-14' src={approvedIcon} />
              </div>
            </div>
            <div className='flex border-2 p-8 px-5 rounded-md'>
              <div className='grid'>
                <span className='text-2xl font-bold'>
                  ₦{formatCurrency(loanId ? customTotalExpectedAmount || 0 : totalExpectedAmount)}
                </span>
                <span>Total Expected Interest </span>
              </div>
              <div>
                <img className='ml-10' src={loanIcon} />
              </div>
            </div>
            <div>
              <Dropdown menu={{ items: menu(dataSource) }} placement='bottomRight' arrow>
                <button className='flex-end btn-primary-200 rounded md:ml-52'>EXPORT</button>
              </Dropdown>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 text-lg  '>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 text-lg '>
              <div className='relative h-14 w-80 mr-5 mt-6 border rounded-md col-span-3'>
                <div className='absolute top-4 left-2 pl-2'>
                  <button onClick={() => searchInputRef.current.focus()}>
                    <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />
                  </button>
                </div>
                <div>
                  <input
                    type='text'
                    ref={searchInputRef}
                    onChange={handleSearch}
                    className='h-14 w-80 pl-16 border pr-10 rounded-md z-0 focus:shadow focus:outline-none'
                    placeholder='Search by business name...'
                  />
                </div>
              </div>
            </div>
            <div className='flex  mt-6'>
              <span className='mt-4'>Filter by:</span>
              <div className='relative flex border rounded-md  ml-3'>
                <RangePicker
                  defaultValue={[todaysDate, pastSixMonths]}
                  format='YYYY-MM-DD'
                  onChange={onDateChange}
                />
                <div className='absolute top-1 right-3 my-6 focus:outline-none pr-2'>
                  <img className=' text-gray-400 z-20 hover:text-gray-500' src={iconImg} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          rowClassName='cursor-pointer'
          onRow={record => {
            return {
              onClick: () => {
                return history.push(`/interest-payment-details/${record.user_id}`, record)
              } // click row
            }
          }}
          rowSelection={rowSelection}
          columns={interestTrackertColums()}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, hideOnSinglePage: true }}
          dataSource={dataSource}
          loading={interestTrackerLoading || loanInterestTrackerLoading}
        />
      </main>
    </DashboardLayout>
  )
}

export const menu = data => [
  {
    label: (
      <button
        onClick={() => {
          exportFile({ data, column: interestTrackertColums() }, 'Pdf', true)
        }}
        disabled={data && data?.length < 1}
        title={data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        PDF
      </button>
    ),
    key: 1
  },
  {
    label: (
      <button
        onClick={() => {
          exportFile({ data, column: interestTrackertColums() }, '.xls', true)
        }}
        disabled={data && data?.length < 1}
        title={data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        Excel
      </button>
    ),
    key: 2
  }
]

export default InterestTracker
