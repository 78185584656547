/* eslint-disable react/display-name */
/* eslint-disable camelcase */

import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import PropTypes from 'prop-types'

const AppTable = ({ columns, tableData, loading, rowClassName, onRow }) => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  return (
    <div className='mb-40'>
      <Table
        onRow={onRow}
        rowClassName={rowClassName}
        columns={columns}
        onChange={value => setPagination(value)}
        pagination={pagination}
        loading={loading}
        dataSource={data}
      />
    </div>
  )
}

AppTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  columns: PropTypes.array,
  rowClassName: PropTypes.string,
  onRow: PropTypes.func
}

export default AppTable
