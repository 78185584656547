import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import noteWhite from '../../../images/icons/note-white.svg'
import noteGray from '../../../images/icons/note-gray.svg'
import { ALL_APP, APP_IN_VIEW, APPROVED_APP, DECLINED_APP } from '../constant'

const ApplicationMenu = ({ type }) => {
  const SUB_MENU = [
    {
      id: 1,
      link: '../applications/all-application',
      title: ALL_APP,
      menuType: 'all-application'
    },
    {
      id: 2,
      link: '../applications/application-in-review',
      title: APP_IN_VIEW,
      menuType: 'application-in-review'
    },
    {
      id: 3,
      link: '../applications/approved-application',
      title: APPROVED_APP,
      menuType: 'approved-application'
    },
    {
      id: 4,
      link: '../applications/declined-application',
      title: DECLINED_APP,
      menuType: 'declined-application'
    }
  ]
  return (
    <div className='grid grid-cols-2 md:grid-cols-4 gap-1 mx-6 mt-4 text-lg'>
      {SUB_MENU.map(({ id, menuType, title, link }) => (
        <Link
          to={link}
          key={id}
          className={`pt-4 cursor-pointer  px-3 pb-3 ${
            type === menuType
              ? 'bg-primary text-white hover:bg-primary-200'
              : 'hover:bg-gray-100 text-gray-700'
          }`}
        >
          <img
            src={type === menuType ? noteWhite : noteGray}
            alt={title}
            width='22'
            height='22'
            className='inline-block mr-2 -mt-2'
          />
          <span className='font-semibold'>{title}</span>
        </Link>
      ))}
    </div>
  )
}

ApplicationMenu.propTypes = {
  type: PropTypes.string
}

export default ApplicationMenu
