import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const VittasButton = ({ text, loading, showicon, link, onClick, ...others }) => {
  const history = useHistory()
  return (
    <button
      {...others}
      onClick={e => {
        if (link) {
          history.push(link)
        }
        if (onClick) {
          onClick(e)
        }
      }}
      className={`${others.className} hover:translate-y-2 duration-500 transform px-6 py-4 text-xl font-semibold  rounded-md text-white bg-gray-900`}
    >
      {text} <i hidden={!showicon} className='ml-2 fa fa-angle-double-right' />{' '}
      <i hidden={!loading} className='fa fa-spinner fa-spin' aria-hidden='true' />
    </button>
  )
}

export default VittasButton

VittasButton.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
  showicon: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func
}
