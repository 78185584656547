import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useState } from 'react'
import { InputField } from '../../../../sharedComponents/input-field'
import { adminServices } from '../../../../services'
import { Select, Spin, notification } from 'antd'
import * as Yup from 'yup'
import WarningModal from './modal/WarningModal'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import PropTypes from 'prop-types'

const VittasTransfer = ({ cancelTransferModal, setRefreshKey }) => {
  const [loading, setLoading] = useState(true)
  const [banksLoading, setBanksLoading] = useState(true)
  const [loadingTransferInitiation, setLoadingTransferInitiation] = useState(false)
  const [banks, setBanks] = useState([])
  const [vittasAccountInfo, setVittasAccountInfo] = useState({})
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [selectedBank, setSelectedBank] = useState(null)
  const [refreshSelectKey, setRefreshSelectKey] = useState(0)
  const [formValues, setFormValues] = useState({})
  const customerId = useParams()?.id

  const getVittasAccountInfo = async () => {
    try {
      const { data } = await adminServices.getVittasAccountInfo()
      if (data.success) {
        setVittasAccountInfo(data.data)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  const getBanks = async () => {
    try {
      const { data } = await adminServices.getBanks()
      if (data.success) {
        setBanksLoading(false)
        setBanks(data.data?.bank)
        setRefreshSelectKey(Math.random())
      }
    } catch (err) {
      setBanksLoading(false)
    }
  }

  const onSelect = value => {
    setSelectedBank(value)
  }

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required('Amount is required')
  })

  const handleSubmit = async values => {
    if (!selectedBank) {
      notification.error({
        message: 'Bank is required',
        description: 'Select a bank from the list'
      })
    }

    if (values?.amount && selectedBank) {
      setFormValues(values)
      setShowWarningModal(true)
    }
  }

  const handleWarningCancel = () => {
    setShowWarningModal(false)
  }

  const handleWarningNext = async () => {
    try {
      setLoadingTransferInitiation(true)
      const { data } = await adminServices.initiateTransfer(
        {
          amount: formValues.amount,
          bankCode: selectedBank
        },
        customerId
      )

      window.localStorage.setItem('RECENT_TRANSFER_TRANSACTION', data)

      if (data.success === 1) {
        setShowWarningModal(false)
        setLoadingTransferInitiation(false)
        cancelTransferModal()
        notification.success({
          message: 'Transfer Success',
          description: 'Your transfer has been processed successfully'
        })
        setRefreshKey(Math.random())
      }
    } catch (err) {
      notification.error({
        message: 'Failed to Process Transfer',
        description: 'We are unable to process your transfer at this time.'
      })
      setLoadingTransferInitiation(false)
      setShowWarningModal(false)
    }
  }

  useEffect(() => {
    async function fetch() {
      await getVittasAccountInfo()
      await getBanks()
    }

    fetch()
  }, [])

  return (
    <main>
      <Formik
        initialValues={{
          amount: '',
          accountName: vittasAccountInfo?.name?.toUpperCase() || '',
          accountNo: vittasAccountInfo?.account?.number || ''
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          {loading && <p className='flex justify-center w-full mt-4'>Please wait...</p>}
          <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='accountName'
                label={<label className='text-black text-base'>Account Name</label>}
                placeholder='Vittas VFD'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='accountName' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='number'
                name='accountNo'
                label={<label className='text-black text-base'>Account Number</label>}
                placeholder='123156278'
                className='form-control bg-white'
                disabled
              />
              <ErrorMessage name='accountNo' component='small' className='text-red-500' />
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <div className='mb-2'>
                <label className='text-black text-base'>Select Bank</label>
              </div>
              <Select
                key={refreshSelectKey}
                showSearch
                size='large'
                className='form-control border-0 mr-0 px-0 mt-0 w-full max-w-sm'
                placeholder='Banks'
                optionFilterProp='children'
                onSelect={onSelect}
                defaultOpen
                filterOption={(input, option) =>
                  option?.children?.toLowerCase()?.includes(input?.toLowerCase())
                }
                loading={banksLoading}
              >
                {banks?.map(({ id, name, code }) => {
                  return (
                    <Select.Option key={id} value={code}>
                      {name}
                    </Select.Option>
                  )
                })}
                {banksLoading && (
                  <Select.Option key='' value='' disabled>
                    <Spin /> Please wait
                  </Select.Option>
                )}
              </Select>
            </div>
            <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8'>
              <Field
                as={InputField}
                type='text'
                name='amount'
                label={<label className='text-black text-base'>Amount</label>}
                placeholder='example'
                className='form-control bg-white'
              />
              <ErrorMessage name='amount' component='small' className='text-red-500' />
            </div>
          </div>
          <div className='py-10 flex justify-center w-full'>
            <button
              className='bg-primary flex mx-auto  text-base mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
              type='submit'
            >
              Continue
            </button>
          </div>
        </Form>
      </Formik>
      {/* WarningModal */}
      <WarningModal
        visible={showWarningModal}
        onCancel={handleWarningCancel}
        onNext={handleWarningNext}
        loading={loadingTransferInitiation}
      />
    </main>
  )
}

VittasTransfer.propTypes = {
  cancelTransferModal: PropTypes.func.isRequired,
  setRefreshKey: PropTypes.func.isRequired
}

export default VittasTransfer
