const PWD_BTN = 'Reset Password'
const EMAIL_LABEL = 'Email Address'
const CODE_LABEL = 'Enter Code'
const VITTAS_INTERNATIONAL = 'Vittas International'
const FORGOT_PASSWORD = 'Forgot Password?'
const SUB_TITLE = 'Enter your email address to reset password.'
const FEEDBACK_RESEND_BTN = 'Resend Code'
const FEEDBACK_SUB_TITLE = 'We just sent an email to '
const FEEDBACK_LABEL = 'Check Your Mail Box'
const FEEDBACK_I_HAVE_TOKEN = 'I Have A Token'
const CHANGE_PWD_BTN = 'Change Password'
const CREATE_NEW_PWD = 'Create New Password'
const TOKEN_LABEL = 'Enter Token'
const CONFIRM_NOW_BTN = 'Confirm Now'
const NEW_PWD_LABEL = 'Enter New Password'
const SIGN_UP_ACCOUNT_CONFIRMATION = 'Account Confirmation'
const CONFIRM_ACC_SUB_TITLE = 'Please enter the code sent to your e-mail'
const APPROVE_NOW = 'Approve Now'
const GENERATE_DOCS = 'Generate MOU docs'
const AMORTIZED_AMOUNT = 'Amortized Amount'
const PENDING_LOAN = 'Pending Loan'
const DECLINE_NOW = 'Decline Loan'
const DISBURSE_LOAN = 'Disburse Loan'
const DROP_REASON = 'Reason for this action:'
const LATE_FEE_PERCENT = 'Late fee percentage'
const LOAN_INTEREST_RATE = 'Loan interest rate'
const MGT_FEE = 'Management Fee'
const PAY_AMOUNT = 'Payment Amount'
const PAYMENT_DATE = 'What date did you receive the payment ?'
const PAYMENT_MONTH = 'What month is the payment for ?'
const IS_LAST_MONTH_PAYMENT = 'Payment for Last Month?'
const ADD_INTEREST_PAYMENT = 'Add Interest Payment'
const ADD_PRINCIPAL_PAYMENT = 'Add Principal Payment'
const ADD_PENALTY_PAYMENT = 'Add Penalty Payment'
const END_LOAN = 'End Loan'
const PAYMENT_DESCRIPTION = 'Payment Description'
const DEFAULTED_MONTH = 'Defaulted Month'
const LOAN_MESSAGE = 'Please fill the form to complete action'
const CONFIRM_DISBURSE = 'Please confirm you want to disburse this loan'
const LOAN_AMOUNT = 'Loan Amount'
const LOAN_DURATION = 'Loan Duration'
export {
  PWD_BTN,
  GENERATE_DOCS,
  CONFIRM_DISBURSE,
  DISBURSE_LOAN,
  DEFAULTED_MONTH,
  ADD_INTEREST_PAYMENT,
  ADD_PRINCIPAL_PAYMENT,
  ADD_PENALTY_PAYMENT,
  END_LOAN,
  PAYMENT_DESCRIPTION,
  IS_LAST_MONTH_PAYMENT,
  PAYMENT_DATE,
  PAY_AMOUNT,
  AMORTIZED_AMOUNT,
  PAYMENT_MONTH,
  LOAN_AMOUNT,
  EMAIL_LABEL,
  CODE_LABEL,
  VITTAS_INTERNATIONAL,
  FORGOT_PASSWORD,
  SUB_TITLE,
  FEEDBACK_RESEND_BTN,
  FEEDBACK_SUB_TITLE,
  FEEDBACK_LABEL,
  FEEDBACK_I_HAVE_TOKEN,
  CHANGE_PWD_BTN,
  CREATE_NEW_PWD,
  NEW_PWD_LABEL,
  TOKEN_LABEL,
  SIGN_UP_ACCOUNT_CONFIRMATION,
  CONFIRM_ACC_SUB_TITLE,
  CONFIRM_NOW_BTN,
  APPROVE_NOW,
  PENDING_LOAN,
  DECLINE_NOW,
  DROP_REASON,
  LATE_FEE_PERCENT,
  LOAN_MESSAGE,
  MGT_FEE,
  LOAN_INTEREST_RATE,
  LOAN_DURATION
}
