/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, Table } from 'antd'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { feedbackColumns } from './constants'
import iconImg from '../../images/icons/angle-down.png'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'
import { exportFile } from '../../utils/export'

const Feedbacks = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const { feedback, feedbackLoading, isServerError } = useSelector(
    ({ loading, feedbacks }) => ({
      feedbackLoading: loading.effects.feedbacks.getFeedbacks,
      feedback: feedbacks?.feedbacks?.map((item) => ({ ...item, key: item.id })) || [],
      isServerError: feedbacks.isServerError
    })
  )

  const onSelectChange = (newSelectedRowKeys, selectedrecords) => {
    setSelectedRowKeys(newSelectedRowKeys)

    // TODO: What do we want to do with the selected records
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  useEffect(() => {
    if ((!feedback?.length || isServerError) && !feedbackLoading) {
      dispatch.feedbacks.getFeedbacks()
    }
  }, [])
  return (
    <DashboardLayout>
      <div className='m-5'>
        <div className='mb-10'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 text-lg  '>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 text-lg mx-4'>
              <div className='relative mt-6 border rounded-md mr-6 ml-3 col-span-3'>
                <div className='absolute top-4 left-3 pl-2'>
                  <i className='fa fa-search text-gray-400 z-20 hover:text-gray-500' />{' '}
                </div>
                <div>
                  <input
                    type='text'
                    disabled
                    className='h-14 w-full pl-14 pr-10 rounded-md z-0 focus:shadow focus:outline-none'
                    placeholder='Search by message etc....'
                  />
                </div>
              </div>
            </div>
            <div className='flex mt-6'>
              <span className=' mt-4'>Filter by:</span>
              <div className='relative flex border rounded-md  ml-3'>
                <input
                  placeholder='Date'
                  type=''
                  disabled
                  className=' h-14 w-36 pl-2 pr-8 text-center rounded-md z-0 focus:shadow focus:outline-none'
                />
                <div className='absolute top-1 right-3 my-6 focus:outline-none pr-2'>
                  <img
                    className=' text-gray-400 z-20 hover:text-gray-500'
                    src={iconImg}
                  />
                </div>
              </div>
            </div>
            <div>
              <Dropdown menu={menu(feedback)} placement='bottomRight' arrow>
                <button className='flex-end btn-primary-200 rounded-md mt-6 md:ml-52'>
                  EXPORT
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={feedbackColumns}
          dataSource={feedback}
          loading={feedbackLoading}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, hideOnSinglePage: true }}
        />
      </div>
    </DashboardLayout>
  )
}

export const menu = (data) => (
  <Menu>
    <Menu.Item>
      <button
        onClick={() => exportFile({ data, column: feedbackColumns }, 'Pdf', true)}
        disabled={data && data?.length < 1}
        title={data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        PDF
      </button>
    </Menu.Item>
    <Menu.Item>
      <button
        onClick={() => exportFile({ data, column: feedbackColumns }, '.xls', true)}
        disabled={data && data?.length < 1}
        title={data?.length < 1 ? 'No data to export' : ''}
        className='rounded-sm p-2 cursor-pointer hover:bg-gray-100 text-lg'
      >
        Excel
      </button>
    </Menu.Item>
  </Menu>
)
export default Feedbacks
