import React, { useState } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import MoneySend from '../../../../../images/money-send.svg'
import Copy from '../../../../../images/copy.svg'
import HistoryCard from './HistoryCard'
import { historyData, historyData2 } from '../../constant'
import NewPin from '../../create-account/modal/NewPin'
import TransferModal from '../modal/TransferModal'

export const VfdAccountTransfer = () => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [transferModalVisible, setTransferModalVisible] = useState(false)

  const handleTransferClick = () => {
    setModalVisible(true)
  }

  const handleNewPinContinue = () => {
    // Set the state to show the CreateCorperateAccount modal
    setModalVisible(false)
    setTransferModalVisible(true)
  }

  return (
    <main className='px-4 w-full py-8'>
      <div className='flex w-full space-x-8'>
        <div className='w-full max-w-sm'>
          <div className='flex justify-between items-center'>
            <h1 className=' text-xl text-vdfHtexts  font-semibold '>Account</h1>
            <span className=''>
              <EllipsisOutlined />
            </span>
          </div>
          <div className='p-5 flex w-3/4 mt-4  flex-col py-7  rounded-lg bg-vdfBoxBg'>
            <h1 className='text-gray-500 text-base  font-semibold leading-normal'>
              Account Details
            </h1>
            <div className='flex flex-col mb-4 gap-4  mt-1'>
              <div className='flex gap-4'>
                <div className=' text-zinc-500 text-sm font-normal '>Account name</div>
                <div className=' text-black text-sm font-semibold '>Eric Okemmadu</div>
              </div>
              <div className='flex gap-4'>
                <div className=' text-zinc-500 text-sm font-normal '>Account number</div>
                <div className='flex gap-2 text-black text-sm font-semibold '>
                  09098654322{' '}
                  <span>
                    <img src={Copy} alt='icon' />{' '}
                  </span>
                </div>
              </div>
              <div className='flex gap-8'>
                <div className=' text-zinc-500 text-sm font-normal '>Account type</div>
                <div className=' text-black text-sm font-semibold '>Cooperate</div>
              </div>
              <div className='flex gap-20'>
                <div className=' text-zinc-500 text-sm font-normal '>Bank</div>
                <div className=' text-black text-sm font-semibold '>VFD</div>
              </div>
            </div>
          </div>
          <div className='p-5 flex w-full max-w-sm flex-col py-7 mt-8 rounded-lg bg-vdfBoxBg'>
            <h1 className='text-gray-500 text-base ml-4 font-semibold leading-normal'>
              Account Balance
            </h1>
            <div className='text-indigo-700  ml-4 mt-8 text-2xl font-semibold'>₦30,000,000.00</div>
          </div>
          <div className='p-5 flex w-full max-w-sm flex-col py-7 mt-8  rounded-lg bg-vdfBoxBg'>
            <h1 className='text-gray-500 text-base ml-4 font-semibold leading-normal'>
              Loan Balance
            </h1>
            <div className='text-indigo-700  ml-4 mt-8 text-2xl font-semibold'>- -</div>
          </div>
        </div>
        <div className='w-full max-w-xs'>
          <h1 className=' text-vdfHtexts text-xl font-semibold'>Actions</h1>
          <div
            onClick={handleTransferClick}
            className='p-5 px-8 cursor-pointer flex bg-indigo-600 bg-opacity-10 w-full max-w-sm flex-col py-7 mt-7 border-2 border-primary  rounded-lg '
          >
            <h1 className='text-primary text-base ml-4 font-semibold leading-normal'>Transfer</h1>
            <div className='text-indigo-700  ml-4 mt-8 text-2xl font-semibold'>
              <img src={MoneySend} alt='icon' color='primary' />
            </div>
          </div>
        </div>
        <div className='w-full'>
          <h1 className=' text-vdfHtexts text-xl font-semibold'>Transaction History</h1>
          <div className='p-5 px-8 flex w-full max-w-2xl flex-col  mt-7  rounded-lg bg-vdfBoxBg'>
            <p className=' text-black text-sm font-light leading-normal'>Dec 12, 2023</p>
            <HistoryCard {...historyData} />
            <HistoryCard {...historyData} />
            <HistoryCard {...historyData} />
            <p className=' text-black text-sm font-light mt-2 leading-normal'>Dec 12, 2023</p>
            <HistoryCard {...historyData2} />
            <HistoryCard {...historyData2} />
          </div>
        </div>
      </div>
      <NewPin
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        title='Verification Pin'
        description='Enter your pin to continue'
        onContinue={handleNewPinContinue}
      />
      <TransferModal
        visible={transferModalVisible}
        onCancel={() => setTransferModalVisible(false)}
        title='Transfer to'
      />
    </main>
  )
}

export default VfdAccountTransfer
