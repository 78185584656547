import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { monthNames } from '../../utils/data'

const BarChart = ({ newDATA }) => {
  const [data, setData] = useState({
    datasets: [
      {
        backgroundColor: '#7FE9AB',
        data: [100000, 500000, 1900000, 2700000, 2900000, 1900000, 2000000],
        label: 'Approved'
      },
      {
        backgroundColor: '#F9DB81',
        data: [1100000, 2000000, 1200000, 2900000, 3000000, 2500000, 1300000],
        label: 'Pending'
      },
      {
        backgroundColor: '#F96B6B',
        data: [1100000, 2000000, 1200000, 2900000, 3000000, 2500000, 1300000],
        label: 'Declined'
      }
    ],
    labels: []
  })

  const labels1 = []
  const mainData1 = []
  const labels2 = []
  const mainData2 = []
  const labels3 = []
  const mainData3 = []

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  useEffect(() => {
    if (newDATA) {
      if (Object.keys(newDATA).length > 0 && newDATA?.breakdown_approved_amount) {
        const entry1 = []
        Object.entries(newDATA?.breakdown_approved_amount).filter(item => {
          entry1.push(item[0].charAt(0) === '0' ? [item[0].slice(1), item[1]] : item)
        })
        const entry2 = []
        Object.entries(newDATA?.breakdown_declined_amount).filter(item => {
          entry2.push(item[0].charAt(0) === '0' ? [item[0].slice(1), item[1]] : item)
        })
        const entry3 = []
        Object.entries(newDATA?.breakdown_pending_amount).filter(item => {
          entry3.push(item[0].charAt(0) === '0' ? [item[0].slice(1), item[1]] : item)
        })
        const approved = Object.fromEntries(entry1)
        const declined = Object.fromEntries(entry2)
        const pending = Object.fromEntries(entry3)

        for (const key in approved) {
          if (Object.hasOwnProperty.call(approved, key)) {
            // Convert to month and push to label
            labels1.push(key - 1)
            mainData1.push(approved[key])
          }
        }

        for (const key in declined) {
          if (Object.hasOwnProperty.call(declined, key)) {
            // Convert to month and push to label
            labels2.push(key - 1)
            mainData2.push(declined[key])
          }
        }

        for (const key in pending) {
          if (Object.hasOwnProperty.call(pending, key)) {
            // Convert to month and push to label
            labels3.push(key - 1)
            mainData3.push(pending[key])
          }
        }

        setData({
          ...data,
          labels: [...data.labels, ...labels1, ...labels2, ...labels3]
            .filter(onlyUnique)
            .sort()
            .map(d => monthNames[d]),
          datasets: [
            { ...data.datasets[0], data: mainData1 },
            { ...data.datasets[1], data: mainData2 },
            { ...data.datasets[2], data: mainData3 }
          ]
        })
      }
    }
  }, [newDATA])

  return (
    <div>
      <Bar data={data} height={370} options={{ maintainAspectRatio: false }} />
    </div>
  )
}

BarChart.propTypes = {
  newDATA: PropTypes.object
}

export default BarChart
