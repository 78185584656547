import { Modal } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'

const PreviewModal = ({ modalVisible, setModalVisible, previewFileUrl }) => {
  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = previewFileUrl
    link.download = 'file-download'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Modal
      open={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={null}
      title={<div className='bg-primary text-white p-2 m-0 inset-0'>Preview</div>}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      className='preview-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          className='text-white mt-4 text-4xl rounded-full bg-white cursor-pointer'
          color='#b1b1ff'
        />
      }
      destroyOnClose
    >
      {!(previewFileUrl?.split('.').indexOf('pdf') > -1) ? (
        <img className='w-full p-2' src={previewFileUrl} />
      ) : (
        <iframe width={450} height={500} src={previewFileUrl} />
      )}

      <button
        className='btn-primary bg-primary px-8 py-2 flex w-17 mt-6 mx-auto self-center justify-center text-white'
        onClick={handleDownload}
      >
        Download
      </button>
    </Modal>
  )
}

export default PreviewModal

PreviewModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  previewFileUrl: PropTypes.string.isRequired
}
