import React, { useState } from 'react'
import VittasTransfer from './VittasTransfer'
import OthersTransfer from './OthersTransfer'
import PropTypes from 'prop-types'

const TransferNav = ({ cancelTransferModal, setRefreshKey }) => {
  const [activeBar, setActiveBar] = useState('Vittas')

  const handleTabClick = tab => {
    setActiveBar(tab)
  }
  return (
    <main className='w-full flex-grow p-4 bg-transparent'>
      <div className='flex justify-center space-x-4  w-full gap-5'>
        <button
          onClick={() => handleTabClick('Vittas')}
          className={`cursor-pointer ${
            activeBar === 'Vittas'
              ? 'border-b-4 text-lg border-blue-700 text-gray-500'
              : ' text-gray-500 px-3 text-lg py-2'
          }`}
        >
          Vittas
        </button>
        <button
          disabled
          className={`cursor-pointer ${
            activeBar === 'Others'
              ? 'border-b-4 text-lg border-blue-700  text-gray-500'
              : ' text-gray-500 px-3 text-lg py-2 opacity-50'
          }`}
        >
          Others
        </button>
      </div>
      <div className=' border-b-2 border-gray-100 mt-5' />
      <div className='w-full overflow-hidden'>
        {activeBar === 'Vittas' && (
          <VittasTransfer cancelTransferModal={cancelTransferModal} setRefreshKey={setRefreshKey} />
        )}
        {activeBar === 'Others' && <OthersTransfer />}
      </div>
    </main>
  )
}

TransferNav.propTypes = {
  cancelTransferModal: PropTypes.func.isRequired,
  setRefreshKey: PropTypes.func.isRequired
}

export default TransferNav
