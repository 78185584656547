import { Upload, message, notification } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ProfileForm } from '..'
import profileImage from '../../../images/profile.jpg'
import { dispatch } from '../../../redux/store'
import { UPLOAD_BASE_URL } from '../../../utils/constants'
import { SUPPORTED_IMAGE_TYPE } from '../../../utils/data'
import { UPDATE_IMAGE, SELECT_IMAGE } from '../constants'
import BusinessInformation from '../business-information/BusinessInformation'
import PropTypes from 'prop-types'

const ProfileInformation = ({ isAdmin }) => {
  const [image, setImage] = useState('')
  const [screenImage, setScreenImage] = useState('')
  const loading = useSelector(({ loading }) => loading.effects.auth.uploadProfileImage)
  const { data } = useSelector(({ auth }) => auth.authUser.userInfo)
  const uploadProps = {
    action: `//${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        // return message.error(`${file.name} is not supported`)
        return message.error('Not supported')
      }

      if (file.size / 1000000 > 35) {
        return message.error('File size more than 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const handleChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        const ImgUrl = info.file.response.data.url
        setImage(ImgUrl)
        setScreenImage(ImgUrl)
      }
    }
  }

  const handleUpload = () => {
    if (screenImage) {
      dispatch.auth.uploadProfileImage(screenImage)
    } else {
      notification.error({
        message: 'Request not completed',
        description: 'Please select an image to upload!'
      })
    }
  }

  return (
    <div className='w-full justify-center mt-8 md:w-3/5 md:mx-6'>
      <div className='flex flex-col'>
        <div className='flex'>
          <div className='flex items-center'>
            <h1 className='font-sans font-bold text-center text-2xl'>
              <img
                src={image || data.photo || profileImage}
                alt='profile'
                className='inline-block rounded-full object-cover'
                style={{ width: '100px', height: '100px' }}
              />
            </h1>
            <div
              className='text-center px-2 mx-auto block'
              style={{ wordWrap: 'break-word', maxWidth: '210px', minWidth: '180px' }}
            >
              <Upload {...uploadProps} onChange={info => handleChange(info, 'cacDocs')}>
                <p className='text-center font-normal text-base text-primary underline mx-auto cursor-pointer'>
                  {SELECT_IMAGE}
                </p>
              </Upload>
              <button
                disabled={!screenImage}
                className='outline-none px-4 rounded-md py-2 text-gray-500 border font-bold mr-4 text-base mt-4'
                style={{ cursor: !screenImage && 'not-allowed' }}
                onClick={handleUpload}
              >
                {loading && <i className='fa fa-spin fa-spinner mr-2' />}
                {UPDATE_IMAGE}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className='px-0 md:px-2'>
            <ProfileForm />

            {!isAdmin && (
              <div className='px-0 md:px-2'>
                <BusinessInformation />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ProfileInformation.propTypes = {
  isAdmin: PropTypes.bool
}

export default ProfileInformation
