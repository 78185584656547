import React from 'react'
import PropType from 'prop-types'
import ReactLoading from 'react-loading'

const Loader = ({ type, color, height, width }) => (
  <ReactLoading type={type} color={color} height={height} width={width} className='mx-auto' />
)

export default Loader

Loader.defaultProps = {
  type: 'spinningBubbles',
  color: '#A2A7C8',
  height: '20%',
  width: '20%'
}

Loader.propTypes = {
  type: PropType.string,
  color: PropType.string,
  height: PropType.string,
  width: PropType.string
}
