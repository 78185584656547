import React from 'react'
import WavyBackgroundHeader from '../../../sharedComponents/WavyBackgroundHeader'
import handshake from '../../../images/handshake.jpg'

const Header = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    })
  }
  return (
    <WavyBackgroundHeader>
      <div className='w-full lg:w-1/2 pt-32'>
        <h1 className='text-4xl sm:text-5xl text-white font-manrope font-bold'>
          Vittas Help Center
        </h1>
        <h5 className='text-xl sm:text-2xl text-white font-manrope font-medium mt-10 mb-16'>
          Let us know any questions you have and we are here to help
        </h5>
        <button
          onClick={scrollToBottom}
          className='px-6 py-4 text-white text-xl font-semibold bg-gray-900 rounded-md'
        >
          Chat With Us <i className='ml-2 fa fa-angle-double-right' />
        </button>
      </div>
      <div className='hidden md:block xl:flex justify-center items-center h-full w-1/2 pt-10'>
        <div className='border-4 border-gray-600 overflow-hidden rounded-2xl'>
          <img src={handshake} alt='Handshake image' />
        </div>
      </div>
    </WavyBackgroundHeader>
  )
}

export default Header
