import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { notification } from 'antd'

import LoanDetails from './LoanDetails'
import BusinessDocs from './BusinessDocs'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import Step5 from '../apply-for-loan/New-apply-loan/steps/Step5'
import { dispatch } from '../../../redux/store'
import { Loader } from '../../../sharedComponents'
import { useSelector } from 'react-redux'

const ApplyExistingLoan = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loanDetails, setLoanDetails] = useState(null)

  const history = useHistory()
  const userIdFromParams = useParams().userId

  const { submitting, isAdmin } = useSelector(({ loading, auth: { authUser } }) => ({
    submitting: loading.effects.loans.applyForExistingCustomer,
    isAdmin: authUser.userInfo?.is_admin
  }))

  const validateAndProcess = async (data = [], step) => {
    switch (step) {
      case 1: {
        const types = data.map(item => item.type)

        if (types.length > 1) {
          const valid = types.includes('bank_statement') && types.includes('pharmacist_license')
          const compareLocalAndCurrentData = !_.isEqual(
            JSON.parse(window.localStorage.getItem('uploadedFiles')),
            data
          )

          const typeOfUploadAction = isAdmin ? 'adminUploadDocument' : 'customerUploadDocument'

          if (valid && compareLocalAndCurrentData) {
            const docsIdsResponse = await dispatch.applications[typeOfUploadAction](
              data?.map(item => ({
                file_url: item.fileUrl,
                type: item.type,
                title: item.fileUrl.split('/')[item.fileUrl?.split('/').length - 1]?.split('-')[1],
                customer_id: userIdFromParams
              }))
            )
            window.localStorage.setItem('uploadedFiles', JSON.stringify(data))
            window.localStorage.setItem('docsIds', JSON.stringify(docsIdsResponse))
          }

          return valid
        }
        return false
      }
      case 2:
        setLoanDetails(data)
        return true

      case 3: {
        const docsIds = JSON.parse(window.localStorage.getItem('docsIds'))
        const res = await dispatch.loans.applyForExistingCustomer({
          ...loanDetails,
          business_documents: docsIds
        })
        if (res.error) {
          setLoading(false)
          throw new Error('Server error')
        }
        return true
      }

      default:
        break
    }

    return true
  }

  const handleNext = async (data, step) => {
    setLoading(true)

    const valid = await validateAndProcess(data, step)

    if (currentStep === 2) {
      window.localStorage.removeItem('docsIds')
      window.localStorage.removeItem('uploadedFiles')
      setLoading(false)
      return
    }

    if (valid) {
      setCurrentStep(currentStep + 1)
    } else {
      notification.warn({
        message: 'Please fill or upload the missing field'
      })
    }
    setLoading(false)
  }

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const handleCustomerInfoButton = () => {
    history.push(`/admin-customers-details/${userIdFromParams}`)
  }

  return (
    <DashboardLayout>
      <main className='w-full h-full flex-grow p-4 bg-gray-100'>
        <div className='absolute top-1/3 w-full mx-auto'>
          {(loading || submitting) && <Loader color='blue' height='200px' width='200px' />}
        </div>
        {currentStep === 0 && (
          <BusinessDocs
            handleCustomerInfoButton={handleCustomerInfoButton}
            handleNext={handleNext}
          />
        )}

        {currentStep === 1 && (
          <LoanDetails
            handleCustomerInfoButton={handleCustomerInfoButton}
            onGoBack={handleGoBack}
            onNext={handleNext}
          />
        )}

        {currentStep === 2 && <Step5 stepForNext={3} onGoBack={handleGoBack} onNext={handleNext} />}
      </main>
    </DashboardLayout>
  )
}

export default ApplyExistingLoan
