import { notification } from 'antd'

import { Roles } from '../../services'
import { reducerActions } from '../reducer'

const initialState = {
  allRoles: []
}

export const roles = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async getAllRoles() {
      dispatch.loans.setError(null)
      try {
        const { data } = await Roles.fetch_all_roles()
        if (data.success) {
          dispatch.roles.setState({
            allRoles: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message,
            duration: 7000
          })
        }
      } catch (error) {
        notification.error({
          message: 'An error occured',
          description: error?.message || error.data.message || 'Something went wrong'
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.roles.setState(initialState)])
    }
  })
}
