import React, { useState } from 'react'
import PropTypes from 'prop-types'

const DropDown = ({ children, ...rest }) => {
  const [openUserMenu, setUserMenu] = useState(false)

  return (
    <div {...rest} className='relative w-1/2 flex justify-end z-auto'>
      <i
        className='fas fa-ellipsis-v light-100 font-bold cursor-pointer w-10 h-10'
        onClick={() => setUserMenu(prev => !prev)}
      />
      {openUserMenu && (
        <div
          onClick={() => setUserMenu(prev => !prev)}
          style={{ zIndex: '1' }}
          className='fixed inset-0'
        />
      )}
      <div
        className={`${
          !openUserMenu && 'hidden'
        } absolute z-20 top-0 bg-white rounded-lg shadow-lg py-2 mt-5 w-44`}
      >
        {children}
      </div>
    </div>
  )
}

DropDown.propTypes = {
  openUserMenu: PropTypes.bool,
  setUserMenu: PropTypes.func,
  children: PropTypes.any
}

export default DropDown
