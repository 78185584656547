import React, { useState } from 'react'
import { Tabs } from 'antd'
import { DashboardLayout } from '../../../../sharedComponents/dashboardLayout'
import { ReviewtabItems } from '../constants'
import { Link } from 'react-router-dom'

const ReviewDetailsPage = () => {
  const [activeKey, setActiveKey] = useState('1') // Set initial active key

  const onTabChange = key => {
    setActiveKey(key) // Update active key on tab change
  }

  return (
    <DashboardLayout>
      <div>
        <Link to='/risk-management' className='hover:text-black'>
          <div className='flex gap-3  items-center my-6'>
            <div>
              <svg width='16' height='14' viewBox='0 0 16 14' fill='none'>
                <path
                  d='M15 5.9993H3.14L6.77 1.6393C6.93974 1.43508 7.0214 1.1718 6.99702 0.907375C6.97264 0.642949 6.84422 0.399038 6.64 0.229299C6.43578 0.0595606 6.1725 -0.0221014 5.90808 0.00227838C5.64365 0.0266581 5.39974 0.155083 5.23 0.359299L0.23 6.3593C0.196361 6.40702 0.166279 6.45716 0.14 6.5093C0.14 6.5593 0.14 6.5893 0.0700002 6.6393C0.0246737 6.75396 0.000941121 6.87601 0 6.9993C0.000941121 7.12259 0.0246737 7.24464 0.0700002 7.3593C0.0700002 7.4093 0.0699999 7.4393 0.14 7.4893C0.166279 7.54144 0.196361 7.59158 0.23 7.6393L5.23 13.6393C5.32402 13.7522 5.44176 13.843 5.57485 13.9052C5.70793 13.9674 5.85309 13.9995 6 13.9993C6.23365 13.9998 6.46009 13.9184 6.64 13.7693C6.74126 13.6854 6.82496 13.5822 6.88631 13.4659C6.94766 13.3496 6.98546 13.2222 6.99754 13.0913C7.00961 12.9603 6.99573 12.8282 6.95669 12.7026C6.91764 12.577 6.8542 12.4603 6.77 12.3593L3.14 7.9993H15C15.2652 7.9993 15.5196 7.89394 15.7071 7.70641C15.8946 7.51887 16 7.26452 16 6.9993C16 6.73408 15.8946 6.47973 15.7071 6.29219C15.5196 6.10466 15.2652 5.9993 15 5.9993Z'
                  fill='#231F20'
                />
              </svg>
            </div>
            <p className='text-xl font-bold'>Binford Pharmacy</p>
          </div>
        </Link>

        <div>
          <Tabs
            activeKey={activeKey} // Bind activeKey to Tabs
            onChange={onTabChange} // Handle tab change
            tabBarGutter={0}
            className='custom-tabs'
            tabBarStyle={{ borderBottom: '1px solid #E8E8E8' }}
            items={ReviewtabItems.map(item => ({
              ...item,
              label: (
                <span
                  className={`py-2 px-4 ${
                    activeKey === item.key ? 'text-vittasBlue' : 'text-gray-600'
                  }`}
                >
                  {item.label}
                </span>
              )
            }))}
          />
        </div>
      </div>
    </DashboardLayout>
  )
}

export default ReviewDetailsPage
