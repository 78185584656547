import React from 'react'
import { Link } from 'react-router-dom'
const SocialIcons = () => {
  return (
    <div className='mx-4'>
      <ul className='flex justify-end'>
        <li className='ml-8'>
          <Link to='/' target='_blank' title='Facebook'>
            <i className='fab fa-facebook text-xl text-white' />
          </Link>
        </li>
        <li className='ml-8'>
          <Link to='https://www.instagram.com' target='_blank' title='Instagram'>
            <i className='fab fa-instagram text-xl text-white' />
          </Link>
        </li>

        <li className='ml-8'>
          <Link target='_blank' to='https://twitter.com' title='Twitter'>
            <i className='fab fa-twitter text-xl text-white' />
          </Link>
        </li>
        <li className='ml-8'>
          <Link target='_blank' to='https://www.linkedin.com/company/vittas' title='LinkedIn'>
            <i className='fab fa-linkedin text-xl text-white' />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default SocialIcons
