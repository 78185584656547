import React from 'react'
import AdminNavBar from './admin-navbar/AdminNavBar'
import SideMenu from './side-menu/SideMenu'
import PropTypes from 'prop-types'

const DashboardLayout = ({ children }) => {
  return (
    <div className='dashboard-main'>
      <SideMenu />
      <div className='dashboard-y'>
        <AdminNavBar />
        <div className='dashboard-x'>{children}</div>
      </div>
    </div>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.object
}

export default DashboardLayout
