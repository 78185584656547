/* eslint-disable react/display-name */
/* eslint-disable camelcase */

import { Popconfirm } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DropDown from '../../sharedComponents/drop-down/DropDown'
import AdminApprovedLoansModal from '../../sharedComponents/modal/AdminApprovedLoansModal'
import AdminDisburseLoansModal from '../../sharedComponents/modal/AdminDisburseLoansModal'
import { getColumnSearchProps } from '../../sharedComponents/antSearch/search'
import CheckStatusModal from '../../sharedComponents/modal/CheckStatusModal'
import { checkPrivilages, formatCurrency } from '../../utils/helpers'

import * as roles from '../../utils/roles'
import IdsComponent from '../../sharedComponents/others/IdsComponent'
import AdminUploadDoc from './AdminUploadDocs'
import DeleteLoan from './DeleteLoan'

const BIZ_EMAIL_ADDRESS = 'Business Email Address'
const BIZ_NAME = 'Business Name'
const BIZ_INFORMATION = 'Business Information'
const APPLICATION_DATE = 'Submission Date'
const VIEW_APPLICATION = 'View Application'
const INTEREST_TRACKER = 'Interest Tracker'
const BIZ_PHONE_NO = 'Business Phone Number'
const APPLICATION = 'Applications'
const REG_ADDRESS = 'Registered Address'
const MEMORANDUM_AND_ARTICLE = 'Memorandum and Articles of Association'
const INDUSTRY = 'Industry'
const SIX_MONTHS_BANK_STATEMENT = '6 Months Bank Statement'
const REQUIRED_BIZ_LICENSE = 'CAC 2'
const BIZ_SIZE = 'Number of staff'
const BIZ_FOUNDED_YEAR = 'Business Founded Year'
const BIZ_RC_NUMBER = 'RC Number'
const FEEDBACK = 'Leave a Feedback'
const BIZ_DOCUMENTS = 'Business Documents'
const PARTICULARS_OF_DIRECTORS = 'Particulars of Directors'
const PHARMACISTS = 'Pharmacist/Medical License'
const BIZ_SIGNATORY = 'Business Signatories'
const HOUSE_ADDRESS = 'House Address'
const MARITAL_STATUS = 'Marital Status'
const DATE_OF_BIRTH = 'Date of Birth'
const CONFIRMATION_STATUS = 'Confirmation status'
const NO_OF_KIDS = 'No of Kids'
const APPROVED = 'Approved'
const CERT_OF_INCORPORATION = 'Certificate of Incorporation'
const BIZ_DESCRIPTION = 'Business Description'
const DECLINED = 'Declined'
const INCORPORATION_DATE = 'Incorporation Date'
const IN_REVIEW = 'In Review'
const ALL_APP = 'All Applications'
const APP_IN_VIEW = 'Application In Review'
const APPROVED_APP = 'Approved Applications'
const APPROVED_DATE = 'Approved Date'
const DECLINED_APP = 'Declined Applications'
const DISBURSED_APP = 'Disbursed Applications'
const FILTER = 'Filter'
const GENDER = 'Gender'
const EXPORT = 'Export'
const GO_BACK = 'Go Back'
const SEARCH_BY = 'Search By Loan'
const LOAN_ID = 'Loan ID'
const ACTION = 'Action'
const APPROVED_LOAN = 'Approve Loan'
const SEND_REMINDER = 'Send Reminder'
const EDIT_APPLICATION = 'Edit Application'
const STATEMENT_SHARE_CAP = 'Certificate of Incorporation (Form 2)'
const CHANGE_OF_REG_ADDRESS = 'Change of registered address (Form 3)'
const APPOINTMENT_OF_DIR = ' CAC 1.1 (Form 7)'
const PASSPORT_PICS = 'Passport'
const GOVERNMENT_ID = 'Govt. ID'
const DEFAULT_LOAN = 'Mark as Defaulted'
const SAVE = 'Save'
const DISTRIBUTORS_LEDGER = 'distributors Ledger '
const DRAG_FILES = 'Drag file'
const SALES_DATA = 'Sales Data'
const OR = 'or'
const LOAN_AGREEMENT = 'Loan Agreement'

export {
  LOAN_AGREEMENT,
  SALES_DATA,
  OR,
  DRAG_FILES,
  DISTRIBUTORS_LEDGER,
  SAVE,
  DEFAULT_LOAN,
  CHANGE_OF_REG_ADDRESS,
  APPOINTMENT_OF_DIR,
  STATEMENT_SHARE_CAP,
  FEEDBACK,
  PASSPORT_PICS,
  GOVERNMENT_ID,
  APPROVED,
  DECLINED,
  IN_REVIEW,
  APPLICATION,
  ALL_APP,
  APP_IN_VIEW,
  APPROVED_APP,
  DECLINED_APP,
  DISBURSED_APP,
  FILTER,
  EXPORT,
  GO_BACK,
  SEARCH_BY,
  VIEW_APPLICATION,
  APPLICATION_DATE,
  BIZ_INFORMATION,
  BIZ_NAME,
  BIZ_EMAIL_ADDRESS,
  BIZ_PHONE_NO,
  INDUSTRY,
  BIZ_SIZE,
  BIZ_FOUNDED_YEAR,
  BIZ_RC_NUMBER,
  BIZ_DESCRIPTION,
  REG_ADDRESS,
  INCORPORATION_DATE,
  BIZ_DOCUMENTS,
  CERT_OF_INCORPORATION,
  REQUIRED_BIZ_LICENSE,
  PARTICULARS_OF_DIRECTORS,
  SIX_MONTHS_BANK_STATEMENT,
  PHARMACISTS,
  MEMORANDUM_AND_ARTICLE,
  BIZ_SIGNATORY,
  HOUSE_ADDRESS,
  MARITAL_STATUS,
  DATE_OF_BIRTH,
  GENDER,
  INTEREST_TRACKER,
  NO_OF_KIDS,
  CONFIRMATION_STATUS
}

export const ALL_ADMIN_APPLICATIONS = (permissions, searchObject) => [
  {
    title: <span className='font-bold'>Loan ID</span>,
    datakey: 'Loan ID',
    dataIndex: 'loan_id',
    key: 'loan_id',
    ...getColumnSearchProps({
      dataIndex: 'loan_id',
      ...searchObject,
      title: 'Loan ID'
    }),
    render: loan_id => (
      <span>
        <IdsComponent id={loan_id} />
      </span>
    )
  },
  {
    title: <span className='font-bold'>Business Name</span>,
    ...getColumnSearchProps({
      dataIndex: 'business_name',
      ...searchObject,
      title: 'Business Name'
    }),
    datakey: 'Business Name',
    dataIndex: 'business_name',
    key: 'business_name',
    render: business_name => <span>{business_name}</span>
  },
  {
    title: <span className='font-bold'>{APPLICATION_DATE}</span>,
    datakey: 'Submission Date',
    key: 'time_submitted',
    dataIndex: 'time_submitted',
    render: time_submitted => {
      return <span>{time_submitted?.substring(0, 10)}</span>
    },
    sorter: (a, b) =>
      new Date(a.loan_application_initiated) - new Date(b.loan_application_initiated)
  },
  {
    title: <span className='font-bold'>Loan Type</span>,
    datakey: 'loan_type',
    key: 'loan_type',
    dataIndex: 'loan_type',
    render: loan_type => <span>{loan_type}</span>
  },
  {
    title: <span className='font-bold'>Loan Purpose</span>,
    datakey: 'Loan Purpose',
    key: 'loan_purpose',
    dataIndex: 'loan_purpose',
    render: loan_purpose => <span>{loan_purpose}</span>
  },
  {
    title: <span className='font-bold'>Loan Amount</span>,
    datakey: 'Loan Amount',
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>₦{formatCurrency(loan_amount)}</span>
  },

  {
    title: <span className='font-bold'>Loan Duration</span>,
    datakey: 'Loan Duration',
    key: 'duration_of_loan',
    dataIndex: 'duration_of_loan',
    render: duration_of_loan => (
      <span>
        {isNaN(Number(duration_of_loan)) ? duration_of_loan : duration_of_loan + ' months'}
      </span>
    )
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    datakey: '',
    key: 'loan_id',
    render: data => {
      return (
        <DropDown>
          <Link
            to={`../application/${data.loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
            title={
              !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
                ? 'You have no permission'
                : ''
            }
            disabled={!checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)}
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>

          <span
            style={{ outline: 0 }}
            className={` cursor-pointer ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <AdminUploadDoc
              loanData={data}
              disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
            />
          </span>
          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link cursor-pointer ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <AdminApprovedLoansModal
              disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
              type='approve'
              data={data}
              icon={<i className='fas fa-pencil-alt mr-2'> {APPROVED_LOAN}</i>}
            />
          </span>
          <Link
            to={`../send-reminder/${data.loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
            title={
              !checkPrivilages([roles.SEND_REMINDER], permissions) ? 'You have no permission' : ''
            }
            disabled={!checkPrivilages([roles.SEND_REMINDER], permissions)}
          >
            <i className='fas fa-stopwatch mr-2' /> {SEND_REMINDER}
          </Link>
          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link text-red-800 ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <AdminApprovedLoansModal
              disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
              type='declined'
              data={data}
              icon={<i className='far fa-trash-alt mr-2'> Decline Loan </i>}
            />
          </span>
          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link text-red-800 ${
              !checkPrivilages([roles.DELETE_LOAN], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.DELETE_LOAN], permissions) ? 'You have no permission' : ''
            }
          >
            <DeleteLoan
              canDeleteLoan={checkPrivilages([roles.DELETE_LOAN], permissions)}
              id={data.loan_id}
            />
          </span>
        </DropDown>
      )
    }
  }
]

export const ADMIN_APPROVED_APPLICATIONS = (
  permissions,
  searchObject,
  { setReason, handleDelete, handleCancel }
) => [
  {
    title: <span className='font-bold'>Business Name</span>,
    datakey: 'Business name',
    dataIndex: 'business_name',
    key: 'business_name',
    ...getColumnSearchProps({
      dataIndex: 'business_name',
      ...searchObject,
      title: 'Business Name'
    })
  },
  {
    title: <span className='font-bold'>Loan Accepted</span>,
    datakey: 'Loan Accepted',
    dataIndex: 'is_accepted_by_customer',
    key: 'is_accepted_by_customer',
    sorter: (a, b) => a.is_accepted_by_customer - b.is_accepted_by_customer,
    render: is_accepted_by_customer => <span>{is_accepted_by_customer ? 'Yes' : 'No'}</span>
  },
  {
    title: <span className='font-bold'>Admin Note</span>,
    datakey: 'Admin Note',
    dataIndex: 'admin_note',
    key: 'admin_note',
    render: admin_note => <span>{admin_note}</span>
  },
  {
    title: <span className='font-bold'>Loan Ended</span>,
    datakey: 'Loan Ended',
    dataIndex: 'has_loan_ended',
    key: 'has_loan_ended',
    render: has_loan_ended => <span>{has_loan_ended ? 'Yes' : 'No'}</span>
  },
  // {
  //   title: <span className='font-bold'>Acceptance Status</span>,
  //   datakey: 'Acceptance Status',
  //   dataIndex: 'has_loan_been_accepted',
  //   key: 'has_loan_been_accepted',
  //   render: has_loan_been_accepted => <span>{has_loan_been_accepted ? 'Yes' : 'No'}</span>
  // },
  {
    title: <span className='font-bold'>Approved Date</span>,
    datakey: 'Approval Date',
    key: 'approval_date',
    dataIndex: 'approval_date',
    render: approval_date => <span>{approval_date?.substring(0, 10)}</span>,
    sorter: (a, b) => new Date(a.approval_date) - new Date(b.approval_date)
  },
  {
    title: <span className='font-bold'>Loan Type</span>,
    datakey: 'loan_type',
    key: 'loan_type',
    dataIndex: 'loan_type',
    render: loan_type => <span>{loan_type}</span>
  },
  {
    title: <span className='font-bold'>Intrest Rate</span>,
    datakey: 'Intrest Rate',
    key: 'loan_interest_rate',
    dataIndex: 'loan_interest_rate',
    render: loan_interest_rate => <span>{loan_interest_rate}</span>
  },
  {
    title: <span className='font-bold'>Loan Duration</span>,
    datakey: 'Loan Duration',
    key: 'loan_duration',
    dataIndex: 'loan_duration',
    render: loan_duration => <span>{loan_duration} Month</span>
  },
  {
    title: <span className='font-bold'>Loan Fixed Principal Amount</span>,
    datakey: 'Loan Amount',
    key: 'loan_principal_amount_fixed',
    dataIndex: 'loan_principal_amount_fixed',
    render: loan_principal_amount_fixed => (
      <span>₦{formatCurrency(loan_principal_amount_fixed)}</span>
    )
  },
  {
    title: <span className='font-bold'>Current Principal Amount</span>,
    datakey: 'Loan Amount',
    key: 'remaining_principal_amount',
    dataIndex: 'remaining_principal_amount',
    render: remaining_principal_amount => <span>₦{formatCurrency(remaining_principal_amount)}</span>
  },
  {
    title: <span className='font-bold'>Loan ID</span>,
    datakey: 'Loan ID',
    dataIndex: 'loan_app_id',
    key: 'loan_app_id',
    render: (loan_app_id, _) => <IdsComponent id={_.unique_loan_key || loan_app_id} />
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    key: 'more',
    render: data => {
      return (
        <DropDown>
          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link text-blue-600 ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <AdminDisburseLoansModal
              disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
              data={data}
              icon={<i className='fas fa-credit-card mr-2'> Disburse Loan </i>}
            />
          </span>

          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link text-red-800 hover:text-red-900 ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <Popconfirm
              title={
                <>
                  <label htmlFor='reason' className='pr-4'>
                    Add reason
                  </label>
                  <input
                    className='border border-blue-700 rounded-md p-1'
                    id='reason'
                    type='text'
                    onChange={e => setReason(e.target.value)}
                  />
                </>
              }
              onConfirm={() => handleDelete(data)}
              onCancel={handleCancel}
              okText='Okay'
              cancelText='Cancel'
            >
              <i className='fas fa-recycle mr-2'> Un-Approve Loan </i>
            </Popconfirm>
          </span>
          <Link
            to={`../application/${data.loan_app_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
            title={
              !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
                ? 'You have no permission'
                : ''
            }
            disabled={!checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)}
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
        </DropDown>
      )
    }
  }
]

export const ADMIN_DECLINED_APPLICATIONS = [
  {
    title: <span className='font-bold'>Loan ID</span>,
    datakey: 'Loan ID',
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: (loan_id, _) => <IdsComponent id={_.unique_loan_key || loan_id} />
  },
  {
    title: <span className='font-bold'>Business Name</span>,
    datakey: 'Business Name',
    dataIndex: 'business_name',
    key: 'business_name',
    render: business_name => <span>{business_name}</span>
  },
  {
    title: <span className='font-bold'>Loan Amount</span>,
    datakey: 'Loan Amount',
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>₦{formatCurrency(loan_amount)}</span>
  },
  {
    title: <span className='font-bold'>Loan Purpose</span>,
    datakey: 'Loan Ended',
    dataIndex: 'loan_purpose',
    key: 'loan_purpose',
    render: loan_purpose => <span>{loan_purpose}</span>
  },
  {
    title: <span className='font-bold'>Loan Duration</span>,
    datakey: 'Loan Duration',
    key: 'duration_of_loan',
    dataIndex: 'duration_of_loan',
    render: duration_of_loan => (
      <span>
        {isNaN(Number(duration_of_loan)) ? duration_of_loan : duration_of_loan + ' months'}
      </span>
    )
  },
  {
    title: <span className='font-bold'>Reason</span>,
    datakey: 'Reason',
    key: 'declined_reason',
    dataIndex: 'declined_reason',
    render: declined_reason => <span>{declined_reason}</span>
  }
]

export const ALL_ADMIN_LOANS = [
  {
    title: <span className='font-bold'>Loan ID</span>,
    datakey: 'Loan ID',
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: loan_id => <span>{loan_id}</span>
  },
  {
    title: <span className='font-bold'>Loan Principal</span>,
    datakey: 'Loan Amount',
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>₦{formatCurrency(loan_amount)}</span>
  },
  {
    title: <span className='font-bold'>Principal Remaining</span>,
    datakey: 'Loan Amount',
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>₦{formatCurrency(loan_amount)}</span>
  },
  {
    title: <span className='font-bold'>Duration</span>,
    datakey: 'Duration',
    key: 'duration',
    dataIndex: 'duration',
    render: loan_application_initiated => <span>12 Months</span>
  },
  {
    title: <span className='font-bold'>{APPROVED_DATE}</span>,
    datakey: { APPROVED_DATE },
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => (
      <span>{loan_application_initiated.substring(0, 10)}</span>
    ),
    sorter: (a, b) => a.loan_application_initiated - b.loan_application_initiated
  },
  {
    title: <span className='font-bold'>Next Payment Date</span>,
    datakey: 'Next Payment Date',
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => (
      <span>{loan_application_initiated.substring(0, 10)}</span>
    ),
    sorter: (a, b) => a.loan_application_initiated - b.loan_application_initiated
  },
  {
    title: <span className='font-bold capitalize'>Payment Status</span>,
    datakey: 'Status',
    key: 'loan_app_status',
    dataIndex: 'loan_app_status',
    render: loan_app_status => {
      return loan_app_status === 'Approved' ? (
        <span className='px-6 py-2 border bg-green-100 text-green-700 hover:text-green-800 rounded-lg hover:bg-green-100 text-sm'>
          Overdue
        </span>
      ) : (
        <span className='px-6 py-2 border bg-red-100 text-red-700 hover:text-red-800 rounded-lg hover:bg-red-100 text-sm'>
          Ongoing
        </span>
      )
    }
  },
  {
    title: <span className='font-bold capitalize'>Status</span>,
    datakey: 'Status',
    key: 'loan_app_status',
    dataIndex: 'loan_app_status',
    render: loan_app_status => {
      return loan_app_status === 'Approved' ? (
        <span className='px-6 py-2 border bg-green-100 text-green-700 hover:text-green-800 rounded-lg hover:bg-green-100 text-sm'>
          {APPROVED}
        </span>
      ) : loan_app_status === 'Pending' ? (
        <span className='px-6 py-2 border bg-yellow-100 text-yellow-600 hover:text-yellow-700 rounded-lg hover:bg-yellow-100 text-sm'>
          {IN_REVIEW}
        </span>
      ) : (
        <span className='px-6 py-2 border bg-red-100 text-red-700 hover:text-red-800 rounded-lg hover:bg-red-100 text-sm'>
          {DECLINED}
        </span>
      )
    }
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    datakey: '',
    key: 'loan_id',
    dataIndex: 'loan_id',
    render: loan_id => {
      const [openUserMenu, setUserMenu] = useState(false)

      return (
        <DropDown openUserMenu={openUserMenu} setUserMenu={setUserMenu}>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='fas fa-pencil-alt mr-2' /> {EDIT_APPLICATION}
          </Link>
          <Link
            to={`../send-reminder/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='fas fa-stopwatch mr-2' /> {SEND_REMINDER}
          </Link>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link text-red-800'
          >
            <i className='far fa-trash-alt mr-2' /> Delete
          </Link>
        </DropDown>
      )
    }
  }
]

export const ALL_ADMIN_USERS = [
  {
    title: <span className='font-bold'>Full Name</span>,
    datakey: 'Loan Amount',
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>First Last</span>
  },
  {
    title: <span className='font-bold'>Email Address</span>,
    datakey: 'Loan Amount',
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>vittas@info.com</span>
  },
  {
    title: <span className='font-bold'>Contact Number</span>,
    datakey: 'Contact Number',
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => <span>0803751xxxx</span>
  },
  {
    title: <span className='font-bold'>Account Type</span>,
    datakey: 'Account Type',
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => <span>None</span>,
    sorter: (a, b) => a.loan_application_initiated - b.loan_application_initiated
  },
  {
    title: <span className='font-bold'>Role</span>,
    datakey: 'Role',
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => (
      <span>{Math.floor(Math.random() * 30) % 2 ? 'Admin' : 'Customer'}</span>
    )
  },
  {
    title: <span className='font-bold capitalize'>Status</span>,
    datakey: 'Status',
    key: 'loan_app_status',
    dataIndex: 'loan_app_status',
    render: loan_app_status => {
      return loan_app_status === 'Approved' ? (
        <span className='px-6 py-2 border bg-green-100 text-green-700 hover:text-green-800 rounded-lg hover:bg-green-100 text-sm'>
          Confirmed
        </span>
      ) : (
        <span className='px-6 py-2 border bg-yellow-100 text-yellow-600 hover:text-yellow-700 rounded-lg hover:bg-yellow-100 text-sm'>
          Unconfirmed
        </span>
      )
    }
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    datakey: '',
    key: 'loan_id',
    dataIndex: 'loan_id',
    render: loan_id => {
      const [openUserMenu, setUserMenu] = useState(false)

      return (
        <DropDown openUserMenu={openUserMenu} setUserMenu={setUserMenu}>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='fas fa-pencil-alt mr-2' /> {EDIT_APPLICATION}
          </Link>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='fas fa-stopwatch mr-2' /> {SEND_REMINDER}
          </Link>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link text-red-800'
          >
            <i className='far fa-trash-alt mr-2' /> Delete
          </Link>
        </DropDown>
      )
    }
  }
]

export const ALL_ADMIN_CONTACT = [
  {
    title: <span className='font-bold'>ID</span>,
    datakey: 'ID',
    key: 'id',
    dataIndex: 'id',
    render: id => <span>{id}</span>
  },
  {
    title: <span className='font-bold'>Email Address</span>,
    datakey: 'Email Address',
    key: 'email',
    dataIndex: 'email',
    render: email => <span>{email}</span>
  },
  {
    title: <span className='font-bold'>Message</span>,
    datakey: 'Message',
    key: 'message',
    dataIndex: 'message',
    render: message => <span>{message}</span>
  }
]

export const ALL_APPLICATIONS = [
  {
    title: <span className='font-bold'>Loan ID</span>,
    datakey: 'Loan ID',
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: loan_id => (
      <span>
        <IdsComponent id={loan_id} />
      </span>
    )
  },

  {
    title: <span className='font-bold'>Application Date</span>,
    datakey: 'Application Date',
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => (
      <span>{loan_application_initiated.substring(0, 10)}</span>
    ),
    sorter: (a, b) =>
      new Date(a.loan_application_initiated) - new Date(b.loan_application_initiated)
  },
  {
    title: <span className='font-bold'>Loan Purpose</span>,
    datakey: 'Loan Purpose',
    key: 'loan_purpose',
    dataIndex: 'loan_purpose',
    render: loan_purpose => <span>{loan_purpose}</span>
  },
  {
    title: <span className='font-bold'>Loan Amount</span>,
    datakey: 'Loan Amount',
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>₦{formatCurrency(loan_amount)}</span>
  },

  {
    title: <span className='font-bold capitalize'>Status</span>,
    datakey: 'Status',
    key: 'loan_app_status',
    dataIndex: '',
    render: data => {
      return !(data?.is_approved && data?.loan_app_status === 'Disbursed') ? (
        <CheckStatusModal
          isDisbursed={data?.is_approved && data?.loan_app_status === 'Disbursed'}
          type='approve'
          data={data}
        >
          Check Status
        </CheckStatusModal>
      ) : (
        <span className='text-black font-manrope px-6'>Active Loan</span>
      )
    }
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    datakey: '',
    key: 'loan_id',
    dataIndex: 'loan_id',
    render: loan_id => {
      return (
        <DropDown>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
        </DropDown>
      )
    }
  }
]

export const IN_REVIEW_APPLICATIONS = [
  {
    title: <span className='font-bold'>Loan ID</span>,
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: loan_id => <IdsComponent id={loan_id} />
  },
  {
    title: <span className='font-bold'>Loan Amount</span>,
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>₦{formatCurrency(loan_amount)}</span>
  },
  {
    title: <span className='font-bold'>Application Date</span>,
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => (
      <span>{loan_application_initiated.substring(0, 10)}</span>
    ),
    sorter: (a, b) =>
      new Date(a.loan_application_initiated) - new Date(b.loan_application_initiated)
  },

  {
    title: <span className='font-bold capitalize'>Status</span>,
    key: 'is_approved',
    dataIndex: 'is_approved',
    render: is_approved => (
      <span className='px-6 py-2 border bg-yellow-100 text-yellow-600 hover:text-yellow-700 rounded-lg hover:bg-yellow-100 text-sm'>
        In Review
      </span>
    )
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    key: 'loan_id',
    dataIndex: 'loan_id',
    render: loan_id => {
      const [openUserMenu, setUserMenu] = useState(false)

      return (
        <DropDown openUserMenu={openUserMenu} setUserMenu={setUserMenu}>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
        </DropDown>
      )
    }
  }
]

export const APPROVED_APPLICATIONS = [
  {
    title: <span className='font-bold'>Loan ID</span>,
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: (loan_id, _) => <IdsComponent id={_.unique_loan_key || loan_id} />
  },
  {
    title: <span className='font-bold'>Loan Amount</span>,
    dataIndex: 'loan_amount',
    key: 'loan_amount',
    render: loan_amount => <span className='lowercase'>₦{formatCurrency(loan_amount)}</span>
  },
  {
    title: <span className='font-bold'>Application Date</span>,
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => (
      <span>{loan_application_initiated.substring(0, 10)}</span>
    ),
    sorter: (a, b) =>
      new Date(a.loan_application_initiated) - new Date(b.loan_application_initiated)
  },
  {
    title: <span className='font-bold'>Application Amount</span>,
    key: 'loan_amount',
    dataIndex: 'loan_amount',
    render: loan_amount => <span>₦{formatCurrency(loan_amount)}</span>
  },

  {
    title: <span className='font-bold'>{APPROVED_DATE}</span>,
    key: 'user_email',
    dataIndex: 'user_email',
    render: user_email => <span>{'1i1111'.substring(0, 10)}</span>
    // sorter: (a, b) => a.approvedDate - b.approvedDate
  },
  {
    title: <span className='font-bold capitalize'>Status</span>,
    key: 'status',
    dataIndex: 'status',
    render: status => (
      <span className='px-6 py-2 border bg-green-100 text-green-700 hover:text-white rounded-lg hover:bg-green-100 text-sm'>
        {APPROVED}
      </span>
    )
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: loan_id => {
      const [openUserMenu, setUserMenu] = useState(false)

      return (
        <DropDown openUserMenu={openUserMenu} setUserMenu={setUserMenu}>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
        </DropDown>
      )
    }
  }
]

export const DECLINE_APPLICATIONS = [
  {
    title: <span className='font-bold'>{LOAN_ID}</span>,
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: loan_id => <span>{loan_id}</span>
  },
  {
    title: <span className='font-bold'>Loan Amount</span>,
    dataIndex: 'loan_amount',
    key: 'loan_amount',
    render: loan_amount => <span className='lowercase'>₦{formatCurrency(loan_amount)}</span>
  },
  {
    title: <span className='font-bold'>Application Date</span>,
    key: 'loan_application_initiated',
    dataIndex: 'loan_application_initiated',
    render: loan_application_initiated => (
      <span>{loan_application_initiated.substring(0, 10)}</span>
    ),
    sorter: (a, b) => a.loan_application_initiated - b.loan_application_initiated
  },
  {
    title: <span className='font-bold'>Declined Date</span>,
    key: 'time_submitted',
    dataIndex: 'time_submitted',
    render: time_submitted => <span>{time_submitted.substring(0, 10)}</span>,
    sorter: (a, b) => a.time_submitted - b.time_submitted
  },
  {
    title: <span className='font-bold'>Loan Purpose</span>,
    key: 'loan_purpose',
    dataIndex: 'loan_purpose',
    render: loan_purpose => <span>{loan_purpose}</span>
  },
  {
    title: <span className='font-bold capitalize'>Status</span>,
    key: 'loan_app_status',
    dataIndex: 'loan_app_status',
    render: loan_app_status => (
      <span className='px-6 py-2 border bg-red-100 text-red-700 hover:text-red-800 rounded-lg hover:bg-red-100 text-sm'>
        Declined
      </span>
    )
  },
  {
    title: <span className='font-bold capitalize'>{ACTION}</span>,
    dataIndex: 'loan_id',
    key: 'loan_id',
    render: loan_id => {
      const [openUserMenu, setUserMenu] = useState(false)

      return (
        <DropDown openUserMenu={openUserMenu} setUserMenu={setUserMenu}>
          <Link
            to={`../application/${loan_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
        </DropDown>
      )
    }
  }
]
