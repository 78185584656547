/* eslint-disable camelcase */
import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useEffect, useState } from 'react'
import { InputField } from '../../../../../../sharedComponents/input-field'
import PropTypes from 'prop-types'
import { adminServices } from '../../../../../../services'
import { notification } from 'antd'
import { copyToClipboard } from '../../../../../../utils/helpers'

const KycVerification = ({ customeBizInfo }) => {
  const [loading, setLoading] = useState(false)
  const [consentUrl, setConsentUrl] = useState(false)
  const [copied, setCopied] = useState(false)
  const handleCopyLink = () => {
    copyToClipboard(consentUrl, 'Consent URL copied successfully!')
    setCopied(true)
  }
  const getCustomerConsent = async () => {
    setLoading(true)
    try {
      const { data } = await adminServices.getCustomerConsent(customeBizInfo?.userId)

      if (data.success) {
        setConsentUrl(data?.data?.consent_url?.url)
      } else {
        notification.error({
          message: 'An error occured',
          description: data?.message || 'Something went wrong!'
        })
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const details = {
    phone_number: customeBizInfo?.buz_phone || '',
    message_body: `Please use this link to verify your bvn ${consentUrl}`,
    user_id_consent: customeBizInfo?.buz_email ? 0 : 1,
    user_id: customeBizInfo.userId,
    email: customeBizInfo?.buz_email || '',
    message_header: 'BVN Verification'
  }

  const sendMessage = async (details, messageType) => {
    try {
      const { data } = await adminServices.adminSendMessage(details, messageType)
      const { success, message } = data
      if (!success) {
        notification.error({
          message: message || 'Could not send message. Please try again'
        })
      }
      if (success) {
        notification.success({
          message: message || 'Message has been sent'
        })
      }
    } catch ({ message, response }) {
      notification.error({
        message: 'An error occured',
        description:
          response?.data?.message || response?.message || message || 'Something went wrong'
      })
    }
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
  }, [copied])

  useEffect(() => {
    async function fetch() {
      await getCustomerConsent()
    }
    fetch()
  }, [])

  return (
    <main>
      <div className='text-black text-base font-bold my-6'>
        Verification link has been sent to {customeBizInfo?.vfd_data?.companyName}
      </div>
      {loading ? (
        <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
      ) : (
        <Formik initialValues={details} enableReinitialize onSubmit={sendMessage}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 mt-8 relative'>
                  <Field
                    as={InputField}
                    type='text'
                    name='whatsapp'
                    label={<label className='text-black text-base'>Whatsapp</label>}
                    className='form-control bg-white'
                    disabled
                  />
                  <button
                    type='button'
                    className='absolute right-6 top-16 transform -translate-y-1/2 border border-blue-500 text-blue-500 px-4 py-1'
                  >
                    Send
                  </button>
                  <ErrorMessage name='whatsapp' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 relative mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    value={values.email}
                    name='email'
                    label={<label className='text-black text-base'>Email</label>}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='email' component='small' className='text-red-500' />
                  <button
                    onClick={() => sendMessage({ ...details }, 'EMAIL')}
                    type='submit'
                    className='absolute right-6 top-16 transform -translate-y-1/2 border border-blue-500 text-blue-500 px-4 py-1'
                  >
                    Send
                  </button>
                </div>
                <div className='w-full sm:-1/2 md:w-1/2 md:px-4 relative mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='phone_number'
                    value={values.phone_number}
                    label={<label className='text-black text-base'>SMS</label>}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='phone_number' component='small' className='text-red-500' />
                  <button
                    onClick={() => {
                      const { message_header, email, ...otherDetails } = details
                      sendMessage({ ...otherDetails }, 'SMS')
                    }}
                    type='submit'
                    className='absolute right-6 top-16 transform -translate-y-1/2 border border-blue-500 text-blue-500 px-4 py-1'
                  >
                    Send
                  </button>
                </div>
                <div className='w-full sm:-1/2 md:w-1/2 relative md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='verificationLink'
                    label={<label className='text-black text-base'>Verification link</label>}
                    className='form-control bg-white'
                    disabled
                  />
                  <ErrorMessage
                    name='verificationLink'
                    component='small'
                    className='text-red-500'
                  />
                  <button
                    type='button'
                    className={`${
                      copied ? 'bg-green-800' : 'bg-blue-800'
                    } absolute right-4 top-9 text-white px-4 py-2 mt-2`}
                    onClick={handleCopyLink}
                  >
                    {copied ? 'Copied' : 'Copy Link'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </main>
  )
}

KycVerification.propTypes = {
  customeBizInfo: PropTypes.any
}

export default KycVerification
