import React from 'react'
import SaasCustomerAction from './SaasCustomerAction'

export const saasCustomersColumns = [
  {
    title: <span className='font-bold'>User ID</span>,
    dataIndex: 'user_id',
    key: 'user_id',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.user_id?.localeCompare(b.name)
  },
  {
    title: <span className='font-bold'>Terminal ID</span>,
    dataIndex: 'terminal_id',
    key: 'terminal_id',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.terminal_id?.localeCompare(b.terminal_id)
  },
  {
    title: <span className='font-bold'>Customer</span>,
    dataIndex: 'customer',
    key: 'customer',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.customer?.localeCompare(b.customer)
  },
  {
    title: <span className='font-bold'>Transaction Status</span>,
    dataIndex: 'transaction_status',
    key: 'transaction_status',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => a.transaction_status?.localeCompare(b.transaction_status)
  },
  {
    title: <span className='font-bold'>Action</span>,
    key: 'action',
    render: (text, record) => <SaasCustomerAction userId={record?.user_id} />
  }
]
