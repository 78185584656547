const SETTINGS = 'Settings'
const ACTION_EMAIL = 'Action Email'
const COMMENT = 'Comment'
const QUESTION = 'Question'
const MESSAGE = 'Message'
const BUG_REPORT = 'Bug Report'
const FEEDBACK_EMAIL = 'Feedback Email'
const REMINDER_EMAIL = 'Reminder Email'

export {
  SETTINGS,
  ACTION_EMAIL,
  COMMENT,
  BUG_REPORT,
  QUESTION,
  MESSAGE,
  FEEDBACK_EMAIL,
  REMINDER_EMAIL
}

export const actionEmail = (action, handleChange) => [
  {
    name: 'name',
    placeholder: '',
    value: action.name,
    handleChange: handleChange,
    type: 'text',
    title: 'Action Name'
  },
  {
    name: 'email',
    placeholder: '',
    value: action.email,
    handleChange: handleChange,
    type: 'text',
    title: 'Action Email'
  },
  {
    name: 'password',
    placeholder: '',
    value: action.password,
    handleChange: handleChange,
    type: 'password',
    title: 'New Password'
  },
  {
    name: 'confirm',
    placeholder: '',
    value: action.confirm,
    handleChange: handleChange,
    type: 'password',
    title: 'Confirm Password'
  }
]
export const reminderEmail = (action, handleChange) => [
  {
    name: 'name',
    placeholder: '',
    value: action.name,
    handleChange: handleChange,
    type: 'text',
    title: 'Reminder Name'
  },
  {
    name: 'email',
    placeholder: '',
    value: action.email,
    handleChange: handleChange,
    type: 'text',
    title: 'Reminder Email'
  },
  {
    name: 'password',
    placeholder: '',
    value: action.password,
    handleChange: handleChange,
    type: 'password',
    title: 'New Password'
  },
  {
    name: 'confirm',
    placeholder: '',
    value: action.confirm,
    handleChange: handleChange,
    type: 'password',
    title: 'Confirm Password'
  }
]

export const inputValidator = (items, type) => {
  let err = ''
  let isValid = true

  if (items && typeof items === 'object') {
    // Check if items is defined and an object
    for (const [key, value] of Object.entries(items)) {
      if (value.toString().trim().length === 0) {
        isValid = false
        err = `${type} ${key} is required!`
        break // to exit the loop if an error is found
      }
    }
  } else {
    isValid = false
    err = ''
  }

  return { isValid, error: err }
}
