import { notification } from 'antd'

import { Distributors } from '../../services'
import { reducerActions } from '../reducer'

const initialState = {
  adminGetDistributors: [],
  customerGetDistributors: []
}

export const distributors = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async adminGetAllDistributors() {
      dispatch.distributors.setError(null)
      try {
        const { data } = await Distributors.adminGetExistingDistributors()
        dispatch.distributors.setState({
          adminGetDistributors: data.data
        })
      } catch ({ response, message }) {
        dispatch.distributors.setError(response)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
      }
    },
    async customerGetAllDistributors() {
      dispatch.distributors.setError(null)
      try {
        const { data } = await Distributors.customerGetExistingDistributors()
        dispatch.distributors.setState({
          customerGetDistributors: data.data
        })
      } catch ({ response, message }) {
        dispatch.distributors.setError(response)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
      }
    },

    async adminAddDistributor(payload) {
      dispatch.distributors.setError(null)

      try {
        const { data } = await Distributors.admin_add_distributors(payload)
        if (data.success) {
          notification.success({
            message: data.message || 'Distributor added successfully'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while adding Distributor'
          })
        }
      } catch (error) {
        dispatch.distributors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async adminUpdateDistributor(payload) {
      const { details } = payload
      dispatch.distributors.setError(null)

      try {
        const { data } = await Distributors.admin_update_distributors(details)
        if (data.success) {
          notification.success({
            message: data.message || 'Distributor updated successfully'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while updating Distributor'
          })
        }
      } catch (error) {
        dispatch.distributors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async adminDeleteDistributor(payload) {
      const { key } = payload
      dispatch.distributors.setError(null)

      try {
        const { data } = await Distributors.admin_delete_distributors(key)
        if (data.success) {
          notification.success({
            message: data.message || 'Distributor deleted successfully'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while deleting Distributor'
          })
        }
      } catch (error) {
        dispatch.distributors.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.distributors.setState(initialState)])
    }
  }),
  selectors: slice => ({
    selectAdminDistributors() {
      return slice(distributors => distributors.adminGetDistributors)
    }
  })
}
