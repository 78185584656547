/* eslint-disable camelcase */
export const SalesAndPaymentParagraph =
  "If you're looking for a convenient way to process payments at your retail location, our POS terminals are the perfect solution. With our easy-to-use system, you can quickly and securely process credit and debit card payments, keep track of inventory, and generate reports to help you manage your business more efficiently"
export const congratsParagraph =
  'Fill out the loan application completely and accurately. Submit the loan application as soon as possible. Benefits of getting our loan includes:'
export const TrackHeading = 'Track and Analyze your Loan Repayment'
export const TrackParagraph =
  'Use a loan repayment tracker to monitor your progress and stay motivated as you pay off your loan'
const TITTLE = 'We are trusted by other businesses'
export const GetStartedHeading = 'Ready to get started?'
export const GetStartedParagraph =
  'Apply for a loan today and get the money you need to take your business to the next level.'
const SUB_TITTLE = 'Multiple Real Values To You'
const SUFFICIENT_FUNDING = 'Sufficient Funding:'
const SUFFICIENT_FUNDING_DETAILS =
  'We provide inventory financing support which will allow you to grow other parts of your business needs.'
const CASH_ON_DELIVERY = 'Cash-on-Delivery:'
const CASH_ON_DELIVERY_DETAILS =
  'Vittas will pay your vendors for you directly; this will enable you to access cash-on-delivery (COD) pricing helping you save money!'
const VOLUME_DISCOUNT = 'Volume Discounts:'
const VOLUME_DISCOUNT_DETAILS =
  ' We will allow you achieve economies of scale and purchase inventory in bulk so you can better serve you customers. No more inventory stock outs!'

const WE_ARE_COMMITTED = 'Looking for loans to support your pharmacy or hospital? Look no further!'
const BECAUSE_WE_BELIEVE =
  'Our healthcare financing solutions are tailored to meet the unique needs of your business'
const VALUE_BUSINESSES =
  'Apply now to secure the funding you need to run and expand your operations.'
const NO_COLLETERAL = 'No Requirement of Collateral'
const NO_COLLETERAL_EXPLAIN =
  'Unlike other compaines, we don’t have a strict collateral requirement for loans'

const FAST_LOAN = 'Get Loan Within 48 Hours'
const FAST_LOAN_LEANDING = 'Our loan processing and  disbursement speed is the fastest in market'

const LOW_INTEREST = 'The Lowest Interest Rate'
const LOW_INTEREST_RATE =
  'Our affordable interest rate is only 3% MoM or less with our partnership discounts'

const BEST_PAYMENT = 'Best Payment Plan For You'
const BEST_PAYMENT_PLAN =
  'Customer’s repay interest only not principal during the duration of the loan'

const CONTENT =
  '“Vittas has been very instrumental to the great boost my company has enjoyed for the past months of our partnership despite the scourge of Covid-19 on the nations economy”'
const CONTENT_NAME = 'Greenland Drugs Limited'

const CONTENT1 =
  '“Vittas delivers on its promises. Unlike other lending institutions we have dealt with in the past, Vittas does not have hidden charges, the entire process is very tranparent”'
const CONTENT1_NAME = 'Grace Johnson'

const CONTENT2 =
  '“Vittas is courteous, knowledgeable and friendly. They truly care about our business and want to make sure we are able to continue to grow by having enogh funds and supports.”'
const CONTENT2_NAME = 'JC Pharmacy'

const NAME = 'Your Full name*'
const ABOUT_US = 'About Us'
const HOW_IT_WORKS = 'How it works'
const FAQ = 'FAQ'
const PRIVACY_POLICY = 'Privacy Policy'
const COPY_RIGHT = '2021 Vittas International. All Right Reserved'
const CUSTOMER_SATISFACTION = 'Customer Satisfaction'
const CLIENTS_RETENTATION = 'Clients retention'
const CUSTOMER_BUSINESS = 'Customer Business Growth'
const HUNDRED = '100%'
const NINETY_ONE = '91%'
const SEVENTY_FIVE = '75%'
const GET_IN_TOUCH = 'Let’s get in touch!'
const CONTACT_OUR_TEAM =
  'Have an inquiry or feedbacks for us? Fill out the form below to contact our team'
const EMAIL = 'Your Email*'
const MESSAGE = 'Your Message*'
const SEND_MEASSGE = 'Send Message'
const VITTAS = 'contact@vittasinternational.com'
const NUMBER = '+234 806 080 5026'
const LOCATION = 'Lagos, Nigeria'
const TIME = 'Monday - Sunday: 8am - 5pm'
const ACCESS_TO_LOAN = 'You secure health'
const GROW = 'We secure'
const YOUR_BUSINESS = 'Your healthcare financing'
const GET_STARTED = 'Get Started'
const STAY_UPDATED = 'Stay Updated on loan news and everything finance'
const SUBSCRIBE_TO_NEWSLETTER = 'Subscribe to Newsletters'
const SUBSCRIBE = 'Subscribe'
const OUR_CLIENT_SAYS = 'See What Our Clients Say'
const REASON_TO_CHOOSE_FROM_US = 'Reasons To Choose From Us'
const LEARN_MORE = 'Learn More'
export const questions = [
  {
    question: 'How do Loan work?',
    answer:
      'First you have to signup on our web page. Then you must submit an application with relevant documentations and then your application will go through a review and approval process. After approval, and all required documents have been submitted and vetted by our team, funds will be disbursed to the relevant bank account.'
  },
  {
    question: 'How long does it take to apply for a loan?',
    answer: 'Filling out a loan application online takes approximately 10-15 minutes to complete.'
  },
  {
    question: 'How long does it take to get a loan?',
    answer:
      'At Vittas, loan approval and funding can occur in as little as 48 hours provided that the applicant has met the documentation requirements and is eligible to access the loan'
  },
  {
    question: 'How much can I borrow?',
    answer:
      'Depending on the size of your business, credit rating and payment history with us (for existing customers only), you can access up to N30 million naira in a single loan disbursement.'
  },
  {
    question: 'How long can I borrow the money for?',
    answer: 'Our loan term is usally from 6 to 12 months.'
  }
]
export const paymentSalesData = [
  {
    id: 1,
    text: 'Accurate tracking of sales'
  },
  {
    id: 2,
    text: 'Inventory management'
  },
  {
    id: 3,
    text: 'Better customer experience'
  },
  {
    id: 4,
    text: 'Streamlining payment processing'
  },
  {
    id: 5,
    text: '100% Transparency'
  }
]
export const GetLoansData = [
  {
    id: 1,
    text: 'Accurate tracking of sales'
  },
  {
    id: 2,
    text: 'Inventory management'
  },
  {
    id: 3,
    text: 'Better customer experience'
  }
]

export {
  NAME,
  TITTLE,
  SUB_TITTLE,
  SUFFICIENT_FUNDING,
  SUFFICIENT_FUNDING_DETAILS,
  CASH_ON_DELIVERY,
  CASH_ON_DELIVERY_DETAILS,
  VOLUME_DISCOUNT,
  VOLUME_DISCOUNT_DETAILS,
  WE_ARE_COMMITTED,
  BECAUSE_WE_BELIEVE,
  VALUE_BUSINESSES,
  NO_COLLETERAL,
  NO_COLLETERAL_EXPLAIN,
  LOW_INTEREST,
  LOW_INTEREST_RATE,
  FAST_LOAN,
  FAST_LOAN_LEANDING,
  BEST_PAYMENT,
  BEST_PAYMENT_PLAN,
  CONTENT,
  CONTENT_NAME,
  CONTENT1,
  CONTENT1_NAME,
  CONTENT2,
  CONTENT2_NAME,
  ABOUT_US,
  HOW_IT_WORKS,
  FAQ,
  PRIVACY_POLICY,
  COPY_RIGHT,
  CLIENTS_RETENTATION,
  CUSTOMER_SATISFACTION,
  CUSTOMER_BUSINESS,
  HUNDRED,
  NINETY_ONE,
  SEVENTY_FIVE,
  GET_IN_TOUCH,
  CONTACT_OUR_TEAM,
  EMAIL,
  MESSAGE,
  SEND_MEASSGE,
  VITTAS,
  NUMBER,
  TIME,
  LOCATION,
  ACCESS_TO_LOAN,
  GROW,
  YOUR_BUSINESS,
  GET_STARTED,
  STAY_UPDATED,
  SUBSCRIBE_TO_NEWSLETTER,
  SUBSCRIBE,
  OUR_CLIENT_SAYS,
  REASON_TO_CHOOSE_FROM_US,
  LEARN_MORE
}
