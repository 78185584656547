import React from 'react'
import PropTypes from 'prop-types'

const MOUAgreement = ({
  customerName = "Customer's name",
  amountInNumber = 'Amount in number',
  amountInWords = 'Amount in words',
  duration = 'duration',
  customerEmail = "Customer's email",
  distributorsName = 'Distributor’s name',
  distributorsAlias = 'Distributor’s Alias',
  customHeight = 50,
  showHighlight = false
}) => {
  return (
    <div width={1200} id='pdf-view' className='mx-20 font-Tinos'>
      <header>
        <div
          style={{
            border: '5px solid black',
            textAlign: 'center',
            padding: '50px'
          }}
        >
          <div>
            <p
              className='font-bold leading-normal text-3xl font-Tinos'
              style={{ wordSpacing: '20px' }}
            >
              MEMORANDUM OF UNDERSTANDING
            </p>
            <p className='font-Tinos'>BETWEEN</p>
            <p
              style={{
                fontSize: '25px',
                marginTop: '70px',
                wordSpacing: '20px'
              }}
              className='font-Tinos'
            >
              VITTAS NIGERIA INTERNATIONAL LIMITED
            </p>
            <p style={{ fontSize: '25px' }} className='font-Tinos'>
              AND
            </p>
            <p
              style={{
                fontSize: '25px',
                marginTop: '70px',
                backgroundColor: showHighlight ? 'yellow' : ''
              }}
              className='mx-96 font-Tinos'
            >
              {customerName}
            </p>
          </div>
        </div>
        <p style={{ border: '5px solid black', marginTop: '10px' }}> </p>
        <div
          style={{
            marginTop: '50px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <h3 className='underline text-lg'>Prepared by:</h3>
          </div>
          <div>
            <h3>Shile Anifowoshe</h3>
            <h3 style={{ marginTop: '30px', wordSpacing: '15px' }}>SUAVE ATTORNEYS</h3>
            <p>2A, Anthony Village Road</p>
            <p>Off Ikorodu Road</p>
            <p>Anthony Lagos State.</p>
            <p>
              Email:{' '}
              <span
                style={{
                  fontSize: '20px',
                  textDecoration: 'solid',
                  fontWeight: 'bold'
                }}
              >
                suaveattorney@gmail.com
              </span>
            </p>
            <p>
              Tel:{' '}
              <span
                style={{
                  fontSize: '20px',
                  textDecoration: 'solid',
                  fontWeight: 'bold'
                }}
              >
                08063155197,0902158361
              </span>
            </p>
          </div>
        </div>
      </header>

      <section style={{ marginTop: '100px' }}>
        <div style={{ fontSize: 'x-large', fontWeight: 500 }}>
          <div style={{ textAlign: 'center' }}>
            <h1 style={{ wordSpacing: '20px' }} className='font-Tinos'>
              {' '}
              LOAN AGREEMENT{' '}
            </h1>
            <p style={{ wordSpacing: '10px' }} className='font-Tinos'>
              THIS <span style={{ fontWeight: 'bold', wordSpacing: '20px' }}>LOAN AGREEMENT</span>{' '}
              (the <span style={{ fontWeight: 'bold' }}>“Agreement”</span>) is made this 3rd day of
              December
            </p>
          </div>
          <div className='font-Tinos'>
            <h3 className='text-center font-Tinos'>BETWEEN </h3>
            Vittas Nigeria International Limited a limited liability company duly registered under
            the laws of the Federal Republic of Nigeria with RC Number 1602151 and its registered
            address at Plot 6, Block 5 Segun Olusemo Crescent, Parkview Estate, Ikoyi, Lagos State
            (hereinafter referred to as the “theLender” which expression shall where the context so
            admits include its legal representatives and assigns) of the first part;
          </div>
          <div className='font-Tinos'>
            <h3 className='text-center font-Tinos'>AND</h3>
            <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }} className='font-Tinos'>
              {customerName}
            </span>{' '}
            a private limited liability company duly registered under the laws of the Federal
            Republic of Nigeria with [Customers RC Number] and its registered address at F&amp;F
            Plaza, 44 Adebisi Awosoga Street, Agege, Lagos State (hereinafter referred to as the
            “the Borrower” which expression shall where the context so admits include its legal
            representative, affiliates and assigns) of the other part;
          </div>
          <dl>
            <dt className='font-bold mr-20'>WHEREAS: -</dt>
            <dl className='ml-16'>
              <dd>
                i. The Lender is a company that provides leading solution in loan advancement
                financing that enable businesses and individuals have better access to working
                capital.
              </dd>
              <dd>
                ii.{' '}
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {distributorsName}{' '}
                </span>
                referred to as{' '}
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {distributorsAlias}
                </span>{' '}
                is a Pharmaceutical distribution outfit and whose customers would be referred to the
                lender for product purchase financing.
              </dd>
              <dd>
                iii. The Borrowers are customers referred to the Lender by{' '}
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {distributorsAlias}
                </span>{' '}
                for advancement of credit facilities in order to facilitate pharmaceutical product
                purchase from{' '}
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {distributorsAlias}
                </span>
                .
              </dd>
              <dd>
                iv. The Borrower on referral has approached the Lender and the Lender has agreed
                advance credit in the sum of ...
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {amountInNumber}
                </span>
                .(...
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {amountInWords}....)
                </span>{' '}
                subject to subject to terms and conditions contained herein.
              </dd>
            </dl>
          </dl>
          <div>
            <p className='font-Tinos' style={{ fontWeight: 'bold', wordSpacing: '20px' }}>
              NOW THIS DEED WITHNESSETH as follows:
            </p>
            <div className='ml-5'>
              <p className='font-Tinos font-bold' style={{ textDecorationLine: 'underline' }}>
                <span className='font-bold mr-5'>1.</span>
                LOAN
              </p>
              <p className='font-Tinos ml-8'>
                <span className='text-black block font-Tinos'>
                  The Lender hereby agrees to grant to the Borrower a ... {duration} month(s) loan
                  in the sum
                </span>
                <span
                  className='font-Tinos'
                  style={{ backgroundColor: showHighlight ? 'yellow' : '' }}
                >
                  {' '}
                  ...... {amountInNumber} ....{' '}
                </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: showHighlight ? 'yellow' : ''
                  }}
                  className='font-Tinos'
                >
                  ( ... {amountInWords} ..... ) (&rdquo; the Loan Amount &rdquo;).
                </span>
              </p>
              <p className='font-Tinos font-bold' style={{ textDecorationLine: 'underline' }}>
                <span className='font-bold mr-5'>2.</span>
                PURPOSE
              </p>
              <p className='font-Tinos ml-8'>
                The Loan Amount shall be for the purpose of purchasing pharmaceutical products from
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {distributorsAlias}
                </span>
              </p>
              <p className='font-Tinos font-bold' style={{ textDecorationLine: 'underline' }}>
                <span className='font-bold mr-5'>3.</span>
                TENOR
              </p>
              <p className='font-Tinos ml-8'>
                The Loan Amount shall be for …three (3) month(s) immediately commencing from the
                date the Loan Amount is transferred or deposited to the bank account provided by the
                Borrower (Commencement Date).
              </p>
              <p
                className='font-bold font-Tinos '
                style={{ textDecorationLine: 'underline', wordSpacing: '20px' }}
              >
                <span className='font-bold mr-5'>4.</span>
                DISBURSEMENT AND CONDITION TO DISBURSEMENT
              </p>
              <p className='font-Tinos ml-8'>
                The Lender shall disburse the Loan Amount to the Borrower upon the execution of
                Agreement by the Parties and the delivery of the executed copies of the under listed
                documents to the Lender by the Borrower in the form acceptable to Lender below;
              </p>
              <ol className='list-decimal list-inside ml-10 font-Tinos'>
                <li className='mt-5'>
                  Certified true copies of both CAC Form 2, 3 and 7 of the Borrower;
                </li>
                <li className='mt-5'>
                  A certified copy of the valid and subsisting license issued by the Pharmacists
                  Council of Nigeria or HEFEMAA and any other license required to be held by the
                  Borrower in relation to its business;
                </li>
                <li className='mt-5'>
                  Duly filled KYC documents indicating all necessary information of the including
                  but not limited to its permanent address, registered phone number, bank account
                  details;
                </li>
                <li className='mt-5'>
                  Certified copies of the Borrower’s Bank Statement for all its bank accounts
                  attached schedule depicting all accounts receivables and payables. Such Bank
                  Statements should cover the 9 months immediately preceding the Commencement Date;
                </li>
                <li className='mt-5 leading-loose'>
                  Certified copies of sales report or downloads directly from the Borrower’s
                  accounting software for the entire financial year at any time during the duration
                  of the loan outstanding.
                </li>
                <li className='mt-5'>
                  Lender can request for cumulative sales downloads from the Borrower’s accounting
                  software for the entire financial year in two (2) separate batches at separate
                  intervals.
                </li>
                <li className='mt-5'>
                  Certified copy of the Borrower’s inventory immediately prior to the Commencement
                  Date. Such inventory should depict its medication and stock turnover;
                </li>
                <li className='mt-5'>
                  Duly executed post dated cheques issued by the Borrwer in favour of the covering
                  both the Loan Amount and the interest payable on same;
                </li>
                <li className='mt-5'>
                  Duly executed resolution (Schedule 3) of the directors of the Borrower the
                  transaction contemplated in this Agreement;
                </li>
                <li className='mt-5'>
                  An undertaking issued by the Borrower stating that the Borrower shall not the
                  subsistence of this Agreement or any time the Loan Amount remains outstanding,
                  open another bank account or change the bank Account into which any remittance due
                  from its customers is to be made;
                </li>
                <li className='mt-5'>
                  Duly filled Data Requirements Form and a corresponding Letter of Undertaking in
                  connection with contents of the Data Requirements Form in the form acceptable to
                  the Lender;
                </li>
                <li className='mt-5'>
                  A duly certified schedule of the medication to be purchased by the Borrower with
                  the Loan Amount and a corresponding Lien created in respect of the medication in
                  favour of Lender.
                </li>
                <li className='mt-5'>
                  The Borrower agrees to allow the Lender perform a background check on the Borrower
                  using any government or third party agencies to assist.
                </li>
              </ol>
              <p className='font-Tinos'>
                The Borrower shall also pay upfront, a one-time management fee of 0.5% and
                maintenance fee of 0.5%, totaling 1% of the Loan Amount on or before the loan is
                disbursed. The both fees shall be deducted from the Loan Amount before it is
                disbursed to the Borrower.
              </p>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className='font-Tinos'
              >
                <span className='font-bold mr-5'>5.</span>
                INTEREST
              </p>
              <p className='font-Tinos ml-8'>
                The Loan Amount shall attract an interest of 3.0 % monthly of the Loan Amount
                <span style={{ fontWeight: 'bold' }}> net of all taxes</span> (“Interest”) from the
                Commencement Date.
              </p>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className='font-Tinos'
              >
                <span className='font-bold mr-5'>6.</span> SECURITY
              </p>
              <p className='font-Tinos  ml-8'>
                {' '}
                <span className='mr-5'> 6.1 </span>As Security for repayment of the Loan Amount, the
                Borrower hereby pledges to provide all the documents listed in clause 4 above and
                execute all actions required by the Lender to give effect to the Security.
              </p>
              <p className='font-Tinos  ml-8'>
                <span className='mr-5'> 6.2</span> Pari Passu Rule: The loan contemplated under this
                Agreement and the thereof shall rank pari passu with any other facility or loan
                created prior to this Agreement.
              </p>
              <p
                className='font-Tinos'
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
              >
                <span className='font-bold mr-5'>7.</span>
                REPAYMENT
              </p>
              <p className='font-Tinos'>
                <span className='mr-5 leading-loose'> 7.1 </span>The Borrower will pay the Interest
                on Loan Amount each month and on the last month, shall pay the Loan Amount together
                with the accrued interest.
              </p>
              <p className='font-Tinos'>
                <span className='mr-5'> 7.2 </span>The Borrower shall repay the Loan Amount together
                with the accrued interest bank transfer to the account of the Lender
                <span
                  style={{
                    fontStyle: 'italic',
                    fontWeight: 'bold',
                    wordSpacing: '20px'
                  }}
                  className='font-Tinos'
                >
                  (ACCOUNT NAME: VITTAS NIGERIA INTERNATIONAL LIMITED, BANK: GUARANTY TRUST BANK
                  PLC, ACCOUNT NUMBER: 0490974134)
                </span>
                and in addition issue …three…post-dated cheques to cover the interest and the final
                post-dated cheque will cover both the interest and the principal sum. The issued
                cheque shall be destroyed by the Lender or returned to the Borrower upon the
                discharge of all obligations.
              </p>
              <p className='font-Tinos'>
                <span className='mr-5'> 7.3 </span>Upon the occurrence of any of the events listed
                in Clause 10 as an event of default,{' '}
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {distributorsAlias}
                </span>{' '}
                shall be notified to immediately stop supplies of medications to the Borrower until
                same has been repaid by the borrower subject to Clause 10.
              </p>
              <p className='font-Tinos'>
                <span className='mr-5'>7.4</span> Monthly interest shall become due and payable by
                the borrow not later than 30 days after disbursement of loan by the Lender.
              </p>
              <p className='font-Tinos'>
                <span className='mr-5'>7.5</span> Subject to 7.4 above, late payment shall attract a
                penalty of 1% of the Loan Amount advanced to the borrower 6 days after due date.
              </p>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className='font-Tinos'
              >
                <span className='font-bold mr-5'>8.</span>
                PREPAYMENT
              </p>
              <p className='font-Tinos ml-8'>
                The Borrower may at any time prepay without premium or penalty, the whole or part of
                any of the Loan Amount or in part, subject to giving not less than two (2) business
                days notice to the Lender of the date and amount of the prepayment and provided that
                the Borrower shall be paying in addition to the respective prepaid payment the
                accrued Interest on same.
              </p>

              <p
                className='font-Tinos'
                style={{
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  wordSpacing: '15px'
                }}
              >
                <span className='font-bold mr-5'>9.</span>
                REPRESENTATIONS AND WARRANTIES
              </p>
              <p className='font-Tinos ml-8 mt-5'>
                The Borrower hereby makes the following representation and warranties to and for the
                benefit of the Lender that:
              </p>
              <p className='font-Tinos font-bold'>(a) Power and Authority</p>
              <p className='font-Tinos ml-8'>
                It has power to enter into, or as the case may be, to comply with, and be bound by
                all obligations expressed on his part under this Loan Agreement including the right
                to borrow under this Loan Agreement and that all requisite resolutions and approvals
                authorizing it to enter into the transaction herein have been approved.
              </p>
              <p className='font-Tinos font-bold'>(b) Purpose</p>
              <p className='font-Tinos ml-8'>
                That it shall utilize the Loan Amount solely for the purpose so stated in this
                Agreement.
              </p>
              <p className='font-Tinos font-bold'>(c) Documentation</p>
              <p className='font-Tinos ml-8'>
                <span className='mr-4'>(i)</span> That the documentation so listed in clause 4 of
                this Agreement shall to the existent so reasonably permissible in the ordinary cause
                of business of the Borrower, remain in an unencumbered state during the subsistence
                of this Agreement.
              </p>
              <p className='font-Tinos ml-8'>
                <span className='mr-4'>(ii)</span> That as at the Commence Date, no notice of
                violation has been served on it by any any regulatory authority.
              </p>
              <p className='font-Tinos ml-8 leading-tight -mt-3'>
                <span className='mr-4'>(iii)</span> That it shall ensure the optimum of its
                obligations under this Agreement and equally ensure that during the subsistence of
                this Agreement, it shall deal professionally with its customers.
              </p>
              <p
                className='font-Tinos'
                style={{
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  wordSpacing: '15px'
                }}
              >
                <span className='font-bold mr-5'>10.</span> EVENTS OF DEFAULT
              </p>
              <p className='font-Tinos ml-8'>
                Upon the happening of any of the following events, the Lender may by notice in
                writing to the Borrower so long as such event is continuing declare such event to
                constitute an Event of Default and the amount of the Loan then outstanding and
                interest accrued thereon and any other monies payable hereunder to be immediately
                due and payable and his obligation to provide monies under this Loan Agreement to be
                terminated;
              </p>
              <p className='ml-5'>
                <p className='font-Tinos'>
                  <span className='mr-5'>(a)</span> If the Borrower does not pay the Loan or
                  interest payable hereunder when due and such amount remain unpaid at the due date;
                </p>
                <p className='font-Tinos'>
                  <span className='mr-5'>(b)</span> If any representation, warranty, covenant,
                  information or undertaking made by the Borrower in this Loan Agreement or any
                  other document previously communicated to the Lender is incorrect or materially
                  inaccurate when made or delivered;
                </p>
                <p className='font-Tinos'>
                  <span className='mr-5'>(C)</span> Any material provision of this Agreement is or
                  becomes for any reason invalid or unenforceable;
                </p>
                <p className='py-10 font-Tinos'>
                  <span className='mr-5'>(d)</span> If the Lender believes that there is the
                  possibility of the Guarantor not being able to fulfil its respective obligations
                  as contained the Deed of Personal Guarantee contained ins Schedule 2 of this
                  Agreement or the possibility of the Security being encumbered, trespassed,
                  disposed or being in an ineffectual state.
                </p>
                <p className='font-Tinos'>
                  <span className='mr-5'>(e)</span> If the Lender believes the business engagement
                  with the Borrower is threatened or likely to be terminated.
                </p>
                <p className='font-Tinos'>
                  <span className='mr-5'>(f)</span> Anything analogous to any of the events
                  specified in this clause occurs the laws of the applicable jurisdiction;
                </p>
                <p className='font-Tinos'>
                  <span className='mr-5'>(g)</span> Following an Event of Default which is
                  continuing, upon the request of the Lender, the Borrower shall immediately repay
                  the loan to a designated account as provided by the Lender.
                </p>
              </p>
              <p className='font-Tinos'>
                For the avoidance of doubt but without prejudice to the right of the Lender in
                respect respect of the Loan Amount and applicable Interest, the Borrower shall fully
                indemnify the Lender immediately upon demand for all cost and liability incurred in
                the case of a breach.
              </p>
              {customHeight && <div style={{ height: customHeight }} />}
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className='font-Tinos'
              >
                <span className='font-bold mr-5'>11.</span>
                ASSIGNMENT
              </p>
              <p className='font-Tinos ml-8'>
                No rights which either Party may have in terms of this Agreement shall be capable
                being assigned or transferred without the prior written consent of the other Party,
                which consent shall not be unreasonably withheld.
              </p>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className='font-Tinos'
              >
                <span className='font-bold mr-5'>12.</span>
                SEVERABILITY
              </p>
              <p className='font-Tinos ml-8'>
                (a) If any provision of this Agreement is held to be illegal, invalid or
                unenforceable in whole or in part, the legality, validity and enforceability of the
                remaining provisions shall not in any way be affected or impaired thereby.
              </p>
              <p className='font-Tinos ml-8'>
                (b) Any term or provision found by a court of competent jurisdiction to be invalid,
                illegal or otherwise unenforceable shall be deemed modified to the extent necessary
                to render such term or provision enforceable, and the rights and obligations of the
                Parties shall be construed and enforced accordingly, preserving to the fullest
                permissible extent the rights and obligations of the Parties.
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  wordSpacing: '15px'
                }}
                className='font-Tinos'
              >
                <span className='font-bold mr-5'>13.</span>
                ENTIRE AGREEMENT
              </p>
              <p className='font-Tinos ml-8'>
                This Agreement contains the entire agreement and understanding of the Parties
                supersedes all prior agreements, understandings or arrangements between the Parties
                (both oral and written) relating to the subject matter of this Agreement. Each of
                the Parties acknowledges and agrees that it has not entered into this Agreement on
                the basis of and does not rely, and has not relied, upon any statement or
                representation (whether negligent or innocent) or warranty or other provision (in
                any case whether oral, written, express or implied) made, given or agreed to by any
                person (whether a party to this Agreement or not) except those expressly set out or
                referred to in this Agreement.
              </p>
              <p
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
                className='font-Tinos'
              >
                <span className='font-bold mr-5'>14.</span>
                NOTICES
              </p>
              <p className='font-Tinos ml-8'>
                Any notice under this Agreement shall be written in the English Language and be
                deemed to be sufficiently served if the same is sent or addressed to any Party by
                electronic emails (email) via the respective under listed email addresses of the
                Parties:
              </p>
              <p className='font-Tinos ml-8'>
                Name of Party: Vittas Nigeria International Limited
                <br />
                Email Address:{' '}
                <a href='mailto: Sulav@VittasInternational.com?cc=Collins@vittasinternational.com'>
                  Sulav@VittasInternational.com; Collins@vittasinternational.com
                </a>
                <br />
                Name of Party:{' '}
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {customerName}
                </span>
                <br />
                Email Address:{' '}
                <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>
                  {customerEmail}
                </span>
              </p>
              <p className='font-Tinos ml-8'>
                For the purpose of this Clause, the Parties expressly agree that time/notification
                stamp of the email shall be proof in respect of the time and receipt status of any
                email shared between the Parties.
              </p>
              <p
                className='font-Tinos'
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
              >
                <span className='font-bold mr-5'>15.</span>
                VARIATION
              </p>
              <p className='font-Tinos ml-8'>
                No variation, supplement, amendment, deletion or replacement of or from this
                Agreement or any of its terms shall be effective unless made in writing and signed
                by or on behalf of each Party.
              </p>
              <p
                className='font-Tinos'
                style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}
              >
                <span className='font-bold mr-5'>16.</span>
                WAIVERS
              </p>
              <p className='font-Tinos ml-8'>
                Any term or provision of this Agreement may be waived in writing at any time by
                Party that is entitled to the benefit thereof. No delay or omission to exercise any
                right, power, or remedy accruing to either Party upon any breach or default under
                this Agreement, shall be deemed to impair, waive or release that right, power or
                remedy.
              </p>
              <p className='underline font-Tinos font-bold'>
                {' '}
                <span className='font-bold mr-5'>17.</span>COUNTERPARTS
              </p>
              <p className='font-Tinos ml-8'>
                This Agreement may be executed in any number of counterparts, all of which together
                shall constitute one and the same agreement, and each Party may enter into this
                Agreement by executing a counterpart.
              </p>
              <p className='font-Tinos font-bold underline' style={{ wordSpacing: '15px' }}>
                <span className='font-bold mr-5'>18.</span>
                GOVERNING LAW
              </p>
              <p className='font-Tinos ml-8'>
                This Agreement shall be governed by and construed in accordance with the laws of the
                Federal Republic of Nigeria.
              </p>
              <p
                className='font-Tinos'
                style={{
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  wordSpacing: '15px'
                }}
              >
                <span className='font-bold mr-5'>19.</span>
                DISPUTE RESOLUTION
              </p>
              <p className='font-Tinos ml-8'>
                Any dispute arising in connection with or in relation to this Agreement shall be
                subject to adjudication by a court of competent jurisdiction in Nigeria.
              </p>
              <p className='font-Tinos ml-8' style={{ wordSpacing: '10px' }}>
                IN WITNESS WHEREOF the parties herein have executed this Agreement in the manner
                below the date and year first above written
              </p>
            </div>
          </div>
        </div>
      </section>

      <footer style={{ marginBottom: '100px' }}>
        <div
          style={{
            fontWeight: 'bolder',
            fontSize: 'x-large',
            margin: 'auto',
            width: '80%'
          }}
        >
          <p className='font-Tinos' style={{ wordSpacing: '15px' }}>
            THE COMMON SEAL OF VITTAS NIGERIA INTERNATIONAL LIMITED (the within named Lender) is
            hereto affixed
          </p>
          <p className='font-Tinos'>In the Presence of</p>
          <div
            style={{ display: 'flex', justifyContent: 'space-around', marginTop: '50px' }}
            className='my-20'
          >
            <p className='font-Tinos' style={{ borderTop: '2px solid black' }}>
              DIRECTOR
            </p>
            <p className='font-Tinos' style={{ borderTop: '2px solid black' }}>
              DIRECTOR/SECRETARY
            </p>
          </div>
          <p className='font-Tinos' style={{ wordSpacing: '10px' }}>
            THE COMMON SEAL OF{' '}
            <span style={{ backgroundColor: showHighlight ? 'yellow' : '' }}>{customerName}</span>{' '}
            (the within named Borrower) is hereto affixed
          </p>
          <p className='font-Tinos'>In the Presence of</p>
          <div style={{ display: 'flex', justifyContent: 'space-around' }} className='my-20'>
            <p className='font-Tinos' style={{ borderTop: '2px solid black' }}>
              DIRECTOR
            </p>
            <p className='font-Tinos' style={{ borderTop: '2px solid black' }}>
              DIRECTOR/SECRETARY
            </p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <p className='font-Tinos' style={{ wordSpacing: '20px' }}>
              SCHEDULE 1
            </p>
            <p className='font-Tinos' style={{ wordSpacing: '20px' }}>
              REPAYMENT SCHEDULE
            </p>
          </div>
          <table style={{ borderCollapse: 'collapse' }} className='mx-auto'>
            <tbody>
              <tr>
                <th
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                  className='font-Tinos'
                >
                  S/N
                </th>
                <th
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                  className='font-Tinos'
                >
                  AMOUNT PAYABLE
                </th>
                <th
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px',
                    wordSpacing: '15px'
                  }}
                  className='font-Tinos'
                >
                  REPAYMENT PERIOD
                </th>
                <th
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                  className='font-Tinos'
                >
                  INTEREST
                </th>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                >
                  1
                </td>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                  className='font-Tinos'
                >
                  [Loan amount in figure]
                </td>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                  className='font-Tinos'
                >
                  [duration in months]
                </td>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                  className='font-Tinos'
                >
                  [Interest in figure ]
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
              </tr>
              <tr>
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
                <td
                  style={{
                    border: '1px solid #000',
                    textAlign: 'left',
                    padding: '8px'
                  }}
                />
              </tr>
            </tbody>
          </table>
        </div>
      </footer>
    </div>
  )
}

MOUAgreement.propTypes = {
  customerName: PropTypes.string.isRequired,
  amountInNumber: PropTypes.string,
  amountInWords: PropTypes.string,
  duration: PropTypes.string,
  customerEmail: PropTypes.string,
  customHeight: PropTypes.number,
  showHighlight: PropTypes.bool,
  distributorsName: PropTypes.string,
  distributorsAlias: PropTypes.string
}
export default MOUAgreement
