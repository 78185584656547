import ApiHandler from './ApiHandler'

export default {
  addInterestPaymentToLoan: data => ApiHandler.post('admin/add_interest_payment', data),
  addPrincipalPaymentToLoan: data => ApiHandler.post('admin/add_principal_payment', data),
  addPenaltyPaymentToLoan: data => ApiHandler.post('admin/add_penalty_payment', data),
  interestPaymentTracker: data => ApiHandler.post('admin/interest-payment-tracker', data),
  updateInterestPayment: data => ApiHandler.put('admin/update-interest-payment', data),
  getUserInterestTrackerDetails: data => ApiHandler.post('admin/user-interest-tracker', data),
  updateInterestTrackerDetails: data => ApiHandler.put('admin/update-interest-payment', data),
  deleteInterestPayment: data => ApiHandler.put('admin/delete_interest_payment', data)
}
