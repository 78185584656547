import React from 'react'
import PropTypes from 'prop-types'

const PaidCard = ({ paid }) => {
  return paid ? (
    <p
      className='py-1 text-center rounded font-manrope px-7 text-xs w-28'
      style={{
        background: 'rgba(121, 242, 192, 1)',
        color: '#006644'
      }}
    >
      Paid
    </p>
  ) : (
    <p
      className='py-1 text-center rounded font-manrope px-7 text-xs w-28'
      style={{ background: '#F8E1E0', color: '#B01C1C' }}
    >
      Not Paid
    </p>
  )
}

export default PaidCard

PaidCard.propTypes = {
  paid: PropTypes.bool
}
