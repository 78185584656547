import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { Icon } from '@iconify/react'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}

const InfoModal = ({ visible, onCancel, title, description }) => {
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      centered
      className='info-modal'
      closeIcon={<Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />}
      bodyStyle={bodyStyle}
      width={700}
    >
      <div className='modal-header bg-primary px-6 py-4  flex justify-between'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>{title}</h2>
      </div>
      <p className='my-4 px-8 tracking-widest leading-loose py-8'>{description}</p>
    </Modal>
  )
}

InfoModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default InfoModal
