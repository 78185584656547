import React, { useState } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import UploadDocs from './UploadDocs'

const AdminUploadDoc = ({ loanData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  return (
    <main className='w-full flex-grow p-2 '>
      <div className='ml-1 flex justify-between'>
        <button className='' onClick={() => setIsModalVisible(true)}>
          <i className='fa fa-upload mr-2' aria-hidden='true' />
          Uplaod Documents
        </button>
        <Modal
          title=''
          onCancel={() => setIsModalVisible(false)}
          width='60%'
          open={isModalVisible}
          footer={false}
        >
          <UploadDocs setIsModalVisible={setIsModalVisible} loanData={loanData} />
        </Modal>
      </div>
    </main>
  )
}

export default AdminUploadDoc

AdminUploadDoc.propTypes = {
  loanData: PropTypes.object
}
