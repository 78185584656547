import React from 'react'
import { Popconfirm, notification } from 'antd'
import PropTypes from 'prop-types'
import { DELETE_ROLE } from './constants'
import { Roles } from '../../../services'
import { dispatch } from '../../../redux/store'

const DeleteRole = ({ roleId }) => {
  const cancel = () => {
    notification.warn({
      message: 'Role not deleted'
    })
  }
  const handleDelete = async () => {
    try {
      const { data } = await Roles.admin_delete_role(roleId)
      notification.success({
        message: 'Success',
        description: data.message || 'Role Deleted'
      })
      await dispatch.roles.getAllRoles()
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: error?.response?.data?.message || 'Something went wrong'
      })
    }
  }

  return (
    <Popconfirm
      title='Are you sure to delete this role?'
      onConfirm={handleDelete}
      onCancel={cancel}
      okText='Yes'
      cancelText='No'
    >
      <button>
        <i className='far fa-trash-alt mr-2' /> {DELETE_ROLE}{' '}
      </button>
    </Popconfirm>
  )
}
DeleteRole.propTypes = {
  roleId: PropTypes.object
}
export default DeleteRole
