/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getColumnSearchProps } from '../../../sharedComponents/antSearch/search'
import DropDown from '../../../sharedComponents/drop-down/DropDown'
import DueSoon from '../../../sharedComponents/DueSoon'
import AdminAddPaymentModal from '../../../sharedComponents/modal/AdminAddPaymentModal'
import {
  ADD_INTEREST_PAYMENT,
  ADD_PENALTY_PAYMENT,
  ADD_PRINCIPAL_PAYMENT
} from '../../../sharedComponents/modal/constant'
import IdsComponent from '../../../sharedComponents/others/IdsComponent'
import { checkPrivilages, formatCurrency } from '../../../utils/helpers'
import CloseLoan from '../../applications/AdminCloseLoan'
import MarkAsDefaultedLoan from '../../applications/DefaultedLoan'
import { INTEREST_TRACKER, VIEW_APPLICATION } from '../../applications/constant'
import { ACTIONS } from '../../loans/constant'
import * as roles from '../../../utils/roles'
import { notification } from 'antd'

const LOAN_APPLICATION = 'Back'
export const APPLY_FOR_LOAN = 'Apply For Loan'

export const adminDisbursedLoanColumn = (permissions, searchObject) => [
  {
    title: <span className='font-bold'>Business name</span>,
    datakey: 'business_name',
    dataIndex: 'business_name',
    ...getColumnSearchProps({
      dataIndex: 'business_name',
      title: 'Business Name',
      ...searchObject
    }),
    key: 'business_name'
  },
  {
    title: <span className='font-bold'>Admin Note</span>,
    datakey: 'Admin Note',
    dataIndex: 'admin_note',
    key: 'admin_note',
    render: admin_note => <span>{admin_note}</span>
  },
  {
    title: <span className='font-bold'>Loan Status</span>,
    datakey: 'Loan Ended',
    dataIndex: 'has_loan_ended',
    key: 'has_loan_ended',
    filters: [
      {
        text: 'Ended',
        value: 1
      },
      {
        text: 'Active',
        value: 0
      }
    ],
    onFilter: (value, record) => record.has_loan_ended === value,
    sorter: (a, b) => a.has_loan_ended - b.has_loan_ended,
    sortDirections: ['ascend'],
    render: has_loan_ended => <span>{has_loan_ended ? 'Ended' : 'Active'}</span>
  },
  {
    title: <span className='font-bold'>Loan Type</span>,
    datakey: 'loan_type',
    dataIndex: 'loan_type',
    key: 'loan_type',
    filters: [
      {
        text: 'Amortized Loan',
        value: 'Amortized Loan'
      },
      {
        text: 'Bullet Loan',
        value: 'Bullet Loan'
      }
    ],
    onFilter: (value, record) => record.loan_type === value
  },
  {
    title: <span className='font-bold'>Next Interest Date</span>,
    datakey: 'Next interest date',
    key: 'next_interest_date',
    dataIndex: 'next_interest_date',
    render: next_interest_date => <DueSoon nextDate={next_interest_date} numOfDays={7} />,
    sorter: (a, b) => new Date(a.next_interest_date) - new Date(b.next_interest_date)
  },
  {
    title: <span className='font-bold'>Disbursed Date</span>,
    datakey: 'Disbursed Date',
    key: 'disbursed_date',
    dataIndex: 'disbursed_date',
    render: disbursed_date => <span>{disbursed_date?.split(' ')[0]}</span>,
    sorter: (a, b) => new Date(a.disbursed_date) - new Date(b.disbursed_date)
  },
  {
    title: <span className='font-bold'>Intrest Rate</span>,
    datakey: 'Intrest Rate',
    key: 'loan_interest_rate',
    dataIndex: 'loan_interest_rate',
    render: loan_interest_rate => <span>{loan_interest_rate}</span>
  },
  {
    title: <span className='font-bold'>Loan Duration</span>,
    datakey: 'Loan Duration',
    key: 'loan_duration',
    dataIndex: 'loan_duration',
    render: loan_duration => <span>{loan_duration} Month</span>
  },
  {
    title: <span className='font-bold'>Loan Fixed Principal Amount</span>,
    datakey: 'Loan Amount',
    key: 'loan_principal_amount_fixed',
    dataIndex: 'loan_principal_amount_fixed',
    render: loan_principal_amount_fixed => (
      <span>₦{formatCurrency(loan_principal_amount_fixed)}</span>
    )
  },
  {
    title: <span className='font-bold'>Current Principal Amount</span>,
    datakey: 'Current Principal Amount',
    key: 'remaining_principal_amount',
    dataIndex: 'remaining_principal_amount',
    render: remaining_principal_amount => <span>₦{formatCurrency(remaining_principal_amount)}</span>
  },
  {
    title: <span className='font-bold'>Loan ID</span>,
    datakey: 'Loan ID',
    dataIndex: 'loan_app_id',
    key: 'loan_app_id',
    render: (loan_app_id, _) => <IdsComponent id={_.unique_loan_key || loan_app_id} />
  },
  {
    title: <span className='font-bold'>Defaulted Status</span>,
    datakey: 'Has Loan Defaulted',
    dataIndex: 'has_loan_defaulted',
    key: 'has_loan_defaulted',
    render: has_loan_defaulted => <span>{has_loan_defaulted ? 'Defaulted' : 'N/A'}</span>
  },
  {
    title: <span className='font-bold capitalize'>{ACTIONS}</span>,
    datakey: '',
    key: 'action',
    render: data => {
      return (
        <DropDown>
          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link text-blue-800 ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <AdminAddPaymentModal
              disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
              type='addInterestPayment'
              data={data}
              buttonText={
                <>
                  <i className='fa fa-plus mr-2' /> {ADD_INTEREST_PAYMENT}
                </>
              }
            />
          </span>
          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link text-blue-800 ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <AdminAddPaymentModal
              disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
              type='addPrincipalPayment'
              data={data}
              buttonText={
                <>
                  <i className='fa fa-plus mr-2' /> {ADD_PRINCIPAL_PAYMENT}
                </>
              }
            />
          </span>
          <span
            style={{ outline: 0 }}
            className={`w-full text-left nav-sm-link text-blue-800 ${
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            }`}
            title={
              !checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)
                ? 'You have no permission'
                : ''
            }
          >
            <AdminAddPaymentModal
              disabled={!checkPrivilages([roles.APPROVE_REJECTED_LOANS], permissions)}
              type='addPenaltyPayment'
              data={data}
              buttonText={
                <>
                  <i className='fa fa-plus mr-2' /> {ADD_PENALTY_PAYMENT}
                </>
              }
            />
          </span>
          <CloseLoan loanId={data.approved_loan_id} permissions={permissions} />
          <MarkAsDefaultedLoan approvedLoanId={data.approved_loan_id} />
          <Link
            to={`../application/${data.loan_app_id}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
            title={
              !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
                ? 'You have no permission'
                : ''
            }
            disabled={!checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)}
          >
            <i className='far fa-eye mr-2' /> {VIEW_APPLICATION}
          </Link>
          <Link
            onClick={e => {
              if (!data.approved_loan_id) {
                e.preventDefault()
                notification.error({
                  message: 'Invalid loan Id'
                })
              }
            }}
            to={`/interest-tracker/${data.approved_loan_id}?start=${
              data.disbursed_date?.split(' ')[0]
            }&duration=${data.loan_duration}`}
            style={{ outline: 0 }}
            className='w-full text-left nav-sm-link'
            title={
              !checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)
                ? 'You have no permission'
                : ''
            }
            disabled={!checkPrivilages([roles.VIEW_LOAN_APPLICATIONS], permissions)}
          >
            <i className='far fa-eye mr-2' /> {INTEREST_TRACKER}
          </Link>
        </DropDown>
      )
    }
  }
]

export { LOAN_APPLICATION }
