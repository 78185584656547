import React from 'react'
import { FooterWriteup, NewsLetterHeading, Subscribe, unsubscribe } from '../constants'

const Newsletter = () => {
  return (
    <div className='bg-primary w-full lg:flex-row lg:flex py-12 p-8 lg:px-20 items-center  justify-between'>
      <div className=''>
        <p className='lg:max-w-xs text-2xl w-full font-bold text-white leading-normal capitalize '>
          {NewsLetterHeading}
        </p>
        <p className='lg:max-w-lg tracking-wider text-lg font-normal text-white'>{FooterWriteup}</p>
      </div>
      <div className='lg:w-4/12 lg:mr-4'>
        <div className='flex justify-center gap-4 flex-col'>
          <input
            className='py-4 w-full px-5 text-lg rounded-lg'
            placeholder='Enter email address'
            type='text'
          />
          <button className='lg:bg-subscribe btn-primary-200 py-4 text-white font-bold text-xl border-white border-2 rounded-lg'>
            {Subscribe}
          </button>
          <p className='text-white text-lg cursor-pointer'>{unsubscribe}</p>
        </div>
      </div>
    </div>
  )
}

export default Newsletter
