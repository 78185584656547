/* eslint-disable prettier/prettier */
import { notification } from 'antd'
import { Payments } from '../../services'
import { pastSixMonths, todaysDate } from '../../utils/dateRange'
import { reducerActions } from '../reducer'

const initialState = {
  paymentDetails: [],
  interestTracker: {},
  interestTrackerDetails: []
}

export const payments = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({

    async adminAddInterestPayment(payload) {
      dispatch.payments.setError(null)
      try {
        const { data } = await Payments.addInterestPaymentToLoan(payload)
        if (data?.success) {
          notification.success({
            message: 'Payment Added Successfully',
            description: data.message
          })
        }
      } catch (error) {
        dispatch.payments.setError(error.response)
        notification.error({
          message: 'An error occured',
          duration: 5000,
          description:
          error?.response?.data?.message ||
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async adminAddPrincipalPayment(payload) {
      dispatch.payments.setError(null)
      try {
        const { data } = await Payments.addPrincipalPaymentToLoan(payload)
        if (data?.success) {
          notification.success({
            message: 'Payment Added Successfully',
            description: data.message
          })
        }
      } catch ({ data, message }) {
        notification.error({
          message: 'An error occured',
          description: message
        })
      }
    },

    async adminAddPenaltyPayment(payload) {
      dispatch.payments.setError(null)
      try {
        const { data } = await Payments.addPenaltyPaymentToLoan(payload)
        if (data?.success) {
          notification.success({
            message: 'Payment Added Successfully',
            description: data.message
          })
        }
      } catch ({ message }) {
        notification.error({
          message: 'An error occured',
          description: message
        })
      }
    },

    async updateInterestPaymentTracker(payload) {
      dispatch.payments.setError(null)
      const { details } = payload
      try {
        const { data } = await Payments.updateInterestPayment(details)
        const { success, message } = data
        if (!success) {
          notification.error({
            message: 'An error occur while updating Interest Payments'
          })
        }
        if (success) {
          notification.success({
            message: 'Payments Updated Successfully',
            description: message
          })

          await dispatch.payments.interestTracker(pastSixMonths, todaysDate)
        }
      } catch (error) {
        notification.error({
          message: 'An error occured',
          description: error?.response?.data?.message || error.message
        })
      }
    },

    async interestTracker (payload) {
      dispatch.payments.setError(null)
      try {
        const { data } = await Payments.interestPaymentTracker(payload)
        if (data.success) {
          dispatch.payments.setState({
            interestTracker: data.data
          })
        }
        if (!data.success) {
          notification.error({
            message: 'An error occured while fetching Interest Payments'
          })
        }

        return data.data
      } catch (error) {
        dispatch.loans.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },

    async interestTrackerDetails(payload) {
      dispatch.payments.setError(null)
      try {
        const { data } = await Payments.getUserInterestTrackerDetails(payload)
        dispatch.payments.setState({
          interestTrackerDetails: data.data
        })
        return data.data
      } catch ({ error, response }) {
        dispatch.payments.setError(response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    },
    async updatePaymentsDetails(payload) {
      dispatch.payments.setState(null)
      try {
        const { data } = await Payments.updateInterestTrackerDetails(payload)
        const { success, message } = data
        if (success) {
          notification.success({
            message: 'Payment details updated successfully',
            description: message
          })
        }

        if (!success) {
          notification.error({
            message: 'An error occur while updating Payment details'
          })
        }
      } catch (error) {
        notification.error({
          message: 'An error occured',
          description: error?.response?.data?.message || error.message
        })
      }
    },

    async deleteInterestPayment(payload) {
      try {
        const { data } = await Payments.deleteInterestPayment(payload)
        const { success, message } = data
        if (success) {
          notification.success({
            message: 'Payment details deleted successfully',
            description: message
          })
        }

        if (!success) {
          notification.error({
            message: 'An error occur while deleting interest payment'
          })
        }
      } catch (error) {
        notification.error({
          message: 'An error occured',
          description: error?.response?.data?.message || error.message
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.payments.setState(initialState)])
    }
  })
}
