import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { notification } from 'antd'
import { dispatch } from '../../../redux/store'

import { CONFIRM_PASSWORD, CURRENT_PASSWORD, NEW_PASSWORD, UPDATE_CHANGES } from '../constants'

const SecurutyInformation = () => {
  const [showPwdOne, setShowPwdOne] = useState(false)
  const [showPwdTwo, setShowPwdTwo] = useState(false)
  const [showPwdThree, setShowPwdThree] = useState(false)
  const [user, setUser] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' })
  const loading = useSelector(({ loading }) => loading.effects.auth.changePassword)

  const onChangeText = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const submitFormHandle = e => {
    e.preventDefault()
    if (!user.oldPassword) {
      return notification.error({
        message: 'An error occured',
        description: 'Current is required!'
      })
    }

    if (!user.newPassword) {
      return notification.error({
        message: 'An error occured',
        description: 'New Password is required!'
      })
    }
    if (user.newPassword.length < 8) {
      return notification.error({
        message: 'An error occured',
        description: 'New Password length must be above 7 characters!'
      })
    }

    if (user.newPassword !== user.confirmPassword) {
      return notification.error({
        message: 'An error occured',
        description: 'New Password does not match!'
      })
    }

    dispatch.auth.changePassword({
      current_password: user.oldPassword,
      new_password: user.newPassword,
      confirm_password: user.confirmPassword
    })
  }

  return (
    <div className='w-full'>
      <div className='flex flex-wrap pb-36 justify-center mt-8'>
        <div className='w-full md:w-3/5 md:mx-6'>
          <div>
            <div className='profile-form'>
              <div className=''>
                <div className='mt-1'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-full mb-6'>
                      <label className='font-semibold'>{CURRENT_PASSWORD} </label>
                      <div className='relative mt-2'>
                        <input
                          className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                          type={showPwdOne ? 'text' : 'password'}
                          value={user.oldPassword}
                          onChange={onChangeText}
                          name='oldPassword'
                        />

                        <button
                          onClick={() => setShowPwdOne(!showPwdOne)}
                          type='submit'
                          className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                        >
                          {showPwdOne ? (
                            <i className='fas fa-eye-slash' />
                          ) : (
                            <i className='fas fa-eye' />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className='w-full mb-6'>
                      <label className='font-semibold'>{NEW_PASSWORD}</label>
                      <div className='relative mt-2'>
                        <input
                          className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                          type={showPwdTwo ? 'text' : 'password'}
                          value={user.newPassword}
                          onChange={onChangeText}
                          name='newPassword'
                        />

                        <button
                          onClick={() => setShowPwdTwo(!showPwdTwo)}
                          type='submit'
                          className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                        >
                          {showPwdTwo ? (
                            <i className='fas fa-eye-slash' />
                          ) : (
                            <i className='fas fa-eye' />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className='w-full mb-6'>
                      <label className='font-semibold'>{CONFIRM_PASSWORD}</label>
                      <div className='relative mt-2'>
                        <input
                          className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                          type={showPwdThree ? 'text' : 'password'}
                          value={user.confirmPassword}
                          onChange={onChangeText}
                          name='confirmPassword'
                        />

                        <button
                          onClick={() => setShowPwdThree(!showPwdThree)}
                          type='submit'
                          className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                        >
                          {showPwdThree ? (
                            <i className='fas fa-eye-slash' />
                          ) : (
                            <i className='fas fa-eye' />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className='w-full flex mt-6 mb-8 justify-start'>
                      <button className='btn-primary-200' onClick={submitFormHandle}>
                        {loading ? (
                          <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                        ) : (
                          ''
                        )}{' '}
                        {UPDATE_CHANGES}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecurutyInformation
