import { notification } from 'antd'
import adminServices from '../../services/administration'
import { reducerActions } from '../reducer'
import queryString from 'query-string'

const initialState = {
  paginatedSystemLogs: [],
  pagination: {
    current_page: 1,
    total_items: 0,
    total_pages: 0
  },
  isServerError: false
}

export const logs = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async getSystemLogs(payload) {
      const query = queryString.stringify({
        page: payload?.page,
        search: payload?.search,
        startDate: payload?.startDate ? payload?.startDate : undefined,
        endDate: payload?.endDate ? payload?.endDate : undefined,
        showAll: payload?.showAll ? payload?.showAll : undefined
      })

      dispatch.logs.setError(false)
      try {
        const { data } = await adminServices.getSystemLogs(query, payload?.per_page)
        const pagination = {
          current_page: data.data.current_page,
          total_items: data.data.total_items,
          total_pages: data.data.total_pages
        }

        dispatch.logs.setState({
          paginatedSystemLogs: data.data.logs,
          pagination: pagination
        })
      } catch ({ response, message }) {
        dispatch.logs.setError(response)
        notification.error({
          message: 'An error occured',
          description: response?.data?.message || message
        })
      }
    },
    async reset() {
      await Promise.all([dispatch.logs.setState(initialState)])
    }
  })
}
