/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Dropdown, message, notification } from 'antd'
import { Icon } from '@iconify/react'
import EmailIcon from '../../../../images/email_circular_icon.png'
import { adminServices } from '../../../../services'

const bodyStyle = {
  padding: 0
}

const BvnCheckStatusModal = ({ visible, onCancel, handleCheckBvn, propData }) => {
  const [loading, setLoading] = useState(false)
  const [statusLoading, setStatusLoading] = useState(false)

  const handleCopyLink = () => {
    // Add logic to copy link to clipboard
    navigator.clipboard.writeText(propData?.link || '')
    message.success('Link copied to clipboard')
  }

  const handleSendLink = async () => {
    // Add logic to send link via email
    setLoading(true)
    const data = {
      user_id: propData?.userId,
      link: propData?.link
    }
    await adminServices
      .sendBvnConsentLink(data)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Consent link sent successfully'
        })
      })
      .catch(error => {
        notification.error({
          message: 'Something went wrong',
          description: error?.response?.data?.message || error?.message
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCheckStatus = async () => {
    setStatusLoading(true)
    await handleCheckBvn().finally(() => {
      setStatusLoading(false)
    })
  }

  const items = [
    {
      key: 'copy',
      label: 'Copy link',
      onClick: handleCopyLink
    },
    {
      key: 'send',
      label: 'Send link via email',
      onClick: handleSendLink
    }
  ]

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className='info-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          color='#b1b1ff'
          className='mt-4 mr-8 text-white text-4xl rounded-full bg-white'
        />
      }
      bodyStyle={bodyStyle}
      width={600}
    >
      <div className='modal-header bg-primary px-6 flex justify-between pb-4'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>Send Link</h2>
      </div>
      <div className='px-4 pb-8 mt-8'>
        <div className='flex flex-col items-center gap-8 justify-center px-8'>
          <div>
            <img alt='email icon' src={EmailIcon} className='w-24 h-24' />
          </div>

          <div className='text-center'>
            Copy or Send consent link to customer. Click on check status to get message
          </div>

          <div className='flex gap-4'>
            <Dropdown menu={{ items }} placement='bottom'>
              <Button type='primary' loading={loading}>
                Copy or send link <Icon icon='mingcute:down-line' className='ml-2' />
              </Button>
            </Dropdown>
            <Button onClick={handleCheckStatus} loading={statusLoading}>
              Check status
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

BvnCheckStatusModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleCheckBvn: PropTypes.func.isRequired,
  propData: PropTypes.object.isRequired
}

export default BvnCheckStatusModal
