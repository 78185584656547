/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { dispatch } from '../../redux/store'
import RefreshToken from './RefreshToken'
import { checkIsAdminRoute } from '../../utils/helpers'
import AdminRoute from './AdminRoutes'
import CustomerRoutes from './CustomerRoutes'
// import newBlog from '../../pages/blog/Blog'
// import { Notification } from '../dashboardLayout/Notification'

const FeatureFlagedRoutes = ({ component: Component, ...rest }) => {
  const { shouldShowNewBlog, isNotificationEnabled } = useSelector(
    ({ featureToggle, loading }) => ({
      shouldShowNewBlog: featureToggle.features?.enableNewBlog,
      isNotificationEnabled: featureToggle.features?.isNotificationEnabled
    })
  )

  // Add more feature flaged open routes here
  const openRoutes = {
    '/blog': shouldShowNewBlog
  }

  // Add more feature flaged admin routes here
  const adminRoutes = {
    '/notifications': isNotificationEnabled
  }

  // Add more feature flaged customer routes here
  const customerRoutes = {
    '/customer-notifications': isNotificationEnabled
  }
  const isOpenRoute = openRoutes[rest.path]
  const isAdminRoutes = adminRoutes[rest.path]
  const isCustomerRoutes = customerRoutes[rest.path]

  return (
    <Route
      {...rest}
      render={props => {
        if (openRoutes[rest.path] === false) {
          return props.history.push('/')
        }

        if (adminRoutes[rest.path] === false) {
          return props.history.push('/admin-dashboard')
        }

        if (customerRoutes[rest.path] === false) {
          return props.history.push('/dashboard')
        }

        switch (true) {
          case isOpenRoute:
            return <Component {...props} {...rest} />
          case isAdminRoutes:
            return <AdminRoute component={Component} {...props} {...rest} />
          case isCustomerRoutes:
            return <CustomerRoutes component={Component} {...props} {...rest} />

          default:
            props.history.goBack()
            break
        }
      }}
    />
  )
}

FeatureFlagedRoutes.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  featureEnabled: PropTypes.bool
}

export default FeatureFlagedRoutes
