import React, { useEffect } from 'react'

function ApplicationFormSection() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '143485837',
          formId: '226475db-f964-420d-acc3-d186efe06a08',
          target: '#hubspotForm'
        })
      }
    })
  }, [])
  return (
    <div className='text-center'>
      <p className='font-bold text-3xl md:text-4xl text-center mb-16'>Application form</p>
      <div id='hubspotForm' />
    </div>
  )
}

export default ApplicationFormSection
