import React from 'react'
import { Table } from 'antd'

import { interestDetailsColumns } from '../constant'

const InterestDetails = () => {
  return <Table className='mt-5' columns={interestDetailsColumns} />
}

export default InterestDetails
