/* eslint-disable indent */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from 'react'
import { formatCurrency } from '../../../utils/helpers'

export const BACK = 'Back'
export const SELECT_RANGE = 'Select Range'
export const SUMMATION = 'Summation'
export const BUSINESS_NAME = 'Business Name'
export const LOAN_PRINCIPAL_AMOUNT = 'Loan Principal Amount'
export const LOAN_DURATION = 'Loan Duration'
export const DATE_DISBURSED = 'Date Disbursed'
export const INTEREST_PAID = 'Interest Paid'
export const REQUIRED_PAYMENT = 'Required Payment'
export const PAYMENT_STATUS = 'Payment Status'
export const DATE = 'Date'
export const STATUS = 'Paid on time'
export const PAYMENT_MONTH = 'Payment Month'

export const LOAN_STATUSES_COLOUR_AND_NAME = {
  'Over paid': 'bg-overPaid text-white',
  Defaulted: 'bg-defaulted text-white',
  Delayed: 'bg-delayed text-white',
  'Not paid': 'bg-notPaid text-white',
  Pending: 'bg-green-500 text-white',
  'Paid on time': 'bg-paidOnTime text-white',
  'Under paid': 'bg-underPaid text-white',
  'Late Payment': 'bg-latePayment text-white',
  'Partial Defaulted': 'bg-partialDefaulted text-white'
}

export const months = [
  {
    text: 'January',
    value: 'January'
  },
  {
    text: 'February',
    value: 'February'
  },
  {
    text: 'March',
    value: 'March'
  },
  {
    text: 'April',
    value: 'April'
  },
  {
    text: 'May',
    value: 'May'
  },
  {
    text: 'June',
    value: 'June'
  },
  {
    text: 'July',
    value: 'July'
  },
  {
    text: 'August',
    value: 'August'
  },
  {
    text: 'September',
    value: 'September'
  },
  {
    text: 'October',
    value: 'October'
  },
  {
    text: 'November',
    value: 'November'
  },
  {
    text: 'December',
    value: 'December'
  }
]
export const interestTrackertColums = () => [
  {
    title: <span className='font-bold'>{BUSINESS_NAME}</span>,
    dataIndex: 'business_name',
    key: 'business_name',
    sorter: (a, b) => {
      const nameA = a.business_name.toUpperCase()
      const nameB = b.business_name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    },
    render: business_name => <span>{business_name}</span>
  },
  {
    title: <span className='font-bold'>{LOAN_PRINCIPAL_AMOUNT}</span>,
    dataIndex: 'loan_principal_amount',
    key: 'loan_principal_amount',
    render: loan_principal_amount => <span>₦{formatCurrency(loan_principal_amount)}</span>
  },
  {
    title: <span className='font-bold'>{PAYMENT_MONTH}</span>,
    dataIndex: 'payment_month',
    key: 'payment_month',
    filters: months,
    onFilter: (value, record) => record.payment_month === value,
    render: payment_month => <span>{payment_month}</span>
  },
  {
    title: <span className='font-bold'>{DATE_DISBURSED}</span>,
    dataIndex: 'disburse_date',
    key: 'disburse_date',
    render: disburse_date => <span>{disburse_date.substring(0, 10)}</span>
  },
  {
    title: <span className='font-bold'>{INTEREST_PAID}</span>,
    dataIndex: 'interest_paid',
    key: 'interest_paid',
    render: interest_paid => (
      <p className={interest_paid > 0 ? 'bg-green-900 p-3 text-white' : ''}>
        ₦{formatCurrency(interest_paid)}
        <sub className='block'>{interest_paid > 0 && 'PAID'}</sub>
      </p>
    )
  },
  {
    title: <span className='font-bold'>{REQUIRED_PAYMENT}</span>,
    dataIndex: 'required_payment',
    key: 'required_payment',
    render: required_payment => <span>₦{formatCurrency(required_payment)}</span>
  },
  {
    title: <span className='font-bold'>{PAYMENT_STATUS}</span>,
    dataIndex: 'payment_status',
    key: 'payment_status',
    render: payment_status => (
      <span
        className={`px-4 py-1 rounded-md text-white text-xs ${
          LOAN_STATUSES_COLOUR_AND_NAME[payment_status]
            ? LOAN_STATUSES_COLOUR_AND_NAME[payment_status]
            : 'bg-red-700'
        }`}
      >
        {payment_status}
      </span>
    )
  },
  {
    title: <span className='font-bold'>{DATE}</span>,
    dataIndex: 'payment_date_manual',
    key: 'payment_date_manual',
    render: payment_date_manual => (
      <span title='The date admin set to be interest payment date'>
        {payment_date_manual?.substring(0, 10) || 'Not paid yet'}
      </span>
    )
  },
  {
    title: <span className='font-bold'>Action</span>,
    dataIndex: '',
    key: 'action',
    dataKey: 'action',
    render: () => <i className='far fa-edit mr-2' />
  }
]
