export const data = [
  {
    id: 1,
    title: 'NEW APPLICATION',
    number: 10,
    bg: 'bg-indigo-600',
    bg2: 'bg-indigo-700',
    link: '/dashboard'
  },
  {
    id: 2,
    title: 'ALL APPLICATIONS',
    number: 180,
    bg: 'bg-red-600',
    bg2: 'bg-red-700',
    link: '/dashboard'
  },
  {
    id: 3,
    title: 'APPROVED',
    number: 18,
    bg: 'bg-blue-600',
    bg2: 'bg-blue-700',
    link: '/dashboard'
  },
  {
    id: 4,
    title: 'PENDING',
    number: 0,
    bg: 'bg-green-600',
    bg2: 'bg-green-700',
    link: '/dashboard'
  }
]

export const superData = [
  {
    id: 1,
    title: 'TOTAL APPLICATION',
    number: 22,
    bg: 'bg-red-600',
    bg2: 'bg-red-700',
    link: '/dashboard'
  },
  {
    id: 2,
    title: 'APPROVED',
    number: 180,
    bg: 'bg-blue-600',
    bg2: 'bg-blue-700',
    link: '/dashboard'
  },
  {
    id: 3,
    title: 'PENDING',
    number: 18,
    bg: 'bg-green-600',
    bg2: 'bg-green-700',
    link: '/dashboard'
  },
  {
    id: 4,
    title: 'DISBURSTED LOANS',
    number: 0,
    bg: 'bg-purple-600',
    bg2: 'bg-purple-700',
    link: '/dashboard'
  }
]
