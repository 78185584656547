import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Steps, notification } from 'antd'
import { Icon } from '@iconify/react'
import AccountCreation from '../vfd/steps/AccountCreation'
import KycVerification from '../vfd/steps/KycVerification'
import Confirmation from '../vfd/steps/Confirmation'
import { adminServices } from '../../../../../services'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}

const { Step } = Steps

const CreateCorperateAccount = ({
  visible,
  onCancel,
  title,
  description,
  customeBizInfo,
  setRefreshKey
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const params = useParams()

  const steps = [
    {
      title: 'Account Creation',
      content: <AccountCreation customeBizInfo={customeBizInfo} />
    },
    {
      title: 'KYC Verification',
      content: <KycVerification customeBizInfo={customeBizInfo} />
    },
    {
      title: 'Confirmation',
      content: <Confirmation customeBizInfo={customeBizInfo} />
    }
  ]

  const createVfdAccount = async () => {
    setLoading(true)
    try {
      const { data } = await adminServices.createCustomerVfdAccount({
        user_id: params?.id,
        outsource_type: 'VFD'
      })

      if (data.success) {
        if (currentStep < steps.length - 1) {
          setCurrentStep(currentStep + 1)
        }
      } else {
        notification.error({
          message: 'An error occured',
          description: data?.message || 'Something went wrong!'
        })
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
      notification.error({
        message: 'An error occured',
        description: 'Sorry, we could not create this customer account at this time'
      })
    }
  }

  const handleContinue = async () => {
    const createVfdAccountStage = 0
    const getCustomerConsentStage = 1

    if (currentStep === createVfdAccountStage) {
      await createVfdAccount()
    }

    if (currentStep === getCustomerConsentStage) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleCancel = () => {
    setCurrentStep(0) // Reset to the first step when the modal is canceled
    onCancel()

    // Get fresh data for user accounbts view
    setRefreshKey(Math.random())
  }

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      centered
      className='info-modal'
      closeIcon={<Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />}
      bodyStyle={bodyStyle}
      width={800}
    >
      <div className='modal-header bg-primary px-6 py-2 flex justify-between'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>{title}</h2>
      </div>
      <div className='py-8 px-8'>
        <p className='text-black text-base text-center font-bold'>{description}</p>
        <Steps current={currentStep} size='big' className='mt-4 text-black text-base font-medium '>
          {steps.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>
        <div className=' mt-4'>{steps[currentStep].content}</div>
        {currentStep !== 2 && (
          <button
            className='bg-primary flex mx-auto text-base mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
            onClick={handleContinue}
          >
            {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
            {currentStep === steps.length - 1 ? 'Done' : 'Continue'}
          </button>
        )}
      </div>
    </Modal>
  )
}

CreateCorperateAccount.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setRefreshKey: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  customeBizInfo: PropTypes.object.isRequired
}

export default CreateCorperateAccount
