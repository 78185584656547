export const cleanType = type => {
  let cleanedType = ''

  switch (type) {
    case 'moa':
      cleanedType = 'Memorandum of Association'
      break
    case 'aoa':
      cleanedType = 'Articles of Association'
      break
    case 'bank_statement':
      cleanedType = 'Bank Statement'
      break
    case 'pharmacist_license':
      cleanedType = 'Pharmacist License'
      break
    case 'business_licenses':
      cleanedType = 'Business License'
      break
    case 'cac_2':
      cleanedType = 'CAC 2'
      break
    case 'cac_3':
      cleanedType = 'CAC 3'
      break
    case 'cac_7':
      cleanedType = 'CAC 7'
      break
  }
  return cleanedType
}
