import React, { useEffect, useState } from 'react'
import { Table, notification, Tooltip } from 'antd'
import 'antd/dist/antd.css'
import PropTypes from 'prop-types'
import { BACK, SAVE_AND_NEXT, getColums } from './constant'
import { useSelector } from 'react-redux'
import AddDirector from './AddDirector'

const StepThree = props => {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showSizeChanger: true,
    current: 1
  })
  const [initialSelectedDirs, setInitialSelectedDirs] = useState([])
  const [openModal, setOpenModal] = useState(false)

  // Initialised the store for selected directors
  let selectedDirs = initialSelectedDirs

  // Handle Table selection
  let newSelectedDirs = []
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      newSelectedDirs = [...selectedRows.map(dir => dir.id)]
    },
    renderCell(checked, record, index, node) {
      if (selectedDirs.indexOf(record.id) > -1) {
        return (
          <Tooltip title='You had previously selected this director. Click "Clear Selections" to remove previously selected Directors. You can add more directors or click "Next" to keep these previously selected ones'>
            {node}
          </Tooltip>
        )
      }
      return node
    },
    getCheckboxProps: record => {
      return {
        disabled: selectedDirs.indexOf(record.id) > -1,
        id: record.id
      }
    }
  }
  // Clear Table selections
  const clearSelectionsHandler = () => {
    const buzInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))
    const applyForLoan = {
      ...buzInfo,
      directors: []
    }
    window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
    setInitialSelectedDirs([])
    selectedDirs = []
  }

  const handleAddDirector = () => {
    setOpenModal(true)
  }

  useEffect(() => {
    if (window.localStorage.getItem('apply-for-loan')) {
      JSON.parse(window.localStorage.getItem('apply-for-loan')).directors &&
        setInitialSelectedDirs(JSON.parse(window.localStorage.getItem('apply-for-loan')).directors)
    } else {
      props.nextKey(1)
    }
  }, [props])

  const { directors, isAdmin } = useSelector(({ auth: { authUser } }) => ({
    directors: authUser.userInfo?.data.directors,
    isAdmin: authUser.userInfo?.is_admin
  }))

  const { directors: directorsDetails } = isAdmin
    ? useSelector(({ users }) => users?.exitingUsers.find(user => props.user?.id === user.id))
    : { directorsDetails: [] }

  // Below adds a key value pair to the directors data to assist tables map the data uniquely

  let i = 0
  for (var o in directors) {
    i++
    if (hasOwnProperty.call(directors, o)) {
      directors[o].key = i
    }
  }

  const handleSubmit = async () => {
    selectedDirs = [...selectedDirs, ...newSelectedDirs]
    if (selectedDirs.length > 1) {
      if (window.localStorage.getItem('apply-for-loan')) {
        const buzInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))

        const cleaned = Array.from(new Set(selectedDirs))

        const applyForLoan = {
          ...buzInfo,
          directors: cleaned
        }
        window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
        props.nextKey(999)
      } else {
        props.nextKey(1)
      }
    } else {
      notification.error({ message: 'You must select at least 2 Directors' })
    }
  }

  const handleBack = () => {
    if (window.localStorage.getItem('apply-for-loan')) {
      const buzInfo = JSON.parse(window.localStorage.getItem('apply-for-loan'))
      const applyForLoan = {
        ...buzInfo,
        directors: []
      }
      window.localStorage.setItem('apply-for-loan', JSON.stringify(applyForLoan))
      props.nextKey(2)
    } else {
      props.nextKey(1)
    }
  }
  const tableDataSource = isAdmin ? directorsDetails : directors
  return (
    <div className='w-full mx-auto md:px-6 rounded-xl bg-theme1-400'>
      <p className='text-center'>
        Please only select the directors you want to include in this loan application
      </p>
      <p className='text-center text-xs'>
        Use the checkbox to select and unselect your desired directors
      </p>
      <div className='overflow-auto p-4 mx-auto border-2 border-gray-400 rounded-lg'>
        <AddDirector user={props?.user} openModal={openModal} setOpenModal={setOpenModal} />
        <Table
          rowSelection={rowSelection}
          columns={getColums}
          onChange={value => setPagination(value)}
          pagination={pagination}
          dataSource={tableDataSource?.map(director => ({ ...director, key: director.id }))}
        />
        <button
          onClick={() => clearSelectionsHandler()}
          className='px-4 py-2 transition-all duration-300 bg-gray-300 active:bg-gray-400 rounded-lg font-bold mb-5 mr-5'
        >
          {' '}
          Clear Selections
        </button>
        <button
          onClick={() => handleAddDirector()}
          className='px-4 py-2 transition-all duration-300 bg-gray-300 active:bg-gray-400 rounded-lg font-bold mb-5 mr-5'
        >
          Add New Directors
        </button>
      </div>

      <div className='w-full flex mt-6 mb-8 justify-start md:ml-4'>
        <button className='btn-primary mr-5' onClick={handleBack}>
          <i className='fas fa-arrow-alt-circle-left' /> {BACK}
        </button>
        <button className='btn-primary' onClick={handleSubmit}>
          {SAVE_AND_NEXT}
        </button>
      </div>
    </div>
  )
}

StepThree.propTypes = {
  nextKey: PropTypes.func,
  user: PropTypes.shape({
    phone_number: PropTypes.string,
    email: PropTypes.string,
    directors: PropTypes.array,
    id: PropTypes.string
  })
}

export default StepThree
