/* eslint-disable camelcase */
/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes, { number } from 'prop-types'
import { Table } from 'antd'
import { calculateRateInPercent, formatCurrency } from '../../utils/helpers'
import PaidCard from '../../sharedComponents/simpleComponents/PaidCard'

const getColumns = [
  {
    title: <span className='font-bold'>Payment ID</span>,
    dataIndex: 'payment_id',
    key: 'payment_id',
    render: payment_id => <span>{payment_id}</span>
  },
  {
    title: <span className='font-bold'>Month Paid</span>,
    dataIndex: 'payment_month',
    key: 'payment_month',
    render: payment_month => <span>{payment_month}</span>
  },
  {
    title: <span className='font-bold'>Type</span>,
    dataIndex: 'payment_type',
    key: 'payment_type',
    render: payment_type => <span>{payment_type}</span>
  },
  {
    title: <span className='font-bold'>Required Amount</span>,
    dataIndex: 'required_payment',
    key: 'required_payment',
    render: required_payment => <span>₦{formatCurrency(required_payment)}</span>
  },
  {
    title: <span className='font-bold'>Amount Paid</span>,
    dataIndex: 'amount_paid',
    key: 'amount_paid',
    render: amount_paid => <span>₦{formatCurrency(amount_paid)}</span>
  },
  {
    title: <span className='font-bold'>Payment Date</span>,
    dataIndex: 'payment_date',
    key: 'payment_date',
    render: payment_date => <span>{payment_date}</span>
  },
  {
    title: <span className='font-bold'>Recorded By</span>,
    dataIndex: 'admin_who_recorded_transaction',
    key: 'admin_who_recorded_transaction',
    render: admin_who_recorded_transaction => <span>{admin_who_recorded_transaction}</span>
  }
]

const Transactions = ({ visible, handleClose, data, repayment, historyLoading }) => {
  const [pagination, setPagination] = useState({
    pageSize: 4,
    current: 1
  })

  return visible ? (
    <div className='fixed z-50 bg-black bg-opacity-50 inset-0 flex flex-col justify-center'>
      <div
        style={{ maxWidth: '950px' }}
        className={`${
          visible && 'scale-100'
        } duration-200 delay-100 bg-white w-full transform mx-auto relative rounded-2xl p-10`}
      >
        <button
          onClick={handleClose}
          className='absolute -top-4 grid place-content-center outline-none focus:outline-none cursor-pointer -right-2 bg-white w-8 h-8 rounded-full'
        >
          <i className='fa fa-times' />
        </button>
        <div className='flex flex-wrap'>
          <div
            style={{ background: '#FAFAFA' }}
            className='bg-gray-100 w-full flex flex-wrap px-5 py-2'
          >
            <div className='w-1/2 md:w-1/3 lg:w-1/6'>
              <h4 className='text-dark1  text-base font-manrope'>Loan ID</h4>
              <h6 className='text-sm font-manrope text-gray-400'>{data.loan_app_id}</h6>
            </div>

            <div className='w-1/2 md:w-1/3 lg:w-1/6'>
              <h4 className='text-dark1  text-base font-manrope'>Collected</h4>
              <h6 className='text-sm font-manrope text-gray-400'>
                {data.loan_principal_amount_fixed}
              </h6>
            </div>

            <div className='w-1/2 md:w-1/3 lg:w-1/6'>
              <h4 className='text-dark1  text-base font-manrope'>Paid Back</h4>
              <h6 className='text-sm font-manrope text-gray-400'>
                ₦{formatCurrency(data.principal_amount_paid)}
              </h6>
            </div>

            <div className='w-1/2 md:w-1/3 lg:w-1/6'>
              <h4 className='text-dark1  text-base font-manrope'>Duration</h4>
              <h6 className='text-sm font-manrope text-gray-400'>{data.loan_duration}</h6>
            </div>

            <div className='w-1/2 md:w-1/3 lg:w-1/6'>
              <h4 className='text-dark1  text-base font-manrope'>Starting</h4>
              <h6 className='text-sm font-manrope text-gray-400'>
                {new Date(data.start_date).toLocaleDateString()}
              </h6>
            </div>

            <div className='w-1/2 md:w-1/3 lg:w-1/6'>
              <h4 className='text-dark1  text-base font-manrope'>Due</h4>
              <h6 className='text-sm font-manrope text-gray-400'>
                {new Date(data.next_interest_date).toLocaleDateString()}
              </h6>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap py-5'>
          <h3 className='w-full text-base font-manrope font-semibold'>Management Fees </h3>
          <div className='w-full md:w-4/5 lg:w-4/6 flex flex-wrap mt-5'>
            <div className='w-full md:w-1/2 flex'>
              <h5 className='font-manrope text-sm mr-5'>Management Fees</h5>
              <h5 className='font-manrope text-sm mr-5'>
                ₦{calculateRateInPercent(0.5, data.loan_principal_amount_fixed)}
              </h5>
            </div>
            <div className='w-full flex md:w-1/2'>
              <div className='font-manrope text-sm mr-5'>
                <PaidCard paid={data.management_fee?.upfront} />
              </div>
              <h5 className='font-manrope text-sm'>0.5% of loan amount</h5>
            </div>
          </div>
          <div className='w-full md:w-4/5 lg:w-4/6 flex flex-wrap mt-5'>
            <div className='w-full md:w-1/2 flex'>
              <h5 className='font-manrope text-sm mr-5'>Maintenance Fees</h5>
              <h5 className='font-manrope text-sm mr-5'>
                ₦{calculateRateInPercent(0.5, data.loan_principal_amount_fixed)}
              </h5>
            </div>
            <div className='w-full flex md:w-1/2'>
              <div className='font-manrope text-sm mr-5'>
                <PaidCard paid={data.management_fee?.maintenance} />
              </div>
              <h5 className='font-manrope text-sm'>0.5% of loan amount</h5>
            </div>
          </div>
        </div>
        <div className='overflow-auto'>
          <Table
            columns={getColumns}
            pagination={pagination}
            onChange={value => setPagination(value)}
            dataSource={repayment}
            loading={historyLoading}
          />
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

Transactions.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.shape({
    loanID: PropTypes.string,
    loanCollected: PropTypes.string,
    amountPaidBack: PropTypes.string,
    loanDuration: PropTypes.string,
    startingDate: PropTypes.string,
    loan_app_id: PropTypes.number,
    start_date: PropTypes.string,
    loan_duration: PropTypes.number,
    principal_amount_paid: PropTypes.string,
    next_interest_date: PropTypes.string,
    loan_principal_amount_fixed: PropTypes.string,
    management_fee: PropTypes.shape({
      maintenance: number,
      upfront: number
    }),
    dueDate: PropTypes.string
  }),
  repayment: PropTypes.shape({
    amount_paid: PropTypes.string,
    payment_id: PropTypes.number,
    payment_month: PropTypes.string,
    payment_type: PropTypes.string,
    required_payment: PropTypes.string,
    payment_date: PropTypes.string,
    admin_who_recorded_transaction: PropTypes.string
  }),
  historyLoading: PropTypes.func
}

export default Transactions
