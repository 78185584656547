/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { InputField } from '../../sharedComponents/input-field'
import { Progress, Upload, message } from 'antd'
import { directorValidator } from '../../utils/inputValidator'
import { SUPPORTED_IMAGE_TYPE } from '../../utils/data'
import PropTypes from 'prop-types'
import {
  BIRTHDATE,
  EMAIL,
  LAST_NAME,
  FIRST_NAME,
  PHONE_NO,
  TITLE_AND_ROLE,
  BANK_VERIFICATION_NO,
  YEARS_OF_EXPERIENCE,
  GENDER,
  MARITAL_STATUS,
  HIGHEST_EDUCATION,
  GOVERNMENT_ID,
  BROWSE_FILE,
  PASSPORT_PICS,
  HOME_ADDRESS
} from '../../sharedComponents/entries/constant'
import { useSelector } from 'react-redux'
import { UPLOAD_BASE_URL } from '../../utils/constants'
import { ADD_DIRECTOR } from './constants'
import { dispatch } from '../../redux/store'
import { NO_OF_KIDS } from '../applications/constant'

const AddDirectorForm = ({ user, distributorRecords }) => {
  const [progroess, setProgress] = useState(0)

  const [error, setError] = useState({})
  const directorLoading = useSelector(
    ({ loading }) => loading.effects.directors.customerAddDirector
  )
  const { token, permissions } = useSelector(({ auth: authUser }) => authUser.authUser)

  const { data, isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin,
    data: authUser.userInfo?.data
  }))

  const [input, setInput] = useState({
    first_name: '',
    last_name: '',
    title: '',
    email: isAdmin ? user?.email : data.email,
    phone: isAdmin ? user?.phone_number : data.phone_number,
    birth_date: '',
    bank_veri_no: '',
    address: '',
    gender: '',
    years_of_experience: '',
    marital_status: '',
    education_level: '',
    photo: '',
    num_kids: '',
    government_id: ''
  })

  const uploadProps = {
    action: `${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        return message.error(`${file.name} is not supported`)
      }
      if (file.size / 1000000 > 35) {
        return message.error('This file size is more than the required 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const handleChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        const ImgUrl = info.file.response.data.url
        if (type === 'photo') {
          setInput({ ...input, photo: ImgUrl })
        } else if (type === 'government_id') {
          setInput({ ...input, government_id: ImgUrl })
        }
      }
    }
  }

  const onChangeText = ({ target }) => {
    let value = target.value
    switch (target.name) {
      case 'email':
        value = target.value.toLowerCase().trim()
        break
    }
    setInput(user => ({
      ...user,
      [target.name]: value
    }))
    setError({ ...error, [target.name]: false })
  }

  const validator = directorValidator(input)

  useEffect(() => {
    if (Object.keys(input).length) {
      if (validator.valid) setProgress(100)
    }
  }, [input])

  const handleSubmit = async () => {
    let details, endpoint
    if (isAdmin && distributorRecords) {
      endpoint = 'admin/distributor/directors'
    } else if (isAdmin) {
      endpoint = 'admin/add-directors'
    } else {
      endpoint = 'customer/create-director'
    }
    if (validator.valid) {
      if (isAdmin && distributorRecords) {
        details = [{ ...input, distributor_id: distributorRecords?.key }]
      } else if (isAdmin) {
        details = [{ ...input, user_id: user?.id }]
      } else {
        details = [input]
      }
      await dispatch.directors.customerAddDirector({
        token,
        permissions,
        details,
        endpoint
      })
      setInput({
        first_name: '',
        last_name: '',
        title: '',
        email: isAdmin ? user?.email : data.email,
        phone: isAdmin ? user?.phone_number : data.phone_number,
        birth_date: '',
        bank_veri_no: '',
        address: '',
        gender: '',
        years_of_experience: '',
        marital_status: '',
        education_level: '',
        photo: '',
        num_kids: '',
        government_id: ''
      })
      setProgress(0)
    } else {
      setError({ ...validator.error })
    }
  }

  return (
    <div className='w-full'>
      <div className='bg-pastel px-3 md:px-5 rounded-lg py-5 text-lg font-semibold text-gray-700 w-full'>
        Add New Director
      </div>
      <div className='mx-auto md:px-6 rounded-xl bg-theme1-400'>
        <div className='flex flex-wrap lg:mx-2 md:mx-auto'>
          <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
            <InputField
              onChange={onChangeText}
              value={input.first_name}
              type='text'
              name='first_name'
              maxLength='35'
              label={FIRST_NAME + ' *'}
              placeholder={FIRST_NAME}
              className='form-control'
            />
            {error.first_name ? <small className='text-red-500'>{error.first_name}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-4'>
            <InputField
              onChange={onChangeText}
              value={input.last_name}
              type='text'
              name='last_name'
              maxLength='35'
              label={LAST_NAME + ' *'}
              placeholder={LAST_NAME}
              className='form-control'
            />
            {error.last_name ? <small className='text-red-500'>{error.last_name}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-4'>
            <label className='text-gray-500 leading-8'>{TITLE_AND_ROLE + ' *'}</label>

            <select
              onChange={onChangeText}
              value={input.title}
              type='text'
              name='title'
              maxLength='35'
              placeholder={TITLE_AND_ROLE}
              className='form-control'
            >
              {[
                'Choose Title/role',
                'Mr',
                'Mrs',
                'Chief',
                'Engr',
                'Dr',
                'Prof',
                'Master',
                'Miss'
              ].map((state, id) => {
                return (
                  <option key={id} value={state === 'Title/role' ? '' : state}>
                    {state}
                  </option>
                )
              })}
            </select>
            {error.title ? <small className='text-red-500'>{error.title}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
            <InputField
              onChange={onChangeText}
              value={input.email}
              type='text'
              name='email'
              maxLength='45'
              label={EMAIL + ' *'}
              placeholder={EMAIL}
              className='form-control'
            />
            {error.email ? <small className='text-red-500'>{error.email}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3  px-1 md:px-4 mt-8'>
            <InputField
              onChange={onChangeText}
              value={input.phone}
              type='text'
              name='phone'
              maxLength='15'
              label={PHONE_NO + ' *'}
              placeholder={PHONE_NO}
              className='form-control'
            />
            {error.phone ? <small className='text-red-500'>{error.phone}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
            <InputField
              onChange={onChangeText}
              value={input.birth_date}
              type='date'
              label={BIRTHDATE + ' *'}
              name='birth_date'
              max={new Date().toISOString().slice(0, 10)}
              placeholder={BIRTHDATE}
              className='form-control'
            />
            {error.birth_date ? <small className='text-red-500'>{error.birth_date}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
            <InputField
              onChange={onChangeText}
              value={input.bank_veri_no}
              type='text'
              name='bank_veri_no'
              maxLength='11'
              label={BANK_VERIFICATION_NO + ' *'}
              placeholder={BANK_VERIFICATION_NO}
              className='form-control'
            />
            {error.bank_veri_no ? <small className='text-red-500'>{error.bank_veri_no}</small> : ''}
          </div>

          <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-8'>
            <InputField
              onChange={e => {
                if (isNaN(Number(e.target.value))) return false
                onChangeText(e)
              }}
              value={input.years_of_experience}
              type='text'
              name='years_of_experience'
              maxLength='2'
              label={YEARS_OF_EXPERIENCE + ' *'}
              placeholder={YEARS_OF_EXPERIENCE}
              className='form-control'
            />
            {error.years_of_experience ? (
              <small className='text-red-500'>{error.years_of_experience}</small>
            ) : (
              ''
            )}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
            <label className='text-gray-500 leading-8'>{GENDER + ' *'}</label>
            <select
              onChange={onChangeText}
              value={input.gender}
              type='text'
              name='gender'
              className='form-control'
            >
              {['Select gender', 'Male', 'Female'].map((state, id) => {
                return (
                  <option key={id} value={state === 'Select gender' ? '' : state}>
                    {state}
                  </option>
                )
              })}
            </select>
            {error.gender ? <small className='text-red-500'>{error.gender}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
            <label className='text-gray-500 leading-8'>{MARITAL_STATUS + ' *'}</label>

            <select
              onChange={onChangeText}
              value={input.marital_status ? input.marital_status : data.marital_status}
              type='text'
              name='marital_status'
              className='form-control'
            >
              {['Marital Status', 'Single', 'Married', 'Others'].map((state, id) => {
                return (
                  <option key={id} value={state === 'Marital Status' ? '' : state}>
                    {state}
                  </option>
                )
              })}
            </select>
            {error.marital_status ? (
              <small className='text-red-500'>{error.marital_status}</small>
            ) : (
              ''
            )}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-8'>
            <InputField
              onChange={e => {
                if (isNaN(Number(e.target.value))) return false
                onChangeText(e)
              }}
              value={input.num_kids}
              type='text'
              name='num_kids'
              maxLength='2'
              label={NO_OF_KIDS + ' *'}
              placeholder={NO_OF_KIDS}
              className='form-control'
            />
            {error.num_kids ? <small className='text-red-500'>{error.num_kids}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
            <label className='ml-2 text-gray-500 leading-8'>{HIGHEST_EDUCATION + ' *'}</label>
            <select
              onChange={onChangeText}
              value={input.education_level ? input.education_level : data.education_level}
              type='text'
              name='education_level'
              className='form-control'
            >
              {['Select one', 'None', 'Higer Education', 'Bachelor', 'Masters', 'Ph.D'].map(
                (state, id) => {
                  return (
                    <option key={id} value={state === 'Select one' ? '' : state}>
                      {state}
                    </option>
                  )
                }
              )}
            </select>
            {error.education_level ? (
              <small className='text-red-500'>{error.education_level}</small>
            ) : (
              ''
            )}
          </div>
          <div className='w-full md:w-1/3 mt-8'>
            <label className='text-gray-500 leading-8'>{HOME_ADDRESS + ' *'}</label>
            <textarea
              onChange={onChangeText}
              value={input.address}
              name='address'
              rows='10'
              placeholder={HOME_ADDRESS}
              className='resize-none border rounded-md w-full appearance-none bg-transparent border-b-2 mr-3 py-2 px-2 leading-tight  mt-1 focus:outline-none  border-gray-400'
            />
            {error.address ? <small className='text-red-500'>{error.address}</small> : ''}
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 px-1 md:px-4 mt-8'>
            <label className='text-gray-500 leading-8'>{PASSPORT_PICS + ' *'}</label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progroess <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{ wordWrap: 'break-word', maxWidth: '190px', minWidth: '180px' }}
                  >
                    <Upload {...uploadProps} onChange={info => handleChange(info, 'photo')}>
                      <p className='text-center font-normal pt-6'>Drag file here </p>
                      <p className='text-center my-6 font-normal'>Or </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        Browse File
                      </p>
                    </Upload>
                  </div>
                ) : (
                  <Progress type='circle' percent={progroess} />
                )}
                {error.photo ? <small className='text-red-500'>{error.photo}</small> : ''}
              </div>
            </div>
          </div>
          <div className='w-full sm:-1/2 md:w-1/3 mt-8'>
            <label className='text-gray-500 leading-8'>{GOVERNMENT_ID + ' *'}</label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progroess <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{ wordWrap: 'break-word', maxWidth: '190px', minWidth: '180px' }}
                  >
                    <Upload {...uploadProps} onChange={info => handleChange(info, 'government_id')}>
                      <p className='text-center font-normal pt-6'>Drag file here </p>
                      <p className='text-center my-6 font-normal'>Or </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        {BROWSE_FILE}
                      </p>
                    </Upload>
                  </div>
                ) : (
                  <Progress type='circle' percent={progroess} />
                )}
                {error.government_id ? (
                  <small className='text-red-500'>{error.government_id}</small>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='w-full flex mt-6 mb-8 justify-start md:ml-4'>
          <button className='btn-primary' onClick={handleSubmit}>
            {directorLoading && <i className='fa fa-spinner spin mr-3' />}
            {ADD_DIRECTOR}
          </button>
        </div>
      </div>
    </div>
  )
}

AddDirectorForm.propTypes = {
  user: PropTypes.shape({
    phone_number: PropTypes.string,
    id: PropTypes.string,
    email: PropTypes.string
  }),
  distributorRecords: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    mou: PropTypes.string,
    aoa: PropTypes.string,
    cac: PropTypes.string,
    directors: PropTypes.array
  })
}

export default AddDirectorForm
