/* eslint-disable */

export const emailReg = /\S+@\S+\.\S+/;
const name = /^[A-Z][a-z]/;
const pwdReg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;

// Validate register comapany page
const registerCompanyValidator = (input) => {
  const error = "";

  if (!input.first_name) {
    return { isValid: false, error: "First Name is required!" };
  }

  if (!input.last_name) {
    return { isValid: false, error: "Last Name is required!" };
  }

  if (!input.email) return { isValid: false, error: "Email is required!" };
  if (!emailReg.test(input.email)) {
    return { isValid: false, error: "Email is not valid!" };
  }
  if (input.email.length > 50) {
    return { isValid: false, error: "Email address must not be above 50!" };
  }
  if (!input.phone_number)
    return { isValid: false, error: "Phone number is required!" };
  if (input.phone_number.length < 11) {
    return { isValid: false, error: "Phone number must be above 11 digit!" };
  }
  return { error, isValid: true };
};

// Validate SignIn page
const signUpValidator = (input) => {
  const error = "";

  if (!input.first_name) {
    return { isValid: false, error: "First Name is required!" };
  }
  if (!name.test(input.first_name)) {
    return { isValid: false, error: "First Name is not valid" };
  }
  if (!input.last_name) {
    return { isValid: false, error: "Last Name is required!" };
  }
  if (!name.test(input.last_name)) {
    return { isValid: false, error: "Last Name is not valid!" };
  }
  if (!input.email) return { isValid: false, error: "Email is required!" };
  if (!emailReg.test(input.email)) {
    return { isValid: false, error: "Email is not valid!" };
  }
  if (input.email.length > 50) {
    return { isValid: false, error: "Email address must not be above 50!" };
  }
  if (!input.phone_number)
    return { isValid: false, error: "Phone number is required!" };
  if (input.phone_number.length < 11) {
    return { isValid: false, error: "Phone number must be above 11 digit!" };
  }
  if (!input.password)
    return { isValid: false, error: "Password is required!" };
  if (!pwdReg.test(input.password)) {
    return {
      isValid: false,
      error:
        "The password needs have at least one number, one lowercase and one uppercase letter!",
    };
  }

  return { error, isValid: true };
};
//
// Validate Login Page
const logInValidator = (input) => {
  const error = {};
  if (!input.email) return { isValid: false, error: "Email is required!" };
  if (!emailReg.test(input.email)) {
    return { isValid: false, error: "Email is not valid!" };
  }
  if (!input.password)
    return { isValid: false, error: "Password is required!" };
  const isValid = Object.keys(error).length === 0;
  return { error, isValid };
};
// Change Password Validator
const resetTempPwd = (input) => {
  const error = {};
  if (!input.email) return { isValid: false, error: "Email is required!" };
  if (!emailReg.test(input.email)) {
    return { isValid: false, error: "Email is not valid!" };
  }
  if (!input.new_password)
    return { isValid: false, error: "Password is required!" };
  const isValid = Object.keys(error).length === 0;
  return { error, isValid };
};
// Change New Password Validator
const resetPwdValidator = (input) => {
  const error = {};
  if (!input.email) return { isValid: false, error: "Email is required!" };
  if (!emailReg.test(input.email)) {
    return { isValid: false, error: "Email is not valid!" };
  }
  if (!input.token) return { isValid: false, error: "Token is required!" };
  if (!input.password)
    return { isValid: false, error: "Password is required!" };
  const isValid = Object.keys(error).length === 0;
  return { error, isValid };
};
// Change New Password for customer Validator
export const resetPasswordValidator = (newPwd) => {
  const error = {};
  if (!newPwd) return { isValid: false, error: "Password is required!" };
  const isValid = Object.keys(error).length === 0;
  return { error, isValid };
};
//
// Validate Login Page
const forgotPwdValidator = (email) => {
  const error = {};
  if (!email) return { isValid: false, error: "Email is required!" };
  if (!emailReg.test(email)) {
    return { isValid: false, error: "Email is not valid!" };
  }
  const isValid = Object.keys(error).length === 0;
  return { error, isValid };
};

export const stepOneValidator = (input) => {
  const error = {};
  if (!input.bizName) error.bizName = "Business name is required!";
  if (!emailReg.test(input.bizEmail)) error.bizEmail = "Email is not valid!";
  if (!input.bizEmail) error.bizEmail = "Email is required!";
  if (input.bizPhone?.length > 15)
    error.bizPhone = "Phone Number is greater than 15 digit!";
  if (input.bizPhone?.length < 10)
    error.bizPhone = "Phone Number is less than 10 digit!";
  if (!input.bizPhone) error.bizPhone = "Phone number is required!";
  if (!input.noOfEmployee)
    error.noOfEmployee = "Number of employee is required!";
  if (!input.incorporationDate)
    error.incorporationDate = "Incorporation date is required!";
  if (!input.bizFound) error.bizFound = "Business found is required!";
  if (!input.bizDescription)
    error.bizDescription = "Business description is required!";
  if (!input.bizIndustry) error.bizIndustry = "Business industry is required!";
  if (!input.bizRcNo) error.bizRcNo = "Business RC number is required!";
  if (!input.bizYear) error.bizYear = "Business year is required!";
  if (!input.bizLocation) error.bizLocation = "Business location is required!";

  const valid = Object.keys(error).length === 0;
  return { valid, error };
};

export const stepThreeValidator = (input) => {
  const error = {};

  if (input.firstName?.trim() === "")
    error.firstName = "First Name is required!";
  if (!input.lastName) error.lastName = "Last Name is required!";
  if (!input.title) error.title = "Tittle is required!";
  if (!emailReg.test(input.email)) error.email = "Email is not valid!";
  if (!input.email) error.email = "Email is required!";
  if (input.phone.length > 15)
    error.phone = "Phone Number is greater than 15 digit!";
  if (input.phone.length < 10)
    error.phone = "Phone Number is less than 10 digit!";
  if (!input.phone) error.phone = "Phone Number is required!";
  if (!input.birthdate) error.birthdate = "Birthdate is required!";
  if (!input.bankVeriNo) error.bankVeriNo = "BankVeriNo is required!";
  if (input.bankVeriNo.length < 11 || input.bankVeriNo.length > 12) {
    error.bankVeriNo = "BVN is not valid!";
  }
  if (!input.bankVeriNo)
    error.bankVeriNo = "Bank Verification Number is required!";
  if (!input.homeAddress) error.homeAddress = "Home Address is required!";
  if (!input.gender) error.gender = "Gender is required!";
  if (!input.yearsOfExperience)
    error.yearsOfExperience = "Years Of Experience is required!";
  if (!input.maritalStatus) error.maritalStatus = "Marital Status is required!";
  if (!input.kids) error.kids = "Number of kids is required!";
  if (!input.educationLevel)
    error.educationLevel = "Education Level is required!";
  if (!input.passportPics) error.passportPics = "Passport pics is required!";
  if (!input.governmentID) error.governmentID = "Government ID is required!";

  const valid = Object.keys(error).length === 0;
  return { valid, error };
};

export const directorValidator = (input) => {
  const error = {};
  if (!input) {
    // Handle the case where 'input' is undefined or null
    return { valid: false, error: "Input data is missing" };
  }

  if (input.first_name?.trim() === "")
    error.first_name = "This feild is required!";
  if (!input.last_name) error.last_name = "This feild is required!";
  if (!input.title) error.title = "This feild is required!";
  if (!emailReg.test(input.email)) error.email = "Email is not valid!";
  if (!input.email) error.email = "This feild is required!";
  if (input.phone?.length > 15)
    error.phone = "Phone Number is greater than 15 digit!";
  if (input.phone?.length < 10)
    error.phone = "Phone Number is less than 10 digit!";
  if (!input.phone) error.phone = "This feild is required!";
  if (!input.birth_date) error.birth_date = "This feild is required!";
  if (!input.bank_veri_no) error.bank_veri_no = "This feild is required!";
  if (input.bank_veri_no?.length < 11 || input.bank_veri_no?.length > 12) {
    error.bank_veri_no = "BVN is not valid!";
  }
  if (!input.num_kids) error.num_kids = "This feild is required!";
  if (!input.years_of_experience)
    error.years_of_experience = "This feild is required!";
  if (!input.address) error.address = "This feild is required!";
  if (!input.gender) error.gender = "This feild is required!";
  if (!input.years_of_experience)
    error.years_of_experience = "This feild is required!";
  if (!input.marital_status) error.marital_status = "This feild is required!";
  if (!input.education_level) error.education_level = "This feild is required!";
  if (!input.photo) error.photo = "This feild is required!";
  if (!input.government_id) error.government_id = "This feild is required!";

  const valid = Object.keys(error).length === 0;
  return { valid, error };
};

export const directorDetailsValidator = (input) => {
  const error = {};

  if (input.first_name.trim() === "")
    error.first_name = "This feild is required!";
  if (!input.last_name) error.last_name = "This feild is required!";
  if (!input.title) error.title = "This feild is required!";
  if (!emailReg.test(input.email)) error.email = "Email is not valid!";
  if (!input.email) error.email = "This feild is required!";
  if (input.phone.length > 15)
    error.phone = "Phone Number is greater than 15 digit!";
  if (input.phone.length < 10)
    error.phone = "Phone Number is less than 10 digit!";
  if (!input.phone) error.phone = "This feild is required!";
  if (!input.birth_date) error.birth_date = "This feild is required!";
  if (!input.bank_veri_no) error.bank_veri_no = "This feild is required!";
  if (input.bank_veri_no.length < 11 || input.bank_veri_no.length > 12) {
    error.bank_veri_no = "BVN is not valid!";
  }
  if (!input.num_kids) error.num_kids = "This feild is required!";
  if (!input.years_of_experience)
    error.years_of_experience = "This feild is required!";
  if (!input.address) error.address = "This feild is required!";
  if (!input.gender) error.gender = "This feild is required!";
  if (!input.years_of_experience)
    error.years_of_experience = "This feild is required!";
  if (!input.marital_status) error.marital_status = "This feild is required!";
  if (!input.education_level) error.education_level = "This feild is required!";

  const valid = Object.keys(error).length === 0;
  return { valid, error };
};

export const distributorValidator = (input) => {
  const error = {};
  if (input.name.trim() === "") error.name = "Name is required";
  if (!input.mou) error.mou = "MOU is required";
  if (!input.address) error.address = "Address is required";
  if (!input.email) error.email = "Email is required";
  if (!input.aoa) error.aoa = "Articles of Incorporations is required";
  if (!input.cac) error.cac = "CAC is required";

  const valid = Object.keys(error).length === 0;
  return { valid, error };
};
export const adminUploadValidator = (doc) => {
  const error = {};
  if (!doc.sales_data) error.sales_data = "Sales Data is required!";
  if (!doc.agreement_doc_url)
    error.agreement_doc_url = "Loan Agreement is required!";
  if (!doc.distributor_vendor_ledger) {
    error.distributor_vendor_ledger = "Distributors Vendor Ledger is required";
  }
  const isValid = Object.keys(error).length === 0;
  return { isValid, error };
};
export const updateDistributorValidator = (input) => {
  const error = {};
  if (!input.name?.trim() === "") error.name = "Name is required";
  if (!input.mou) error.mou = "MOU is required";
  if (!input.address) error.address = "Address is required";
  if (!input.email) error.email = "Email is required";

  const valid = Object.keys(error).length === 0;
  return { valid, error };
};

export const stepFourValidator = (input) => {
  const error = {};

  if (input.loanAmount.trim() === "")
    error.loanAmount = "Loan amount is required!";
  if (!input.loanPurpose) error.loanPurpose = "Loan purpose is required!";
  if (!input.loanDuration) error.loanDuration = "Loan duration is required!";

  const valid = Object.keys(error).length === 0;
  return { valid, error };
};

export const stepTwoValidator = (input) => {
  const error = {};
  if (!input.pharmacistLicense)
    error.pharmacistLicense = "Pharmacist License is required!";
  if (!input.businessLicenses)
    error.businessLicenses = "Business Licenses is required!";
  if (!input.bankStatement) error.bankStatement = "Bank Statement is required!";
  if (!input.cac2) error.cac2 = "cac2 is required!";
  if (!input.cac7) error.cac7 = "cac7 is required!";

  const valid = Object.keys(error).length === 0;

  return { valid, error };
};

export const step2Validator = (fileName) => {
  const error = {};
  if (!fileName?.pharmacist_license)
    error.pharmacist_license = "Pharmacist License is required!";
  if (!fileName?.business_licenses)
    error.business_licenses = "Business Licenses is required!";
  if (!fileName?.bank_statement)
    error.bank_statement = "Bank Statement is required!";
  if (!fileName?.cac_2) error.cac_2 = "cac2 is required!";
  if (!fileName?.cac_7) error.cac_7 = "cac7 is required!";
  if (!fileName?.aoa) error.aoa = "Article of Association is required!";
  if (!fileName?.moa) error.moa = "Memorandum of Association is required!";

  const valid = Object.keys(error).length === 0;

  return { valid, error };
};

export const step3Validator = (input) => {
  const error = {};

  if (!input.first_name || input.first_name.trim() === "")
    error.first_name = "This field is required!";
  if (!input.last_name || input.last_name.trim() === "")
    error.last_name = "This field is required!";
  if (!input.email || !emailReg.test(input.email))
    error.email = "Email is not valid!";
  if (!input.email) error.email = "This field is required!";
  if (
    !input.phone ||
    (typeof input.phone !== "string" && !Array.isArray(input.phone))
  ) {
    error.phone = "Invalid phone number!";
  } else {
    if (typeof input.phone === "string" && input.phone.length > 15) {
      error.phone = "Phone Number is greater than 15 digits!";
    } else if (Array.isArray(input.phone) && input.phone.length < 10) {
      error.phone = "Phone Number is less than 10 digits!";
    }
  }
  if (!input.birth_date) error.birth_date = "This field is required!";
  if (!input.bank_veri_no) error.bank_veri_no = "This field is required!";
  if (input.bank_veri_no.length < 11 || input.bank_veri_no.length > 12) {
    error.bank_veri_no = "BVN is not valid!";
  }
  if (!input.address) error.address = "This field is required!";
  if (!input.gender) error.gender = "This field is required!";
  if (!input.marital_status) error.marital_status = "This field is required!";
  if (!input.education_level) error.education_level = "This field is required!";
  if (!input.photo || input.photo.trim() === "") {
    {
      error.photo = "This field is required!";
    }
    if (!input.government_id || input.government_id.trim() === "") {
      {
        error.government_id = "This field is required!";
      }

      const valid = Object.keys(error).length === 0;
      return { valid, error };
    }
  }
};

// Validate business info Page
export const bizInfoValidator = (input) => {
  const error = {};
  if (!input.pharmacistLicense)
    error.pharmacistLicense = "Pharmacist License is required!";
  if (!input.licenseExp)
    error.licenseExp = "Pharmatical License Expiry is required!";
  if (!input.businessName) error.businessName = "Business Name is required!";

  const valid = Object.keys(error).length === 0;

  return { valid, error };
};

// Register Validation
export const registerValidator = (input) => {
  const error = {};
  if (!input.first_name) error.first_name = "This feild is required!";
  if (!input.last_name) error.last_name = "This feild is required!";
  if (!input.email) error.email = "This feild is required!";
  if (!input.phone_number) error.phone_number = "This feild is required!";
  if (!input.password) error.password = "This feild is required!";
  if (!input.password2) error.password2 = "This feild is required!";

  const valid = Object.keys(error).length === 0;
  return valid;
};

// change password
export const changePwdValidator = (input) => {
  const error = {};
  if (!input.current_password)
    error.current_password = "This feild is required!";
  if (!input.new_password) error.new_password = "This feild is required!";
  if (!input.confirm_password)
    error.confirm_password = "This feild is required!";
  if (input.new_password !== input.confirm_password) {
    error.confirm_password = "Password does not match!";
  }
  const valid = Object.keys(error).length === 0;
  return { error, valid };
};

const checkName = (name) => {
  switch (name) {
    case "note":
      return "Loan reason";
    case "mgtFee":
      return "Management Fee";
    case "loanInterestRate":
      return "Loan Interest Rate";
    case "agreement_doc_url":
      return "Please upload the agreement docs you downloaded";
    default:
      return name;
  }
};

const approveLoanValidator = (loans) => {
  let error = "";
  let isValid = true;
  for (const keys in loans) {
    delete loans.distributors_name;
    if (loans[keys]?.length === 0) {
      error = `${checkName(keys)} is required!`;
      isValid = false;
      break;
    }
  }
  return { error, isValid };
};

const addLoanPaymentValidator = (loans) => {
  let error = "";
  let isValid = true;
  for (const keys in loans) {
    if (loans[keys]?.length === 0) {
      error = `${checkName(keys)} is required!`;
      isValid = false;
      break;
    }
  }
  return { error, isValid };
};

export {
  checkName,
  logInValidator,
  addLoanPaymentValidator,
  signUpValidator,
  forgotPwdValidator,
  resetPwdValidator,
  approveLoanValidator,
  registerCompanyValidator,
  resetTempPwd,
};
