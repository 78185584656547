import React, { useState, useEffect } from 'react'
import { InputField } from '../../sharedComponents/input-field'
import { Progress, Upload, message } from 'antd'
import { distributorValidator } from '../../utils/inputValidator'
import { SUPPORTED_IMAGE_TYPE } from '../../utils/data'
import PropTypes from 'prop-types'
import { BROWSE_FILE } from '../../sharedComponents/entries/constant'
import { useSelector } from 'react-redux'
import { UPLOAD_BASE_URL } from '../../utils/constants'
import { dispatch } from '../../redux/store'
import {
  NAME,
  MOU,
  ADDRESS,
  ADD_DISTRIBUTOR,
  DRAG_FILES,
  OR,
  EMAIL,
  CAC,
  ARTICLES_OF_INCORPORATION
} from './constants'

const AddDistributors = props => {
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState({})
  const distributorLoading = useSelector(
    ({ loading }) => loading.effects.distributors.adminAddDistributor
  )

  const [input, setInput] = useState({
    name: '',
    address: '',
    mou: '',
    email: '',
    cac: '',
    aoa: ''
  })

  const uploadProps = {
    action: `${UPLOAD_BASE_URL}upload`,
    name: 'file',
    beforeUpload: file => {
      if (!SUPPORTED_IMAGE_TYPE.includes(file.type)) {
        return message.error(`${file.name} is not supported`)
      }
      if (file.size / 1000000 > 35) {
        return message.error('This file size is more than the required 30mb')
      }
      return file.size / 1000000 < 35 && SUPPORTED_IMAGE_TYPE.includes(file.type)
        ? true
        : Upload.LIST_IGNORE
    }
  }

  const handleChange = (info, type) => {
    if (info.file && info.file.status === 'done') {
      if (info.file.response.success) {
        const ImgUrl = info.file.response.data.url
        if (type === 'mou') {
          setInput({ ...input, mou: ImgUrl })
        } else if (type === 'aoa') {
          setInput({ ...input, aoa: ImgUrl })
        } else if (type === 'cac') {
          setInput({ ...input, cac: ImgUrl })
        }
      }
    }
  }
  const onChangeText = ({ target }) => {
    setInput({ ...input, [target.name]: target.value })
    setError({ ...error, [target.name]: false })
  }

  const validator = distributorValidator(input)

  useEffect(() => {
    if (Object.keys(input).length) {
      if (validator.valid) setProgress(100)
    }
  }, [props, input])

  const handleSubmit = async () => {
    if (validator.valid) {
      dispatch.distributors.adminAddDistributor(input)
      setInput({
        name: '',
        address: '',
        mou: '',
        email: '',
        cac: '',
        aoa: ''
      })
      setProgress(0)
    } else {
      setError({ ...validator.error })
    }
  }
  return (
    <div className='w-full'>
      <div className=''>
        <div className='flex flex-wrap mb-4 justify-center'>
          <div className='w-full md:w-2/5 px-2 mb-6'>
            <label className='text-sm text-gray-900  font-semibold'>{NAME + ' *'}</label>
            <InputField
              className='form-control'
              name='name'
              type='text'
              onChange={onChangeText}
              value={input.name}
              maxLength='35'
              placeholder={NAME}
            />
            {error.name ? <small className='text-red-500'>{error.name}</small> : ''}
          </div>
          <div className='w-full md:w-2/5 px-2 mb-6'>
            <label className='text-sm text-gray-900  font-semibold'>{EMAIL + ' *'}</label>
            <InputField
              onChange={onChangeText}
              value={input.email}
              type='text'
              name='email'
              maxLength='45'
              placeholder={EMAIL}
              className='form-control'
            />
            {error.email ? <small className='text-red-500'>{error.email}</small> : ''}
          </div>
          <div className='w-full md:w-2/5 px-2 mb-6'>
            <label className='text-sm text-gray-900  font-semibold'>{ADDRESS + ' *'}</label>
            <textarea
              name='address'
              type='text'
              onChange={onChangeText}
              value={input.address}
              rows='10'
              placeholder={ADDRESS}
              className='form-control resize-none border rounded-md w-full appearance-none bg-transparent border-b-2 mr-3 py-1 px-1 leading-tight  mt-1 focus:outline-none  border-gray-400'
            />
            {error.address ? <small className='text-red-500'>{error.add}</small> : ''}
          </div>
          <div className='w-full md:w-2/5 lg:w-2/5 px-4'>
            <label className='text-sm text-gray-900  font-semibold'>{MOU + '*'}</label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progress <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{
                      wordWrap: 'break-word',
                      maxWidth: '190px',
                      minWidth: '180px'
                    }}
                  >
                    <Upload.Dragger {...uploadProps} onChange={info => handleChange(info, 'mou')}>
                      <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                      <p className='text-center my-6 font-normal'>{OR} </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        {BROWSE_FILE}
                      </p>
                    </Upload.Dragger>
                  </div>
                ) : (
                  <Progress type='circle' percent={progress} />
                )}
                {error.mou ? <small className='text-red-500'>{error.mou}</small> : ''}
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/5 lg:w-2/5 px-4'>
            <label className='text-sm text-gray-900  font-semibold'>{CAC + '*'}</label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progress <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{
                      wordWrap: 'break-word',
                      maxWidth: '190px',
                      minWidth: '180px'
                    }}
                  >
                    <Upload.Dragger {...uploadProps} onChange={info => handleChange(info, 'cac')}>
                      <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                      <p className='text-center my-6 font-normal'>{OR} </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        {BROWSE_FILE}
                      </p>
                    </Upload.Dragger>
                  </div>
                ) : (
                  <Progress type='circle' percent={progress} />
                )}
                {error.cac ? <small className='text-red-500'>{error.cac}</small> : ''}
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/5 lg:w-2/5 px-4'>
            <label className='text-sm text-gray-900  font-semibold'>
              {ARTICLES_OF_INCORPORATION + '*'}
            </label>
            <div className='flex w-full px-3 mt-3 border'>
              <div className='flex justify-center bg-white w-full my-6 mx-6 rounded-lg border-dashed border-4 border-light-blue-500 h-32'>
                {progress <= 0 ? (
                  <div
                    className='text-center px-2'
                    style={{
                      wordWrap: 'break-word',
                      maxWidth: '190px',
                      minWidth: '180px'
                    }}
                  >
                    <Upload.Dragger {...uploadProps} onChange={info => handleChange(info, 'aoa')}>
                      <p className='text-center font-normal pt-6'>{DRAG_FILES} </p>
                      <p className='text-center my-6 font-normal'>{OR} </p>
                      <p className='text-center font-normal text-base text-primary underline'>
                        {BROWSE_FILE}
                      </p>
                    </Upload.Dragger>
                  </div>
                ) : (
                  <Progress type='circle' percent={progress} />
                )}
                {error.aoa ? <small className='text-red-500'>{error.aoa}</small> : ''}
              </div>
            </div>
          </div>
          <div className='w-full flex mt-6 mb-8 justify-center'>
            <button className='w-full md:w-2/5 px-2 btn-primary' onClick={handleSubmit}>
              {distributorLoading ? (
                <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
              ) : (
                ''
              )}
              {ADD_DISTRIBUTOR}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

AddDistributors.propTypes = {
  user: PropTypes.shape({
    phone_number: PropTypes.string,
    email: PropTypes.string
  })
}

export default AddDistributors
