/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, notification, DatePicker } from 'antd'
import { Icon } from '@iconify/react'
import { CalendarOutlined, LoadingOutlined } from '@ant-design/icons'
import { SaasCustomerService } from '../../../../services'

const bodyStyle = {
  padding: 0
}

const SaasCustomerCsvModal = ({ userId, visible, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [csvStartDate, setCsvStartDate] = useState(null)
  const [csvEndDate, setCsvEndDate] = useState(null)

  const handleCSVDateFilter = (_, date) => {
    const startDate = date[0]
    const endDate = date[1]

    setCsvStartDate(startDate)
    setCsvEndDate(endDate)
  }

  const handleSubmission = async () => {
    if (!csvStartDate || !csvEndDate) {
      notification.error({
        message: 'Input validation error',
        description: 'Start date and End date are required for CSV export'
      })
      return
    }

    setLoading(true)

    await SaasCustomerService.exportSaasCustomerCsv(userId, csvStartDate, csvEndDate)
      .then(response => {
        notification.success({
          message: 'Success',
          description: 'Customer CSV exported successfully'
        })

        const blob = new Blob([response.data], { type: 'text/csv' })

        // Create a URL for the Blob and download it
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', `oyasync_${userId}.csv`) // Set the download file name
        document.body.appendChild(link)
        link.click()
        link.remove() // Clean up after the download

        onCancel()
      })
      .catch(error => {
        notification.error({
          message: 'Something went wrong',
          description: error?.response?.data?.message || error?.message
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className='info-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          color='#b1b1ff'
          className='mt-4 mr-8 text-white text-4xl rounded-full bg-white'
        />
      }
      bodyStyle={bodyStyle}
      width={800}
    >
      <div className='modal-header bg-primary px-6 flex justify-between pb-4'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>Export CSV</h2>
      </div>
      <div className='px-4 pb-8'>
        <p className='font-bold text-lg py-8 pl-8'>
          Kindly select the start and end date for your query
        </p>
        <div className='flex flex-col gap-8 justify-center px-8'>
          <div className='flex items-end gap-4'>
            <div>
              <div className='mb-2'>Export CSV</div>
              <div className='relative flex border rounded-md'>
                <DatePicker.RangePicker
                  onChange={handleCSVDateFilter}
                  picker='date'
                  suffixIcon={<CalendarOutlined />}
                />
              </div>
            </div>
          </div>

          <div className='flex justify-start'>
            <button
              onClick={handleSubmission}
              className='bg-primary rounded-md md:px-12 py-2 text-white outline border border-gray-300 flex items-center justify-center'
            >
              {loading ? <LoadingOutlined spin className='py-1' /> : 'Export'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

SaasCustomerCsvModal.propTypes = {
  userId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default SaasCustomerCsvModal
