/* eslint-disable react/display-name */
/* eslint-disable camelcase */

import React from 'react'
const EXPORT = 'EXPORT'
const SYSTEM_LOGS = 'SYSTEM_LOGS'

export { EXPORT, SYSTEM_LOGS }

export const SYSTEM_LOGS_ARR = [
  {
    title: <span className='font-bold'>Date</span>,
    datakey: 'Date',
    dataIndex: 'event_date',
    key: 'event_date',
    defaultSortOrder: ['ascend'],
    sorter: (a, b) => new Date(a.event_date) - new Date(b.event_date),
    render: event_date => <span>{event_date?.split(' ')[0]}</span>
  },
  {
    title: <span className='font-bold'>Log ID</span>,
    datakey: 'Log ID',
    dataIndex: 'log_id',
    key: 'log_id',
    render: log_id => <span>{log_id}</span>
  },
  {
    title: <span className='font-bold'>Customer ID</span>,
    datakey: 'Customer ID',
    dataIndex: 'customer_id',
    key: 'customer_id',
    render: customer_id => <span>{customer_id || '-'}</span>
  },
  {
    title: <span className='font-bold'>Action</span>,
    datakey: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: action => <span>{action}</span>
  },
  {
    title: <span className='font-bold'>Description</span>,
    datakey: 'Description',
    dataIndex: 'action_description',
    key: 'action_description',
    render: action_description => <span>{action_description}</span>
  },
  {
    title: <span className='font-bold'>Event URL</span>,
    datakey: 'Event URL',
    dataIndex: 'event_url',
    key: 'event_url',
    render: event_url => <span className='text-blue-500'>{event_url}</span>
  }
]
