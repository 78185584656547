import React from 'react'
import PropTypes from 'prop-types'

function FeatureCard({ iconUrl, title, description }) {
  return (
    <div className='bg-fadedLime select-none h-331px px-4 flex justify-center flex-col items-center gap-3 mr-4 md:mr-26px'>
      <div>
        <img src={iconUrl} alt='feature icon' />
      </div>
      <p className='font-bold text-xl text-center md:text-2xl'>{title}</p>
      <p className='text-center'>{description}</p>
    </div>
  )
}

FeatureCard.propTypes = {
  iconUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
}

export default FeatureCard
