/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { DatePicker, Spin } from 'antd'
import moment from 'moment'
import { formatCurrency } from '../../utils/helpers'

const LineChartAdmin = () => {
  const [data, setData] = useState({
    datasets: [
      {
        backgroundColor: '#D6FAE0',
        data: [],
        label: 'Disbursed funds',
        fill: true
      },
      {
        backgroundColor: '#FDDFE0',
        data: [],
        label: 'Recovered funds',
        fill: true
      }
    ]
  })
  const [year, setYear] = useState(String(moment().year()))
  const { adminLineChart } = useSelector(({ applications }) => applications)
  const isAdminAppLineChartLoading = useSelector(
    ({ loading }) => loading.effects.applications.adminAppLineChart
  )

  function disabledDate(current) {
    return !moment().isAfter(current)
  }

  useEffect(() => {
    if (Object.keys(adminLineChart)?.length === 0 && !isAdminAppLineChartLoading) {
      dispatch.applications.adminAppLineChart(String(moment().year()))
    }
  }, [])

  useEffect(() => {
    if (Object.keys(adminLineChart)?.length === 0) return false
    setData({
      ...data,
      datasets: [
        { ...data.datasets[0], data: adminLineChart.disbursedWithMonthsLabels },
        { ...data.datasets[1], data: adminLineChart.receivedWithMonthsLabels }
      ]
    })
  }, [adminLineChart])

  return (
    <div>
      {isAdminAppLineChartLoading ? (
        <Spin size='large' />
      ) : (
        <div>
          <div className='flex justify-around'>
            <DatePicker
              className='relative'
              onChange={(_, year) => {
                setYear(year)
                dispatch.applications.adminAppLineChart(year)
              }}
              bordered
              placeholder='Filter by year'
              picker='year'
              disabledDate={disabledDate}
            />
            <p className='grid text-green-600 transform duration-700 hover:-translate-y-4'>
              ₦{formatCurrency(adminLineChart.totalDisbursedFund)} <sub>Total disbursed {year}</sub>
            </p>
            <p className='grid text-red-500 transform duration-700 hover:-translate-y-4'>
              ₦{formatCurrency(adminLineChart.totalReceivedFund)} <sub>Total recovered {year}</sub>
            </p>
          </div>

          <Line
            data={data}
            height={370}
            width={500}
            options={{
              maintmaintainAspectRatio: true,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    type: 'time',
                    time: {
                      unit: 'month'
                    }
                  }
                ]
              }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default LineChartAdmin
