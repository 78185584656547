import { CsvBuilder } from 'filefy'
/**
 * A function that downloads bank statement
 * @param {Array} bankStatementColumn the columns for the bank statement
 * @param {Array} bankStatement the actual array of objects, the bank statement data
 * @returns {void} nothing
 */
export function downloadCSV(bankStatementColumn, bankStatement) {
  const csvBuilder = new CsvBuilder('bankstatemt.csv')
  let index = 1
  csvBuilder.setColumns(['#', ...bankStatementColumn.map(({ title }) => title)])
  bankStatement.forEach(record => {
    csvBuilder.addRow([
      index++,
      record.amount,
      record.balance,
      record.date,
      record.narration,
      record.type,
      record._id
    ])
  })
  csvBuilder.exportFile()
}
