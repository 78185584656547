import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { notification } from 'antd'
import { useSelector } from 'react-redux'

import { signUpValidator } from '../../utils/inputValidator'
import { dispatch } from '../../redux/store'
import { ConfirmSignUpModal } from '../../sharedComponents/modal'
import Logo from '../../images/login/logo.svg'
import {
  SIGN_UP_LABEL_FULL_NAME,
  SIGN_UP_LABEL_LASTNAME,
  SIGN_UP_LABEL_EMAIL,
  SIGN_UP_LABEL_PHONE_NO,
  SIGN_UP_LABEL_PWD,
  SIGN_UP_BTN,
  ALREADY_HAVE_AN_ACCOUNT,
  LOGIN,
  SIGN_UP_TEXT,
  TROUBLE_SIGNING_UP,
  CONTACT_SUPPORT,
  SIGN_UP_LABEL_BUSINESS_NAME
} from './constants'
import { capitalizedWord } from '../../utils/helpers'

const SignUp = () => {
  const history = useHistory()
  const loading = useSelector(({ loading }) => loading.effects.auth.register)
  const [showPwd, setShowPwd] = useState(false)
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    business_name: '',
    account_type: 'Personal',
    dob: '',
    state_of_origin: '',
    local_govt: '',
    bvn: '',
    nin: '',
    gender: ''
  })

  const submitFormHandle = async () => {
    const validator = signUpValidator(user)
    if (validator.isValid) {
      const userDetails = {}

      for (const key in user) {
        if (Object.hasOwnProperty.call(user, key)) {
          const element = user[key]
          userDetails[key] = element.trim()
        }
      }
      await dispatch.auth.register({ history, user: userDetails })
    } else {
      notification.error({
        message: 'An error occured',
        description: validator.error
      })
    }
  }

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.nativeEvent.keyCode === 13) {
      submitFormHandle()
    }
  }

  const onChangeText = ({ target }) => {
    let value = target.value
    switch (target.name) {
      case 'first_name':
        value = capitalizedWord(target.value)
        break
      case 'last_name':
        value = capitalizedWord(target.value)
        break
      case 'email':
        value = target.value.toLowerCase().trim()
        break
      case 'business_name':
        value = target.value
        break
      case 'password':
        value = target.value
        break
      case 'phone_number':
        value = target.value
        break
    }
    setUser(user => ({
      ...user,
      [target.name]: value
    }))
  }
  return (
    <>
      <div className='grid md:grid-cols-5 h-full gap-5 w-full'>
        <div className='hidden md:flex flex-col items-center justify-center bg-login-img col-span-3 h-screen bg-cover'>
          <div>
            <img src={Logo} alt='logo' />
            <div className='mt-5'>
              <p className='text-violet300 text-5xl font-semibold mb-0 leading-normal'>
                Focus on securing health
              </p>
              <p className='text-white text-5xl font-semibold max-w-2xl leading-normal'>
                while we take care of your healthcare loans.
              </p>
              <p className='mt-6 text-zinc300 text-xl font-normal'>
                The one stop platform for healthcare finance
              </p>
            </div>
          </div>
        </div>
        <div className='px-4 pt-24 col-span-2 overflow-scroll h-screen'>
          <div className='mt-2 px-8 grid gap-2 grid-cols-1 py-4 mx-auto text-gray-900'>
            <h1 className='text-4xl font-bold'>{SIGN_UP_TEXT} </h1>
            <div className=''>
              <div className='mt-2'>
                <span className='text-sm text-black'>{SIGN_UP_LABEL_FULL_NAME}</span>
                <input
                  className='auth-input'
                  type='text'
                  value={user.first_name}
                  onChange={onChangeText}
                  name='first_name'
                  maxLength='35'
                />
              </div>
              <div className='mt-5'>
                <span className='text-sm text-black'>{SIGN_UP_LABEL_LASTNAME}</span>
                <input
                  className='auth-input'
                  type='text'
                  value={user.last_name}
                  onChange={onChangeText}
                  name='last_name'
                  maxLength='35'
                />
              </div>
              <div className='mt-5'>
                <span className='text-sm text-black'>{SIGN_UP_LABEL_EMAIL}</span>
                <input
                  className='auth-input'
                  type='email'
                  value={user.email}
                  onChange={onChangeText}
                  name='email'
                  maxLength='35'
                />
              </div>
              <div className='mt-5'>
                <span className='text-sm text-black'>{SIGN_UP_LABEL_PHONE_NO}</span>
                <input
                  className='auth-input'
                  type='tel'
                  maxLength='15'
                  value={user.phone_number}
                  onChange={onChangeText}
                  pattern='[7-9]{1}[0-9]{9}'
                  title='Phone number'
                  name='phone_number'
                />
              </div>
              <div className='mt-5'>
                <span className='text-sm text-black'>{SIGN_UP_LABEL_BUSINESS_NAME}</span>
                <input
                  className='auth-input'
                  type='text'
                  value={user.business_name}
                  onChange={onChangeText}
                  name='business_name'
                  maxLength='35'
                />
              </div>
              <div className='mt-5'>
                <div className='flex items-center mt-6'>
                  <div className='w-full'>
                    <span className='text-sm text-black'>{SIGN_UP_LABEL_PWD}</span>

                    <div className='relative mt-2'>
                      <input
                        className='text-base block w-full bg-gray-300 border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:shadow-outline focus:bg-white'
                        type={showPwd ? 'text' : 'password'}
                        value={user.password}
                        onChange={onChangeText}
                        name='password'
                        maxLength='35'
                        onKeyPress={handleKeypress}
                      />

                      <button
                        onClick={() => setShowPwd(!showPwd)}
                        type='submit'
                        className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                      >
                        {showPwd ? (
                          <i className='fas fa-eye-slash' />
                        ) : (
                          <i className='fas fa-eye' />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-5'>
                <button
                  data-testid='submit-signup'
                  onClick={submitFormHandle}
                  className='py-2 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline text-xl'
                >
                  {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                  {SIGN_UP_BTN}
                </button>
              </div>
              <div className='mt-7'>
                {/* <a
                  href='https://api.vittasinternational.com/login'
                  className='block py-3 bg-white text-black rounded-md w-full focus:outline-none focus:shadow-outline cursor-pointer text-lg text-center'
                >
                  <img src={googleIcon} alt='google icon' className='inline mr-2' />
                  {CONTINUE_USING_GOOGLE}{' '}
                  {loadingGoogle ? (
                    <img src={loader} width='25' alt='loader' className='inline mr-2' />
                  ) : (
                    ''
                  )}
                </a> */}
              </div>
              <div className='text-center my-5 text-lg'>
                {ALREADY_HAVE_AN_ACCOUNT}
                <Link to='/login' className='text-primary ml-2 underline'>
                  {LOGIN}
                </Link>
              </div>
              <div className='text-center my-5 text-lg'>
                {TROUBLE_SIGNING_UP}
                <Link to='/contact-us' className='text-primary ml-2 underline'>
                  {CONTACT_SUPPORT}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmSignUpModal headerTitle='VITTAS_INTERNATIONAL' />
    </>
  )
}

export default SignUp
