import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

const FormSelect = ({ value, onChange, options, label, name, onClick, ...others }) => {
  return (
    <div className='relative inline-block w-full text-gray-700 mt-2'>
      <select
        value={value}
        onChange={onChange}
        name={name}
        onClick={onClick}
        className={`${others.className} w-full py-3 pl-3 pr-6 text-base placeholder-gray-600 border  appearance-none focus:shadow-outline`}
        placeholder='Regular input'
      >
        {!options?.length && <option value=''>{label}</option>}
        {options.map(({ value, name }, i) => (
          <option disabled={others.disabled} value={value} key={i}>
            {name}
          </option>
        ))}
      </select>
      <div className='absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none'>
        <i className='fas fa-chevron-down' />
      </div>
    </div>
  )
}

FormSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string
}

export const AntDesignFormSelect = ({
  options,
  value,
  onChange,
  placeholder,
  name,
  loading,
  filterOption,
  notFoundContent,
  showSearch = false,
  ...props
}) => {
  return (
    <Select
      showSearch={showSearch}
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      loading={loading}
      filterOption={filterOption}
      notFoundContent={notFoundContent}
      {...props}
    />
  )
}

AntDesignFormSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
  filterOption: PropTypes.func,
  notFoundContent: PropTypes.node,
  showSearch: PropTypes.bool
}

export default FormSelect
