import ApiHandler from './ApiHandler'

export default {
  addCustomerPosTerminal: data => ApiHandler.post('admin/add-pos-terminal', data),
  getSaasCustomers: (size = 1000) => ApiHandler.get(`admin/users-sass-data?per_page=${size}`, {}),
  exportSaasCustomerCsv: (userId, startDate, endDate) =>
    ApiHandler.get(
      `admin/export-user-sass/${userId}?start_date=${startDate}&end_date=${endDate}`,
      {}
    )
}
