import React from 'react'
import { Table } from 'antd'

import { disbursementDetailsColumns } from '../constant'

const DisbursementDetails = () => {
  return <Table className='mt-5' columns={disbursementDetailsColumns} />
}

export default DisbursementDetails
