import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import {
  MESSAGE,
  SEND_REMINDER,
  PRESENT_TEMPLATE,
  REMINDER_SUBJECT,
  RECIPIENT,
  CANCEL
} from './constants'
import { notification } from 'antd'
import { Feedback } from '../../../services'
import { validateReminder } from '../../../utils/validator'
import AttachDocument from './AttachDocument'

const Reminder = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [reminder, setReminder] = useState({
    template: '',
    subject: '',
    recipient: '',
    message: ''
  })

  const handleCancel = () => {
    setReminder({ template: '', subject: '', recipient: '', message: '' })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const { valid, error } = validateReminder(reminder)
    if (!valid) {
      notification.error({
        message: 'Error Occurred',
        description: error
      })
      return
    }

    try {
      setLoading(true)
      const { data } = await Feedback.sendSeedbacks(reminder)

      if (data.success) {
        notification.success({
          message: 'Thanks',
          description: data.message
        })
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Something went wrong'
      })
      setLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {SEND_REMINDER}
          </h3>
        </div>
        <div className='mt-8 mb-16 px-4'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16 mx-2'>
            <div className='mb-16'>
              <div className='mb-6'>
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>{PRESENT_TEMPLATE}</label>
                  <select
                    name='template'
                    onChange={({ target }) => setReminder({ ...reminder, template: target.value })}
                    className='w-full border bg-white rounded px-3 py-2 outline-none form-control'
                  >
                    <option className='py-1' value=''>
                      -Select Options-
                    </option>
                    <option className='py-1' value='option 1'>
                      Option 1
                    </option>
                    <option className='py-1' value='option 2'>
                      Option 2
                    </option>
                    <option className='py-1' value='option 3'>
                      Option 3
                    </option>
                  </select>
                </div>
                <div className='w-full px-2 mb-6'>
                  <label className='text-sm text-gray-900 font-semibold'>{REMINDER_SUBJECT}</label>
                  <input
                    className='form-control'
                    name='subject'
                    type='text'
                    onChange={({ target }) => setReminder({ ...reminder, subject: target.value })}
                    value={reminder.firstName}
                  />
                </div>
                <div className='w-full px-2 mb-6'>
                  <span className='text-sm text-gray-900 font-semibold'>{RECIPIENT}</span>
                  <input
                    className='form-control'
                    name='recipient'
                    type='text'
                    onChange={({ target }) => setReminder({ ...reminder, recipient: target.value })}
                    value={reminder.lastName}
                  />
                </div>
              </div>
              <span className='text-sm text-gray-800 font-bold'> {MESSAGE}</span>
              <textarea
                className='w-full h-32 border-gray-300 resize-none border-2 rounded-lg text-gray-900 mt-1 p-3 focus:outline-none focus:shadow-outline'
                name='message'
                value={reminder.description}
                onChange={({ target }) => setReminder({ ...reminder, message: target.value })}
                placeholder='Type here...'
              />
              <div className='d-flex'>
                <button
                  onClick={handleCancel}
                  className='border-gray-500 mr-3 mt-6 text-base w-40 py-3 border rounded-lg hover:bg-gray-400 text-gray-700 hover:text-gray-50;'
                >
                  {CANCEL}
                </button>
                <button
                  onClick={handleSubmit}
                  className='bg-primary text-base mt-6 w-40 py-3 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
                >
                  {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                  {SEND_REMINDER}
                </button>
              </div>
            </div>
            <AttachDocument />
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default Reminder
