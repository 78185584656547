import React from 'react'
import header from '../images/header.svg'
import PropTypes from 'prop-types'

const WavyBackgroundHeader = ({ children }) => {
  return (
    <div
      className='flex w-screen px-5 sm:px-10 md:px-24'
      style={{
        backgroundImage: `url(${header})`,
        height: '524px'
      }}
    >
      {children}
    </div>
  )
}

WavyBackgroundHeader.propTypes = {
  children: PropTypes.any
}

export default WavyBackgroundHeader
