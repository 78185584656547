import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import FeatureCard from './cards/FeatureCard'
import ICON_F1 from '../../images/landing/f_1.png'
import ICON_F2 from '../../images/landing/f_2.png'
import ICON_F3 from '../../images/landing/f_3.png'
import ICON_F4 from '../../images/landing/f_4.png'
import ICON_F5 from '../../images/landing/f_5.png'
import ICON_F6 from '../../images/landing/f_6.png'

function KeyFeatureSection() {
  const [refreshKey, setRefreshKey] = useState(0)
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 9000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  }

  const data = [
    {
      iconUrl: ICON_F1,
      title: 'Loan Range',
      description: 'Loan options ranging from $2000 to $45000.'
    },
    {
      iconUrl: ICON_F2,
      title: 'Equipment Focus',
      description: 'Reputed brands for diagnostics and therapies'
    },
    {
      iconUrl: ICON_F3,
      title: 'Collateral',
      description: 'No additional collateral required, lease purchase model'
    },
    {
      iconUrl: ICON_F4,
      title: 'Currency',
      description: 'USD loans and repayments'
    },
    {
      iconUrl: ICON_F5,
      title: 'Location',
      description: 'Currently available in Lagos'
    },
    {
      iconUrl: ICON_F6,
      title: 'Terms',
      description: '12-15 months repayment duration'
    }
  ]

  useEffect(() => {
    setTimeout(() => {
      setRefreshKey(Math.random())
    }, 1000)
  }, [])

  return (
    <section>
      <div className='mb-4'>
        <p className='font-bold text-3xl md:text-3xl'>Key features</p>
      </div>

      <div key={refreshKey}>
        <Carousel responsive={responsive}>
          {data.map((feature, index) => (
            <FeatureCard {...feature} key={index} />
          ))}
        </Carousel>
      </div>
    </section>
  )
}

export default KeyFeatureSection
