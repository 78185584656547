/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { notification } from 'antd'
import { Roles } from '../../../services'
import { UPDATE_ROLE } from './constants'
import { PropTypes } from 'prop-types'
import { dispatch } from '../../../redux/store'

const UpdateRole = ({ role }) => {
  const [roles, setRoles] = useState()
  const [loading, setLoading] = useState(false)

  const handleChange = ({ target }) => {
    setRoles({ ...roles, [target.name]: Number(target.checked) })
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const { data } = await Roles.updatePermissions(roles)
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message
        })
      }
      setRoles(roles)
      setLoading(false)
      await dispatch.roles.getAllRoles()
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: error?.response?.data?.message || 'Something went wrong'
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    const { role_title, ...others } = role
    setRoles(others)
  }, [role])

  return (
    <main className='w-full flex-grow p-4 bg-white'>
      <div className='mt-8'>
        <div className='w-full md:w-8/12 px-2 mb-6 m-0'>
          <h2 className='font-bold'>Privileges:</h2>
          {roles &&
            Object.entries(roles).map((item, index) => (
              <div key={index} className='flex items-center mt-7'>
                <input
                  onChange={handleChange}
                  type='checkbox'
                  name={item[0]}
                  checked={item[1]}
                  className='h-4 w-4 text-blue-600 bg-primary focus:ring-blue-500 border-blue-300 rounded'
                />
                <label htmlFor={item[0]} className='ml-2 block text-sm text-gray-900'>
                  {item[0]}
                </label>
              </div>
            ))}
        </div>
        <div className='grid justify-items-end '>
          <div>
            <button
              onClick={handleSubmit}
              className='bg-primary mx-auto text-base mt-6 py-3 px-4 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
            >
              {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
              {UPDATE_ROLE}
            </button>
          </div>
        </div>
      </div>
    </main>
  )
}

UpdateRole.propTypes = {
  role: PropTypes.object
}
export default UpdateRole
