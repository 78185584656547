/* eslint-disable no-sequences */
/* eslint-disable camelcase */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import React from 'react'
import 'antd/dist/antd.css'
import { Table } from 'antd'
import { expandedColumns } from './constants'

/**
 *
 * @param {array} userLoans
 * @param {boolean} loading
 * @param {*} setModalVisibleWithRecord that sets visible and current loan
 * @returns
 */
export const expandedRowRender = (userLoans, loading, setModalVisibleWithRecord) => {
  return (
    <Table
      rowKey={record => record.key}
      columns={expandedColumns(setModalVisibleWithRecord)}
      dataSource={userLoans}
      bordered
      loading={{ spinning: loading, size: 'large' }}
      pagination={false}
      rowClassName='bg-green-100 text-black'
    />
  )
}
