import React, { useState, useEffect } from 'react'
import { Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import { resetPwdValidator } from '../../utils/inputValidator'
import { CHANGE_PWD_BTN, CREATE_NEW_PWD, EMAIL_LABEL, TOKEN_LABEL, NEW_PWD_LABEL } from './constant'
import { dispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const ResetPwdModal = ({ headerTitle }) => {
  const history = useHistory()
  const loading = useSelector(({ loading }) => loading.effects.auth.resetPwd)
  const changePwdModal = useSelector(({ auth }) => auth.changePwdModal)
  const { email: userEmail } = useSelector(({ auth }) => auth.pwdModalFeedback)

  const [visible, setModal] = useState(false)
  const [showPwd, setShowPwd] = useState(false)

  const [{ email, token, password }, setInput] = useState({
    email: '',
    token: '',
    password: ''
  })

  const handleCancel = () => {
    setModal(false)
    dispatch.auth.changePwdModal(false)
  }

  const onChangeText = ({ target }) => {
    setInput(userInput => ({ ...userInput, [target.name]: target.value }))
  }

  useEffect(() => {
    setModal(changePwdModal)
  }, [changePwdModal])

  useEffect(() => {
    if (userEmail) setInput(userInput => ({ ...userInput, email: userEmail }))
  }, [userEmail])

  const submitFormHandle = () => {
    const validator = resetPwdValidator({ email, token, password })
    if (validator.isValid) {
      dispatch.auth.resetPwd({ history, email, token, new_password: password })
    } else {
      notification.error({
        message: 'An error occured',
        description: validator.error
      })
    }
  }

  const handleKeypress = e => {
    // it triggers by pressing the enter key
    if (e.nativeEvent.keyCode === 13) {
      submitFormHandle()
    }
  }

  return (
    <>
      <Modal open={visible} title={headerTitle} onCancel={handleCancel} footer={[]} zIndex='1000'>
        <div className='flex flex-wrap mt-2'>
          <div className='w-full text-white'>
            <h1 className='text-black text-2xl mt-2 font-bold text-center'>{CREATE_NEW_PWD}</h1>
            <div className='mt-5'>
              <span className='text-sm text-black text-left'>{EMAIL_LABEL}</span>
              <input
                className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                type='email'
                value={email}
                onChange={onChangeText}
                name='email'
              />
            </div>
            <div className='mt-5'>
              <span className='text-sm text-black text-left'>{TOKEN_LABEL}</span>
              <input
                className='w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline'
                type='text'
                value={token}
                onChange={onChangeText}
                name='token'
                autoComplete='token'
              />
            </div>

            <div className='flex items-center mt-6'>
              <div className='w-full'>
                <span className='text-sm text-black text-left'>{NEW_PWD_LABEL}</span>
                <div className='relative pt-2'>
                  <input
                    className='text-base block w-full text-black bg-gray-300 border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:shadow-outline focus:bg-white'
                    type={showPwd ? 'text' : 'password'}
                    value={password}
                    onChange={onChangeText}
                    name='password'
                    onKeyPress={handleKeypress}
                  />

                  <button
                    onClick={() => setShowPwd(!showPwd)}
                    type='submit'
                    className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                  >
                    {showPwd ? <i className='fas fa-eye-slash' /> : <i className='fas fa-eye' />}
                  </button>
                </div>
              </div>
            </div>

            <div className='mt-5'>
              <button
                onClick={submitFormHandle}
                className='py-3 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
              >
                {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                {CHANGE_PWD_BTN}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

ResetPwdModal.propTypes = {
  headerTitle: PropTypes.string.isRequired
}

export default ResetPwdModal
