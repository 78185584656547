import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import successImg from '../../../../../images/wrapper.svg'
const SuccessModal = ({ loading }) => {
  const [visible, setVisible] = useState(true)

  const handleContinue = () => {
    setVisible(false)
  }

  return (
    <Modal open={visible} centered footer={null} closable={false} maskClosable={false} width={400}>
      <div className='text-center flex flex-col items-center'>
        <img src={successImg} alt='success' className='w-24 h-auto' />
        <h1 className='text-3xl font-bold mt-4'>Success</h1>
        <h2 className='text-2xl px-6 mt-2 mb-6'>Transfer Successful</h2>
        <button
          className='btn-primary px-7 py-2 text-blue-700 bg-white outline border border-blue-500 flex items-center justify-center'
          onClick={handleContinue}
        >
          {loading ? <LoadingOutlined spin /> : 'Continue'}
        </button>
      </div>
    </Modal>
  )
}

SuccessModal.propTypes = {
  loading: PropTypes.bool.isRequired
}

export default SuccessModal
