import { notification } from 'antd'
import { reducerActions } from '../reducer'
import { adminServices } from '../../services'

const initialState = {
  isServerError: false
}

export const registerCompany = {
  state: initialState,
  reducers: reducerActions,
  effects: dispatch => ({
    async register(payload) {
      dispatch.registerCompany.setError(null)
      try {
        const { data } = await adminServices.addUser(payload)
        if (data.success) {
          notification.success({
            message: data.message || 'Registered'
          })
        }
        if (!data.success) {
          notification.error({
            message: data.message || 'An error occured while registering company'
          })
        }
      } catch (error) {
        dispatch.registerCompany.setError(error.response)
        notification.error({
          message: 'An error occured',
          description:
            error?.response?.data?.message ||
            error?.response?.data?.msg ||
            error?.message ||
            error?.data?.message ||
            'Something went wrong'
        })
      }
    }
  })
}
