const USER = 'Choose a user below'
const CUSTOMERS = 'All Customers'
const NEXT = 'Next'
export const Application = 'Application'
export const applyLoanlocalStorageKey = 'apply-for-loan'
export const steps = {
  1: 'businessInformation',
  2: 'businessDocuments',
  3: 'signatories',
  4: 'loanDetails'
}

export { USER, CUSTOMERS, NEXT }
