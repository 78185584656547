import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Steps } from 'antd'
import { Icon } from '@iconify/react'
import LoanDetailsStep from '../steps/LoanDetailsStep'
import InterestDetails from '../steps/InterestDetails'
import DisbursementDetails from '../steps/DisbursementDetails'

const closeButtonStyle = 'mt-7 mr-8 text-white text-4xl rounded-full bg-white'
const bodyStyle = {
  padding: 0
}

const { Step } = Steps

const LoanDetails = ({ visible, onCancel, title, description, currentDebtAccount }) => {
  const [currentStep, setCurrentStep] = useState(0)

  const steps = [
    {
      title: 'Loan Details',
      content: <LoanDetailsStep currentDebtAccount={currentDebtAccount} />
    },
    {
      title: 'Interest Details',
      content: <InterestDetails />
    },
    {
      title: 'Disbursement details',
      content: <DisbursementDetails />
    }
  ]

  const handleCancel = () => {
    setCurrentStep(0) // Reset to the first step when the modal is canceled
    onCancel()
  }
  const handleNext = () => {
    setCurrentStep(prevStep => prevStep + 1)
  }

  const isLastStep = currentStep === steps.length - 1

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      centered
      className='info-modal'
      closeIcon={<Icon icon='iconamoon:close-thin' color='#b1b1ff' className={closeButtonStyle} />}
      bodyStyle={bodyStyle}
      width={900}
    >
      <div className='modal-header bg-primary px-6 py-2 flex justify-between'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>{title}</h2>
      </div>
      <div className='py-8 px-8'>
        <p className='text-black text-base text-center font-bold'>{description}</p>
        <Steps current={currentStep} size='big' className='mt-4 text-black text-base font-medium '>
          {steps.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>
        <div className=' mt-4'>{steps[currentStep].content}</div>
        {!isLastStep && (
          <button
            type='primary'
            className='bg-primary flex mx-auto text-base mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
            onClick={handleNext}
          >
            {' '}
            Next
          </button>
        )}
        {isLastStep && (
          <button
            className='bg-primary flex mx-auto text-base mt-6 py-3 px-6 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50'
            onClick={handleCancel}
          >
            {' '}
            Continue
          </button>
        )}
      </div>
    </Modal>
  )
}

LoanDetails.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  currentDebtAccount: PropTypes.object.isRequired
}

export default LoanDetails
