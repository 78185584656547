import React from 'react'
import { Popconfirm, notification } from 'antd'
import PropTypes from 'prop-types'
import { Loans } from '../../services'
import { END_LOAN } from '../../sharedComponents/modal/constant'
import { dispatch } from '../../redux/store'
import * as roles from '../../utils/roles'
import { checkPrivilages } from '../../utils/helpers'

const CloseLoan = ({ loanId, permissions }) => {
  const cancel = () => {
    notification.warn({
      message: 'Loan not ended'
    })
  }

  const confirm = async () => {
    try {
      const { data } = await Loans.getCloseLoan(loanId)
      if (data.success) {
        notification.success({
          message: 'Success',
          description: data.message || 'Loan ended'
        })
        await dispatch.applications.adminApplications()
      } else {
        notification.error({
          message: 'An error occured',
          description: data.message
        })
      }
    } catch (error) {
      notification.error({
        message: 'An error occured',
        description: error?.response.message || 'Cannot end loan'
      })
    }
  }
  return (
    <Popconfirm
      title='Are you sure to end this loan?'
      onConfirm={confirm}
      onCancel={cancel}
      okText='Yes'
      cancelText='No'
      disabled={!checkPrivilages([roles.CLOSE_LOAN], permissions)}
    >
      <button
        className='w-full text-left nav-sm-link hover:text-blue-800'
        disabled={!checkPrivilages([roles.CLOSE_LOAN], permissions)}
      >
        <i className='fa fa-minus mr-2' /> {END_LOAN}{' '}
      </button>
    </Popconfirm>
  )
}
CloseLoan.propTypes = {
  loanId: PropTypes.object,
  permissions: PropTypes.bool
}
export default CloseLoan
