import React from 'react'
import { Dropdown } from 'antd'
import PropTypes from 'prop-types'

const AntdDropdown = ({ menu, componentViewer }) => {
  return (
    <Dropdown menu={menu} placement='bottomLeft' arrow>
      {componentViewer || <i className='fa fa-ellipsis-v' />}
    </Dropdown>
  )
}

export default AntdDropdown

AntdDropdown.propTypes = {
  menu: PropTypes.any,
  componentViewer: PropTypes.element
}
