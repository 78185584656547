/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import _ from 'lodash'
import { DashboardLayout } from '../../../../sharedComponents/dashboardLayout'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'
import Step5 from './steps/Step5'
import { applyLoanlocalStorageKey, steps } from '../constants'
import { dispatch, select } from '../../../../redux/store'
import { Loans } from '../../../../services'
import { removeCurrencyFormatting } from '../../../../utils/helpers'
import { getQueryParams } from '../../../../utils/customeReactHook'
import { notification } from 'antd'

const NewApplyForLoan = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [currentStepData, setCurrentStepData] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const disableStepThree = Boolean(getQueryParams().get('disableStepThree'))

  const history = useHistory()
  const user = useLocation().state?.user
  const userIdFromParams = useParams().userId

  const exitingUsers = useSelector(select.users.getUsersNotAdmin)
  const { isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin
  }))
  /**
   *@description handles the next step
   * @param {object} values the form values for that step
   */
  const handleNext = async (values = null, fromStep) => {
    if (values) {
      // get previous data either from local storage or from the server
      const retrivedFromLocalStorage =
        JSON.parse(window.localStorage.getItem(applyLoanlocalStorageKey)) || {}

      if (values.loan_id) {
        // TODO: this block needs to be refactored
        const previousStepDataFromLocal = retrivedFromLocalStorage?.[steps[fromStep]]
        if (previousStepDataFromLocal) {
          const newVlaues = {
            ...retrivedFromLocalStorage,
            [steps[fromStep]]: { ...previousStepDataFromLocal, ...values }
          }
          // update the current step in the server
          const { loan_id, ...others } = values
          if (!_.isEqual(previousStepDataFromLocal, values)) {
            const data = await dispatch.loans.applyForLoan({
              [steps[fromStep]]: others,
              step: fromStep,
              loan_id,
              history
            })

            if (data) {
              window.localStorage.setItem(applyLoanlocalStorageKey, JSON.stringify(newVlaues))
            } else {
              notification.error({
                message: 'An error occured while saving the form'
              })
              return null
            }
          }
        } else {
          // else get the data from the server
          const retrivedFromServer = await Loans.getLoanAplicationBySteps(
            { loan_id: values.loan_id },
            fromStep
          )
          const newVlaues = { ...retrivedFromServer.data.data, ...values }

          window.localStorage.setItem(
            applyLoanlocalStorageKey,
            JSON.stringify({ ...retrivedFromLocalStorage, [steps[fromStep]]: newVlaues })
          )
          const { loan_id, ...others } = values
          const data = await dispatch.loans.applyForLoan({
            [steps[fromStep]]: others,
            step: fromStep,
            loan_id: values.loan_id,
            history
          })

          if (!data) {
            notification.error({
              message: 'An error occured while saving the form'
            })
            return null
          }
        }
      } else {
        const previousStepsData =
          JSON.parse(window.localStorage.getItem(applyLoanlocalStorageKey)) || {}
        // if there's no loan id, then it is a new loan application
        let requestPayload
        switch (fromStep) {
          case 1:
            requestPayload = {
              [steps[fromStep]]: values,
              step: fromStep
            }
            break
          case 2:
            requestPayload = {
              [steps[fromStep]]: values?.docsIds,
              step: fromStep,
              loan_id: retrivedFromLocalStorage?.[steps[1]]?.loan_id
            }
            break
          case 3:
            requestPayload = {
              [steps[fromStep]]: values?.directorIds,
              step: fromStep,
              loan_id: retrivedFromLocalStorage?.[steps[1]]?.loan_id
            }
            break
          case 4:
            window.localStorage.setItem(
              applyLoanlocalStorageKey,
              JSON.stringify({
                ...previousStepsData,
                [steps[fromStep]]: {
                  ...values,
                  loan_id: retrivedFromLocalStorage?.[steps[1]]?.loan_id
                }
              })
            )
        }

        if (fromStep !== 4) {
          const data = await dispatch.loans.applyForLoan({ ...requestPayload, history })

          // save the loan id to the local storage and the values
          window.localStorage.setItem(
            applyLoanlocalStorageKey,
            JSON.stringify({
              ...previousStepsData,
              [steps[fromStep]]: { ...values, loan_id: data?.data?.loan_id }
            })
          )

          if (!data) {
            // if step one fails, start the loan app afresh since no loan id is saved
            if (fromStep === 1) {
              window.localStorage.removeItem(applyLoanlocalStorageKey)
              setCurrentStep(1)
            }

            notification.error({
              message: 'An error occured while saving the form'
            })
            return null
          }
        }
      }
    }

    if (fromStep === 5) {
      // get the step 4 data from local storage and call the api
      const retrivedFromLocalStorage =
        JSON.parse(window.localStorage.getItem(applyLoanlocalStorageKey)) || {}
      const previousStepDataFromLocal = retrivedFromLocalStorage?.[steps[4]]

      if (previousStepDataFromLocal) {
        const { loan_id, distributors, ...others } = previousStepDataFromLocal
        const data = await dispatch.loans.applyForLoan({
          [steps[4]]: {
            ...others,
            loanAmount: removeCurrencyFormatting(others.loanAmount),
            loanDuration: String(others?.loanDuration)
          },
          step: 4,
          loan_id,
          distributors: distributors?.map(dist => ({
            ...dist,
            distributor_amount: removeCurrencyFormatting(dist.distributor_amount)
          })),
          history
        })

        if (!data) {
          throw new Error('An error occured while submitting the form')
        }
        return
      }
    }
    const nextStep = disableStepThree && fromStep === 2 ? fromStep + 2 : fromStep + 1
    setCurrentStep(nextStep)
  }

  /**
   * @description handles the previous step
   * @param {number} step the step number to go to
   * @returns {object} the form values for that step
   */
  const handleGoBack = step => {
    setCurrentStep(prevStep => {
      const nextStep = disableStepThree && prevStep === 2 ? prevStep - 2 : prevStep - 1
      if (typeof step === 'number') {
        return step
      }
      return nextStep
    })
  }

  const clearCurrentStep = step => {
    const storedData = JSON.parse(window.localStorage.getItem(applyLoanlocalStorageKey)) || {}

    const { [steps[step]]: stepToRemove, ...others } = storedData

    window.localStorage.setItem(applyLoanlocalStorageKey, JSON.stringify(others))
    setCurrentStepData(null)
  }

  // TODO: Retrive loan ID from url param. Also ask backend to send the loan ID via email to customers/admins when creating a new loan application. so they can use it to continue the application

  /**
   * @description loads the stored step values from local storage when the component mounts
   * @returns {void}
   */
  useEffect(() => {
    // Load the stored step values from local storage when the component mounts
    const storedData = JSON.parse(window.localStorage.getItem(applyLoanlocalStorageKey)) || {}
    const storedValues = storedData?.[steps[currentStep]] || null

    setCurrentStepData(storedValues)
  }, [currentStep])

  /**
   * @description sets the current user to the user from the location state or the user from the params
   * @returns {void}
   */
  useEffect(() => {
    if (user) {
      setCurrentUser(user)
    } else if (userIdFromParams) {
      const foundUser = exitingUsers.find(user => user.id === userIdFromParams)
      setCurrentUser({ ...foundUser, id: userIdFromParams })
    } else if (isAdmin) {
      // if it is an admin, then there's no user for the loan. you need to select a user first. hence go back
      history.goBack()
    }
  }, [user, userIdFromParams, exitingUsers, isAdmin])

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            user={currentUser}
            savedData={currentStepData}
            onNext={handleNext}
            disableStepThree={disableStepThree}
            clearCurrentStep={clearCurrentStep}
          />
        )
      case 2:
        return (
          <Step2
            user={currentUser}
            savedData={currentStepData}
            onNext={handleNext}
            onGoBack={handleGoBack}
            disableStepThree={disableStepThree}
            clearCurrentStep={clearCurrentStep}
          />
        )
      case 3:
        return (
          <Step3
            user={currentUser}
            savedData={currentStepData}
            onNext={handleNext}
            onGoBack={handleGoBack}
            disableStepThree={disableStepThree}
            clearCurrentStep={clearCurrentStep}
          />
        )
      case 4:
        return (
          <Step4
            user={currentUser}
            savedData={currentStepData}
            onNext={handleNext}
            disableStepThree={disableStepThree}
            onGoBack={handleGoBack}
          />
        )
      case 5:
        return <Step5 onNext={handleNext} user={currentUser} onGoBack={handleGoBack} />
      default:
        return null
    }
  }

  return (
    <DashboardLayout>
      <main className='w-full h-full flex-grow p-4 bg-gray-100'>
        <div className='md:ml-10 mt-4'>
          {/* <h3 className='font-bold text-2xl mb-6 '>{Application}</h3> */}
        </div>
        <div className='flex-grap'>{renderCurrentStep()}</div>
      </main>
    </DashboardLayout>
  )
}

export default NewApplyForLoan
