import React from 'react'
import {
  S_6_CONTACT_US,
  S_6_INFORMATIN,
  S_6_CONTACT_EMAIL,
  S_6_CONTACT_PHONE,
  S_6_CONTACT_LOCATION,
  S_6_CONTACT_TIME
} from './constants'
import Contact from './Contact'
import { SocialIcons } from '../../sharedComponents/social-icons'

const ContactUs = () => {
  return (
    <div className='w-full bg-no-repeat bg-img-3'>
      <div className='flex flex-wrap pt-16 pb-6 md:pl-32'>
        <div className='w-full mb-8'>
          <h1 className='font-sans font-bold text-white text-2xl md:text-4xl py-6 pb-2 px-6'>
            {S_6_CONTACT_US}
          </h1>
        </div>
        <div className='w-full md:w-3/5 md:pr-2'>
          <div className='w-full md:w-4/5'>
            <div className='rounded-xl bg-white p-4 mx-2'>
              <Contact />
            </div>
          </div>
        </div>
        <div className='w-full md:w-2/5 md:pl-2'>
          <div className='rounded-l-xl mt-20 bg-white py-6 md:pl-6 md:pr-24 mx-2 md:mx-0'>
            <h1 className='font-sans font-bold text-center text-2xl pt-4 pb-6 uppercase'>
              {S_6_INFORMATIN}
            </h1>
            <div className='pr-2 mb-8 font-sans text-black text-base px-6'>
              <p>
                <i className='pr-2 mb-8 fas fa-envelope text-xl text-gray-600' />{' '}
                {S_6_CONTACT_EMAIL}
              </p>
              <p>
                <i className='pr-2 mb-8 fas fa-phone-alt text-xl text-gray-600' />{' '}
                {S_6_CONTACT_PHONE}
              </p>
              <p>
                <i className='pr-2 mb-8 fas fa-map-marker-alt text-xl text-gray-600' />{' '}
                {S_6_CONTACT_LOCATION}
              </p>
              <p>
                <i className='pr-2 fas fa-clock text-xl text-gray-600' /> {S_6_CONTACT_TIME}
              </p>
            </div>
          </div>
        </div>
        <div className='w-full pr-6 mt-16'>
          <SocialIcons />
        </div>
      </div>
    </div>
  )
}

export default ContactUs
