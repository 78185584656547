import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { ApplicationTable, ApplicationSearch } from './components'
import ApplicationMenu from './components/ApplicationMenu'
import { useParams } from 'react-router'
import {
  ALL_APPLICATIONS,
  APPROVED_APPLICATIONS,
  DECLINE_APPLICATIONS,
  IN_REVIEW_APPLICATIONS
} from './constant'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'
import { Empty } from 'antd'
import Retry from '../../sharedComponents/retryReload/Retry'

const Applications = () => {
  const [columnType, setColumnType] = useState({ data: [], column: [] })
  const [input, setInput] = useState('')
  const [data, setDate] = useState('')

  const searchLoader = useSelector(({ loading }) => loading.effects.applications.searchApplication)
  const searchedData = useSelector(({ applications }) => applications.searchedApplication)
  const { loading, applications } = useSelector(({ applications, loading }) => ({
    applications,
    loading: loading.global
  }))

  const { slug } = useParams()

  const handleSearch = e => {
    e.preventDefault()
    setInput(e.target.value)
    dispatch.applications.searchApplication({
      amount: e.target.value.split(',').join(''),
      url: 'customer/search-loan'
    })
  }
  const handleDate = (date, dateString) => {
    const dateRange = {
      start: dateString[0],
      end: dateString[1]
    }
    setDate(dateString[1])
    dispatch.applications.searchApplicationByDate(dateRange)
  }

  async function fetchData(type, column, dispatchNewFetch) {
    // Based on type, get the particular table data
    if (applications[type] && !(applications[type]?.length > 0)) {
      const data = await dispatchNewFetch()
      setColumnType({
        data: data?.map((item, index) => ({
          ...item,
          key: index,
          id: index
        })),
        column
      })
    } else {
      // If data is still in memory, don't do a fetch again
      setColumnType({ data: applications[type], column })
    }
  }

  function getColumn(type) {
    switch (type) {
      case 'all-application':
        fetchData('allApplications', ALL_APPLICATIONS, dispatch.applications.allApplications)
        break
      case 'application-in-review':
        fetchData('pendingApplications', IN_REVIEW_APPLICATIONS, dispatch.applications.pendingApp)
        break
      case 'approved-application':
        fetchData(
          'approvedApplications',
          APPROVED_APPLICATIONS,
          dispatch.applications.approvedApplications
        )
        break
      case 'declined-application':
        fetchData(
          'declinedApplications',
          DECLINE_APPLICATIONS,
          dispatch.applications.declinedApplications
        )
        break
      default:
        fetchData('allApplications', ALL_APPLICATIONS, dispatch.applications.allApplications)
    }
  }
  useEffect(() => {
    getColumn(slug)
  }, [slug])

  return (
    <DashboardLayout>
      <div className='w-full flex-grow py-4 bg-white'>
        <ApplicationSearch
          data={columnType}
          value={input}
          onChange={handleSearch}
          handleDate={handleDate}
        />
        <ApplicationMenu type={slug} />
        {!applications.isServerError ? (
          <div className='mt-8 mb-16 px-4 overflow-x-auto z-40'>
            {loading || searchLoader ? (
              <h1 className='ml-8 font-bold text-center py-36 text-xl text-gray-500'>
                <i className='fa fa-spin fa-spinner mr-2' /> Loading...
              </h1>
            ) : !loading && columnType?.data?.length === 0 ? (
              <Empty />
            ) : (
              <ApplicationTable
                tableData={{
                  column:
                    input.length > 0 || data.length > 0 ? ALL_APPLICATIONS : columnType.column,
                  data: input.length > 0 || data.length > 0 ? searchedData : columnType.data,
                  type: slug,
                  loading
                }}
              />
            )}
          </div>
        ) : (
          <Retry />
        )}
      </div>
    </DashboardLayout>
  )
}

export default Applications
