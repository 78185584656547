/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from 'react'
const BUSINESS_NAME = 'Business Name '
const BUSINESS_EMAIL = 'Business Email '
const BUSINESS_PHONE_NO = 'Phone Number '
const NO_OF_EMPLOYEE = 'Number of Employees '
const INCORPORATION_DATE = 'Incorporation Date '
const BUSINESS_INDUSTRY = 'Business Industry'
const SELECT_BUSINESS_INDUSTRY = 'Select Business Industry'
const BUSINESS_LOCATION =
  'Location of the business (if more than one location, please specify the location in CAC, followed by all other locations) '
const SAVE_AND_NEXT = 'Save and Next'
const CAC_DOCUMENT = 'CAC Document(s) *'
const DRAG_FILES = 'Drag file here'
const BROWSE_FILE = 'Browse File'
const CAC_FORM_2 = 'CAC form 2 (Statement of share capital) *'
const MEMORANDUM_OF_ASSOC = 'Memorandum of Association'
const ARTICLE_OF_ASSOC = 'Articles of Association'
const PHARMACEUTICAL_LICENSE = 'Pharmaceutical License *'
const PHARMACEUTICAL_LICENSE_EXP = 'Pharmaceutical license expiration date'
const BUSINESS_LICENSE = 'Business License *'
const NO_OF_SIGNATORIES = 'Number of Signatories'
const SIX_MONTHS_BANK_STATMT = '6 Month Bank Statement (PDF or Excel) *'
const OR = 'Or'
const LOAN_AMOUNT = 'Loan amount'
const LOAN_DURATION = 'Loan Duration (# months)'
const LOAN_PURPOSE = 'Loan Purpose'
const SUCCESS_MESSAGE = 'Application Submitted Successfully'
const ERROR_MESSAGE = 'Something went wrong, Please refresh this page and try again!'
const SUBMIT_NOW = 'Submit Now'
const PHARMACEUTICAL_LICENSE_DATE = 'MM/YYYY'
const BIRTHDATE = 'Birthdate'
const EQUIPMENT_LOAN = 'Equipment Loan'
const MEDICATION_LOAN = 'Medication Loan'
const CAC_FORM_3 = 'CAC form 3 (change of registered address)'
const CAC_FORM_7 = 'CAC form 7(Appointment of directors) *'
const BACK = 'Back'
const EMAIL = 'Email'
const GENDER = 'Gender'
const HIGHEST_EDUCATION = 'Highest educational degree earned'
const NO_OF_KIDS = 'Number of Kids'
const MARITAL_STATUS = 'Marital Status'
const PHONE_NO = 'Phone number'
const LAST_NAME = 'Last name'
const FIRST_NAME = 'First name'
const HOME_ADDRESS = 'Home Address'
const PASSPORT_PICS = 'Passport Picture (File size: 30mb max.)'
const GOVERNMENT_ID = 'Government ID (File size: 30mb max.)'
const YEARS_OF_EXPERIENCE = 'Years of Experience'
const BANK_VERIFICATION_NO = 'Bank verification number'
const TITLE_AND_ROLE = 'Choose Title/Role'
const YEAR_AT_BIZ = 'Years at business *'
const RC_NUMBER = 'Business RC number *'
const BIZ_DESCRIPTION = 'Business Description'
const FOUNDED_DATE = 'Date when the business was founded *'
const SALES_REP = 'Select a sales Person below'
const SALES_PERSON = 'Sales Person'
const DISTRIBUTOR_AMOUNT = 'Amount for distributor'
const DISTRIBUTORS = 'Select distributor below'
const LOAN_TYPE = 'Loan type'
const STATE_OF_ORIGIN = 'State Of Origin'
const LOCAL_GOVT = 'Local Govt'
export {
  LOCAL_GOVT,
  SALES_REP,
  STATE_OF_ORIGIN,
  DISTRIBUTORS,
  BUSINESS_NAME,
  FOUNDED_DATE,
  YEAR_AT_BIZ,
  EQUIPMENT_LOAN,
  MEDICATION_LOAN,
  RC_NUMBER,
  BUSINESS_EMAIL,
  BIZ_DESCRIPTION,
  BUSINESS_PHONE_NO,
  NO_OF_EMPLOYEE,
  INCORPORATION_DATE,
  BUSINESS_INDUSTRY,
  BUSINESS_LOCATION,
  SAVE_AND_NEXT,
  CAC_DOCUMENT,
  CAC_FORM_7,
  CAC_FORM_3,
  DRAG_FILES,
  CAC_FORM_2,
  BROWSE_FILE,
  MEMORANDUM_OF_ASSOC,
  ARTICLE_OF_ASSOC,
  OR,
  PHARMACEUTICAL_LICENSE,
  PHARMACEUTICAL_LICENSE_EXP,
  BUSINESS_LICENSE,
  SIX_MONTHS_BANK_STATMT,
  NO_OF_SIGNATORIES,
  SELECT_BUSINESS_INDUSTRY,
  LOAN_AMOUNT,
  LOAN_PURPOSE,
  LOAN_DURATION,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  PHARMACEUTICAL_LICENSE_DATE,
  SUBMIT_NOW,
  DISTRIBUTOR_AMOUNT,
  BIRTHDATE,
  PHONE_NO,
  HOME_ADDRESS,
  TITLE_AND_ROLE,
  LAST_NAME,
  FIRST_NAME,
  GOVERNMENT_ID,
  PASSPORT_PICS,
  BANK_VERIFICATION_NO,
  YEARS_OF_EXPERIENCE,
  HIGHEST_EDUCATION,
  MARITAL_STATUS,
  SALES_PERSON,
  NO_OF_KIDS,
  EMAIL,
  GENDER,
  BACK,
  LOAN_TYPE
}

export const getColums = [
  {
    title: <span className='font-bold'>{FIRST_NAME}</span>,
    dataIndex: 'first_name',
    key: 'first_name',
    render: first_name => <span>{first_name}</span>
  },
  {
    title: <span className='font-bold'>{LAST_NAME}</span>,
    dataIndex: 'last_name',
    key: 'last_name',
    render: last_name => <span>{last_name}</span>
  },
  {
    title: <span className='font-bold'>{EMAIL}</span>,
    dataIndex: 'email',
    key: 'email',
    render: email => <span>{email}</span>
  },
  {
    title: <span className='font-bold'>Title</span>,
    dataIndex: 'title',
    key: 'title',
    render: title => <span>{title}</span>
  }
]

export const AOA = `Articles of association form a document that specifies the regulations for a company's operations and defines the company's purpose. The document lays out how tasks are to be accomplished within the organization, including the process for appointing directors and the handling of financial records. Articles of association often identify the manner in which a company will issue shares, pay dividends, audit financial records, and provide voting rights. This set of rules can be considered a user's manual for the company because it outlines the methodology for accomplishing the day-to-day tasks that must be completed.
While the content of the articles of association and the exact terms used vary from jurisdiction to jurisdiction, the document is quite similar throughout the world and generally contains provisions on the company name, the company's purpose, the share capital, the company's organization, and provisions regarding shareholder meetings`
export const PharmaceuticalLicense =
  'Pharmaceutical License means any state pharmacy board license or other similar license required to be maintained by the Credit Parties and their Restricted Subsidiaries to enable the sale, transfer or other distribution of pharmaceutical products of the Credit Parties and their Restricted Subsidiaries.'
export const businessLicense =
  'A business license is an official legal document or permit that allows an individual or company to conduct specific types of business activities within a particular jurisdiction. It is a government-issued authorization that grants the right to operate legally and is usually obtained from the local, state, or national government, depending on the type of business and its location.'
export const bankStatement =
  "A bank statement is a monthly summary of a bank account holder's financial transactions. It includes the account holder's name, number, and type of account. The statement details all deposits, withdrawals, checks, electronic transfers, and fees during the period. It shows the beginning and closing balances of the account. Interest earned on an interest-bearing account is also listed. Bank statements help account holders track their finances, reconcile transactions, and monitor their account balance. They serve as important records for budgeting, taxes, and auditing. Regularly reviewing statements is crucial to identify errors or unauthorized transactions."
export const MOA =
  "The Memorandum of Association (MOA) is a crucial legal document that plays a foundational role in the establishment and functioning of a company. When a group of individuals decides to form a company, they must define its fundamental principles, objectives, and scope of activities. The MOA serves this purpose by outlining the company's primary purpose, the business it intends to carry out, and the powers it can exercise."
export const cac7 =
  'This is to be filled by the directors, and all particulars of the directors must be filled.'
export const cac3 =
  'A director and secretary of the company will fill out the notice of situation or change of address form.'
export const cac2 =
  'This is filled by a director, and it includes share capital and return of allotment form.'
