import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dispatch } from '../../../redux/store'
import { DashboardLayout } from '../../../sharedComponents/dashboardLayout'
import AppTable from '../../../sharedComponents/table/AppTable'
import { ADMIN_DECLINED_APPLICATIONS } from '../../applications/constant'
import ApplicationSearch from './ApplicationSearch'
import { LOAN_APPLICATION } from './constants'
import useGetLoanStatus from './useGetLoanStatus'

const AdminDeclined = () => {
  const history = useHistory()

  const [input, setInput] = useState('')

  const loadingSearch = useSelector(({ loading }) => loading.effects.applications.searchApplication)

  // TODO: search to be refactored with new endpoint.
  /** @deprecated this search function will be removced */
  const handleSearch = e => {
    e.preventDefault()
    setInput(e.target.value)

    if (e.target.value === '') {
      // to be removed
      dispatch.applications.adminAppStatistics()
      return false
    }

    dispatch.applications.searchApplication({
      amount: e.target.value.split(',').join(''),
      url: 'admin/search-loan'
    })
  }

  const { declined: declinedLoanData, loading } = useGetLoanStatus('declined')

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {LOAN_APPLICATION}
          </h3>
        </div>
        <ApplicationSearch value={input} onChange={handleSearch} />
        <div className='mb-6 px-4 overflow-x-auto'>
          <AppTable
            columns={ADMIN_DECLINED_APPLICATIONS}
            tableData={declinedLoanData}
            loading={loading || loadingSearch}
          />
        </div>
      </main>
    </DashboardLayout>
  )
}

export default AdminDeclined
