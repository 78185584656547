import React from 'react'
import HeaderBackground from '../HeaderBackground'
import { HeaderHeading, HeaderText, Subscribe } from '../constants'

const Header = () => {
  return (
    <HeaderBackground>
      <div className='w-screen items-center justify-center flex flex-col gap-8'>
        <h1 className='text-2xl lg:text-6xl text-white font-manrope font-black text-center mt-8'>
          {HeaderHeading}
        </h1>
        <h3 className='text-lg lg:max-w-3xl lg:text-xl tracking-wider text-center self-center text-white font-manrope '>
          {HeaderText}
        </h3>
        <div className='flex justify-center gap-4 w-full'>
          <div className='lg:w-1/4'>
            <input
              className='py-3 w-full px-5 text-lg rounded-lg'
              placeholder='Enter email address'
              type='text'
            />
          </div>
          <button className='bg-primary rounded-lg py-3 text-white px-4 lg:px-8 text-xl font-bold border  border-blue-400'>
            {Subscribe}
          </button>
        </div>
      </div>
    </HeaderBackground>
  )
}

export default Header
