import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { allLoanStatus } from '../../../redux/models/constants'
import { dispatch } from '../../../redux/store'

/**
 * Hook that helps to get loans by status
 * @param {string} status the current loan status
 * @param {boolean} shouldUseStateData if true, it will use the state data, else it will fetch new data
 * @returns {{loading: Boolean; pending: Array; approved: Array; declined: Array; disbursed: Array}} the list of the current loan status
 */
function useGetLoanStatus(status, shouldUseStateData) {
  const currentStatus = allLoanStatus[status]
  const applications = useSelector(({ applications }) => applications)
  const loading = useSelector(({ loading }) => loading.effects.applications.adminGetLoanByStatus)
  const { userInfo } = useSelector(({ auth }) => auth.authUser)

  useEffect(() => {
    if (userInfo?.is_admin && applications[currentStatus].length <= 0) {
      dispatch.applications.adminGetLoanByStatus({ status, shouldUseCache: shouldUseStateData })
    }
  }, [dispatch, status, shouldUseStateData])

  return {
    [status]: applications[currentStatus]?.map((item, idx) => ({ ...item, key: idx })),
    loading
  }
}

export default useGetLoanStatus
