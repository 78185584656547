// import maximizingGrowth from '../../images/blog/Growth2.png'
// import Creditworthiness from '../../images/blog/Credit-Worthiness.png'
// import CityInspire from '../../images/blog/City-Inspire.png'
// import HealthcareChild from '../../images/blog/Healthcare-Child.png'
import blogindex0 from '../../images/blog/blogIndex0.png'
import blogindex1 from '../../images/blog/blogIndex1.png'
import blogindex2 from '../../images/blog/blogIndex2.png'
import blogindex3 from '../../images/blog/blogIndex3.png'
import blogindex4 from '../../images/blog/blogIndex4.png'
import blogindex5 from '../../images/blog/blogIndex5.png'
export const NewsLetterHeading = 'Sign up for weekly newsletter'
export const unsubscribe = 'Unsubscribe at any time'
export const Subscribe = 'Subscribe'
export const HeaderHeading = ' The latest News for You'
export const HeaderText =
  'Vittas helps hospitals and pharmacies with access to cheap and affordable loans which require no collateral to support the purchase of medication and medical equipment.'
export const ReadMore = 'Read More'
export const FooterWriteup =
  'Our healthcare financing solutions are tailored to meet the unique needs of your business. Apply now to secure the funding you need to run and expand your operations.'

export const blogs = [
  {
    title: 'Maximizing Growth: A Case Study of a Nigerian Hospital that Secured Financing',
    paragraphs: [
      'In Nigeria, the healthcare industry is a canvas upon which new strokes of progress are constantly painted. And yet, for many hospitals, the palette is limited by the lack of access to financing. According to a recent study by the Nigerian Ministry of Health, only a mere 15% of Nigerian hospitals have access to funding from commercial banks. In this post, we will take a closer look at one Nigerian hospital that, against the odds, secured financing and used it to flourish and improve patient care.',
      "The hospital in question is a medium-sized facility located in the outskirts of Lagos, much like the houses that line the streets of that city, it too was showing its age. The hospital was facing a plethora of challenges, including an aging infrastructure, limited staff and resources, and a lack of access to funding. The hospital's management team recognized that in order to continue providing high-quality care to patients, they needed to make significant investments in their facilities and equipment.",
      'The hospital formulated a plan after a thorough review of their options and decided to pursue a tailored loan specifically designed for Nigerian hospitals. The loan was configured to optimize the hospital’s success; it offered a number of benefits, including flexible repayment terms, competitive interest rates, and financing for working capital and inventory. With the loan, the hospital constructed a new wing, created better patient care services, was able to purchase new equipment and upgrade their facilities, which improved patient care and increased efficiency.',
      "The hospital also used the loan to expand their staff and serve more patients which improved their bottom line. According to the hospital's management team, the loan had a significant impact on their operations, allowing them to maximize growth and improve patient care. In fact, since securing the loan, the hospital has seen a 25% increase in patient volume and a 15% increase in revenue.",
      'This case study illustrates the importance of tailored financing solutions for Nigerian hospitals and how it can help to improve the quality of care and services provided to patients. With the right financing options and support, Nigerian hospitals can overcome financial obstacles and achieve their goals. Financing plays a crucial role in the survival and growth of healthcare facilities. By contrast the National Bureau of Statistics (NBS) found hospitals without access to funding reported a decline in patient volume by 30% and a decline in revenue by 20%. Access to funding is essential for hospitals to provide optimal care and survive in a challenging economic environment.'
    ],
    image: blogindex0,
    id: 0,
    date: 'Nov 2, 2022',
    publishedBy: 'Ifeoluwa Oyero',
    Brief:
      'In Nigeria, the healthcare industry is a canvas upon which new strokes of progress are constantly...'
  },
  {
    title: 'The Importance of Flexible Repayment Terms for Nigerian Healthcare Businesses',
    image: blogindex1,
    paragraphs: [
      'As healthcare businesses in Nigeria continue to face financial challenges, the importance of flexible repayment terms for loans has become increasingly apparent. Recent data shows that nearly 60% of Nigerian businesses struggle with cash flow management, making it difficult for them to meet loan repayment obligations. However, by offering flexible repayment options, fine-tuned for a specific business’ needs, lenders can help these businesses better manage their cash flow and budget, ultimately increasing the chances of loan repayment.',
      'One study composed by the Nigerian Small and Medium Enterprises Development Agency (SMEDAN) found that businesses with flexible repayment terms were able to weather unexpected financial disruptions, such as economic downturns or natural disasters, more effectively than those with inflexible terms. The study revealed that businesses with flexible repayment options were 40% less likely to default on loans during times of economic hardship. These businesses were built to better handle unexpected market conditions.',
      'Additionally, the Central Bank of Nigeria(CBN) reported that Nigerian hospitals and pharmacies that had flexible repayment options were more likely to invest in growth and expansion initiatives. The study found that these businesses were able to increase revenue by an average of 25% within the first year of securing financing with flexible repayment terms.',
      'It is clear that flexible repayment options can play a crucial role in helping Nigerian healthcare businesses secure financing and achieve long-term success. As such, it is important for lenders to consider offering these options to improve the chances of loan repayment and support the growth of the healthcare industry in Nigeria.'
    ],
    id: 1,
    date: 'Jan 4, 2023',
    publishedBy: 'Victor',
    Brief:
      'As healthcare businesses in Nigeria continue to face financial challenges, the importance of...'
  },
  {
    title: 'Why Nigerian Pharmacies Desperately Need Tailored Financial Support',
    image: blogindex2,
    paragraphs: [
      'In Nigeria, pharmacies are a vital part of the healthcare system. However, many of these pharmacies are struggling to expand and improve their operations due to a lack of access to credit. A study by the Central Bank of Nigeria found that only 10% of Nigerian Micro, Small and Medium Enterprises (MSMEs) have access to formal credit.',
      'This is where tailored or personalized financial support comes in. Tailored loan options can provide the necessary financial support for Nigerian pharmacies while also addressing the specific challenges they face. For example, tailored loans can offer flexible repayment terms and competitive interest rates that are tailored to the needs of Nigerian pharmacies. This can help pharmacies manage cash flow and budget more effectively, allowing them to invest in the supplies and equipment they need to improve patient care. A study by the World Bank found that firms with access to credit are more likely to grow and create jobs.',
      'Fitted or tailored loans can also help pharmacies grow and expand. With access to funding, pharmacies can increase their inventory, hire more staff, and make necessary renovations to their facilities. This can help them to serve more patients and improve their bottom line. A study by the Nigerian Association of Chambers of Commerce, Industry, Mines and Agriculture (NACCIMA) found that small and medium-sized businesses that receive financial support have a higher likelihood of survival and growth.',
      'In addition, tailored loans can also provide added benefits such as working capital loans and inventory financing that can help pharmacies manage their cash flow and inventory. As a result, pharmacies can purchase supplies in bulk and take advantage of volume discounts, which can lower their costs and increase their profits. A Nigerian Medical Association study revealed that many pharmacies in Nigeria struggle with cash flow and inventory management.',
      'Our takeaway: tailored financial support is a vital solution for Nigerian pharmacies. These loans can provide the necessary funding for pharmacies to improve their operations, serve more patients and grow their businesses. With loans developed specifically for this segment, Nigerian pharmacies can improve their chances of success and help to improve the quality of patient care! Better care has altered economic productivity in mature markets. This will have similar positive societal implications in Nigeria!'
    ],
    id: 2,
    date: 'Jan 20, 2023',
    publishedBy: 'James Ayoola',
    Brief:
      'In Nigeria, pharmacies are a vital part of the healthcare system. However, many of these ...'
  },
  {
    title: "5 Key Metrics to Prove Your Nigerian Pharmacy's Creditworthiness",
    image: blogindex3,
    paragraphs: [
      'When it comes to securing financing for your Nigerian pharmacy, creditworthiness is key. Lenders want to know that your business is financially stable and capable of repaying the loan. This will allow you to activate more customized loan solutions. But how do you demonstrate this to potential lenders?',
      "Here are 5 key metrics that can help prove your pharmacy's creditworthiness and increase your chances of getting approved for a loan:"
    ],
    id: 3,
    date: 'Jan 23, 2023',
    publishedBy: 'Emma Oyero',
    Brief:
      'Cash flow: A positive cash flow is a sign that your pharmacy is generating enough revenue to cover....',
    orderedList: [
      'Cash flow: A positive cash flow is a sign that your pharmacy is generating enough revenue to cover its expenses. This is important for lenders because it shows that your business is able to meet its financial obligations.',
      'Revenue: Lenders will want to see a steady increase in revenue over time, as this indicates that your business is growing and has a strong customer base.',
      'Profitability: A profitable pharmacy is more likely to be able to repay a loan. Lenders will look at your profit margins to see if your business is generating enough money to cover its costs and make a profit.',
      'Debt-to-income ratio: This metric compares the amount of debt a pharmacy has to its income. A lower debt-to-income ratio is better, as it shows that the pharmacy has more capacity to take on more debt.',
      "Credit score: Your pharmacy's credit score is another important metric that lenders will look at. A higher credit score indicates that your business has a good credit history and is less of a risk."
    ],
    footerNote:
      'By keeping these key metrics in mind and working to improve them, Nigerian pharmacies can increase their chances of getting approved for a loan and securing the financing they need to grow and thrive; this adapted approach will ultimately allow them to better serve their patients.'
  },
  {
    title: 'Why Nigerian Pharmacies Desperately Need Tailored Financial Support..',
    image: blogindex4,
    paragraphs: [
      'In Nigeria, pharmacies are a vital part of the healthcare system. However, many of these pharmacies are struggling to expand and improve their operations due to a lack of access to credit. A study by the Central Bank of Nigeria found that only 10% of Nigerian Micro, Small and Medium Enterprises (MSMEs) have access to formal credit.',
      'This is where tailored or personalized financial support comes in. Tailored loan options can provide the necessary financial support for Nigerian pharmacies while also addressing the specific challenges they face. For example, tailored loans can offer flexible repayment terms and competitive interest rates that are tailored to the needs of Nigerian pharmacies. This can help pharmacies manage cash flow and budget more effectively, allowing them to invest in the supplies and equipment they need to improve patient care. A study by the World Bank found that firms with access to credit are more likely to grow and create jobs.',
      'Fitted or tailored loans can also help pharmacies grow and expand. With access to funding, pharmacies can increase their inventory, hire more staff, and make necessary renovations to their facilities. This can help them to serve more patients and improve their bottom line. A study by the Nigerian Association of Chambers of Commerce, Industry, Mines and Agriculture (NACCIMA) found that small and medium-sized businesses that receive financial support have a higher likelihood of survival and growth.',
      'In addition, tailored loans can also provide added benefits such as working capital loans and inventory financing that can help pharmacies manage their cash flow and inventory. As a result, pharmacies can purchase supplies in bulk and take advantage of volume discounts, which can lower their costs and increase their profits. A Nigerian Medical Association study revealed that many pharmacies in Nigeria struggle with cash flow and inventory management.',
      'Our takeaway: tailored financial support is a vital solution for Nigerian pharmacies. These loans can provide the necessary funding for pharmacies to improve their operations, serve more patients and grow their businesses. With loans developed specifically for this segment, Nigerian pharmacies can improve their chances of success and help to improve the quality of patient care! Better care has altered economic productivity in mature markets. This will have similar positive societal implications in Nigeria!'
    ],
    id: 4,
    date: 'Jan 20, 2023',
    publishedBy: 'Nancy Joy',
    Brief:
      'In Nigeria, pharmacies are a vital part of the healthcare system. However, many of these...'
  },
  {
    title: 'Why Nigerian Pharmacies Desperately Need Tailored Financial Support...',
    image: blogindex5,
    paragraphs: [
      'In Nigeria, pharmacies are a vital part of the healthcare system. However, many of these pharmacies are struggling to expand and improve their operations due to a lack of access to credit. A study by the Central Bank of Nigeria found that only 10% of Nigerian Micro, Small and Medium Enterprises (MSMEs) have access to formal credit.',
      'This is where tailored or personalized financial support comes in. Tailored loan options can provide the necessary financial support for Nigerian pharmacies while also addressing the specific challenges they face. For example, tailored loans can offer flexible repayment terms and competitive interest rates that are tailored to the needs of Nigerian pharmacies. This can help pharmacies manage cash flow and budget more effectively, allowing them to invest in the supplies and equipment they need to improve patient care. A study by the World Bank found that firms with access to credit are more likely to grow and create jobs.',
      'Fitted or tailored loans can also help pharmacies grow and expand. With access to funding, pharmacies can increase their inventory, hire more staff, and make necessary renovations to their facilities. This can help them to serve more patients and improve their bottom line. A study by the Nigerian Association of Chambers of Commerce, Industry, Mines and Agriculture (NACCIMA) found that small and medium-sized businesses that receive financial support have a higher likelihood of survival and growth.',
      'In addition, tailored loans can also provide added benefits such as working capital loans and inventory financing that can help pharmacies manage their cash flow and inventory. As a result, pharmacies can purchase supplies in bulk and take advantage of volume discounts, which can lower their costs and increase their profits. A Nigerian Medical Association study revealed that many pharmacies in Nigeria struggle with cash flow and inventory management.',
      'Our takeaway: tailored financial support is a vital solution for Nigerian pharmacies. These loans can provide the necessary funding for pharmacies to improve their operations, serve more patients and grow their businesses. With loans developed specifically for this segment, Nigerian pharmacies can improve their chances of success and help to improve the quality of patient care! Better care has altered economic productivity in mature markets. This will have similar positive societal implications in Nigeria!'
    ],
    id: 5,
    date: 'Jan 20, 2023',
    publishedBy: 'Paul James',
    Brief:
      'In Nigeria, pharmacies are a vital part of the healthcare system. However, many of these...'
  }
]
