import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Statistics } from '../../sharedComponents/statistics'
import { superData } from '../../utils/statistics'
import { NEW_APPLICATION } from './constants'
import { BarChart, LineChart } from '../../sharedComponents/chartjs'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { useSelector } from 'react-redux'
import { dispatch } from '../../redux/store'
import BankMono from '../../sharedComponents/mono/MonoSDKIntegration'

const CustomerDashboard = () => {
  const { lineChart, barChart } = useSelector(({ loans }) => loans)
  // const { bankStatementFromMono } = useSelector(({ auth: { authUser } }) => ({
  //   bankStatementFromMono: authUser.userInfo.data?.bank_statement
  // }))
  const [date, setState] = React.useState({
    start: '',
    end: ''
  })

  useEffect(async () => {
    await dispatch.featureToggle.getAllFeatureToggles()
    const d = new Date()
    const prevYear = new Date(d.setDate(d.getDate() - 360))
    setState({
      start: prevYear.toISOString().substring(0, 10),
      end: new Date().toISOString().substring(0, 10)
    })
  }, [])

  useEffect(() => {
    if (date.start.length) {
      if (!lineChart || !barChart) {
        dispatch.loans.getCustomerBarChart(date)
        dispatch.loans.getCustomerLineChart(date)
      }
    }
  }, [date])

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <p className='ml-1 flex justify-end'>
          <Link to='/form-preview' className='app-btn'>
            {NEW_APPLICATION}
          </Link>
          <BankMono shouldShowbutton />
        </p>
        <Statistics data={superData} />
        <div className='flex flex-wrap mb-16'>
          <div className='w-full md:w-3/5 mt-16 px-2 md:px-4'>
            <LineChart newDATA={lineChart} />
          </div>
          <div className='w-full md:w-2/5 mt-16 px-2 md:px-4'>
            <BarChart newDATA={barChart} />
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default CustomerDashboard
