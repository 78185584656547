import React from 'react'
import PropTypes from 'prop-types'

function HeroSection({ executeScroll }) {
  return (
    <section className='bg-woman-doctor h-screen md:h-80vh flex flex-col gap-6 justify-center'>
      <p className='text-white text-3xl md:text-12 font-bold text-center md:leading-10'>
        Welcome to Vittas International Biomedical Equipment Financing
      </p>
      <div className='flex justify-center'>
        <p className='text-center text-lightGray md:max-w-1000px md:text-xl'>
          Are you a clinician or medical practice in Lagos looking to enhance your capabilities with
          cutting-edge biomedical equipment? Look no further! Vittas International offers biomedical
          loans ranging from $25,000 to $35,000, exclusively designed to empower clinicians and
          clinics with the latest tools for improved diagnostics and therapies
        </p>
      </div>
      <div className='flex justify-center'>
        <button
          className='bg-newBgBlue w-130px h-48px  text-white rounded-12px'
          onClick={executeScroll}
        >
          Apply Now
        </button>
      </div>
    </section>
  )
}

HeroSection.propTypes = {
  executeScroll: PropTypes.func
}

export default HeroSection
