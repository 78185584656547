/* eslint-disable camelcase */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../../redux/store'
import 'antd/dist/antd.css'
import { Modal, Spin, Table } from 'antd'
import { userColumn } from './constants'
import { expandedRowRender } from './ExpandedRowRender'
import PropTypes from 'prop-types'
import LoanTable from './TransactionHistory'
import ListBankStatements from './ListBankStatements'
import { capitalizedWord } from '../../../utils/helpers'
import ViewBankStatement from './ViewBankStatement'

function CustomerLoans({ exitingUsers }) {
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const [bankStatements, setIsBankStatement] = useState({
    visible: false,
    data: []
  })
  const [currentBankStatement, setCurrentBankStatement] = useState({
    visible: false,
    data: []
  })
  const isLoading = useSelector(({ loading }) => loading.effects.users.getAllusers)
  const userLoans = useSelector(select.loans.getUserLoansById)
  const [modalVisibilityAndCurrLoanRecord, setModalVisibleWithRecord] = useState({
    visible: false,
    records: []
  })

  const isgetUserLoansLoading = useSelector(({ loading }) => loading.effects.loans.getUserLoans)

  const showBankStatement = statement => {
    setIsBankStatement({ data: statement, visible: true })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const data = exitingUsers
    .map(
      ({
        first_name,
        last_name,
        id,
        is_account_confirmed,
        updated_at,
        created_at,
        phone_number,
        email,
        image,
        documents,
        business_name,
        ...more
      }) => ({
        name: capitalizedWord(`${first_name + ' ' + last_name}`),
        id,
        key: id,
        is_account_confirmed: `${is_account_confirmed ? 'yes' : 'No'}`,
        updated_at: updated_at.split('.')[0],
        created_at: created_at.split('.')[0],
        phone_number,
        bank_statement: documents?.filter(document => document.type === 'bank_statement'),
        email,
        image,
        business_name: capitalizedWord(business_name) || 'N/A',
        ...more
      })
    )
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

  useEffect(() => {
    dispatch.users.getAllusers({ showAll: true })
  }, [])

  return isLoading ? (
    <Spin size='large' />
  ) : (
    <>
      <Table
        rowKey={record => record.key}
        className='components-table'
        pagination={{
          defaultPageSize: '20',
          showSizeChanger: true,
          showQuickJumper: true
        }}
        columns={userColumn(showBankStatement, {
          searchInput,
          handleSearch,
          handleReset,
          setSearchText,
          searchedColumn,
          searchText,
          setSearchedColumn
        })}
        expandable={{
          expandedRowRender: () =>
            expandedRowRender(userLoans, isgetUserLoansLoading, setModalVisibleWithRecord),
          onExpand: (expanded, record) => {
            if (expanded) {
              dispatch.loans.getUserLoans({ record })
            }
          }
        }}
        dataSource={data}
      />
      <LoanTable
        setModalVisibleWithRecord={setModalVisibleWithRecord}
        modalVisibilityAndCurrLoanRecord={modalVisibilityAndCurrLoanRecord}
      />
      <>
        <Modal
          title='List of bank statements'
          onCancel={() => setIsBankStatement({ data: [], visible: false })}
          width='60%'
          open={bankStatements.visible}
          footer={false}
        >
          <ListBankStatements
            setCurrentBankStatement={setCurrentBankStatement}
            statements={bankStatements.data}
          />
        </Modal>

        <ViewBankStatement
          data={currentBankStatement?.data}
          visible={currentBankStatement?.visible}
          setCurrentBankStatement={setCurrentBankStatement}
          total={currentBankStatement?.data?.length}
        />
      </>
    </>
  )
}

export default CustomerLoans

CustomerLoans.propTypes = {
  exitingUsers: PropTypes.array
}
