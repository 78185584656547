/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { cleanType } from '../../utils/cleanTypes'
import DocDetails from './DocDetails'
import { select } from '../../redux/store'

export const getColums = setDetails => [
  {
    title: <span className='font-bold'>Title</span>,
    dataIndex: 'title',
    key: 'title',
    render: (title, records) => (
      <span>
        {records.type === 'bank_statement' && records.file_url === null
          ? 'Mono Bank Statment'
          : title}
      </span>
    )
  },
  {
    title: <span className='font-bold'>Type</span>,
    dataIndex: 'type',
    key: 'type',
    render: type => <span>{cleanType(type)}</span>
  },
  {
    title: <span className='font-bold'>Date Uploaded</span>,
    dataIndex: 'created_at',
    key: 'created_at',
    render: created_at => <span>{created_at}</span>
  },

  {
    title: <span className='font-bold capitalize'>Actions</span>,
    key: 'action',
    render: records => (
      <button
        onClick={() =>
          setDetails({
            ...records,
            isMono: records.type === 'bank_statement' && records.file_url === null
          })
        }
        className='px-6 py-2 border text-primary hover:text-white rounded-lg hover:bg-primary text-sm'
      >
        View/Delete
      </button>
    )
  }
]

const ListDocs = ({ customerId }) => {
  const [selectedData, setCurrentDataSelected] = useState({})
  const [modalVisibility, setModalVisible] = useState(false)
  const [customer, setCustomerDocs] = useState()
  const [pagination, setPagination] = useState({
    pageSize: 20,
    showSizeChanger: true,
    current: 1
  })
  const { data, isAdmin } = useSelector(({ auth: { authUser } }) => ({
    data: authUser.userInfo?.data?.documents,
    isAdmin: authUser.userInfo?.is_admin
  }))
  const allCustomers = useSelector(select.users.getUsersNotAdmin)

  const setDetails = record => {
    setCurrentDataSelected(record)
    setModalVisible(true)
  }

  useEffect(() => {
    if (isAdmin) {
      setCustomerDocs(allCustomers.find(item => item.id === customerId))
    }
  }, [isAdmin, allCustomers])

  // TODO: Add a header with clear customer name and business name
  return (
    <div className='max-w-[800px] flex flex-col flex-wrap'>
      <DocDetails
        visible={modalVisibility}
        data={selectedData}
        handleClose={() => setModalVisible(false)}
      />

      <Table
        title={re => (
          <div className='flex justify-between'>
            {isAdmin ? (
              <h2 className='text-right text-lg font-bold'>
                Business name: {customer?.business_name}
              </h2>
            ) : (
              ''
            )}
            <h2 className='text-right text-lg font-bold'>Total Douments: {re.length}</h2>
          </div>
        )}
        columns={getColums(setDetails)}
        onChange={value => setPagination(value)}
        pagination={pagination}
        dataSource={isAdmin ? customer?.documents : data}
      />
    </div>
  )
}

export default ListDocs

ListDocs.propTypes = {
  customerId: PropTypes.string
}
