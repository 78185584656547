import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const RefreshToken = () => {
  const state = useSelector(state => state)
  const [modelsErrors, setModelsError] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    for (const modelName in state) {
      if (Object.hasOwnProperty.call(state, modelName)) {
        const model = state[modelName]
        if (model.isServerError && model.isServerError?.data?.msg === 'Token has expired') {
          setModelsError(model?.isServerError)
          break
        }
      }
    }
  }, [state])

  return (
    modelsErrors && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
          height: '100vh',
          width: '100vw',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      >
        <div className='bg-white w-2/3 lg:w-1/6 mx-auto mt-20 rounded-md p-5'>
          <h1 className='text-lg'>An error occured or your token expired.</h1>
          <p className='text-base'>Please try reloading 😞</p>
          <Button className='text-white text-lg mt-10' onClick={() => dispatch.auth.refreshToken()}>
            Reload
          </Button>
        </div>
      </div>
    )
  )
}

export default RefreshToken
