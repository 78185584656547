import React from 'react'
import PropTypes from 'prop-types'

const DummyInput = ({ label, value, className, ...others }) => {
  return (
    <div>
      {label && <label className='text-gray-500 leading-8'>{label}</label>}
      <p className={className} {...others}>
        {value}
      </p>
    </div>
  )
}

export default DummyInput

DummyInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string
}
