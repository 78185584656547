import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import * as txtType from './constants'
import { notification } from 'antd'
import { Feedback } from '../../services'
import DropDown from '../../sharedComponents/drop-down/DropDown'

const Templates = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [openUserMenu, setUserMenu] = useState(false)

  const [template, setTemplate] = useState({
    title: '',
    subject: '',
    message: ''
  })

  const handleSubmit = async e => {
    e.preventDefault()
    if (!template.title) {
      notification.error({
        message: 'Error Occurred',
        description: 'Template Title is required!'
      })
      return
    }
    if (!template.subject) {
      notification.error({
        message: 'Error Occurred',
        description: 'Subject is required!'
      })
      return
    }
    if (!template.message) {
      notification.error({
        message: 'Error Occurred',
        description: 'Message is required!'
      })
      return
    }
    try {
      setLoading(true)
      const { data } = await Feedback.sendFeedbacks(template)

      if (data.success) {
        notification.success({
          message: 'Thanks',
          description: data.message
        })
        setTemplate({
          type: '',
          description: ''
        })
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Something went wrong'
      })
      setLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <main className='w-full flex-grow p-4 bg-white'>
        <div className='ml-1 flex justify-start mt-4'>
          <h3 onClick={() => history.goBack()} className='font-bold text-lg cursor-pointer'>
            <i className='fas fa-arrow-left mr-3' />
            {txtType.CREATE_NEW_TEMPLATE}
          </h3>
        </div>
        <div className='mt-8 mb-16 px-4 overflow-x-auto'>
          <div className='flex flex-wrap'>
            <div className='mt-4 w-full md:w-4/6 px-3'>
              <div className='mb-6'>
                <div className='w-full mb-6'>
                  <span className='text-sm text-gray-900 font-semibold'>
                    {txtType.TEMPLATE_TITLE}
                  </span>
                  <input
                    className='form-control'
                    name='title'
                    type='text'
                    onChange={({ target }) =>
                      setTemplate({ ...template, [target.name]: target.value })
                    }
                    value={template.title}
                  />
                </div>
                <div className='w-full mb-6'>
                  <span className='text-sm text-gray-900 font-semibold'>{txtType.SUBJECT}</span>
                  <input
                    className='form-control'
                    name='subject'
                    type='text'
                    onChange={({ target }) =>
                      setTemplate({ ...template, [target.name]: target.value })
                    }
                    value={template.subject}
                  />
                </div>
              </div>
              <span className='text-sm text-gray-800 font-bold'>{txtType.MESSAGE}</span>
              <textarea
                className='w-full h-32 border-gray-300 resize-none border-2 rounded-lg text-gray-900 mt-1 p-3 focus:outline-none focus:shadow-outline'
                name='message'
                value={template.message}
                onChange={({ target }) => setTemplate({ ...template, [target.name]: target.value })}
                placeholder='Type here...'
              />
              <div className='d-flex'>
                <button
                  onClick={() => setTemplate({ ...template, feedbackType: '', description: '' })}
                  className='border-gray-500 mr-3 mt-6 text-base w-40 py-3 border rounded-lg hover:bg-gray-400 text-gray-700 hover:text-gray-50;'
                >
                  {txtType.CANCEL}
                </button>
                <button
                  onClick={handleSubmit}
                  className='bg-primary text-base mt-6 w-40 py-3 border rounded-lg hover:bg-primary-200 text-white hover:text-gray-50;'
                >
                  {loading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                  {txtType.CREATE_TEMPLATE}
                </button>
              </div>
            </div>
            <div className='mt-20 md:mt-4 w-full md:w-2/6 px-3'>
              <h2 className='pt-4 mb-5 font-bold text-xl'>{txtType.EXISTING_TEMPLATE} </h2>
              <div className='w-full md:w-4/5 my-6'>
                <input
                  className='form-control'
                  placeholder='Search Template'
                  type='text'
                  onChange={() => {}}
                  value=''
                />
              </div>
              <div>
                <table width='100%'>
                  <tr style={{ lineHeight: '50px' }}>
                    <td>Promo Message</td>
                    <td>
                      <i className='fas fa-ellipsis-v light-100 font-bold cursor-pointer' />
                    </td>
                  </tr>
                  <tr style={{ lineHeight: '50px' }}>
                    <td>New Month Message</td>
                    <td>
                      <i className='fas fa-ellipsis-v light-100 font-bold cursor-pointer' />
                    </td>
                  </tr>
                  <tr style={{ lineHeight: '50px' }}>
                    <td>Christmas Message</td>
                    <td>
                      {' '}
                      <DropDown openUserMenu={openUserMenu} setUserMenu={setUserMenu}>
                        <Link
                          to='../application/2'
                          style={{ outline: 0, lineHeight: '25px' }}
                          className='w-full text-left nav-sm-link'
                        >
                          <i className='far fa-eye mr-2' /> {txtType.VIEW_TEMPLATE}
                        </Link>
                        <Link
                          to='../application/2'
                          style={{ outline: 0, lineHeight: '25px' }}
                          className='w-full text-left nav-sm-link'
                        >
                          <i className='fas fa-pencil-alt mr-2' /> {txtType.EDIT_TEMPLATE}
                        </Link>
                        <Link
                          to='../application/2'
                          style={{ outline: 0, lineHeight: '25px' }}
                          className='w-full text-left nav-sm-link text-red-800'
                        >
                          <i className='far fa-trash-alt mr-2' /> {txtType.DELETE_TEMPLATE}
                        </Link>
                      </DropDown>{' '}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DashboardLayout>
  )
}

export default Templates
