import { Popconfirm } from 'antd'
import DropDown from '../../../sharedComponents/drop-down/DropDown'
import { formatCurrency } from '../../../utils/helpers'
import { useHistory } from 'react-router-dom'
import LoanDetails from './components/LoanDetails'
import CustomerDocs from './components/CustomerDocs'
import BankStatement from './components/BankStatement'
import DTIAnalysis from './components/DTIAnalysis'

const ActionColumn = () => {
  const history = useHistory()

  return (
    <DropDown>
      <span
        className='w-full text-left nav-sm-link text-blue-600 cursor-pointer'
        onClick={() => history.push('/approved-details-page?id=some-random-uuid-xxxxxxxxx')}
      >
        Show Details
      </span>

      <span className='w-full text-left nav-sm-link text-blue-600 cursor-pointer'>Disburse</span>

      <Popconfirm
        title='Are you sure you want to delete this loan?'
        // onConfirm={() => handleDelete(record)} // Uncomment and implement delete functionality
        okText='Yes'
        cancelText='No'
      >
        <span className='w-full text-left nav-sm-link text-red-800 hover:text-red-900 cursor-pointer'>
          Delete
        </span>
      </Popconfirm>
    </DropDown>
  )
}

export const ApprovedTableColumns = [
  {
    title: <span className='font-bold'>Business Name</span>,
    dataIndex: 'business_name',
    key: 'business_name'
  },
  {
    title: <span className='font-bold'>Loan Amount Requested</span>,
    dataIndex: 'loan_amount_requested',
    key: 'loan_amount_requested',
    render: amount => <span>₦{formatCurrency(amount)}</span>
  },
  {
    title: <span className='font-bold'>Loan Term</span>,
    dataIndex: 'loan_term',
    key: 'loan_term'
  },
  {
    title: <span className='font-bold'>Interest Rate</span>,
    dataIndex: 'interest_rate',
    key: 'interest_rate',
    render: rate => <span>{rate}%</span>
  },
  {
    title: <span className='font-bold'>Loan Type</span>,
    dataIndex: 'loan_type',
    key: 'loan_type'
  },
  {
    title: <span className='font-bold'>Loan Purpose</span>,
    dataIndex: 'loan_purpose',
    key: 'loan_purpose'
  },
  {
    title: <span className='font-bold'>Action</span>,
    key: 'action',
    render: (text, record) => <ActionColumn record={record} />
  }
]

export const ApprovedTabItems = [
  {
    label: 'Loan Details',
    key: '1',
    children: <LoanDetails />
  },
  {
    label: 'Customer Documents',
    key: '2',
    children: <CustomerDocs />
  },
  {
    label: 'Bank Statements',
    key: '3',
    children: <BankStatement />
  },
  {
    label: 'DTI Analysis',
    key: '4',
    children: <DTIAnalysis />
  }
]
