import { Modal, notification } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../redux/store'
import { resetPasswordValidator } from '../../../utils/inputValidator'
import { RESET_LABEL_PWD, PWD_BTN } from './constants'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

const AdminResetPwdForCustomer = userRecords => {
  const [modal, setModal] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  const pwdLoading = useSelector(({ loading }) => loading.effects.auth.resetPasswordForCustomer)
  const [newPwd, setNewPwd] = useState('')

  const generatePwd = () => {
    const randomPwd = Math.random().toString(26).slice(8) + Math.random().toString(26).slice(8)
    setNewPwd(randomPwd)
  }

  const handleChange = ({ target }) => {
    setNewPwd(target.value)
  }

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().min(7, 'Too Short!').max(16, 'Too Long!').required('Required')
  })
  const validator = resetPasswordValidator(newPwd)

  const handleSubmit = async () => {
    if (validator.isValid) {
      const details = { user_id: userRecords.userRecords.id, new_password: newPwd }
      await dispatch.auth.resetPasswordForCustomer(details)
      setNewPwd('')
    } else {
      notification.error({
        message: 'An error occured',
        description: validator.error
      })
    }
  }

  return (
    <main>
      <div className='px-4'>
        <button onClick={() => setModal(true)}>Reset Password</button>
      </div>

      <Modal
        open={modal}
        title='Reset password'
        width='50%'
        onCancel={() => setModal(false)}
        footer={false}
      >
        <label className='text-sm text-black'>{RESET_LABEL_PWD}</label>
        <div className='relative mt-5'>
          <Formik
            initialValues={{ newPwd }}
            validationSchema={resetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isValid }) => (
              <Form onChange={handleChange}>
                <Field
                  name='password'
                  className='text-base block w-full border border-gray-200 rounded-lg p-3 px-4 focus:outline-none focus:bg-white'
                  type={showPwd ? 'text' : 'password'}
                  value={newPwd}
                />
                {errors.password && touched.password ? <div>{errors.password}</div> : null}
                <button type='button' className='mt-3' onClick={generatePwd}>
                  Generate password
                </button>
                <button
                  onClick={() => setShowPwd(!showPwd)}
                  type='button'
                  className='text-gray-500 text-xl font-bold absolute top-1 right-2 my-2 mr-2 focus:outline-none'
                >
                  {showPwd ? <i className='fas fa-eye-slash' /> : <i className='fas fa-eye' />}
                </button>
                <button
                  type='submit'
                  className=' mt-5 py-3 font-bold bg-primary text-white rounded-md w-full focus:outline-none focus:shadow-outline'
                >
                  {pwdLoading ? <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' /> : ''}{' '}
                  {PWD_BTN}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </main>
  )
}

export default AdminResetPwdForCustomer
