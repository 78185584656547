const CREATE_NEW_TEMPLATE = 'Create New Template'
const TEMPLATE_TITLE = 'Template Title'
const SUBJECT = 'Subject'
const MESSAGE = 'Message'
const CANCEL = 'Cancel'
const CREATE_TEMPLATE = 'Create Template'
const EXISTING_TEMPLATE = 'Existing Template'
const VIEW_TEMPLATE = 'View Template'
const EDIT_TEMPLATE = 'Edit Template'
const DELETE_TEMPLATE = 'Delete Template'

export {
  CREATE_NEW_TEMPLATE,
  TEMPLATE_TITLE,
  SUBJECT,
  MESSAGE,
  CANCEL,
  CREATE_TEMPLATE,
  EXISTING_TEMPLATE,
  VIEW_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE
}
